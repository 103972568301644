import {
  ADD_FINANCIAL_TO_ASSET,
  GET_ASSET_FINANCIALS,
  UPDATE_ASSET_FINANCIAL,
  DELETE_ASSET_FINANCIAL
} from "../actionTypes";

export const addFinancialToAsset = (data, dispatch) => {
  dispatch({
    type: ADD_FINANCIAL_TO_ASSET,
    payload: data
  });
};

export const getAssetFinancials = (data, dispatch) => {
  dispatch({
    type: GET_ASSET_FINANCIALS,
    payload: data
  });
};

export const updateAssetFinancial = (data, dispatch) => {
  dispatch({
    type: UPDATE_ASSET_FINANCIAL,
    payload: data
  });
};

export const deleteAssetFinancial = (data, dispatch) => {
  dispatch({
    type: DELETE_ASSET_FINANCIAL,
    payload: data
  });
};
