import React from "react";

import PerfectScrollbar from "react-perfect-scrollbar";
import { Nav, NavItem, NavLink } from "reactstrap";
import clsx from "clsx";

const ChatSidebar = props => {
  const renderConverstationName = conversation => {
    if (conversation.users.length === 0) return null;
    if (conversation.users.length === 1) return conversation.users[0].fullName;
    else
      return `${conversation.users[0].fullName} and ${conversation.users
        .length - 1} others`;
  };
  const { conversations, activeConversation } = props;
  if (!conversations) return <div />;

  return (
    <div
      className={clsx(
        "app-inner-content-layout--sidebar pos-r order-2 order-lg-3 bg-white app-inner-content-layout--sidebar__sm",
        { "layout-sidebar-open": props.isInnerSidebarOpen }
      )}
    >
      <div className="text-uppercase font-size-sm text-primary font-weight-bold my-3 px-3">
        Conversations
      </div>
      <PerfectScrollbar className="scroll-area-xl">
        <Nav pills className=" nav-neutral-first flex-column">
          {conversations.map(conversation => {
            return (
              <NavItem key={conversation._id}>
                <NavLink
                  href="#/"
                  onClick={e => props.onClick(conversation, "", e)}
                  active={
                    activeConversation &&
                    activeConversation._id === conversation._id
                  }
                >
                  <div className="align-box-row">
                    <div className="avatar-icon-wrapper avatar-icon-sm">
                      <div className="avatar-icon rounded-circle">
                        {conversation.users.length > 0 && (
                          <img alt="..." src={conversation.users[0].picture} />
                        )}
                      </div>
                    </div>
                    <div className="pl-2">
                      <span className="d-block text-black font-size-sm font-weight-bold">
                        {renderConverstationName(conversation)}
                      </span>
                    </div>
                  </div>
                </NavLink>
              </NavItem>
            );
          })}
        </Nav>
      </PerfectScrollbar>
    </div>
  );
};

export default ChatSidebar;
