import { put, call, takeEvery, takeLatest } from "redux-saga/effects";
import { Opportunities } from "../api/agent";
import showToast from "../helpers/toastNotification";

import {
  GET_OPPORTUNITY_DETAILS,
  GET_OPPORTUNITY_DETAILS_SUCCESS,
  GET_OPPORTUNITY_DETAILS_FAILURE,
  GET_PARTNER_DETAILS,
  GET_PARTNER_DETAILS_SUCCESS,
  GET_PARTNER_DETAILS_FAILURE,
  GET_NEIGHBORHOOD_HIGHLIGHTS,
  GET_NEIGHBORHOOD_HIGHLIGHTS_SUCCESS,
  GET_NEIGHBORHOOD_HIGHLIGHTS_FAILURE,
  CREATE_OPPORTUNITY,
  CREATE_OPPORTUNITY_SUCCESS,
  CREATE_OPPORTUNITY_FAILURE,
  GET_OPPORTUNITY_CONVERSATION,
  GET_OPPORTUNITY_CONVERSATION_SUCCESS,
  GET_OPPORTUNITY_CONVERSATION_FAILURE
} from "../actions/actionTypes";

// GET opportunity details saga
function* getOpportunityDetails(action) {
  try {
    const payload = yield call(
      Opportunities.getOpportunityData,
      action.payload.slug
    );
    yield put({ type: GET_OPPORTUNITY_DETAILS_SUCCESS, payload });
  } catch (error) {
    const err = error.response.body;
    yield put({ type: GET_OPPORTUNITY_DETAILS_FAILURE, err });
  }
}
export function* watchGetOpportunityDetails() {
  yield takeLatest(GET_OPPORTUNITY_DETAILS, getOpportunityDetails);
}

// GET partner details saga
function* getPartnerDetails(action) {
  try {
    const payload = yield call(
      Opportunities.getPartnerData,
      action.payload.slug
    );
    yield put({ type: GET_PARTNER_DETAILS_SUCCESS, payload });
  } catch (error) {
    const err = error.response.body;
    yield put({ type: GET_PARTNER_DETAILS_FAILURE, err });
  }
}
export function* watchGetPartnerDetails() {
  yield takeLatest(GET_PARTNER_DETAILS, getPartnerDetails);
}

function* getNeighborhoodHighlights(action) {
  try {
    const payload = yield call(
      Opportunities.getNeighborhoodHighlights,
      action.payload.zip
    );
    yield put({ type: GET_NEIGHBORHOOD_HIGHLIGHTS_SUCCESS, payload });
  } catch (error) {
    const err = error.response.body;
    yield put({ type: GET_NEIGHBORHOOD_HIGHLIGHTS_FAILURE, err });
  }
}
export function* watchGetNeighborhoodHighlights() {
  yield takeLatest(GET_NEIGHBORHOOD_HIGHLIGHTS, getNeighborhoodHighlights);
}

function* createOpportunity(action) {
  try {
    const { medias } = action.payload;
    if (medias.length > 0) {
      const payload = yield call(Opportunities.uploadFile, medias);
      action.payload.medias = payload;
    }
    const payload = yield call(Opportunities.createOpportunity, action.payload);
    showToast("s", "Opportunity added successfully");
    yield put({ type: CREATE_OPPORTUNITY_SUCCESS, payload });
  } catch (error) {
    const err = error.response.body;
    showToast("e", "Unable to create opportunity");
    yield put({ type: CREATE_OPPORTUNITY_FAILURE, err });
  }
}

export function* watchCreateOpportunity() {
  yield takeEvery(CREATE_OPPORTUNITY, createOpportunity);
}

function* getOpportunityConversation(action) {
  try {
    const payload = yield call(Opportunities.getConversation, action.payload);
    yield put({ type: GET_OPPORTUNITY_CONVERSATION_SUCCESS, payload });
  } catch (error) {
    const err = error.response.body;
    yield put({ type: GET_OPPORTUNITY_CONVERSATION_FAILURE, err });
  }
}

export function* watchGetOpportunityConversation() {
  yield takeEvery(GET_OPPORTUNITY_CONVERSATION, getOpportunityConversation);
}
