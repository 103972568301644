import React, { useState } from "react";
import { getIn } from "formik";
import {
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  Nav,
  NavItem
} from "reactstrap";
import { NavLink as NavLinkStrap } from "reactstrap";

const DropdownField = props => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

  const onChange = option => {
    const {
      field,
      form: { setFieldValue }
    } = props;
    setFieldValue(field.name, option && option.value);
    if (props.onChange) props.onChange(option.value);
  };

  const {
    field,
    form: { /*touched, errors, setFieldValue,*/ values },
    className
  } = props;
  let { options } = props;

  // const touch = getIn(touched, field.name);
  // const error = getIn(errors, field.name);
  const value = getIn(values, field.name);
  let defaultOption;
  if (options && options.length > 0) {
    defaultOption = options.find(option => {
      return option.value === value;
    });
  }

  return (
    <InputGroupButtonDropdown
      addonType="append"
      isOpen={dropdownOpen}
      toggle={toggleDropdown}
      className={className || "px-4"}
    >
      <DropdownToggle color="outline-primary" className="btn-pill" caret>
        {defaultOption.label}
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-xl dropdown-menu">
        <Nav className="nav-pills flex-column">
          {options.map((option, i) => {
            return (
              <NavItem key={i}>
                <NavLinkStrap
                  onClick={() => onChange(option)}
                  active={option.value === value}
                >
                  <span> {option.label}</span>
                </NavLinkStrap>
              </NavItem>
            );
          })}
        </Nav>
      </DropdownMenu>
    </InputGroupButtonDropdown>
  );
};

export default DropdownField;
