import React from "react";
import Image from "react-graceful-image";
import PropTypes from "prop-types";

const ScaledImage = ({ src, alt, className, width, height, scaled }) => {
  let imageUrl = src;
  if (scaled && (width || height)) {
    const pattern = /media.bettercapital.us\/file/g;

    let sizes = [];
    if (width) sizes.push("w_" + width);
    if (height) sizes.push("h_" + height);
    imageUrl = imageUrl && imageUrl.replace(pattern, "$&/" + sizes.join(","));
  }
  return (
    <Image src={imageUrl} className={className} width={width} height={height} />
  );
};

ScaledImage.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  scaled: PropTypes.bool,
  alt: PropTypes.string
};

ScaledImage.defaultProps = {
  scaled: false,
  alt: ""
};

export default ScaledImage;
