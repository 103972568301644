import React, { Component } from "react";
import { CardBody, Button } from "reactstrap";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import holderImage from "../../../img/property_placeholder.jpg";
import { connect } from "react-redux";
import Slider from "react-slick";

import { getAssetMedias } from "../../../actions/asset/assetAction";
import LoadingIndicator from "../../../shared/components/LoadingIndicator";

import AddMedia from "./AddMedia";
import DeleteMedia from "./DeleteMedia";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

function SliderArrowNext(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <FontAwesomeIcon icon={faChevronRight} />
    </div>
  );
}

function SliderArrowPrev(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <FontAwesomeIcon icon={faChevronLeft} />
    </div>
  );
}

class AssetMedias extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddModal: false,
      loadingMedia: false,
      showAsModal: false,
      showDeleteModal: false,
      fileIdToDelete: null,
      files: {}
    };
  }

  componentDidMount() {
    const {
      getAssetMedias,
      asset: { slug }
    } = this.props;

    this.setState({ loadingMedia: true, error: null }, () => {
      getAssetMedias(slug);
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { assetMedia } = nextProps;
    if (assetMedia) {
      if (assetMedia.isAddMediasSuccess) {
        return {
          loadingMedia: false,
          files: assetMedia.files
        };
      } else if (assetMedia.isDeleteMediaSuccess) {
        return {
          loadingMedia: false,
          files: assetMedia.files
        };
      } else if (assetMedia.isGetMediasSuccess) {
        return {
          loadingMedia: false,
          files: assetMedia.files
        };
      }
    }
  }

  componentWillUnmount() {
    const { files } = this.state;
    // Make sure to revoke the data uris to avoid memory leaks
    Object.keys(files).map((fileId, idx) => {
      return URL.revokeObjectURL(files[fileId]);
    });
  }

  toggleAddModal = () => {
    this.setState(prevState => ({
      showAddModal: !prevState.showAddModal
    }));
  };

  toggleDeleteModal = fileId => {
    this.setState(prevState => ({
      showDeleteModal: !prevState.showDeleteModal,
      fileIdToDelete: fileId
    }));
  };

  render() {
    const { loadingMedia, files } = this.state;
    const {
      asset: { slug }
    } = this.props;
    if (loadingMedia) {
      return <LoadingIndicator loading={loadingMedia} />;
    }
    return (
      <div className="px-4">
        <div className="float-right">
          <AddMedia
            toggleModal={this.toggleAddModal}
            modalOpen={this.state.showAddModal}
            assetSlug={slug}
          />
        </div>
        <Slider
          className="rounded-lg overflow-hidden mb-5 mb-lg-0 slider-dots-light media-slider"
          slidesToShow={1}
          slidesToScroll={1}
          nextArrow={<SliderArrowNext />}
          prevArrow={<SliderArrowPrev />}
        >
          {_.isEmpty(files) ? (
            <div className="mb-2">
              <CardBody style={{ minHeight: "400px" }}>
                <div className="slider-item">
                  <img src={holderImage} alt="" />
                </div>
              </CardBody>
            </div>
          ) : (
            Object.keys(files).map((fileId, idx) => {
              return (
                <div key={`asset-media-${idx}`} className="mb-2">
                  <CardBody style={{ minHeight: "400px" }}>
                    <div className="slider-item">
                      <Button
                        tag="a"
                        href="#/"
                        onClick={e => this.toggleDeleteModal(fileId)}
                        color="danger"
                        className="ribbon-vertical ribbon-vertical--danger ribbon-vertical--right"
                      >
                        <span>
                          <FontAwesomeIcon
                            icon={faTrash}
                            className="font-size-lg"
                          />
                        </span>
                      </Button>
                      <img
                        key={fileId}
                        src={files[fileId]}
                        alt=""
                        width={"760px"}
                        height={"400px"}
                      />
                    </div>
                  </CardBody>
                </div>
              );
            })
          )}
        </Slider>
        <DeleteMedia
          toggleModal={this.toggleDeleteModal}
          modalOpen={this.state.showDeleteModal}
          assetSlug={slug}
          fileId={this.state.fileIdToDelete}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  assetMedia: state.assetMedia
});

const mapDispatchToProps = dispatch => {
  return {
    getAssetMedias: slug => getAssetMedias(slug, dispatch)
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssetMedias);
