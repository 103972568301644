import { store } from "../store";
import { logoutMalformed } from "../actions/auth/authAction";
import { LOGOUT_REQUEST } from "../actions/auth/authActionTypes";

export default () => {
  logoutMalformed();
  store.dispatch({
    type: LOGOUT_REQUEST
  });
};
