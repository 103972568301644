import React from "react";

import { Row, Col, Card, Button } from "reactstrap";
import AreaChartWidget from "../../shared/components/AreaChartWidget";
import LoadingIndicator from "../../shared/components/LoadingIndicator";
import { numberFormatter } from "../../helpers";
import { Link } from "react-router-dom";

import Chart from "react-apexcharts";
import hero2 from "../../assets/images/hero-bg/hero-2.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

class TransactionSnapshot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      snapshotType: "month",
      currentFilter: {},
      loadingCashflow: false,
      filterChange: true,
      overallCashflow: []
    };
  }

  getChartOptions = labels => {
    return {
      stroke: {
        curve: "smooth",
        width: [0, 4]
      },
      chart: {
        toolbar: {
          show: false
        }
      },
      colors: ["rgba(60, 68, 177, 0.3)", "#3c44b1"],
      fill: {
        opacity: 1
      },
      labels: labels,
      xaxis: {
        type: "string"
      },
      yaxis: {
        labels: {
          formatter: function(value) {
            return numberFormatter(value);
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      grid: {
        strokeDashArray: "5",
        borderColor: "rgba(125, 138, 156, 0.3)"
      },
      legend: {
        show: false
      }
    };
  };
  render() {
    const { loadingCashflow, overallCashflow } = this.props;

    if (loadingCashflow || !overallCashflow) return <LoadingIndicator />;
    const cashflowKeys = Object.keys(overallCashflow);
    const incomeData = cashflowKeys.reduce(
      (result, key) => {
        result["labels"].push(key);
        result["data"].push(overallCashflow[key].income);
        return result;
      },
      { labels: [], data: [] }
    );

    const expenseData = cashflowKeys.reduce(
      (result, key) => {
        result["labels"].push(key);
        result["data"].push(overallCashflow[key].expense);
        return result;
      },
      { labels: [], data: [] }
    );

    const incomeExpenseData = [
      {
        name: "Income",
        type: "column",
        data: incomeData["data"]
      },
      {
        name: "Expense",
        type: "line",
        data: expenseData["data"]
      }
    ];

    return (
      <>
        <Card className="card-box my-4">
          <Row className="no-gutters">
            <Col xl="5">
              <div className="hero-wrapper bg-composed-wrapper bg-sunrise-purple h-100 rounded br-xl-right-0">
                <div className="flex-grow-1 w-100 d-flex align-items-center">
                  <div
                    className="bg-composed-wrapper--image rounded br-xl-right-0"
                    style={{ backgroundImage: "url(" + hero2 + ")" }}
                  />
                  <div className="bg-composed-wrapper--bg bg-second opacity-5 rounded br-xl-right-0" />
                  <div className="bg-composed-wrapper--content p-5">
                    <div className="align-self-center px-4">
                      <div className="py-3">
                        <AreaChartWidget
                          title="Income"
                          labels={incomeData["labels"]}
                          data={incomeData["data"]}
                          type="income"
                        />
                      </div>
                      <div className="py-3">
                        <AreaChartWidget
                          title="Expense"
                          labels={expenseData["labels"]}
                          data={expenseData["data"]}
                          type="expense"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xl="7">
              <div className="p-4 d-flex align-items-center justify-content-between">
                <div className="font-weight-bold font-size-md m-0">
                  Transaction Snapshot
                </div>
              </div>

              <div className="divider" />
              <div>
                <Chart
                  options={this.getChartOptions(incomeData["labels"])}
                  series={incomeExpenseData}
                  type="line"
                  height={365}
                />
              </div>

              <div className="divider bg-dark opacity-1" />
              <div className="p-4 bg-secondary text-center">
                <Button
                  to="/transactions"
                  color="primary"
                  tag={Link}
                  className="hover-scale-sm px-5"
                >
                  <span className="btn-wrapper--label">View Transactions</span>
                  <span className="btn-wrapper--icon">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </span>
                </Button>
              </div>
            </Col>
          </Row>
        </Card>
      </>
    );
  }
}

export default TransactionSnapshot;

//
// const mapStateToProps = state => ({
//   overallCashflow: state.transactions.overallCashflow
// });
//
// const mapDispatchToProps = dispatch => ({
//   getOverallCashflow: data => getOverallCashflow(data, dispatch)
// });
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(TransactionSnapshot);
