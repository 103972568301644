import React, { PureComponent } from "react";

class customSelect extends PureComponent {
  render() {
    const {
      field,
      form: { setFieldValue },
      disabled,
      placeholder
    } = this.props;

    const options = this.props.options.map(({ value, label }) => (
      <option value={value} key={value}>
        {label}
      </option>
    ));

    options.unshift(
      <option defaultValue key={0}>
        {placeholder}
      </option>
    );
    return (
      <select
        value={this.props.value}
        onChange={({ target }) => setFieldValue(field.name, target.value)}
        className="form-control "
        style={{ height: "47px" }}
        disabled={disabled}
      >
        {options}
      </select>
    );
  }
}

export default customSelect;
