import React, { Component, Fragment } from "react";
import { Formik, Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  UncontrolledTooltip
} from "reactstrap";

import ToggleSwitch from "../../shared/components/form/ToggleSwitch";
import MaskedField from "../../shared/components/form/MaskedField";

export default class DealAssumptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      success: null,
      modalOpen: false
    };
  }
  resetForm = null;

  toggleModal = () => {
    this.setState({ modalOpen: !this.state.modalOpen });
  };

  onSubmit = values => {
    this.setState({ modalOpen: false }, () => {
      if (this.props.updateAssumptions) {
        this.props.updateAssumptions(values);
      }
    });
  };

  getValidationSchema = assumptions => {
    assumptions.map(assumption => {
      let rObj = {};
      rObj[assumption.label] = Yup.string("").required(
        assumption.name + " is required"
      );
      return rObj;
    });
  };
  render() {
    const { loading } = this.state;
    const { assumedValues, assumptions } = this.props;
    const validationSchema = this.getValidationSchema(assumptions);
    return (
      <Formik
        enableReinitialize
        initialValues={assumedValues}
        validationSchema={validationSchema}
        onSubmit={this.onSubmit}
      >
        {props => {
          const { handleSubmit } = props;
          return (
            <Fragment>
              <Button
                color="neutral-first"
                className="btn-pill p-0 d-40 btn-icon btn-animated-icon"
                id="SendMessageTooltip2"
                onClick={this.toggleModal}
              >
                <span>
                  <FontAwesomeIcon icon={faCog} className="font-size-sm" />
                </span>
              </Button>
              <UncontrolledTooltip target="SendMessageTooltip2">
                Update assumptions
              </UncontrolledTooltip>

              <Modal
                isOpen={this.state.modalOpen}
                className={"modal-dialog modal-dialog-centered"}
                contentClassName={"newexpensemodal"}
              >
                <ModalHeader toggle={this.toggleModal}>
                  Deal Assumptions
                </ModalHeader>
                <form onSubmit={handleSubmit}>
                  <ModalBody>
                    {assumptions &&
                      assumptions.map((assumption, idx) => {
                        return (
                          <div
                            className="form-group inputblock"
                            key={`assumption-key-${idx}`}
                          >
                            <label htmlFor="exampleInputEmail1">
                              {assumption.name}
                            </label>
                            {assumption.type === "toggle" ? (
                              <Field
                                name={assumption.label}
                                component={ToggleSwitch}
                              />
                            ) : (
                              <Field
                                name={assumption.label}
                                component={MaskedField}
                                addonSize={"lg"}
                                maskType={assumption.type}
                                addonType={assumption.addonType}
                                addonText={assumption.addonText}
                              />
                            )}
                          </div>
                        );
                      })}
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="primary"
                      className="btn savebtns btn-primary px-5  d-inline-block mx-2"
                      type="submit"
                      disabled={loading}
                    >
                      Update
                    </Button>
                  </ModalFooter>
                </form>
              </Modal>
            </Fragment>
          );
        }}
      </Formik>
    );
  }
}
