import { put, call, takeLatest } from 'redux-saga/effects';
import { Deal } from '../api/agent';

import {
  ADD_DEAL,
  ADD_DEAL_SUCCESS,
  ADD_DEAL_FAILURE,

  GET_DEALS,
  GET_DEALS_SUCCESS,
  GET_DEALS_FAILURE,
  GET_DEAL_LISTING_SUCCESS,
  GET_DEAL_LISTING_FAILURE,
  GET_DEAL_LISTING,
  TRANSFER_ASSET,
  TRANSFER_ASSET_SUCCESS,
  TRANSFER_ASSET_FAILURE,
} from '../actions/actionTypes';


function* addDeal(action) {
  try {
    const payload = yield call(Deal.addDeal, action.payload);
    yield put({ type: ADD_DEAL_SUCCESS, payload });
    yield put({ type:'UPDATE_DEAL_RECORD', payload });

  } catch (error) {
    const err = error.response.body;
    yield put({ type: ADD_DEAL_FAILURE, err });
  }
}
export function* watchAddDeal() {
  yield takeLatest(ADD_DEAL, addDeal)
}

function* getDeals(action) {
  try {
    const payload = yield call(Deal.getDeals, action.payload);
    yield put({ type: GET_DEALS_SUCCESS, payload });

  } catch (error) {
    const err = error.response.body;
    yield put({ type: GET_DEALS_FAILURE, err });
  }
}
export function* watchGetDeals() {
  yield takeLatest(GET_DEALS, getDeals)
}


function* getMyDeals(action) {
  try {
    const payload = yield call(Deal.getMyDeals, action.payload);
    yield put({ type: GET_DEAL_LISTING_SUCCESS, payload });

  } catch (error) {
    const err = error.response.body;
    yield put({ type: GET_DEAL_LISTING_FAILURE, err });
  }
}

export function* watchGetUserDeals() {
  yield takeLatest(GET_DEAL_LISTING, getMyDeals)
}


function* transferAsset(action) {
  try {
    const payload = yield call(Deal.transferAsset, action.payload);
    yield put({ type: TRANSFER_ASSET_SUCCESS, payload });
    yield put({ type: "UPDATE_TRANSFER_RECORD", payload });

    
  } catch (error) {
    const err = error.response.body;
    yield put({ type: TRANSFER_ASSET_FAILURE, err });
  }
}

export function* watchTransferAsset() {
  yield takeLatest(TRANSFER_ASSET, transferAsset)
}

