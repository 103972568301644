import {
  SAVE_TRANSACTIONS,
  SAVE_TRANSACTION,
  GET_TRANSACTIONS_FROM_BANK,
  GET_ALL_TRANSACTIONS,
  GET_TRANSACTIONS,
  UPDATE_TRANSACTION,
  DELETE_TRANSACTION,
  DOWNLOAD_SAMPLE_CSV,
  MARK_ALL_PAID,
  REMOVE_ALL_DUPLICATES,
  DOWNLOAD_PAST_SAMPLE_XLSX,
  DELETE_MULTI_TRANSACTION
} from "../actionTypes";

export const saveTransactions = (data, dispatch) => {
  dispatch({
    type: SAVE_TRANSACTIONS,
    payload: data
  });
};

export const saveTransaction = (data, dispatch) => {
  dispatch({
    type: SAVE_TRANSACTION,
    payload: data
  });
};

export const getTransactionsFromBank = (slug, dispatch) => {
  dispatch({
    type: GET_TRANSACTIONS_FROM_BANK,
    payload: slug
  });
};

export const getAllTransactions = (data, dispatch) => {
  dispatch({
    type: GET_ALL_TRANSACTIONS,
    payload: data
  });
};

export const getTransactions = (slug, dispatch) => {
  dispatch({
    type: GET_TRANSACTIONS,
    payload: slug
  });
};

export const updateTransaction = (data, dispatch) => {
  dispatch({
    type: UPDATE_TRANSACTION,
    payload: data
  });
};

export const deleteTransaction = (data, dispatch) => {
  dispatch({
    type: DELETE_TRANSACTION,
    payload: data
  });
};
export const deleteMultiTransaction = (data, dispatch) => {
  dispatch({
    type: DELETE_MULTI_TRANSACTION,
    payload: data
  });
};

export const downloadSampleCSV = (data, dispatch) => {
  dispatch({
    type: DOWNLOAD_SAMPLE_CSV,
    payload: data
  });
};

export const markAllPaid = (data, dispatch) => {
  dispatch({
    type: MARK_ALL_PAID,
    payload: data
  });
};
export const removeAllDuplicates = (data, dispatch) => {
  dispatch({
    type: REMOVE_ALL_DUPLICATES,
    payload: data
  });
};

export const downloadPastSampleXLSX = (data, dispatch) => {
  dispatch({
    type: DOWNLOAD_PAST_SAMPLE_XLSX,
    payload: data
  });
};
