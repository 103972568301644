import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as Yup from "yup";

import {
  Button,
  Col,
  Row,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  FormGroup,
  Label
} from "reactstrap";
import classNames from "classnames";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import Dropzone from "react-dropzone";
import { Field, withFormik } from "formik";
import CreatableSelect from "react-select/creatable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Upload, Check, X } from "react-feather";

import FormField from "../../../shared/components/form/FormField";
import MaskedField from "../../../shared/components/form/MaskedField";
import FinancialInputField from "../../../shared/components/form/FinancialInputField";
import PreviewFile from "../../../shared/components/DropZone/PreviewFile";
// import ToggleSwitch from "../../../shared/components/form/ToggleSwitch";
import customSelect from "../../../shared/components/form/customSelect";
import { isFetching, isSuccess } from "../../../reducers/reducerUtils";
// import ZillowTOS from "../../../shared/components/ZillowTOS";
// import EntitySelect from "../../../shared/components/form/EntitySelect";
import { sortAlphaNumeric } from "../../../helpers";
import showToast from "../../../helpers/toastNotification";

import { lookupAsset, lookupParcel } from "../../../actions/asset/assetAction";
import { createOpportunity } from "../../../actions/opportunity";

import SectionHeader from "../../../shared/components/SectionHeader";

const defaultAsset = {
  title: "",
  entity: "",
  user_role: "",
  parcel_id: "",
  address: "",
  beds: 0,
  baths: 0,
  stories: 0,
  units: "",
  co_ordinates: [],
  imageUrl: "",
  investment_type: "",
  management_platform: "",
  self_managed: true,
  county: "",
  lotSizeSquareFeet: 0,
  areaSquareFeet: "",
  yearBuilt: "",
  asset_type: "",
  purchase_date: "",
  purchase_price: "",
  down_payment: "",
  market_value: "",
  market_rent: "",
  property_tax: { amount: "0", frequency: "Yearly", isPercent: false },
  insurance: { amount: "0", frequency: "Yearly", isPercent: false },
  hoa_fee: { amount: "0", frequency: "Monthly", isPercent: false },
  management_fee: { amount: "0", frequency: "Monthly", isPercent: false },
  rent: { amount: "", frequency: "Monthly", isPercent: false },
  rents: [],
  repair_estimate: 0,
  after_repair_value: "",
  sale_price: "",
  occupany_rate: 0,
  vacancy_rate: 0,
  neighborhood_rating: "",
  cap_rate: 0,
  description: "",
  imageFiles: [],
  street: "",
  city: "",
  state: "",
  zip: ""
};

const OpportunityCreateForm = ({
  parcelLookup,
  assetLookup,
  getFieldMeta,
  setFieldValue,
  handleChange,
  errors,
  lookupAsset,
  lookupParcel,
  handleSubmit,
  values
}) => {
  const [lookingUpAsset, setLookingUpAsset] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [assetMetadata, setAssetMetadata] = useState(null);
  const [parcelInfo, setParcelInfo] = useState(null);
  const [units, setUnits] = useState([]);
  const handleSelect = async address => {
    setFieldValue(`address`, address);
    try {
      const results = await geocodeByAddress(address);
      const { address_components } = results[0];
      address_components.forEach(component => {
        if (component.types.includes("route"))
          setFieldValue(`street`, component.long_name);
        if (component.types.includes("locality"))
          setFieldValue(`city`, component.long_name);
        if (component.types.includes("administrative_area_level_1"))
          setFieldValue(`state`, component.short_name);
        if (component.types.includes("postal_code"))
          setFieldValue(`zip`, component.long_name);
      });
      const latLng = await getLatLng(results[0]);
      setFieldValue("co_ordinates", [latLng.lat, latLng.lng]);
    } catch (error) {
      console.log(error);
    }
    setLookingUpAsset(true);
    setIsLoading(true);
    lookupAsset(address);
  };

  const onChangeUnit = option => {
    if (assetMetadata) {
      let selectedAsset = assetMetadata[option.value];
      setSelectedAsset(selectedAsset);
    }
  };

  useEffect(
    () => {
      if (lookingUpAsset && !isFetching(assetLookup)) {
        if (isSuccess(assetLookup)) {
          let assets = assetLookup.data.data;
          if (assets.length === 0) {
            setLookingUpAsset(false);
            setAssetMetadata(assets);
            setSelectedAsset({
              parcelID: "",
              beds: "",
              baths: "",
              stories: "",
              units: "",
              imageUrl:
                "https://media.bettercapital.us/file/bettercapital/common/streetview.jpeg",
              self_managed: true,
              metadata: {
                parcelID: "",
                beds: "",
                baths: "",
                stories: "",
                units: "",
                imageUrl:
                  "https://media.bettercapital.us/file/bettercapital/common/streetview.jpeg"
              }
            });
          } else {
            setLookingUpAsset(false);
            setAssetMetadata(assets);
            setSelectedAsset(assets.length === 1 ? assets[0] : null);
          }
        }
      }
      if (isLoading && !isFetching(parcelLookup)) {
        if (isSuccess(parcelLookup)) {
          setIsLoading(false);
          setParcelInfo(parcelLookup.data);
        }
      }
    },
    [assetLookup, parcelLookup]
  );

  useEffect(
    () => {
      if (selectedAsset) {
        setFieldValue(`beds`, selectedAsset.beds || 0);
        setFieldValue(`stories`, selectedAsset.stories || 0);
        setFieldValue(`units`, selectedAsset.units || 1);
        setFieldValue(`baths`, selectedAsset.baths || 0);
        setFieldValue(`imageUrl`, selectedAsset.imageUrl);
        setFieldValue(`parcel_id`, selectedAsset.parcelID);
        setFieldValue(`areaSquareFeet`, selectedAsset.areaSquareFeet || "");
        setFieldValue(`asset_type`, "Single_Family");
        setFieldValue(
          `lotSizeSquareFeet`,
          selectedAsset.lotSizeSquareFeet || 0
        );
        setFieldValue(`yearBuilt`, selectedAsset.yearBuilt || 0);
        setFieldValue(`apn`, selectedAsset.apn || "");
        setFieldValue(`county`, selectedAsset.county || "");
        if (selectedAsset.co_ordinates) {
          setFieldValue(`co_ordinates`, selectedAsset.co_ordinates);
        }

        if (selectedAsset.parcelID) {
          setIsLoading(true);
          lookupParcel(selectedAsset.parcelID);
        }
      }
    },
    [selectedAsset]
  );

  useEffect(
    () => {
      if (parcelInfo) {
        setFieldValue(`purchase_date`, parcelInfo.purchase_date || "");
        setFieldValue(`purchase_price`, parcelInfo.purchase_price || "");
        setFieldValue(`down_payment`, parcelInfo.down_payment || "");
        setFieldValue(`market_value`, parcelInfo.market_value || "");
        setFieldValue(`market_rent`, parcelInfo.market_rent || 0);
        setFieldValue(`parcelInfo`, parcelInfo);
      }
    },
    [parcelInfo]
  );

  useEffect(
    () => {
      if (assetMetadata) {
        let units = assetMetadata
          .map((asset, idx) => {
            if (asset.unitType && asset.unit) {
              return {
                label: asset.unitType + " " + asset.unit,
                value: idx
              };
            }
            return null;
          })
          .filter(Boolean)
          .sort((a, b) => sortAlphaNumeric(a.label, b.label));
        setUnits(units);
      }
    },
    [assetMetadata]
  );

  return (
    <main className="p-5">
      <SectionHeader />
      <Row>
        <Col md={12} className="text-left h2-title">
          <h2>Add a Property</h2>
        </Col>
      </Row>
      <Row className="row">
        <Col md={12} className="m-auto">
          <form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Deal Type</Label>
                  <Field
                    name="asset_type"
                    placeholder="Deal Type"
                    component={customSelect}
                    value={getFieldMeta(`asset_type`).value}
                    options={[
                      { value: "Single_Family", label: "Single Family" },
                      { value: "Duplex", label: "Duplex" },
                      { value: "Condo", label: "Condo" },
                      { value: "Townhome", label: "Townhome" },
                      { value: "Triplex", label: "Triplex" },
                      { value: "Fourplex", label: "Fourplex" },
                      { value: "Multi_Family", label: "Multi_Family" },
                      { value: "Land", label: "Land" },
                      { value: "Commercial", label: "Commercial" },
                      { value: "Other", label: "Other" }
                    ]}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Deal Title</Label>
                  <Field
                    name="title"
                    component={FormField}
                    type={"text"}
                    placeholder="Title"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Address</Label>
                  <PlacesAutocomplete
                    value={getFieldMeta(`address`).value || ""}
                    onSelect={handleSelect}
                    onChange={handleChange(`address`)}
                    searchOptions={{
                      componentRestrictions: { country: "us" }
                    }}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps
                    }) => (
                      <>
                        <InputGroup className="input-group-seamless">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <FontAwesomeIcon
                                icon={faSearch}
                                className="mx-auto"
                              />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            {...getInputProps({
                              placeholder: "Enter your property address",
                              className: "form-control"
                            })}
                            type="search"
                          />
                          {errors["summary"] && errors["summary"].address ? (
                            <p className={"text-danger mt-2"}>
                              {errors["summary"].address}
                            </p>
                          ) : null}
                        </InputGroup>
                        {suggestions.length > 0 && (
                          <div className="autocomplete-suggestion-container ">
                            {suggestions.map(suggestion => {
                              const className = classNames(
                                "autocomplete__suggestion-item",
                                {
                                  "autocomplete__suggestion-item--active":
                                    suggestion.active
                                }
                              );

                              return (
                                <div
                                  key={suggestion.id}
                                  {...getSuggestionItemProps(suggestion, {
                                    className
                                  })}
                                >
                                  <strong>
                                    {suggestion.formattedSuggestion.mainText}
                                  </strong>{" "}
                                  <small>
                                    {
                                      suggestion.formattedSuggestion
                                        .secondaryText
                                    }
                                  </small>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </>
                    )}
                  </PlacesAutocomplete>
                </FormGroup>
              </Col>
              <Col md={6}>
                {assetMetadata && assetMetadata.length > 1 && (
                  <FormGroup>
                    <Label>Select Unit</Label>
                    <CreatableSelect
                      name="unit"
                      placeholder="Select your unit"
                      options={units}
                      theme={theme => ({
                        ...theme,
                        borderRadius: "0.29rem",
                        borderWidth: 1,
                        colors: {
                          ...theme.colors,
                          primary25: "rgba(60,68,177,0.15)",
                          primary50: "rgba(60,68,177,0.15)",
                          primary: "#3c44b1"
                        }
                      })}
                      onChange={onChangeUnit}
                    />
                  </FormGroup>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                {/* <div className="form-group">
									<textarea rows="5" cols="135" className="description">
										Deal Description
									</textarea>
								</div> */}
                <Field
                  component="textarea"
                  placeholder="Deal Description"
                  name="description"
                  rows="5"
                  cols="135"
                />
              </Col>
            </Row>
            <hr />
            <Row>
              <Col lg={12}>
                <div className="text-left h2-title">
                  <h2>Property Details</h2>
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={4}>
                <FormGroup>
                  <Label>Year Build</Label>
                  <Field
                    name="yearBuilt"
                    component={FormField}
                    type={"number"}
                    placeholder="Built in"
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label>Area</Label>
                  <Field
                    name="areaSquareFeet"
                    component={FormField}
                    type={"number"}
                    placeholder="Plot size"
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label>Lot size</Label>
                  <Field
                    name="lotSizeSquareFeet"
                    component={FormField}
                    type={"number"}
                    placeholder="Plot size"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <FormGroup>
                  <Label>Beds</Label>
                  <Field
                    name="beds"
                    component={FormField}
                    type={"number"}
                    placeholder="beds"
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Baths</Label>
                  <Field
                    name="baths"
                    component={FormField}
                    type={"number"}
                    placeholder="baths"
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Stories</Label>
                  <Field
                    name="stories"
                    component={FormField}
                    type={"number"}
                    placeholder="stories"
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Number of units</Label>
                  <Field
                    name="units"
                    component={FormField}
                    type={"number"}
                    placeholder="No. of units"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Sale Price</Label>
                  <Field
                    name="sale_price"
                    component={FormField}
                    type={"number"}
                    placeholder="Sale price"
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Repair Estimate</Label>
                  <Field
                    name="repair_estimate"
                    component={FormField}
                    type={"number"}
                    placeholder="Repair estimate"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>After Repair value</Label>
                  <Field
                    name="after_repair_value"
                    component={FormField}
                    type={"number"}
                    placeholder="After repair value"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Occupency Rate</Label>
                  <Field
                    name="occupany_rate"
                    component={FormField}
                    type={"number"}
                    placeholder="Occupancy rate"
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Vacancy Rate</Label>
                  <Field
                    name="vacancy_rate"
                    component={FormField}
                    type={"number"}
                    placeholder="Vacancy rate"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Neighborhood Rating</Label>
                  <Field
                    name="neighborhood_rating"
                    component={FormField}
                    type={"text"}
                    placeholder="Neighborhood rating"
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Cap Rating</Label>
                  <Field
                    name="cap_rate"
                    component={FormField}
                    type={"number"}
                    placeholder="Cap rating"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Market Rent</Label>
                  <Field
                    name="market_rent"
                    component={MaskedField}
                    type="text"
                    addonSize={"lg"}
                    addonType={"prepend"}
                    addonText={"$"}
                    maskType="currency"
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Market Value</Label>
                  <Field
                    name="market_value"
                    component={MaskedField}
                    type="text"
                    addonSize={"lg"}
                    addonType={"prepend"}
                    addonText={"$"}
                    maskType="currency"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="row">
              <Col md={6}>
                <FormGroup>
                  <Label>Insurance</Label>
                  <Field
                    name="insurance"
                    component={FinancialInputField}
                    type="text"
                    addonSize={"lg"}
                    addonType={"prepend"}
                    addonText={"$"}
                    maskType="currency"
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>HOA Fee</Label>
                  <Field name="hoa_fee" component={FinancialInputField} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Property Taxes</Label>
                  <Field
                    name="property_tax"
                    component={FinancialInputField}
                    frequencies={["Yearly", "Half-Yearly"]}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>PM Fee</Label>
                  <Field
                    name="management_fee"
                    component={FinancialInputField}
                    allowPercent={true}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <FormGroup>
                  <Label>Street</Label>
                  <Field
                    name="street"
                    component={FormField}
                    type={"text"}
                    placeholder="Street"
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>City</Label>
                  <Field
                    name="city"
                    component={FormField}
                    type={"text"}
                    placeholder="City"
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>State</Label>
                  <Field
                    name="state"
                    component={FormField}
                    type={"text"}
                    placeholder="state"
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Zip</Label>
                  <Field
                    name="zip"
                    component={FormField}
                    type={"text"}
                    placeholder="zip"
                  />
                </FormGroup>
              </Col>
            </Row>
            <hr />
            <Row className="mt-3">
              <Col lg={12}>
                <div className="text-left h2-title">
                  <h2>Upload your detailed Documents</h2>
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <div className="form-group">
                  <label htmlFor="file" className="label-title">
                    Upload your deal pictures here
                  </label>
                  <br />
                  <Dropzone
                    accept="image/*"
                    minSize={0}
                    maxSize={5242880}
                    onDrop={acceptedFiles => {
                      // do nothing if no imageFiles
                      if (acceptedFiles.length === 0) {
                        return;
                      }
                      // on drop we add to the existing imageFiles
                      setFieldValue(
                        "imageFiles",
                        values.imageFiles.concat(acceptedFiles)
                      );
                    }}
                  >
                    {({
                      isDragActive,
                      isDragAccept,
                      isDragReject,
                      acceptedFiles,
                      rejectedFiles,
                      getRootProps,
                      getInputProps
                    }) => (
                      <div
                        {...getRootProps({
                          className: "dropzone-upload-wrapper"
                        })}
                      >
                        <input {...getInputProps()} />
                        <div
                          className={`dropzone-inner-wrapper bg-white dropzone-inner-wrapper-lg`}
                        >
                          {isDragAccept && (
                            <div>
                              <div className="d-60 hover-scale-lg icon-blob icon-blob-animated btn-icon text-success mx-auto">
                                <svg
                                  className="d-60 opacity-2"
                                  viewBox="0 0 600 600"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g transform="translate(300,300)">
                                    <path
                                      d="M170.4,-137.2C213.2,-82.3,234.8,-11.9,223.6,56.7C212.4,125.2,168.5,191.9,104.3,226.6C40.2,261.3,-44.1,264,-104,229.8C-163.9,195.7,-199.4,124.6,-216.2,49.8C-233,-25.1,-231,-103.9,-191.9,-158C-152.7,-212.1,-76.4,-241.6,-6.3,-236.6C63.8,-231.6,127.7,-192.2,170.4,-137.2Z"
                                      fill="currentColor"
                                    />
                                  </g>
                                </svg>
                                <div className="blob-icon-wrapper">
                                  <Check className="d-50" />
                                </div>
                              </div>
                              <div className="font-size-sm text-success">
                                All image files will be uploaded!
                              </div>
                            </div>
                          )}
                          {isDragReject && (
                            <div>
                              <div className="d-60 hover-scale-lg icon-blob icon-blob-animated btn-icon text-danger mx-auto">
                                <svg
                                  className="d-60 opacity-2"
                                  viewBox="0 0 600 600"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g transform="translate(300,300)">
                                    <path
                                      d="M169,-144C206.7,-87.5,216.5,-18,196.9,35.7C177.3,89.4,128.3,127.1,75.2,150.7C22,174.2,-35.4,183.5,-79.7,163.1C-124,142.7,-155.1,92.6,-164.1,40.9C-173.1,-10.7,-160.1,-64,-129,-118.9C-98,-173.8,-49,-230.4,8.3,-237.1C65.7,-243.7,131.3,-200.4,169,-144Z"
                                      fill="currentColor"
                                    />
                                  </g>
                                </svg>
                                <div className="blob-icon-wrapper">
                                  <X className="d-50" />
                                </div>
                              </div>
                              <div className="font-size-sm text-danger">
                                Some image files will be rejected!
                              </div>
                            </div>
                          )}
                          {!isDragActive && (
                            <div>
                              <div className="d-60 hover-scale-lg icon-blob btn-icon text-first mx-auto">
                                <svg
                                  className="d-60 opacity-2"
                                  viewBox="0 0 600 600"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g transform="translate(300,300)">
                                    <path
                                      d="M171.2,-128.5C210.5,-87.2,223.2,-16.7,205.1,40.4C186.9,97.5,137.9,141.1,81.7,167.2C25.5,193.4,-38,202.1,-96.1,181.2C-154.1,160.3,-206.7,109.7,-217.3,52.7C-227.9,-4.4,-196.4,-68,-153.2,-110.2C-110,-152.4,-55,-173.2,5.5,-177.5C65.9,-181.9,131.9,-169.8,171.2,-128.5Z"
                                      fill="currentColor"
                                    />
                                  </g>
                                </svg>
                                <div className="blob-icon-wrapper">
                                  <Upload className="d-50" />
                                </div>
                              </div>
                              <div className="font-size-sm file-size">
                                Drag image files here! File size max. 2mb.
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </div>
              </Col>
              <Col md={6}>
                <div>
                  {values.imageFiles.length <= 0 && (
                    <div className="text-info text-center font-size-sm">
                      Uploaded image files will appear here!
                    </div>
                  )}
                  {values.imageFiles.length > 0 && (
                    <div>
                      <Row className="p-2">
                        {values.imageFiles.map(file => (
                          <PreviewFile
                            file={file}
                            key={file.name}
                            asCard={true}
                          />
                        ))}
                      </Row>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            {/* <Row className="mt-3">
							<Col md={6}>
								<div className="form-group">
									<label htmlFor="file" className="label-title">
										Upload your deal pictures here
									</label>
									<br />
									<input type="file" id="myfile" name="myfile" />
									<br />
									<label className="file-size">File size max. 2mb.</label>
								</div>
							</Col>
							<Col md={6}>
								<div className="form-group">
									<label htmlFor="file" className="label-title">
										Upload your deal rent roll here
									</label>
									<br />
									<input type="file" id="myfile" name="myfile" />
									<br />
									<label className="file-size">File size max. 2mb.</label>
								</div>
							</Col>
						</Row>
						<Row>
							<Col md={6}>
								<div className="form-group">
									<label htmlFor="file" className="label-title">
										Upload your deal Inspection here
									</label>
									<br />
									<input type="file" id="myfile" name="myfile" />
									<br />
									<label className="file-size">File size max. 2mb.</label>
								</div>
							</Col>
							<Col md={6}>
								<div className="form-group">
									<label htmlFor="file" className="label-title">
										Upload your deal Repair Estimate here
									</label>
									<br />
									<input type="file" id="myfile" name="myfile" />
									<br />
									<label className="file-size">File size max. 2mb.</label>
								</div>
							</Col>
						</Row>
						<Row>
							<Col md={6}>
								<div className="form-group">
									<label htmlFor="file" className="label-title">
										Upload your deal Occupancy Status here
									</label>
									<br />
									<input type="file" id="myfile" name="myfile" />
									<br />
									<label className="file-size">File size max. 2mb.</label>
								</div>
							</Col>
						</Row> */}

            <Row>
              <Button color={"primary"} type="submit">
                Submit
              </Button>
            </Row>
          </form>
        </Col>
      </Row>
    </main>
  );
};

const OpportunityCreate = withFormik({
  mapPropsToValues: props => ({
    parcel_id: props.asset.parcel_id,
    investment_type: props.investment_type || "rental",
    title: props.asset.title,
    entity: props.asset.entity,
    user_role: props.user_role,
    address: props.asset.address,
    beds: props.asset.beds,
    baths: props.asset.baths,
    co_ordinates: props.asset.co_ordinates,
    units: props.asset.units,
    stories: props.asset.stories,
    asset_type: props.asset.asset_type,
    lotSizeSquareFeet: props.asset.lotSizeSquareFeet,
    areaSquareFeet: props.asset.areaSquareFeet,
    apn: props.asset.apn,
    county: props.asset.county,
    yearBuilt: props.asset.yearBuilt,
    imageUrl: props.asset.imageUrl,
    management_platform: props.asset.management_platform,
    purchase_date: props.asset.purchase_date,
    purchase_price: props.asset.purchase_price,
    down_payment: props.asset.down_payment,
    market_value: props.asset.market_value,
    market_rent: props.asset.market_rent,
    property_tax: props.asset.property_tax,
    insurance: props.asset.insurance,
    hoa_fee: props.asset.hoa_fee,
    self_managed: props.asset.self_managed,
    management_fee: props.asset.management_fee,
    rent: props.asset.rent,
    rents: props.asset.rents,
    repair_estimate: props.asset.repair_estimate,
    after_repair_value: props.asset.after_repair_value,
    sale_price: props.asset.sale_price,
    occupany_rate: props.asset.occupany_rate,
    vacancy_rate: props.asset.vacancy_rate,
    neighborhood_rating: props.asset.neighborhood_rating,
    cap_rate: props.asset.cap_rate,
    description: props.asset.description,
    imageFiles: props.asset.imageFiles,
    street: props.asset.street,
    city: props.asset.city,
    state: props.asset.state,
    zip: props.asset.zip
  }),

  validationSchema: Yup.object().shape({
    address: Yup.string().required("Address is required")
  }),

  handleSubmit: async (values, { props }) => {
    const opportunity = {
      description: values.description,
      posted_by: 22,
      medias: values.imageFiles,
      property: {
        after_repair_value: values.after_repair_value.toString(),
        repair_estimate: values.repair_estimate,
        baths: values.baths,
        beds: values.beds,
        cap_rate: parseInt(values.cap_rate),
        hoa_fees: parseInt(values.hoa_fee.amount),
        insurance: parseInt(values.insurance.amount),
        lot_size: Math.round(values.lotSizeSquareFeet),
        management_fee: parseInt(values.management_fee.amount),
        market_value: values.market_value.toString().replace(",", ""),
        neighborhood_rating: values.neighborhood_rating,
        status: "active",
        no_of_units: values.units,
        occupany_rate: values.occupany_rate,
        property_tax: parseInt(values.property_tax.amount),
        rent: parseInt(values.market_rent.toString().replace(",", "")),
        sale_price: values.sale_price,
        type: values.asset_type,
        vacancy_rate: values.vacancy_rate,
        year_build: values.yearBuilt,
        location: {
          street: values.street,
          city: values.city,
          state: values.state,
          zip: values.zip,
          lat: values.co_ordinates[0],
          lng: values.co_ordinates[1]
        }
      },
      slug: values.address
        .toLowerCase()
        .replace(/,/g, "")
        .split(" ")
        .join("-"),
      title: values.address
    };
    if (opportunity.medias.length === 0) {
      return showToast("e", "Upload Images for properties");
    }
    props.createOpportunity(opportunity);
  }
})(OpportunityCreateForm);

const mapStateToProps = state => ({
  asset: defaultAsset,
  assetLookup: state.asset.assetLookup,
  parcelLookup: state.asset.parcelLookup
});

const mapDispatchToProps = dispatch => ({
  lookupAsset: address => lookupAsset(address, dispatch),
  lookupParcel: address => lookupParcel(address, dispatch),
  createOpportunity: data => createOpportunity(dispatch, data)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OpportunityCreate);
