import {
  PROPERTY_PARSE,
} from '../actionTypes';

export const propParser = (data, dispatch) => {
  dispatch({
    type: PROPERTY_PARSE,
    payload: data,
  });
}
