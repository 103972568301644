import React, { PureComponent } from "react";
import RentalAnalyzer from "./RentalAnalyzer";
import NoteAnalyzer from "./NoteAnalyzer";
import FlipAnalyzer from "./FlipAnalyzer";

import DealSummary from "./DealSummary";
import LoadingIndicator from "../../shared/components/LoadingIndicator";
import { Helmet } from "react-helmet";

import { connect } from "react-redux";
import {
  getInvestmentInfo,
  getBasicInfo,
  getAssetStats,
  cleanup
} from "../../actions/asset/assetAction";

class DealPermalink extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      assetSlug: props.match.params.asset,
      analysis: {}
    };
  }

  componentWillMount() {
    const assetSlug = this.props.match.params.asset;
    this.props.getBasicInfo(assetSlug);
    this.props.getInvestmentInfo(assetSlug);
    this.props.getAssetStats({ slug: assetSlug });
  }

  componentWillUnmount() {
    this.props.cleanup();
  }

  render() {
    const assetSlug = this.props.match.params.asset;
    const {
      assetBasicInfo: { loadingBasicInfoSuccess, basicInfo },
      assetInvestmentInfo: { loadingInvestmentInfoSuccess, investmentInfo }
    } = this.props;
    if (!loadingBasicInfoSuccess || !loadingInvestmentInfoSuccess) {
      return <LoadingIndicator />;
    }
    let analysis;
    let seoTags;

    if (["rental"].includes(basicInfo.dealType)) {
      seoTags = [
        <meta
          key={"meta-tag-og:title"}
          property="og:title"
          content={`Rental investment with $${
            investmentInfo.marketRent
          } monthly rent`}
        />,
        <meta
          key={"meta-tag-og:description"}
          property="og:description"
          content={`Rental Property listed for $${
            basicInfo.dealSalesPrice
          }, market rent on this property is around $${
            investmentInfo.marketRent
          } Monthly`}
        />,
        <meta
          key={"meta-tag-og:image"}
          property="og:image"
          content={basicInfo.imageUrl}
        />
      ];
      analysis = (
        <RentalAnalyzer
          assetSlug={this.props.match.params.asset}
          basicInfo={basicInfo}
          investmentInfo={investmentInfo}
          {...this.props}
        />
      );
    } else if (basicInfo.dealType === "note") {
      seoTags = [
        <meta
          key={"meta-tag-og:title"}
          property="og:title"
          content={`Notes investment with ${basicInfo.interestRate}% interest`}
        />,
        <meta
          key={"meta-tag-og:description"}
          property="og:description"
          content={`Investing $${
            basicInfo.investmentAmount
          } on this Note will yield a cashFlow of $${(basicInfo.investmentAmount *
            basicInfo.interestRate) /
            1200} Monthly`}
        />,
        <meta
          key={"meta-tag-og:image"}
          property="og:image"
          content={basicInfo.imageUrl}
        />
      ];
      analysis = (
        <NoteAnalyzer
          basicInfo={basicInfo}
          investmentInfo={investmentInfo}
          {...this.props}
        />
      );
    } else if (["flip", "for_sale"].includes(basicInfo.dealType)) {
      analysis = (
        <FlipAnalyzer
          basicInfo={basicInfo}
          investmentInfo={investmentInfo}
          {...this.props}
        />
      );
    }

    return (
      <div>
        <Helmet>{seoTags}</Helmet>
        {analysis}
        <DealSummary basicInfo={basicInfo} assetSlug={assetSlug} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  assetBasicInfo: state.assetBasicInfo,
  assetInvestmentInfo: state.assetInvestmentInfo,
  stats: state.statsReducer,
  expenses: state.assetExpenses
});

const mapDispatchToProps = dispatch => ({
  getAssetStats: data => getAssetStats(data, dispatch),
  getBasicInfo: slug => getBasicInfo(slug, dispatch),
  cleanup: () => cleanup(dispatch),
  getInvestmentInfo: slug => {
    getInvestmentInfo(slug, dispatch);
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DealPermalink);
