import React, { Component } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import CreatableSelect from "react-select/creatable";
import LoadingIndicator from "../../shared/components/LoadingIndicator";
import { Button } from "reactstrap";

import renderInputGroupButtonDropdown from "../../shared/components/form/InputGroupBtnDropdown";

const validationSchema = Yup.object({});

const defaultOptions = {
  investmentExperience: [
    {
      label: "Fundamental Awareness",
      value: "FUNDAMENTAL AWARENESS"
    },
    { label: "Novice", value: "NOVICE" },
    { label: "Intermediate", value: "INTERMEDIATE" },
    { label: "Advanced", value: "ADVANCED" },
    { label: "Expert", value: "EXPERT" }
  ],
  investmentBudget: [
    {
      label: "Less than 100k",
      value: "LESS THAN 100k"
    },
    { label: "100k - 250k", value: "100k - 250k" },
    { label: "250k - 500k", value: "250k - 500k" },
    { label: "> 500k", value: "> 500k" }
  ],
  investmentTypes: [
    { label: "Buy & Hold", value: "Buy & Hold" },
    { label: "Notes", value: "Notes" },
    { label: "Syndication", value: "Syndication" },
    { label: "Flips", value: "Flips" },
    { label: "Commercial", value: "Commercial" }
  ],
  services: [
    {
      label: "Real Estate Agent",
      value: "Real Estate Agent"
    },
    { label: "CPA", value: "CPA" },
    { label: "Legal", value: "Legal" },
    {
      label: "1031 Intermediary",
      value: "1031 Intermediary"
    },
    {
      label: "Property Manager",
      value: "Property Manager"
    },
    { label: "Insurance", value: "Insurance" },
    { label: "Lender", value: "Lender" }
  ]
};

class InvestorInfoSetting extends Component {
  constructor(props) {
    super(props);
    const {
      desiredInvestmentTypes,
      desiredServices
    } = props.investorProfileInfo;

    this.state = {
      desiredInvestmentTypes: desiredInvestmentTypes || [],
      desiredServices: desiredServices || []
    };
  }

  resetForm = null;

  addInvestorProfile = (values, { resetForm }) => {
    values._id = this.props.userDetail.userInfo._id;
    const { desiredServices, desiredInvestmentTypes } = this.state;

    if (desiredServices.length > 0) {
      values.desiredServices = desiredServices;
    }
    if (desiredInvestmentTypes.length > 0) {
      values.desiredInvestmentTypes = desiredInvestmentTypes;
    }
    this.props.updateInvestorProfile(values);
  };

  onChangeInvestment = values => {
    this.setState({ desiredInvestmentTypes: values.map(type => type.value) });
  };

  onChangeServices = values => {
    this.setState({ desiredServices: values.map(type => type.value) });
  };
  render() {
    const { investorProfileInfo } = this.props;

    let initialValues;
    if (investorProfileInfo) {
      initialValues = {
        investmentExperience: investorProfileInfo.investmentExperience,
        investmentBudget: investorProfileInfo.investmentBudget
      };
    } else {
      return <LoadingIndicator />;
    }

    return (
      <div className="py-4">
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={this.addInvestorProfile}
        >
          {props => {
            this.resetForm = props.resetForm;
            const { handleSubmit } = props;
            const { loading } = this.state;
            return (
              <form onSubmit={handleSubmit}>
                {this.state.error && <p>{this.state.errormessage}</p>}
                <div className="form-group inputblock">
                  <label htmlFor="exampleInputEmail1">
                    Investment Experience:
                  </label>
                  <Field
                    name="investmentExperience"
                    component={renderInputGroupButtonDropdown}
                    options={defaultOptions.investmentExperience}
                  />
                </div>
                <div className="form-group inputblock">
                  <label htmlFor="exampleInputEmail1">Investment Budget:</label>
                  <Field
                    name="investmentBudget"
                    component={renderInputGroupButtonDropdown}
                    options={defaultOptions.investmentBudget}
                  />
                </div>
                <div className="form-group inputblock">
                  <label htmlFor="exampleInputEmail1">
                    Desired Investment Type:
                  </label>
                  <CreatableSelect
                    isMulti
                    isSearchable
                    name="desiredInvestmentTypes"
                    id="desiredInvestmentTypes"
                    placeholder="Select Investment type or add your own"
                    searchable={true}
                    options={defaultOptions.investmentTypes}
                    defaultValue={this.state.desiredInvestmentTypes.map(
                      type => {
                        return { value: type, label: type };
                      }
                    )}
                    theme={theme => ({
                      ...theme,
                      borderRadius: "0.29rem",
                      borderWidth: 1,
                      colors: {
                        ...theme.colors,
                        primary25: "rgba(60,68,177,0.15)",
                        primary50: "rgba(60,68,177,0.15)",
                        primary: "#3c44b1"
                      }
                    })}
                    onChange={this.onChangeInvestment}
                  />
                </div>
                <div className="form-group inputblock">
                  <label htmlFor="exampleInputEmail1">Desired Services:</label>
                  <CreatableSelect
                    name="investmentServices"
                    id="investmentServices"
                    isMulti
                    isSearchable
                    options={defaultOptions.services}
                    placeholder="Select Services Interested"
                    defaultValue={this.state.desiredServices.map(type => {
                      return { value: type, label: type };
                    })}
                    theme={theme => ({
                      ...theme,
                      borderRadius: "0.29rem",
                      borderWidth: 1,
                      colors: {
                        ...theme.colors,
                        primary25: "rgba(60,68,177,0.15)",
                        primary50: "rgba(60,68,177,0.15)",
                        primary: "#3c44b1"
                      }
                    })}
                    onChange={values => this.onChangeServices(values)}
                  />
                </div>
                <Button
                  color="primary"
                  className="btn btn-primary px-5  d-inline-block mx-2"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? "Updating" : "Update"}
                </Button>
              </form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

export default InvestorInfoSetting;
