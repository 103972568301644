import {
  INVITE_USER
} from '../actionTypes';


export const inviteUser = (data, dispatch)=>{
  dispatch({
      type: INVITE_USER,
      payload:data,
  });
}

