import React, { useEffect, useMemo } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { Field, Formik } from "formik";
import SelectField from "../../shared/components/form/SelectField";
import { asset_list } from "../../actions/asset/assetAction";
import { connect } from "react-redux";

const LinkToAssetModal = ({
  isShow,
  onClose,
  selectedFile,
  handleSubmit,
  asset_list,
  assetList
}) => {
  const initialValues = {
    asset_slug: "",
    documentId: selectedFile
  };

  const options = useMemo(
    () => {
      if (assetList) {
        return assetList.map(asset => {
          return {
            value: asset.slug,
            label: asset.asset_name
          };
        });
      } else {
        return [];
      }
    },
    [assetList]
  );

  useEffect(() => {
    asset_list();
  }, []);

  return (
    <Modal isOpen={isShow} toggle={onClose} centered>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ handleSubmit }) => {
          return (
            <>
              <ModalHeader>Add Asset</ModalHeader>
              <ModalBody>
                <Field
                  name="asset_slug"
                  component={SelectField}
                  options={options}
                  placeholder="Select Assets"
                />
              </ModalBody>
              <ModalFooter>
                <div className={"d-flex justify-content-end"}>
                  <Button className={"btn-sm mr-2"} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    className={"btn-sm mr-2"}
                    color={"primary"}
                    onClick={() => {
                      handleSubmit();
                      onClose();
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </ModalFooter>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = state => {
  return {
    assetList: state.assetList.data
  };
};

const mapDispatchToProps = dispatch => {
  return {
    asset_list: data => asset_list(data, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkToAssetModal);
