import React, { Component } from "react";
import { Badge, Table, Button } from "reactstrap";
import { connect } from "react-redux";

import UpdateTransaction from "./UpdateTransaction";
import DeleteTransaction from "./DeleteTransaction";
import {
  markAllPaid,
  removeAllDuplicates
} from "../../../actions/transaction/";

import { formatCurrency } from "../../../helpers";
import { categoryOptions } from "../../../helpers/constants";

import TransactionAction from "./TransactionAction";

class ReviewTransactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRow: null,
      actionType: null
    };
  }

  ctaFunction = () => {
    const { assetSlug, type, markAllPaid, removeAllDuplicates } = this.props;
    if (type === "pending") markAllPaid({ slug: assetSlug });
    else if (type === "duplicate") removeAllDuplicates({ slug: assetSlug });
  };

  getCategoryName = type => {
    let matchedOption = categoryOptions
      .flatMap(cat => cat.options)
      .find(option => option.value === type.toLowerCase());
    return matchedOption ? matchedOption.label : type;
  };

  handleAction = (type, row) => {
    this.setState({ actionType: type, selectedRow: row }, () => {});
  };

  closeModal = () => {
    this.setState({ selectedRow: null, actionType: null });
  };

  render() {
    const { transactions, type } = this.props;
    const title = type === "pending" ? "Pending Items" : "Duplicate Items";
    const btnLabel = type === "pending" ? "Mark All as Paid" : "Remove All";
    if (!transactions || transactions.length === 0) return <div />;
    const { actionType, selectedRow } = this.state;

    return (
      <div className="bordered_table mb-3">
        <div className="float-left p-2">
          <h4>{title}</h4>
        </div>
        <div className="float-right p-2">
          <Button
            to="#/"
            color="primary"
            className="add_btns ml-auto"
            onClick={this.ctaFunction}
          >
            {btnLabel}
          </Button>
        </div>
        {selectedRow && (
          <UpdateTransaction
            transaction={selectedRow}
            assetSlug={this.props.assetSlug}
            asset={this.props.asset}
            showModal={actionType === "edit"}
            closeModal={this.closeModal}
          />
        )}
        {selectedRow && (
          <DeleteTransaction
            transaction={selectedRow}
            assetSlug={this.props.assetSlug}
            showModal={actionType === "delete"}
            closeModal={this.closeModal}
          />
        )}
        <Table className="mb-0 ">
          <thead className="thead-light">
            <tr>
              <th>Category</th>
              <th>Payee</th>
              <th>Description</th>
              <th>Amount</th>
              <th>Date</th>
              <th>Type</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((t, i) => (
              <tr key={i} style={{ backgroundColor: "antiquewhite" }}>
                {/* <td>{++i}</td> */}
                <td>{this.getCategoryName(t.category)}</td>
                <td>{t.payee}</td>
                <td>{t.name}</td>
                <td>{formatCurrency.format(t.amount)}</td>
                <td>{new Date(t.date).toLocaleDateString("en-US")}</td>
                <td>
                  <Badge color={t.type === "INCOME" ? "success" : "danger"}>
                    {t.type}
                  </Badge>
                </td>
                <td>
                  <Badge color={t.isPending ? "danger" : "success"}>
                    {t.isPending ? "Unpaid" : "Paid"}
                  </Badge>
                </td>
                <td>
                  <TransactionAction
                    onSelect={type => this.handleAction(type, t)}
                    transaction={t}
                    isReview={true}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  markAllPaid: data => markAllPaid(data, dispatch),
  removeAllDuplicates: data => removeAllDuplicates(data, dispatch)
});

export default connect(
  null,
  mapDispatchToProps
)(ReviewTransactions);
