import React, { Component } from "react";
import { Formik, Field } from "formik";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label
} from "reactstrap";
import { connect } from "react-redux";

import MaskedField from "../../../shared/components/form/MaskedField";
import { closeAsset } from "../../../actions/asset/assetAction";
import { isFetching, isSuccess } from "../../../reducers/reducerUtils";

class CloseAsset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      closeModal: false
    };
  }

  formikResetForm;

  updateStatus = (values, { resetForm }) => {
    this.setState({ loading: true, error: null }, () => {
      this.formikResetForm = resetForm;
      this.props.closeAsset({
        slug: this.props.assetSlug,
        payoff_amount: values.payoff_amount,
        payoff_date: values.payoff_date,
        investment_status: "closed"
      });
    });
  };

  closeModal = resetForm => {
    const { toggleModal } = this.props;
    if (this.props.modalOpen)
      this.setState({ loading: false, error: null }, () => {
        resetForm();
        toggleModal({});
      });
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    const { assetBasicInfo } = nextProps;
    if (
      prevState.loading &&
      !isFetching(assetBasicInfo) &&
      assetBasicInfo.type === "CLOSE"
    ) {
      if (isSuccess(assetBasicInfo)) {
        return {
          loading: false,
          closeModal: true
        };
      } else {
        return {
          loading: false,
          error: closeAsset.data.error,
          closeModal: true
        };
      }
    }
    return null;
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.closeModal && !prevState.closeModal) {
      this.formikResetForm && this.formikResetForm();
      this.props.toggleModal();
    }
  };

  render() {
    const initialValues = {
      payoff_amount: "",
      payoff_date: new Date()
    };

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={this.updateStatus}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {props => {
          return (
            <Modal
              isOpen={this.props.modalOpen}
              className={"modal-dialog modal-dialog-centered"}
              contentClassName={"newexpensemodal"}
              toggle={() => this.closeModal(props.resetForm)}
            >
              <ModalHeader>Close Investment</ModalHeader>
              <form onSubmit={props.handleSubmit}>
                <ModalBody>
                  <FormGroup>
                    <Label>Payoff amount</Label>
                    <Field
                      name="payoff_amount"
                      component={MaskedField}
                      type="text"
                      addonSize={"lg"}
                      addonType={"prepend"}
                      addonText={"$"}
                      maskType="currency"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Payoff date</Label>
                    <Field
                      name="payoff_date"
                      component={MaskedField}
                      type="text"
                      addonSize={"lg"}
                      addonType={"prepend"}
                      addonText={"$"}
                      maskType="date"
                    />
                  </FormGroup>
                </ModalBody>
                <ModalFooter>
                  <Button
                    type="reset"
                    className="btn btn-secondary px-5 d-inline-block mx-2"
                    onClick={() => this.closeModal(props.resetForm)}
                    disabled={this.state.loading}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    className="btn savebtns btn-primary px-5  d-inline-block mx-2"
                    type="submit"
                    disabled={this.state.loading}
                  >
                    {this.state.loading ? "Closing" : "Close"}
                  </Button>
                </ModalFooter>
              </form>
            </Modal>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = state => ({
  assetBasicInfo: state.assetBasicInfo
});

const mapDispatchToProps = dispatch => {
  return {
    closeAsset: data => closeAsset(data, dispatch)
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CloseAsset);
