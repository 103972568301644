import React, { Component } from "react";
import { connect } from "react-redux";
import { deleteAssetContact } from "../../../actions/contacts";
import ConfirmModal from "../../../shared/components/ConfirmModal";

class DeleteContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null
    };
  }

  closeModal = () => {
    const { toggleModal } = this.props;
    this.setState({ loading: false, error: null }, () => {
      toggleModal({});
    });
  };

  deleteContact = () => {
    this.setState({ loading: true, error: null }, () => {
      this.props.deleteAssetContact({
        email: this.props.contact.email,
        role: this.props.contact.role,
        slug: this.props.assetSlug
      });
    });
  };

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps && this.state.loading) {
      const { contacts } = nextProps;
      if (contacts.deletingSuccess === true) this.closeModal();
      else if (contacts.deletingSuccess === false)
        this.setState({ loading: false, error: contacts.error });
    }
  }

  render() {
    const { contact } = this.props;
    return (
      <ConfirmModal
        title={`Are you sure to delete ${contact.email}?`}
        subtitle={"You cannot undo this operation."}
        type="danger"
        modalOpen={this.props.modalOpen}
        onCancel={this.closeModal}
        onSubmit={this.deleteContact}
        btnText={"Delete"}
        btnTextOnLoading={"Deleting"}
        loading={this.state.loading}
        error={this.state.error}
      />
    );
  }
}

const mapStateToProps = state => ({
  contacts: state.contacts
});

const mapDispatchToProps = dispatch => {
  return {
    deleteAssetContact: data => deleteAssetContact(data, dispatch)
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteContact);
