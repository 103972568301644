/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";
import { Card, Button, Badge } from "reactstrap";
import { Link } from "react-router-dom";
import { getPartner } from "../../actions/partners";
import ScaledImage from "../../shared/components/ScaledImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import heroBg11 from "../../assets/images/hero-bg/hero-11.png";
import { faCommentAlt } from "@fortawesome/free-solid-svg-icons";

class OwnerCard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showPostDeal: false
    };
  }

  componentWillMount = () => {
    const { partnerSlug } = this.props.basicInfo;
    this.props.getPartner(partnerSlug);
  };

  render() {
    const {
      basicInfo,
      partnerProp: { getPartnerSuccess, partnerInfo },
      auth: { user }
    } = this.props;

    if (!getPartnerSuccess || !partnerInfo) {
      return (
        <Card className="mb-5">
          <div className="card-badges">
            <Badge pill color="danger" className="h-auto px-3 py-1">
              Investor
            </Badge>
          </div>
          <a
            href="#/"
            onClick={e => e.preventDefault()}
            className="image-title-overlay h-180px"
            title="..."
          >
            <ScaledImage
              src={heroBg11}
              className="card-img-top img-fit-container"
              alt="..."
            />
          </a>
          <div className="py-3 px-4">
            <div className="d-flex justify-content-between mb-4">
              <div className="d-flex align-items-center">
                <div className="avatar-icon-wrapper mr-2">
                  <div className="avatar-icon">
                    <img alt="..." src={basicInfo.ownerImage} />
                  </div>
                </div>
                <div>
                  <a
                    href="#/"
                    onClick={e => e.preventDefault()}
                    className="font-weight-bold"
                    title="..."
                  >
                    {basicInfo.fullName}
                  </a>
                  <span className="text-black-50 d-block">
                    Investor Listing
                  </span>
                </div>
              </div>
            </div>
            {user && user.id !== basicInfo.ownerId && (
              <div className="d-flex justify-content-between pt-2">
                <Button
                  tag={Link}
                  color="first"
                  className="hover-scale-lg"
                  to={{
                    pathname: `/chats/${basicInfo.ownerId}`,
                    query: { basicInfo }
                  }}
                >
                  <span className="btn-wrapper--icon">
                    <FontAwesomeIcon icon={faCommentAlt} />
                  </span>
                  <span className="btn-wrapper--label">Message</span>
                </Button>
              </div>
            )}
          </div>
        </Card>
      );
    }

    return (
      <Card className="mb-5">
        <div className="card-badges">
          <Badge pill color="warning" className="h-auto px-3 py-1">
            Partner
          </Badge>
        </div>
        <Link
          to={`/partners/${partnerInfo.slug}`}
          className="image-title-overlay h-180px"
        >
          <ScaledImage
            src={partnerInfo.imageUrl}
            className="card-img-top img-fit-container"
            alt="..."
          />
        </Link>
        <div className="py-3 px-4">
          <div className="d-flex justify-content-between mb-4">
            <div className="d-flex align-items-center">
              <div className="avatar-icon-wrapper mr-2">
                <div className="avatar-icon">
                  <img alt="..." src={basicInfo.ownerImage} />
                </div>
              </div>
              <div>
                <Link
                  to={`/partners/${partnerInfo.slug}`}
                  className="font-weight-bold"
                >
                  {partnerInfo.name}
                </Link>
                <span className="text-black-50 d-block">{partnerInfo.bio}</span>
              </div>
            </div>
          </div>
          {user && user.id !== basicInfo.ownerId && (
            <div className="d-flex justify-content-between pt-2">
              <Button
                tag={Link}
                color="first"
                className="hover-scale-lg"
                to={{
                  pathname: `/chats/${partnerInfo.user}`,
                  query: { basicInfo }
                }}
              >
                <span className="btn-wrapper--icon">
                  <FontAwesomeIcon icon={faCommentAlt} />
                </span>
                <span className="btn-wrapper--label">Message</span>
              </Button>
            </div>
          )}
        </div>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  partnerProp: state.partners,
  auth: state.login
});

const mapDispatchToProps = dispatch => ({
  getPartner: slug => getPartner(slug, dispatch)
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OwnerCard);
