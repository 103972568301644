import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Modal } from "reactstrap";
import { deleteAssetMedia } from "../../../actions/asset/assetAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faTimes } from "@fortawesome/free-solid-svg-icons";

class DeleteMedia extends Component {
  constructor() {
    super();
    this.state = {
      files: [],
      loading: false,
      error: null,
      closeModal: false
    };
  }

  onChange = newFiles => {
    this.setState({ files: newFiles });
  };

  handleSubmit = e => {
    this.setState({ loading: true, error: null }, () => {
      this.props.deleteAssetMedia({
        slug: this.props.assetSlug,
        fileId: this.props.fileId
      });
    });
  };

  handleReset = () => {
    this.setState(
      { loading: false, error: null, files: [], closeModal: false },
      () => {
        this.props.toggleModal();
      }
    );
  };

  componentWillUnmount() {
    // Make sure to revoke the data uris to avoid memory leaks
    // this.state.files.forEach(file => URL.revokeObjectURL(file.preview));
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { assetMedia } = nextProps;
    if (prevState.loading && assetMedia) {
      return { loading: false, closeModal: true };
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.closeModal && this.state.closeModal) {
      this.handleReset();
    }
  }

  render() {
    const { modalOpen } = this.props;
    const { loading } = this.state;
    return (
      <Modal centered isOpen={modalOpen} toggle={this.handleReset}>
        <div className="text-center p-5">
          <div className="avatar-icon-wrapper rounded-circle m-0">
            <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
              <FontAwesomeIcon
                icon={faTimes}
                className="d-flex align-self-center display-3"
              />
            </div>
          </div>
          <h4 className="font-weight-bold mt-4">
            Are you sure you want to delete?
          </h4>
          <p className="mb-0 font-size-lg text-muted">
            You cannot undo this operation.
          </p>
          <div className="pt-4">
            <Button
              onClick={e => this.handleReset(e)}
              color="neutral-secondary"
              className="btn-pill mx-1"
              disabled={loading}
            >
              <span className="btn-wrapper--label">Cancel</span>
            </Button>
            <Button
              onClick={e => this.handleSubmit(e)}
              color="danger"
              className="btn-pill mx-1"
              disabled={loading}
            >
              <span className="btn-wrapper--label">
                {loading ? "Deleting" : "Delete"}
              </span>
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  assetMedia: state.assetMedia
});

const mapDispatchToProps = dispatch => ({
  deleteAssetMedia: data => deleteAssetMedia(data, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteMedia);
