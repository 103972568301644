import React, { PureComponent } from "react";
import DatePicker from "react-datepicker";
import { getIn } from "formik";
import classNames from "classnames";

class DatePickerField extends PureComponent {
  handleOnChange = value => {
    const {
      field: { name },
      form: { setFieldValue },
      onChange
    } = this.props;
    setFieldValue(name, value);
    if (onChange) onChange(value);
  };

  render() {
    const {
      field,
      form: { touched, errors, setFieldTouched },
      placeholder,
      minDate,
      maxDate,
      disabled,
      className,
      mode
    } = this.props;

    const touch = getIn(touched, field.name);
    const errorMessage = getIn(errors, field.name);
    let dateValue = field.value;
    if (dateValue) {
      if (
        dateValue.toString().includes("-") &&
        !dateValue.toString().includes("T")
      )
        dateValue = new Date(dateValue.replace("-", "/"));
      else dateValue = new Date(dateValue);
    }

    let dateFormat;
    switch (mode) {
      case "YEAR":
        dateFormat = "yyyy";
        break;
      case "MONTH":
        dateFormat = "MMMM";
        break;
      default:
        dateFormat = "MM/dd/yyyy";
        break;
    }

    return (
      <div
        className={classNames("datepicker", {
          "is-invalid": touch && errorMessage,
          "year-picker": mode === "YEAR"
        })}
      >
        <DatePicker
          dateFormat={dateFormat}
          minDate={minDate || null}
          maxDate={maxDate || null}
          onChange={this.handleOnChange}
          onBlur={e => setFieldTouched(field.name, e)}
          onTouch={this.onTouch}
          selected={dateValue}
          placeholderText={placeholder}
          showMonthDropdown={mode !== "YEAR"}
          showYearDropdown={mode !== "YEAR"}
          showYearPicker={mode === "YEAR"}
          disabled={disabled}
          className={classNames(className || "form-control", {
            "is-invalid": touch && errorMessage
          })}
        />
        <div className="invalid-feedback">{errorMessage}</div>
      </div>
    );
  }
}

export default DatePickerField;
