import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { getIn } from "formik";

import Rating from "react-rating";

const RatingsField = ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  id,
  label,
  className,
  disabled,
  ...props
}) => {
  const errorMessage = getIn(errors, field.name);
  return (
    <div>
      <Rating
        initialRating={field.value}
        stop={5}
        emptySymbol={
          <span className="text-black-50 mx-1 opacity-3">
            <FontAwesomeIcon size="3x" icon={faThumbsUp} />
          </span>
        }
        fullSymbol={
          <span className="text-success mx-1">
            <FontAwesomeIcon size="3x" icon={faThumbsUp} />
          </span>
        }
        onChange={e => setFieldValue(field.name, e)}
      />
      <div className="invalid-feedback">{errorMessage}</div>
    </div>
  );
};

export default RatingsField;
