import React, { useState } from "react";

import { Card, Col, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEllipsisH,
  faTrash,
  faRotate,
} from "@fortawesome/free-solid-svg-icons";
import propertyware from "../../../img/propertyware.png";
import rentmanager from "../../../img/rentmanager.png";
import { withRouter } from "react-router-dom";

import TransactionSync from "./TransactionSync";
import DeletePropertyManager from "./DeletePropertyManager";

import {
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import { NavLink as NavLinkStrap } from "reactstrap";

const PMCard = ({
  history,
  integration,
  currentFilter,
  setCurrentFilter,
  syncIntegration,
}) => {
  const [showDelete, setShowDelete] = useState(false);
  const [showSync, setShowSync] = useState(false);

  const getLogoForType = (type) => {
    switch (type) {
      case "propertyware":
        return propertyware;
      case "rentmanager":
        return rentmanager;
      default:
        return null;
    }
  };

  return (
    <Col md="4" lg="8" xl="3">
      <Card className="card-box mb-5 card-box-hover">
        <div className="card-tr-actions">
          <UncontrolledDropdown>
            <DropdownToggle
              outline
              color="primary"
              className="d-flex align-items-center justify-content-center d-40 mr-1 p-0 rounded-pill"
            >
              <FontAwesomeIcon icon={faEllipsisH} />
            </DropdownToggle>
            <DropdownMenu right className="dropdown-menu-md p-0">
              <Nav className="nav-pills flex-column">
                <NavItem>
                  <NavLinkStrap
                    className="d-flex rounded-sm "
                    onClick={() => setShowSync((prevState) => !prevState)}
                  >
                    <div className="nav-link-icon">
                      <FontAwesomeIcon icon={faRotate} />
                    </div>
                    <span>Sync</span>
                  </NavLinkStrap>
                </NavItem>
                <NavItem>
                  <NavLinkStrap
                    className="d-flex rounded-sm "
                    onClick={() => setShowDelete((prevState) => !prevState)}
                  >
                    <div className="nav-link-icon">
                      <FontAwesomeIcon icon={faTrash} />
                    </div>
                    <span>Delete</span>
                  </NavLinkStrap>
                </NavItem>
              </Nav>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <div className="text-center py-3">
          <div
            className="w-50 rounded-lg border-0 my-3 shadow-xxl btn-icon p-2 bg-white card-icon-wrapper mx-auto"
            style={{ height: 50 }}
          >
            <img
              className="rounded-lg"
              alt="..."
              src={getLogoForType(integration.options.type)}
            />
          </div>
          <div className="font-size-xl font-weight-bold my-2 text-black">
            {integration.options.pwDomain}
          </div>
          <div className="display-4 text-black font-weight-bold">
            {integration.assets && integration.assets.length}
          </div>
          <div className="divider mt-2 mb-3 mx-auto border-2 w-25 bg-danger rounded border-danger" />
          <div className="font-weight-bold font-size-sm text-uppercase">
            Assets
          </div>
          <div className="divider mx-auto my-3" />
          <div className="text-center px-3">
            <Button
              className="d-block w-100 btn-transition-none border-0 shadow-none"
              color="neutral-primary"
              onClick={() => {
                currentFilter.integration = integration._id;
                setCurrentFilter(currentFilter);
                history.push("/dashboard");
              }}
            >
              <span className="btn-wrapper--icon">
                <FontAwesomeIcon icon={faEye} />
              </span>
              <span className="btn-wrapper--label">View Portfolio</span>
            </Button>
          </div>
        </div>

        <TransactionSync
          integration={integration}
          isOpen={showSync}
          setIsOpen={setShowSync}
        />
        <DeletePropertyManager
          integration={integration}
          showModal={showDelete}
          onToggle={() => setShowDelete((prevState) => !prevState)}
        />
      </Card>
    </Col>
  );
};

export default withRouter(PMCard);
