export function updateObject(oldObject, newValues) {
  // Encapsulate the idea of passing a new object as the first parameter
  // to Object.assign to ensure we correctly copy data instead of mutating
  return Object.assign({}, oldObject, newValues);
}

export function updateItemInArray(array, itemId, updateItemCallback) {
  const updatedItems = array.map(item => {
    if (item.id !== itemId) {
      // Since we only want to update one item, preserve all others as they are now
      return item;
    }

    // Use the provided callback to create an updated item
    const updatedItem = updateItemCallback(item);
    return updatedItem;
  });

  return updatedItems;
}

export function setLoadingState(currentState, key = null, type = null) {
  let payload = {
    state: "LOADING",
    type: type
  };
  const data = key ? { [key]: payload } : payload;
  return Object.assign({}, currentState, data);
}

export function isInit(currentState) {
  return currentState && currentState.state === "INIT";
}
export function isLoading(currentState) {
  return currentState && currentState.state === "LOADING";
}
export function isFetching(currentState) {
  return (
    (currentState && currentState.state === "LOADING") ||
    (currentState && currentState.state === "INIT")
  );
}
export function isSuccess(currentState) {
  return currentState && currentState.state === "SUCCESS";
}
export function isError(currentState) {
  return currentState && currentState.state === "ERROR";
}

export function setSuccessResponse(
  currentState,
  response,
  key = null,
  type = null
) {
  let payload = {
    state: "SUCCESS",
    data: response.payload,
    type: type
  };
  const data = key ? { [key]: payload } : payload;
  return Object.assign({}, currentState, data);
}
export function setErrorResponse(
  currentState,
  response,
  key = null,
  type = null
) {
  let payload = {
    state: "ERROR",
    data: response.message,
    type: type
  };
  const data = key ? { [key]: payload } : payload;
  return Object.assign({}, currentState, data);
}
