import React from "react";
import {
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu
} from "reactstrap";
import { connect } from "react-redux";
import { isEmpty, get, isEqual, omit } from "lodash";
import PlaidUpdateLinkButton from "./PlaidUpdateLinkButton";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

import BankTransactions from "./BankTransactions";
import ConfirmModal from "../../shared/components/ConfirmModal";
import TableWithSearch from "../../shared/components/TableWithSearch";
import { isFetching, isSuccess } from "../../reducers/reducerUtils";
import { getTransactionsFromBank } from "../../actions/transaction";
import { updateAccount } from "../../actions/accounts/accountActions";

import { getTxnFilterPattern } from "../../helpers";

class BankAccountTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountToFetch: null,
      accountToDelete: null,
      fetchingBankData: false,
      fetching: false,
      updating: false,
      showDeleteModal: false,
      daysToFetch: 7,
      transactions: []
    };
  }

  columnProps = [
    {
      dataField: "institutionName",
      text: "Bank Name",
      sort: true,
      editable: false
    },
    {
      dataField: "accountName",
      text: "Account Name",
      sort: true,
      editable: true
    },
    {
      dataField: "createdAt",
      text: "Linked on",
      sort: true,
      editable: false,
      formatter: (cell, row) => {
        return moment(cell).format("DD-MMM-YYYY h:mm:ss a");
      }
    },
    {
      dataField: "lastFetched",
      text: "Last Fetch",
      sort: true,
      editable: false,
      formatter: (cell, row) => {
        return moment(cell).format("DD-MMM-YYYY h:mm:ss a");
      }
    },
    {
      text: "Actions",
      dataField: "",
      isDummyField: true,
      editable: false,
      formatter: (cell, account) => {
        let accountLinkToken = get(this.props.accountLinkTokens, account._id);
        if (accountLinkToken) {
          return (
            <>
              <PlaidUpdateLinkButton
                linkToken={accountLinkToken}
                onUpdate={this.props.onUpdateAccount}
              />

              <Button
                color="neutral-danger"
                onClick={() => this.deleteAccount(account._id)}
                className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center"
              >
                <FontAwesomeIcon icon={faTimes} className="font-size-sm" />
              </Button>
            </>
          );
        } else {
          return (
            <>
              <UncontrolledDropdown tag="span" className="m-2">
                <DropdownToggle color="first" caret>
                  <span className="btn-wrapper--icon">
                    <FontAwesomeIcon
                      icon={faDownload}
                      className="font-size-sm"
                    />
                  </span>
                  <span className="btn-wrapper--label">Fetch</span>
                </DropdownToggle>
                <DropdownMenu>
                  <div role="menuitem">
                    <a
                      className="dropdown-item"
                      href="#/"
                      onClick={() => this.fetchAccount(account._id, 7)}
                    >
                      Last 7 days
                    </a>
                  </div>
                  <div role="menuitem">
                    <a
                      className="dropdown-item"
                      href="#/"
                      onClick={() => this.fetchAccount(account._id, 14)}
                    >
                      Last 14 days
                    </a>
                  </div>
                  <div role="menuitem">
                    <a
                      className="dropdown-item"
                      href="#/"
                      onClick={() => this.fetchAccount(account._id, 30)}
                    >
                      Last 30 days
                    </a>
                  </div>
                  <div role="menuitem">
                    <a
                      className="dropdown-item"
                      href="#/"
                      onClick={() => this.fetchAccount(account._id, 60)}
                    >
                      Last 60 days
                    </a>
                  </div>
                  <div role="menuitem">
                    <a
                      className="dropdown-item"
                      href="#/"
                      onClick={() => this.fetchAccount(account._id, 180)}
                    >
                      Last 180 days
                    </a>
                  </div>
                  <div role="menuitem">
                    <a
                      className="dropdown-item"
                      href="#/"
                      onClick={() => this.fetchAccount(account._id, 360)}
                    >
                      Last 360 days
                    </a>
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown>
              <Button
                color="neutral-danger"
                onClick={() => this.deleteAccount(account._id)}
                className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center"
              >
                <FontAwesomeIcon icon={faTimes} className="font-size-sm" />
              </Button>
            </>
          );
        }
      }
    }
  ];

  fetchAccount = (accountId, daysToFetch) => {
    this.setState({ accountToFetch: accountId, fetchingBankData: true }, () => {
      this.props.getTransactionsFromBank({
        account_id: accountId,
        days_to_fetch: daysToFetch
      });
    });
  };

  deleteAccount = accountId => {
    this.setState({ accountToDelete: accountId, showDeleteModal: true });
  };

  onDeleteCancel = () => {
    this.setState({ accountToDelete: null, showDeleteModal: false });
  };

  onDeleteSubmit = () => {
    this.setState({ showDeleteModal: false }, () => {
      this.props.onDeleteAccount(this.state.accountToDelete);
    });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      transactions: { bankTransactions }
    } = nextProps;

    if (prevState.fetchingBankData) {
      return {
        fetchingBankData: false,
        fetching: true,
        transactions: []
      };
    }

    if (prevState.fetching && !isEmpty(bankTransactions)) {
      if (!isFetching(bankTransactions)) {
        if (isSuccess(bankTransactions)) {
          const {
            data: { transactions, importedTransactions, filterPatterns }
          } = bankTransactions;

          // lets find out all the imported transactions
          if (importedTransactions)
            transactions.reduce(
              (r, txn) => (
                // eslint-disable-next-line
                (txn["imported"] = importedTransactions.includes(txn.txnId)),
                r.push(txn),
                r
              ),
              []
            );

          if (filterPatterns)
            transactions.reduce((r, txn) => {
              let filterIndex = filterPatterns.findIndex(
                pattern =>
                  isEqual(
                    omit(pattern, [
                      "_id",
                      "account",
                      "category",
                      "assetSplits"
                    ]),
                    getTxnFilterPattern(txn)
                  )
                // eslint-disable-next-line
              );

              txn["hasFilter"] = filterIndex >= 0;
              if (filterIndex >= 0) txn["filter"] = filterPatterns[filterIndex];

              r.push(txn);

              return r;
            }, []);
          return {
            transactions: bankTransactions.data.transactions,
            importedTransactions: bankTransactions.data.importedTransactions,
            filterPatterns: bankTransactions.data.filterPatterns,
            fetching: false
          };
        } else {
          return {
            transactions: [],
            importedTransactions: [],
            filterPatterns: [],
            error: bankTransactions.error,
            fetching: false
          };
        }
      }
    }
    return null;
  }

  handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, searchText, data, cellEdit }
  ) => {
    if (cellEdit) {
      const { rowId, newValue } = cellEdit;
      if (rowId && newValue) {
        let rowData = data.find(row => row._id === rowId);
        if (rowData.accountName !== newValue) {
          this.setState({ updatingName: true }, () => {
            this.props.updateAccount({
              _id: rowData._id,
              accountName: newValue
            });
          });
        }
      }
    } else if (sortField) {
      this.props.onSort(sortField, sortOrder);
    }
  };

  updateSavedTransactions = (selectedRows, updateType) => {
    this.setState({ updating: true }, () => {
      let updatedTxnIds = selectedRows.map(txn => txn.txnId);
      let updatedTxns = this.state.transactions.reduce((result, txn) => {
        if (updatedTxnIds.includes(txn.txnId)) {
          txn.hasFilter = updateType !== "import";
          txn.imported = updateType === "import";
        }
        result.push(txn);
        return result;
      }, []);

      this.setState({
        transactions: updatedTxns,
        error: null,
        createFilter: false,
        updating: false
      });
    });
  };

  render() {
    const { accounts } = this.props;
    let selectedAccount = accounts.find(
      account => account._id === this.state.accountToFetch
    );

    return (
      <div className="bordered_table">
        <div className="table-responsive-md">
          <TableWithSearch
            keyField="_id"
            data={accounts}
            totalSize={accounts.length}
            columns={this.columnProps}
            onTableChange={this.handleTableChange}
            resetQueryParams={this.handleResetQueryParams}
            enableCellEdit={true}
            remoteData={true}
            showSearch={false}
            showPagination={false}
            noDataIndicator={"No bank account found!"}
            sizePerPage={10}
            page={0}
          />
          {!this.state.fetching && !this.state.updating && selectedAccount && (
            <BankTransactions
              parsedTransactions={this.state.transactions}
              assets={this.props.assets}
              account={selectedAccount}
              onImport={this.updateSavedTransactions}
            />
          )}
          <ConfirmModal
            title={"Are you sure to delete this account?"}
            subtitle={"You cannot undo this operation."}
            type="danger"
            modalOpen={this.state.showDeleteModal}
            onCancel={this.onDeleteCancel}
            onSubmit={this.onDeleteSubmit}
            error={this.state.error}
            loading={this.state.loading}
            btnText={"Delete"}
            btnTextOnLoading={"Deleting"}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  transactions: state.transactions
});

const mapDispatchToProps = dispatch => {
  return {
    getTransactionsFromBank: data => getTransactionsFromBank(data, dispatch),
    updateAccount: data => updateAccount(data, dispatch)
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BankAccountTable);
