import React, { Component } from "react";
import { connect } from "react-redux";
import { asset_list, resetAssetList } from "../../actions/asset/assetAction";
import { getUserDetail } from "../../actions/auth/authAction";
import {
  getEntities,
  getOverallCashflow,
} from "../../actions/asset/assetAction";
import { getIntegrations } from "../../actions/integrations";

import moment from "moment-timezone";

import DashWidget from "../../shared/components/DashWidget";
import LoadingIndicator from "../../shared/components/LoadingIndicator";
import SectionHeader from "../../shared/components/SectionHeader";
import DashFilter from "../../shared/components/DashFilter";
import LaunchPad from "../../shared/components/LaunchPad";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { Button } from "reactstrap";
import { Link } from "react-router-dom";

import { Row } from "reactstrap";
import _ from "lodash";
import AssetListingTable from "./AssetListing";
import AssetLookupModal from "./AssetLookup/AssetLookupModal";
import TransactionSnapshot from "./TransactionSnapshot";
import { isFetching, isSuccess } from "../../reducers/reducerUtils";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    let queryParams = new URLSearchParams("status=active");
    if (props.currentFilter.entity) {
      queryParams.set("entity", props.currentFilter.entity);
    }
    if (props.currentFilter.status) {
      queryParams.set("status", props.currentFilter.status);
    }
    if (props.currentFilter.integration) {
      queryParams.set("integration", props.currentFilter.integration);
    }
    this.state = {
      assets: [],
      currentFilter: props.currentFilter,
      assetsCopy: [],
      loading: true,
      loadingCashflow: false,
      fetchCashflow: false,
      refreshAssets: false,
      error: null,
      searchText: "",
      showInvestor: false,
      showAssetLookup: false,
      statsLoading: true,
      initialLoad: false,
      queryParams: queryParams.toString(),
      stats: {
        cashflow: {
          currMonth: 0,
          prevMonth: 0,
        },
        noi: {
          currMonth: 0,
          prevMonth: 0,
        },
        cummCashflow: 0,

        accCOCROI: 0,
        accCOCROIAnnual: 0,
        dealOfTheMonth: null,
      },
    };
  }

  componentDidMount() {
    this.setState(
      { loading: true, statsLoading: true, initialLoad: true, error: null },
      () => {
        this.props.asset_list(this.state.queryParams);
        this.props.getEntities();
        this.props.getIntegrations();
      }
    );
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { overallCashflow } = nextProps;

    if (prevState.fetchCashflow && !prevState.loadingCashflow) {
      return {
        loadingCashflow: true,
        fetchCashflow: false,
      };
    }

    if (prevState.loadingCashflow && !isFetching(overallCashflow)) {
      if (isSuccess(overallCashflow)) {
        return {
          loadingCashflow: false,
          overallCashflow: overallCashflow.data,
        };
      } else {
        return {
          loadingCashflow: false,
          error: overallCashflow.error,
          overallCashflow: [],
        };
      }
    }

    let asst = nextProps.asset.data;
    const assetNextProps = nextProps.asset;
    let newAssetLength = asst ? asst.length : 0;
    let currentAssetLength = prevState.assets ? prevState.assets.length : 0;
    if (
      prevState.loading ||
      (newAssetLength !== currentAssetLength && !prevState.searchText)
    ) {
      if (assetNextProps.getAssetListSuccess === true) {
        return {
          assetsCopy: asst ? [...asst] : [],
          assets: asst ? [...asst] : [],
          loading: false,
        };
      } else if (assetNextProps.getAssetListSuccess === false) {
        return { loading: false, error: assetNextProps.error };
      }
    }

    return null;
  }

  onFilterChange = (filter) => {
    const currentType = new URLSearchParams(window.location.search).get("type");
    filter.assetType = currentType;

    let queryParams = new URLSearchParams(this.state.queryParams);
    if (filter.entity) {
      queryParams.set("entity", filter.entity);
    } else {
      queryParams.delete("entity");
    }
    if (filter.integration) {
      queryParams.set("integration", filter.integration);
    } else {
      queryParams.delete("integration");
    }
    if (filter.status) {
      queryParams.set("status", filter.status);
    } else {
      queryParams.delete("status");
    }

    if (filter.tag) {
      queryParams.set("tag", filter.tag);
    } else {
      queryParams.delete("tag");
    }

    this.setState(
      {
        currentFilter: filter,
        fetchCashflow: true,
        queryParams: queryParams.toString(),
      },
      () => {
        this.props.asset_list(queryParams.toString());
        this.fetchCashflow(filter);
      }
    );
  };

  toggleModal = () => {
    this.setState((nextState) => ({
      showInvestor: !nextState.showInvestor,
    }));
  };

  toggleAssetLookup = () => {
    this.setState((nextState) => ({
      showAssetLookup: !nextState.showAssetLookup,
    }));
  };

  componentDidUpdate = (prevProps, prevState) => {
    const currentType = new URLSearchParams(window.location.search).get("type");
    const { currentFilter, initialLoad } = this.state;
    if (
      currentFilter &&
      (currentFilter.assetType !== currentType || initialLoad)
    ) {
      let filter = currentFilter;
      filter.assetType = currentType;
      this.setState(
        { currentFilter: filter, initialLoad: false, fetchCashflow: true },
        () => {
          this.fetchCashflow(filter);
        }
      );
    }
  };

  fetchCashflow = (filter) => {
    let fromDate = filter.fromDate;
    let toDate = filter.toDate;
    if (!moment.isMoment(fromDate)) fromDate = moment(filter.fromDate);
    if (!moment.isMoment(toDate)) toDate = moment(filter.toDate);

    this.props.getOverallCashflow({
      fromDate: fromDate.utcOffset(0, true),
      toDate: toDate.utcOffset(0, true),
      assetType: filter.assetType,
      entity: filter.entity,
      status: filter.status,
      integration: filter.integration,
    });
  };

  searchAsset = (value) => {
    let searchedAsset = value.trim();
    //regular expression will fail if contain '\' at end.
    const length = searchedAsset.length;
    if (searchedAsset.charAt(length - 1) === "\\") {
      searchedAsset = searchedAsset.slice(0, length - 1);
    }
    if (searchedAsset === "")
      this.setState({
        assets: this.state.assetsCopy,
        searchText: searchedAsset,
      });
    else {
      let output = this.state.assetsCopy.filter(({ asset_name }) =>
        asset_name.match(new RegExp(searchedAsset, "gi"))
      );
      this.setState({ assets: output, searchText: searchedAsset });
    }
  };

  refreshAssets = () => {
    this.setState(
      {
        loading: true,
      },
      () => {
        this.props.asset_list(this.state.queryParams.toString());
      }
    );
  };

  render() {
    const { assets, loading, loadingCashflow, overallCashflow } = this.state;

    const { searchText } = this.state;

    const {
      user: { userInfo },
    } = this.props;

    let currentType = new URLSearchParams(window.location.search).get("type");
    if (assets.length === 0 && !currentType) currentType = "rental";
    if (_.isEmpty(userInfo)) {
      return <LoadingIndicator loading={_.isEmpty(userInfo)} />;
    }
    let dashboardTitle = "My Dashboard";
    switch (currentType) {
      case "rental":
        dashboardTitle = "My Rentals";
        break;
      case "note":
        dashboardTitle = "My Private Money Loans";
        break;
      case "primary":
        dashboardTitle = "My Primary";
        break;
      case "syndication":
        dashboardTitle = "My Syndications";
        break;
      case "fund":
        dashboardTitle = "My Funds";
        break;
      default:
        dashboardTitle = "My Dashboard";
        break;
    }
    if (loading) {
      return <LoadingIndicator />;
    } else
      return (
        <main className="p-5">
          <SectionHeader titleHeading={dashboardTitle}>
            <Button
              tag={Link}
              color="primary"
              className={"btn-pill mr-2"}
              to={{
                pathname: `/assets/add`,
              }}
            >
              <span className="btn-wrapper--icon">
                <FontAwesomeIcon icon={faPlus} />
              </span>
              <span className="btn-wrapper--label">Add Asset</span>
            </Button>
            <DashFilter
              onChange={this.onFilterChange}
              currentFilter={this.state.currentFilter}
            />
          </SectionHeader>
          {!searchText && assets.length === 0 && <LaunchPad />}
          {!loadingCashflow && (
            <Row className="justify-content-between align-items-center">
              {/* {this.state.investorProfile && */}
              <DashWidget title={"Cash Flow"} data={overallCashflow} />
              <DashWidget
                title={"Net Operating Income"}
                data={overallCashflow}
              />
              {currentType === "note" || currentType === "syndication" ? (
                <DashWidget title={"Investment"} data={overallCashflow} />
              ) : (
                <DashWidget title={"Equity"} data={overallCashflow} />
              )}

              <DashWidget title={"Performance"} data={overallCashflow} />
            </Row>
          )}
          {currentType ? (
            <>
              <AssetListingTable
                assets={assets}
                loading={loading}
                searchAsset={this.searchAsset}
                refreshAssets={this.refreshAssets}
                toggleAssetLookup={this.toggleAssetLookup}
                currentType={currentType}
                {...this.props}
              />
              <AssetLookupModal
                modalOpen={this.state.showAssetLookup}
                toggleModal={this.toggleAssetLookup}
              />
            </>
          ) : (
            <TransactionSnapshot
              loadingCashflow={loadingCashflow}
              overallCashflow={overallCashflow}
            />
          )}
        </main>
      );
  }
}

function mapStateToProps(state) {
  return {
    asset: state.assetList,
    user: state.userDetail,
    overallCashflow: state.transactions.overallCashflow,
    currentFilter: state.filter.currentFilter,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    asset_list: (query) => asset_list(query, dispatch),
    resetAssetList: () => resetAssetList(dispatch),
    getUserDetail: () => getUserDetail(dispatch),
    getEntities: () => getEntities(dispatch),
    getOverallCashflow: (data) => getOverallCashflow(data, dispatch),
    getIntegrations: (pagination) => getIntegrations(dispatch, pagination - 1),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);
