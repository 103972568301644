/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiDomain } from "@mdi/js";
import { mdiHomeCity } from "@mdi/js";
import { mdiHammerScrewdriver } from "@mdi/js";
import { mdiCashMultiple } from "@mdi/js";
import { mdiHomeGroup } from "@mdi/js";

import { Card, Nav, NavItem, NavLink } from "reactstrap";

const getTabname = path => {
  switch (path) {
    case "/rentals":
    case "/rentals/":
      return "RENTALS";
    case "/notes":
    case "/notes/":
      return "NOTES";
    case "/syndications":
    case "/syndications/":
      return "SYNDICATIONS";
    case "/flips":
    case "/flips/":
      return "FLIPS";
    case "/for_sale":
    case "/for_sale/":
      return "FOR_SALE";
    default:
      return "RENTALS";
  }
};

class DealSidebar extends PureComponent {
  constructor(props) {
    super(props);
    const { location } = props;
    const locationName = location.pathname;
    const tabname = getTabname(
      locationName.slice(locationName.lastIndexOf("/"))
    );
    this.state = {
      activeTab: tabname
    };
  }

  toggle = tab => {
    this.setState({ activeTab: tab });
  };

  render() {
    const { activeTab } = this.state;
    return (
      <div className="col-sm-12 col-md-4 col-lg-4 col-xl-2 font-size-l">
        <Card className="card-box mb-5">
          <div className="bg-composed-wrapper bg-primary">
            <div className="bg-composed-wrapper--content text-light p-4">
              <h4 className="mb-4">Opportunities</h4>
              <p className="mb-0 opacity-7">
                Curated better cash flow opportunities.
              </p>
            </div>
          </div>
          <Nav pills className="nav-primary flex-column p-3">
            <NavItem>
              <NavLink
                tag={Link}
                to={"/deals/"}
                onClick={e => this.toggle("RENTALS")}
                active={activeTab === "RENTALS"}
              >
                <div className="nav-link-icon">
                  <Icon
                    className="icon"
                    color={activeTab === "RENTALS" ? "#fff" : "#7a7b97"}
                    path={mdiHomeCity}
                    size={1}
                  />
                </div>
                <span>Rentals</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={Link}
                to={"/deals/notes"}
                onClick={e => this.toggle("NOTES")}
                active={activeTab === "NOTES"}
              >
                <div className="nav-link-icon">
                  <Icon
                    className="icon"
                    color={activeTab === "NOTES" ? "#fff" : "#7a7b97"}
                    path={mdiCashMultiple}
                    size={1}
                  />
                </div>
                <span>Notes</span>
                {/*TODO add the number <Badge color="first" className="ml-auto">
                  23
                </Badge>*/}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={Link}
                to={"/deals/syndications"}
                onClick={e => this.toggle("SYNDICATIONS")}
                active={activeTab === "SYNDICATIONS"}
              >
                <div className="nav-link-icon">
                  <Icon
                    className="icon"
                    color={activeTab === "SYNDICATIONS" ? "#fff" : "#7a7b97"}
                    path={mdiDomain}
                    size={1}
                  />
                </div>
                <span>Syndication</span>
                {/*TODO add the number <Badge color="first" className="ml-auto">
                  23
                </Badge>*/}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={Link}
                to={"/deals/flips"}
                onClick={e => this.toggle("FLIPS")}
                active={activeTab === "FLIPS"}
              >
                <div className="nav-link-icon">
                  <Icon
                    className="icon"
                    color={activeTab === "FLIPS" ? "#fff" : "#7a7b97"}
                    path={mdiHammerScrewdriver}
                    size={1}
                  />
                </div>
                <span>Flips</span>
                {/*TODO add the number <Badge color="first" className="ml-auto">
                  23
                </Badge>*/}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={Link}
                to={"/deals/for_sale"}
                onClick={e => this.toggle("FOR_SALE")}
                active={activeTab === "FOR_SALE"}
              >
                <div className="nav-link-icon">
                  <Icon
                    className="icon"
                    color={activeTab === "FOR_SALE" ? "#fff" : "#7a7b97"}
                    path={mdiHomeGroup}
                    size={1}
                  />
                </div>
                <span>For Sale</span>
                {/*TODO add the number <Badge color="first" className="ml-auto">
                  23
                </Badge>*/}
              </NavLink>
            </NavItem>
          </Nav>
        </Card>
      </div>
    );
  }
}

export default DealSidebar;
