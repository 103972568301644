import { FV, PMT, CUMPRINC, PPMT, IPMT } from "formulajs";
import { unmaskCurrency } from "../helpers";
import Moment from "moment";
import { extendMoment } from "moment-range";

const vacancyLossPercent = 0.05;
const annualOperatingExpenseIncrease = 0.02;
const annualRevenueIncrease = 0.02;
const closingCostPercent = 0.03;
const moment = extendMoment(Moment);

function getYearlyAmount(financials, category) {
  const type = category === "RENT" ? "INCOME" : "EXPENSE";
  const items = financials.filter(
    f => f.type === type && f.category === category
  );
  let amount = 0;
  if (items.length === 0) return amount;
  items.forEach((item, i) => {
    if (item.frequency === "Monthly") {
      amount += item.amount * 12;
    } else if (item.frequency === "Yearly") {
      amount += item.amount;
    }
  });

  return amount;
}

function getEquityAccrued(rate, periods, principal, year) {
  let previousYear = 0;
  if (year === 1) previousYear = 0;
  else if (year === 2) previousYear = 1;
  else if (year === 5) previousYear = 2;
  else if (year === 10) previousYear = 5;

  const equityStartPeriod = year + previousYear * 12;
  const equityEndPeriod = year * 12;
  return CUMPRINC(
    rate / 100,
    periods,
    principal,
    equityStartPeriod,
    equityEndPeriod,
    0
  );
}

export function getKPIsFromActuals(
  basicInfo,
  cashflow,
  financials,
  year,
  dateFilter
) {
  if (basicInfo.assetType === "note") {
    const noteFinancial = financials.find(
      financial => financial.category === "INTEREST"
    );
    if (!noteFinancial) return {};

    // We need to see for the given date range how many payments
    // are remaining
    let { fromDate, toDate } = dateFilter;
    toDate = moment.min(moment(basicInfo.investmentEndDate), moment(toDate));
    fromDate = moment.max(
      moment(basicInfo.investmentStartDate),
      moment(fromDate)
    );
    // we need to make sure we take into consideration monthly, Quarterly, yearly
    const no_of_payments = Math.ceil(toDate.diff(fromDate, "months", true));

    const dateRange = moment().range(fromDate, toDate);

    const filterCashflow = cashflow.filter(data => {
      return dateRange.contains(moment(data.date, "MMM-YYYY"));
    });

    const monthlyPayment =
      (unmaskCurrency(noteFinancial.meta.interest) *
        unmaskCurrency(noteFinancial.meta.investment_amount)) /
      1200;

    let payoffTotal = cashflow.reduce((r, month) => {
      // eslint-disable-next-line
      Object.keys(month.incomeByCategory).map(category => {
        // TODO: we need to do bit more verification
        if (category.search(/payoff|principal/gi) > -1)
          r = r + month.incomeByCategory[category];
      });
      return r;
    }, 0);

    return {
      investment_amount: unmaskCurrency(noteFinancial.meta.investment_amount),
      interest_rate: unmaskCurrency(noteFinancial.meta.interest),
      monthly_payment: monthlyPayment,
      payments_remaining: no_of_payments - filterCashflow.length,
      principal_remaining:
        unmaskCurrency(noteFinancial.meta.investment_amount) - payoffTotal
    };
  } else if (basicInfo.assetType === "syndication") {
    const syndicationFinancial = financials.find(
      financial => financial.category === "DISTRIBUTION"
    );

    if (!syndicationFinancial) return {};

    // We need to see for the given date range how many payments
    // are remaining
    let { fromDate, toDate } = dateFilter;
    toDate = moment.min(moment(basicInfo.investmentEndDate), moment(toDate));
    fromDate = moment.max(
      moment(basicInfo.investmentStartDate),
      moment(fromDate)
    );
    const dateRange = moment().range(fromDate, toDate);

    const filterCashflow = cashflow.filter(data => {
      return dateRange.contains(moment(data.date, "MMM-YYYY"));
    });

    let investmentAmount = unmaskCurrency(
      syndicationFinancial.meta.investment_amount
    );

    let kpi = {
      investment_amount: investmentAmount,
      projected_annualized_irr: unmaskCurrency(
        syndicationFinancial.meta.annualized_irr
      )
    };
    const no_of_payments = Math.ceil(toDate.diff(fromDate, "months", true));

    const grossIncome = cashflow.reduce((result, row) => {
      result = result + row.income;
      return result;
    }, 0);

    if (syndicationFinancial.frequency === "Quarterly") {
      let quarterly_payment = grossIncome / filterCashflow.length;
      kpi["quarterly_payment"] = quarterly_payment;
      kpi["annual_coc"] = (quarterly_payment * 400) / investmentAmount;
      kpi["payments_remaining"] =
        Math.floor(no_of_payments / 3) - filterCashflow.length;
    } else {
      let monthly_payment = grossIncome / filterCashflow.length;
      kpi["monthly_payment"] = monthly_payment;
      kpi["annual_coc"] = (monthly_payment * 1200) / investmentAmount;
      kpi["payments_remaining"] = no_of_payments - filterCashflow.length;
    }
    return kpi;
  } else {
    const downPaymentPercent =
      basicInfo.downPayment > 0
        ? (basicInfo.downPayment * 100) / basicInfo.purchaseValue
        : 100;
    const purchasePrice = basicInfo.purchaseValue;
    const marketValue = basicInfo.marketValue;
    const no_of_months = cashflow.length;
    const downPayment = (purchasePrice * downPaymentPercent) / 100;
    const principal = purchasePrice - downPayment;
    const mortgageInfo = financials.find(
      financial => financial.category === "MORTGAGE"
    );
    // TODO add the finanical metadata for mortgage
    let equityAccrued = -getEquityAccrued(4.13 / 12, 360, principal, 1);
    if (mortgageInfo && mortgageInfo.meta) {
      let metadata = mortgageInfo.meta;
      if (metadata.interest_rate && metadata.loan_amount) {
        let rate = Number(unmaskCurrency(metadata.interest_rate));
        let loan_amount = Number(unmaskCurrency(metadata.loan_amount));
        equityAccrued = -getEquityAccrued(rate / 12, 360, loan_amount, 1);
      }
    }
    const grossIncome = cashflow.reduce((result, row) => {
      result = result + row.income;
      return result;
    }, 0);
    const grossIncomeMonthly = grossIncome / no_of_months;
    const grossExpense = cashflow.reduce((result, row) => {
      result = result + row.expense;
      return result;
    }, 0);
    const grossExpenseMonthly = grossExpense / no_of_months;

    // TODO: we need to calcuate if the property tax, insurance are included.
    // const totalExpense = propertyTax + insurance + capExp + pmFee;

    // TODO: change this, financing info should be passed in.
    const netOperatingIncome =
      grossIncomeMonthly * 12 - grossExpenseMonthly * 12;
    // const totalMortgage = mortgageMonthly * 12;
    // const totalCashFlow = netOperat/ingIncome - totalMortgage;

    // TOOD: we need to make sure if the mortgage is included in the transactions
    const totalCashFlow = netOperatingIncome;

    // TODO: we need to capture if there was a purchase done in this year
    // and it involved closing costs,
    // const closingCost = closingCostPercent * purchasePrice;
    // const repairCost = data.rehabEstimate || 0;
    // const cashOutlay = downPayment + closingCost + repairCost;
    const cashOutlay = downPayment;
    const cashROI = totalCashFlow / cashOutlay;
    const purchaseCapRate = (grossIncomeMonthly * 12) / purchasePrice;
    // const fiftyPercentRule = repairCost / grossIncome;
    // const twoPercentRule = grossIncome / 12 / purchasePrice;
    const totalReturn = totalCashFlow + (equityAccrued || 0);
    // TODO: add mortgage fee if any
    let totalROI = totalReturn / downPayment;

    // if (downPayment > 0 || closingCost > 0) {
    // totalROI = totalReturn / (downPayment + closingCost);
    // }

    return {
      noi: (totalCashFlow / 12) * no_of_months,
      cash_roi: cashROI * 100,
      purchase_cap: purchaseCapRate * 100,
      total_roi: totalROI * 100,
      equity: marketValue - principal
      // unlevered_cap: ((totalCashFlow / 12) * no_of_months) / purchasePrice,
      // levered_cap: ((totalCashFlow / 12) * no_of_months) /
      // operating_expense_return: (grossExpense * 100) / totalCashFlow
    };
  }
}

export function yearlyProjection(data, year) {
  // We need to return the following things
  // Monthly Income, Monthly Expenses,
  // Cash Flow, Cash ROI, Net Operating Income,
  // Purchase Cap Rate

  // Rental Income
  let rentalIncome = data.marketRent * 12;
  const currentLease = getYearlyAmount(data.financials, "RENT");
  if (!data.useMarketRent && currentLease) {
    rentalIncome = currentLease;
  }
  let propertyTax = getYearlyAmount(data.financials, "REAL_ESTATE_TAXES");
  let insurance = getYearlyAmount(data.financials, "INSURANCE");
  let capExp = getYearlyAmount(data.financials, "CAPITAL EXPENDITURE");
  let pmFee = getYearlyAmount(data.financials, "PROPERTY MANAGEMENT");

  if (year !== 0) {
    rentalIncome = FV(annualRevenueIncrease, year - 1, 0, -rentalIncome);
    propertyTax = FV(annualOperatingExpenseIncrease, year - 1, 0, -propertyTax);
    insurance = FV(annualOperatingExpenseIncrease, year - 1, 0, -insurance);
    capExp = FV(annualOperatingExpenseIncrease, year - 1, 0, -capExp);
    pmFee = FV(annualOperatingExpenseIncrease, year - 1, 0, -pmFee);
  }

  const vacancyLoss = rentalIncome * vacancyLossPercent;
  const grossIncome = rentalIncome - vacancyLoss;
  const totalExpense = propertyTax + insurance + capExp + pmFee;
  const downPaymentPercent = data.downPaymentPercent;

  // TODO: change this, financing info should be passed in.
  const netOperatingIncome = grossIncome - totalExpense;
  const downPayment = (data.purchasePrice * downPaymentPercent) / 100;
  const purchasePrice = data.purchasePrice;
  const principal = purchasePrice - downPayment;
  const equityAccrued = -getEquityAccrued(4.13 / 12, 360, principal, year);
  const mortgageMonthly = -PMT(4.13 / 1200, 360, principal);
  const totalMortgage = mortgageMonthly * 12;
  const totalCashFlow = netOperatingIncome - totalMortgage;
  const closingCost = closingCostPercent * purchasePrice;
  const repairCost = data.rehabEstimate || 0;
  const cashOutlay = downPayment + closingCost + repairCost;
  const cashROI = totalCashFlow / cashOutlay;
  const purchaseCapRate = rentalIncome / purchasePrice;
  const fiftyPercentRule = repairCost / grossIncome;
  const twoPercentRule = rentalIncome / 12 / purchasePrice;
  const totalReturn = totalCashFlow + (equityAccrued || 0);
  // TODO: add mortgage fee if any
  let totalROI = totalReturn;
  if (downPayment > 0 || closingCost > 0) {
    totalROI = totalReturn / (downPayment + closingCost);
  }
  return {
    purchasePrice: purchasePrice,
    rentalIncome: rentalIncome,
    vacancyLoss: vacancyLoss,
    grossIncome: grossIncome,
    propertyTaxes: propertyTax,
    insurance: insurance,
    capitalExpenditure: capExp.toFixed(2),
    totalExpense: totalExpense.toFixed(2),
    netOperatingIncome: netOperatingIncome.toFixed(2),
    totalCashFlow: totalCashFlow.toFixed(2),
    cashROI: (cashROI * 100).toFixed(2),
    purchaseCapRate: purchaseCapRate.toFixed(2),
    fiftyPercentRule: fiftyPercentRule.toFixed(2),
    twoPercentRule: twoPercentRule.toFixed(2),
    downPayment: downPayment,
    downPaymentPercent: downPaymentPercent,
    useMarketRent: data.useMarketRent,
    dealSalesPrice: data.dealSalesPrice,
    totalMortgage: totalMortgage.toFixed(2),
    equityAccrued: equityAccrued.toFixed(2),
    totalROI: (totalROI * 100).toFixed(2),
    totalReturn: totalReturn.toFixed(2)
  };
}

export function analyzeAsset(data) {
  return {
    current: yearlyProjection(data, 1),
    future: {
      2: yearlyProjection(data, 2),
      5: yearlyProjection(data, 5),
      10: yearlyProjection(data, 10)
    }
  };
}

export function calculatePayment(values, category, frequency) {
  let term;
  switch (frequency) {
    case "Yearly":
      term = 1;
      break;
    case "Quarterly":
      term = 4;
      break;
    default:
    case "Monthly":
      term = 12;
      break;
  }
  if (category === "MORTGAGE") {
    return -PMT(
      unmaskCurrency(values.interest) / (term * 100),
      unmaskCurrency(values.term) * term,
      unmaskCurrency(values.principal)
    );
  } else if (category === "INTEREST") {
    // Simple Interest
    return (
      ((unmaskCurrency(values.interest) / 100) *
        unmaskCurrency(values.investment_amount)) /
      term
    );
    // const no_of_months = moment(values.loan_end).diff(
    //   moment(values.loan_start),
    //   "months"
    // );
    // if (values.amortized) {
    //   newData.amount = -PMT(
    //     values.interest / 1200,
    //     no_of_months,
    //     values.investment_amount.replace(/,/g, "")
    //   );
    // }
  } else if (category === "DISTRIBUTION") {
    return (
      ((unmaskCurrency(values.annual_coc) / 100) *
        unmaskCurrency(values.investment_amount)) /
      term
    );
  }
  return 0;
}

export function calculateMortgage(values) {
  let amount = 0;
  // if the principal is 0, lets compute it
  if (values && !values.principal) {
    const { loan_amount, interest_rate, term } = values;
    if (loan_amount && interest_rate && term) {
      values.principal =
        -PPMT(
          unmaskCurrency(interest_rate) / 1200,
          1,
          12 * unmaskCurrency(term),
          unmaskCurrency(loan_amount)
        ) || 0;

      values.interest =
        -IPMT(
          unmaskCurrency(interest_rate) / 1200,
          1,
          12 * unmaskCurrency(term),
          unmaskCurrency(loan_amount)
        ) || 0;
    }
  }

  amount = values.principal ? parseFloat(unmaskCurrency(values.principal)) : 0;
  amount += values.interest ? parseFloat(unmaskCurrency(values.interest)) : 0;
  amount += values.escrow ? parseFloat(unmaskCurrency(values.escrow)) : 0;
  amount += values.pmi ? parseFloat(unmaskCurrency(values.pmi)) : 0;
  return amount;
}
