import React, { PureComponent } from "react";
import { Button, Badge } from "reactstrap";
import { deleteDocument } from "../../../actions/asset/assetAction";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { faLink, faTrash } from "@fortawesome/free-solid-svg-icons";

import TableWithSearch from "../../../shared/components/TableWithSearch";
import DeleteIcon from "../../../assets/images/delete-icon.png";

class DocumentsTable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 5,
      selectedIds: []
    };
  }
  selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect, rowIndex) => {
      if (isSelect) {
        this.setState({ selectedIds: [...this.state.selectedIds, row._id] });
      } else {
        this.setState({
          selectedIds: this.state.selectedIds.filter(id => id !== row._id)
        });
      }
    },
    onSelectAll: (isSelect, rows) => {
      const ids = rows.map(r => r._id);
      if (isSelect) {
        this.setState({ selectedIds: ids });
      } else {
        this.setState({ selectedIds: [] });
      }
    }
  };
  columnProps = [
    {
      dataField: "originalName",
      text: "Name",
      sort: true,
      style: {
        maxWidth: "200px"
      }
    },
    /*{
      dataField: "storage",
      text: "Storage Location",
      sort: true,
      formatter: (cell, row) => {
        return row.storage === "s3" ? "Native" : "Drive";
      }
    },
    {
      dataField: "source",
      text: "Uploaded via",
      sort: true,
      align: 'center',
       style: {
      fontWeight: 'Semibold',
      fontSize: '14px',
      color:'#171725'
    }
    },
    {
      dataField: "uploadedBy",
      text: "Uploaded By",
      sort: true,
      formatter: (cell, row) => {}
    },*/
    {
      dataField: "assetId",
      text: "Asset",
      sort: true,
      formatter: (cell, row) => {
        if (cell) {
          return <Badge color={"primary"}>{cell.asset_name}</Badge>;
        } else {
          return (
            <Button
              color="neutral-warning"
              className={"btn-sm mr-2"}
              onClick={() => this.props.toggleLinkToAssetModal(row)}
            >
              <span className="btn-wrapper--icon">
                <FontAwesomeIcon icon={faLink} />
              </span>
              <span className="btn-wrapper--label">Link to asset</span>
            </Button>
          );
        }
      }
    },
    {
      text: "Tags",
      dataField: "tags",
      formatter: (cell, row) => {
        return cell.map((tag, idx) => {
          return (
            <Badge key={`tag-${idx}`} color="badge badge-first m-2">
              {tag}
            </Badge>
          );
        });
      }
    },
    {
      text: "Actions",
      dataField: "",
      isDummyField: true,
      formatter: (cell, row) => {
        const { assetId } = row;
        return (
          <>
            <Button
              className={"btn-sm btn-neutral-primary mr-2"}
              onClick={() => {
                if (row.storage === "drive")
                  return this.props.openFile(row._id, assetId && assetId.slug);
                else
                  return this.props.toggleViewFileModal(
                    row._id,
                    assetId && assetId.slug
                  );
              }}
            >
              <FontAwesomeIcon
                icon={row.storage === "drive" ? faExternalLinkAlt : faEye}
              />
            </Button>
            <Button
              className={"btn-sm btn-neutral-danger"}
              onClick={() => {
                this.props.toggleDeleteFileModal(
                  this.state.selectedIds.length > 0
                    ? this.state.selectedIds
                    : row._id,
                  assetId && assetId.slug
                );
                this.setState({ selectedIds: [] });
              }}
            >
              <div>
                <img src={DeleteIcon} alt="deleteicon" />
              </div>
            </Button>
          </>
        );
      }
    }
  ];

  handleDeleteDocument = id => {
    deleteDocument(id);
  };

  handleTableChange = (type, attrs) => {
    const { page, sizePerPage } = attrs;
    this.setState({ page, sizePerPage }, () => {
      this.props.handleTableChange(type, attrs);
    });
  };

  render() {
    const { documents, totalCount } = this.props;
    return (
      <TableWithSearch
        keyField="_id"
        data={documents}
        totalSize={totalCount}
        page={this.state.page}
        sizePerPage={this.state.sizePerPage}
        columns={this.columnProps}
        onTableChange={this.handleTableChange}
        searchPlaceholder="Search Documents..."
        noDataIndicator="No Documents found!"
        onSelectRow={this.selectRow}
      >
        <Button
          color="danger"
          className="mx-2"
          disabled={this.state.selectedIds.length === 0}
          onClick={() => {
            this.props.toggleDeleteFileModal(this.state.selectedIds, "");
            this.setState({ selectedIds: [] });
          }}
        >
          <span className="btn-wrapper--icon">
            <FontAwesomeIcon icon={faTrash} />
          </span>
          <span className="btn-wrapper--label">Delete</span>
        </Button>
      </TableWithSearch>
    );
  }
}

export default DocumentsTable;
