import React, { Component } from "react";
import { Field, withFormik } from "formik";
import { Button, Col, Row, FormGroup, Label } from "reactstrap";
import MaskedField from "../../../../shared/components/form/MaskedField";
import * as Yup from "yup";
import { isFetching, isSuccess } from "../../../../reducers/reducerUtils";
import { connect } from "react-redux";
import ZillowTOS from "../../../../shared/components/ZillowTOS";
import EntitySelect from "../../../../shared/components/form/EntitySelect";
import customSelect from "../../../../shared/components/form/customSelect";
import FormField from "../../../../shared/components/form/FormField";

import { lookupParcel } from "../../../../actions/asset/assetAction";

class Investment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      parcelInfo: null,
      error: null
    };
  }
  componentDidMount = () => {
    const { asset } = this.props;
    if (asset && asset.parcel_id && !asset.parcelInfo) {
      this.setState({ loading: true }, () => {
        this.props.lookupParcel(asset.parcel_id);
      });
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { parcelLookup } = nextProps;
    if (prevState.loading && !isFetching(parcelLookup)) {
      if (isSuccess(parcelLookup)) {
        return {
          loading: false,
          parcelInfo: parcelLookup.data
        };
      } else {
        return {
          loading: false,
          error: parcelLookup.error
        };
      }
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { parcelInfo } = this.state;
    if (parcelInfo && !prevState.parcelInfo) {
      this.props.setFieldValue(`purchase_date`, parcelInfo.purchase_date);
      this.props.setFieldValue(`purchase_price`, parcelInfo.purchase_price);
      this.props.setFieldValue(`down_payment`, parcelInfo.down_payment);
      this.props.setFieldValue(`market_value`, parcelInfo.market_value);
      this.props.setFieldValue(`market_rent`, parcelInfo.market_rent);
      this.props.setFieldValue(`parcelInfo`, parcelInfo);
    }
  };

  rentalFields = () => {
    return (
      <Row className="p-4" xl={2}>
        <Col md={6}>
          <FormGroup>
            <Label>Purchase date</Label>
            <Field
              name="purchase_date"
              component={MaskedField}
              type="text"
              addonSize={"lg"}
              addonType={"prepend"}
              addonText={"$"}
              maskType="date"
            />
          </FormGroup>

          <FormGroup>
            <Label>Purchase Price</Label>
            <Field
              name="purchase_price"
              component={MaskedField}
              type="text"
              addonSize={"lg"}
              addonType={"prepend"}
              addonText={"$"}
              maskType="currency"
            />
          </FormGroup>
          <FormGroup>
            <Label>Down Payment</Label>
            <Field
              name="down_payment"
              component={MaskedField}
              type="text"
              addonSize={"lg"}
              addonType={"prepend"}
              addonText={"$"}
              maskType="currency"
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>Market Value</Label>
            <Field
              name="market_value"
              component={MaskedField}
              type="text"
              addonSize={"lg"}
              addonType={"prepend"}
              addonText={"$"}
              maskType="currency"
            />
          </FormGroup>
          <FormGroup>
            <Label>Market Rent</Label>
            <Field
              name="market_rent"
              component={MaskedField}
              type="text"
              addonSize={"lg"}
              addonType={"prepend"}
              addonText={"$"}
              maskType="currency"
            />
          </FormGroup>
          <ZillowTOS />
        </Col>
      </Row>
    );
  };

  notesFields = () => {
    return (
      <Row className="p-4" xl={2}>
        <Col md={6}>
          <FormGroup>
            <Label>Investment Amount</Label>
            <Field
              name="investment_amount"
              component={MaskedField}
              addonSize={"lg"}
              addonType={"prepend"}
              addonText={"$"}
              maskType="currency"
            />
          </FormGroup>
          <FormGroup>
            <Label>Interest Rate</Label>
            <Field
              name="note_interest"
              component={MaskedField}
              addonSize={"lg"}
              addonType={"prepend"}
              addonText={"%"}
              maskType="percent"
            />
          </FormGroup>
          <FormGroup>
            <Label>Points</Label>
            <Field
              name="note_points"
              component={MaskedField}
              addonSize={"lg"}
              addonType={"prepend"}
              addonText={"%"}
              maskType="percent"
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>Investment Start Date</Label>{" "}
            <Field
              name="investment_start_date"
              component={MaskedField}
              type="text"
              addonSize={"lg"}
              addonType={"prepend"}
              addonText={"$"}
              maskType="date"
            />
          </FormGroup>
          <FormGroup>
            <Label>Investment End Date</Label>
            <Field
              name="investment_end_date"
              component={MaskedField}
              type="text"
              addonSize={"lg"}
              addonType={"prepend"}
              addonText={"$"}
              maskType="date"
            />
          </FormGroup>
          <FormGroup>
            <Label>Distribution Frequency</Label>
            <Field
              name="distribution_frequency"
              component={customSelect}
              options={[
                { value: "Monthly", label: "Monthly" },
                { value: "Quarterly", label: "Quarterly" }
              ]}
            />
          </FormGroup>
        </Col>{" "}
      </Row>
    );
  };

  syndicationFields = () => {
    return (
      <Row className="p-4" xl={2}>
        <Col md={6}>
          <FormGroup>
            <Label>Syndication Name</Label>
            <Field name="address" component={FormField} />
          </FormGroup>
          <FormGroup>
            <Label>Entity Name</Label>
            <Field name="entity" component={EntitySelect} />
          </FormGroup>
          <FormGroup>
            <Label>Investment Amount</Label>
            <Field
              name="investment_amount"
              component={MaskedField}
              addonSize={"lg"}
              addonType={"prepend"}
              addonText={"$"}
              maskType="currency"
            />
          </FormGroup>

          <FormGroup>
            <Label>Investment Start Date</Label>{" "}
            <Field
              name="investment_start_date"
              component={MaskedField}
              type="text"
              addonSize={"lg"}
              addonType={"prepend"}
              addonText={"$"}
              maskType="date"
            />
          </FormGroup>
          <FormGroup>
            <Label>Investment End Date</Label>
            <Field
              name="investment_end_date"
              component={MaskedField}
              type="text"
              addonSize={"lg"}
              addonType={"prepend"}
              addonText={"$"}
              maskType="date"
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>Annual Rate (Projected)</Label>
            <Field
              name="annual_coc"
              component={MaskedField}
              addonSize={"lg"}
              addonType={"prepend"}
              addonText={"%"}
              maskType="percent"
            />
          </FormGroup>
          <FormGroup>
            <Label>Annualized Return (Projected)</Label>
            <Field
              name="annualized_irr"
              component={MaskedField}
              addonSize={"lg"}
              addonType={"prepend"}
              addonText={"%"}
              maskType="percent"
            />
          </FormGroup>
          <FormGroup>
            <Label>Distribution Frequency</Label>
            <Field
              name="distribution_frequency"
              component={customSelect}
              options={[
                { value: "Monthly", label: "Monthly" },
                { value: "Quarterly", label: "Quarterly" }
              ]}
            />
          </FormGroup>
        </Col>{" "}
      </Row>
    );
  };
  flipField = () => {
    return (
      <Row className="p-4" xl={2}>
        <Col md={6}>
          <FormGroup>
            <Label>Purchase date</Label>
            <Field
              name="purchase_date"
              component={MaskedField}
              type="text"
              addonSize={"lg"}
              addonType={"prepend"}
              addonText={"$"}
              maskType="date"
            />
          </FormGroup>
          <FormGroup>
            <Label>Purchase Price</Label>
            <Field
              name="purchase_price"
              component={MaskedField}
              type="text"
              addonSize={"lg"}
              addonType={"prepend"}
              addonText={"$"}
              maskType="currency"
            />
          </FormGroup>
          <FormGroup>
            <Label>Down Payment</Label>
            <Field
              name="down_payment"
              component={MaskedField}
              type="text"
              addonSize={"lg"}
              addonType={"prepend"}
              addonText={"$"}
              maskType="currency"
            />
          </FormGroup>
          <FormGroup>
            <Label>Rehab Estimate</Label>
            <Field
              name="rehab_estimate"
              component={MaskedField}
              addonSize={"lg"}
              addonType={"prepend"}
              addonText={"$"}
              maskType="currency"
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>After Repair Value</Label>
            <Field
              name="after_repair_value"
              component={MaskedField}
              addonSize={"lg"}
              addonType={"prepend"}
              addonText={"$"}
              maskType="currency"
            />
          </FormGroup>
          <FormGroup>
            <Label>Market Value</Label>
            <Field
              name="market_value"
              component={MaskedField}
              type="text"
              addonSize={"lg"}
              addonType={"prepend"}
              addonText={"$"}
              maskType="currency"
            />
          </FormGroup>
          <FormGroup>
            <Label>Market Rent</Label>
            <Field
              name="market_rent"
              component={MaskedField}
              type="text"
              addonSize={"lg"}
              addonType={"prepend"}
              addonText={"$"}
              maskType="currency"
            />
          </FormGroup>
          <ZillowTOS />
        </Col>
      </Row>
    );
  };

  render() {
    const {
      values,
      backHandler,
      handleSubmit,
      isSubmitting,
      cantBack
    } = this.props;
    let content;

    switch (values["investment_type"]) {
      case "syndication":
        content = this.syndicationFields();
        break;
      case "note":
        content = this.notesFields();
        break;
      case "flip":
        content = this.flipField();
        break;
      case "rental":
      case "primary":
      default:
        content = this.rentalFields();
        break;
    }
    return (
      <div className="wizard-steps horizontal">
        <form onSubmit={handleSubmit} className="px-2">
          {content}
          <div className={"d-flex justify-content-end col-12 py-3"}>
            {values["investment_type"] !== "syndication" &&
              values["investment_type"] !== "fund" && (
                <Button
                  className={"mr-4"}
                  outline
                  color={"primary"}
                  onClick={() => backHandler(values)}
                  disabled={cantBack || isSubmitting}
                >
                  Prev
                </Button>
              )}
            <Button color={"primary"} type="submit" disabled={isSubmitting}>
              {values["investment_type"] === "syndication" ||
              values["investment_type"] === "note" ||
              values["investment_type"] === "fund" ? (
                <>Submit</>
              ) : (
                <>Next</>
              )}
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

const InvestmentForm = withFormik({
  mapPropsToValues: props => ({
    parcel_id: props.asset.parcel_id,
    investment_type: props.investment_type || "rental",
    entity: props.asset.entity,
    user_role: props.user_role,
    address: props.asset.address,
    beds: props.asset.beds,
    baths: props.asset.baths,
    co_ordinates: props.asset.co_ordinates,
    units: props.asset.units,
    stories: props.asset.stories,
    asset_type: props.asset.asset_type,
    lotSizeSquareFeet: props.asset.lotSizeSquareFeet,
    areaSquareFeet: props.asset.areaSquareFeet,
    apn: props.asset.apn,
    county: props.asset.county,
    yearBuilt: props.asset.yearBuilt,
    imageUrl: props.asset.imageUrl,
    management_platform: props.asset.management_platform,
    purchase_date: props.asset.purchase_date,
    purchase_price: props.asset.purchase_price,
    down_payment: props.asset.down_payment,
    market_value: props.asset.market_value,
    market_rent: props.asset.market_rent,
    property_tax: props.asset.property_tax,
    insurance: props.asset.insurance,
    hoa_fee: props.asset.hoa_fee,
    self_managed: props.asset.self_managed,
    management_fee: props.asset.management_fee,
    rent: props.asset.rent,
    rents: props.asset.rents
  }),

  validationSchema: Yup.object().shape({}),
  handleSubmit: (values, { props }) => {
    values.rents = [];
    if (values.units > 1) {
      for (var i = 1; i <= values.units; i++) {
        values.rents.push({ name: i, amount: "" });
      }
    }
    props.nextHandler(values);
  }
})(Investment);

const mapStateToProps = state => ({
  parcelLookup: state.asset.parcelLookup
});

const mapDispatchToProps = dispatch => ({
  lookupParcel: parcelID => lookupParcel(parcelID, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvestmentForm);
