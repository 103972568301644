import {
  UPLOAD_FILE_FAILURE,
  UPLOAD_FILE_SUCCESS
} from "../actions/actionTypes";

const initialState = {
  url: ""
};

export default function(state = initialState, action) {
  switch (action.type) {
    case UPLOAD_FILE_SUCCESS:
      return {
        displayUrl: action.payload.displayUrl,
        fileName: action.payload.fileName,
        fileId: action.payload.fileId,
        uploadFileSuccess: true
      };
    case UPLOAD_FILE_FAILURE:
      return {
        uploadFileSuccess: false,
        error: "Unable to upload Image"
      };
    default:
      return state;
  }
}
