import { put, call, takeEvery, takeLatest } from "redux-saga/effects";
import { Contact } from "../api/agent";

import {
  ADD_ASSET_CONTACT,
  ADD_ASSET_CONTACT_SUCCESS,
  ADD_ASSET_CONTACT_FAILURE,
  UPDATE_ASSET_CONTACT,
  UPDATE_ASSET_CONTACT_SUCCESS,
  UPDATE_ASSET_CONTACT_FAILURE,
  DELETE_ASSET_CONTACT,
  DELETE_ASSET_CONTACT_FAILURE,
  DELETE_ASSET_CONTACT_SUCCESS,
  GET_ASSET_CONTACTS,
  GET_ASSET_CONTACTS_SUCCESS,
  GET_ASSET_CONTACTS_FAILURE,
  GET_ALL_CONTACTS,
  GET_ALL_CONTACTS_SUCCESS,
  GET_ASSET_CHECKLIST,
  ADD_CONTACT,
  ADD_CONTACT_SUCCESS,
  ADD_CONTACT_FAILURE,
  UPDATE_CONTACT,
  UPDATE_CONTACT_SUCCESS,
  UPDATE_CONTACT_FAILURE,
  DELETE_CONTACT,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT_FAILURE,
  GET_CONTACT_CONVERSATION,
  GET_CONTACT_CONVERSATION_SUCCESS,
  GET_CONTACT_CONVERSATION_FAILURE
} from "../actions/actionTypes";
import showToast from "../helpers/toastNotification";

// GET asset contact saga
function* getAssetContacts(action) {
  try {
    const payload = yield call(Contact.getAssetContacts, action.payload);
    yield put({ type: GET_ASSET_CONTACTS_SUCCESS, payload });
  } catch (error) {
    const err = error.response.body;
    yield put({ type: GET_ASSET_CONTACTS_FAILURE, err });
  }
}
export function* watchGetAssetContacts() {
  yield takeLatest(GET_ASSET_CONTACTS, getAssetContacts);
}

//add asset contact saga
export function* addAssetContact(action) {
  try {
    const slug = action.payload.slug;

    const payload = yield call(Contact.addAssetContact, action.payload);
    yield put({ type: GET_ASSET_CHECKLIST, payload: slug });

    yield put({ type: ADD_ASSET_CONTACT_SUCCESS, payload });
    showToast("s", "Contact Added Successfully");
  } catch (error) {
    const err = error.response.body;
    showToast("e", err.message || "Unable To Add Contact");
    yield put({ type: ADD_ASSET_CONTACT_FAILURE, err });
  }
}
export function* watchAddAssetContact() {
  yield takeEvery(ADD_ASSET_CONTACT, addAssetContact);
}

//update asset contact saga
function* updateAssetContact(action) {
  try {
    const payload = yield call(Contact.updateAssetContact, action.payload);
    yield put({ type: UPDATE_ASSET_CONTACT_SUCCESS, payload });
    showToast("s", "Contact Updated Successfully");
  } catch (error) {
    const err = error.response.body;
    showToast("e", err.message || "Unable To Update Contact");
    yield put({ type: UPDATE_ASSET_CONTACT_FAILURE, err });
  }
}
export function* watchUpdateAssetContact() {
  yield takeLatest(UPDATE_ASSET_CONTACT, updateAssetContact);
}

//delete asset contact saga
function* deleteAssetContact(action) {
  try {
    const slug = action.payload.slug;
    const payload = yield call(Contact.deleteAssetContact, action.payload);
    showToast("s", "Contact Deleted Successfully");

    yield put({ type: GET_ASSET_CHECKLIST, payload: slug });
    yield put({ type: DELETE_ASSET_CONTACT_SUCCESS, payload });
  } catch (error) {
    const err = error.response.body;
    showToast("e", err.message || "Unable To Delete Contact");
    yield put({ type: DELETE_ASSET_CONTACT_FAILURE, err });
  }
}
export function* watchDeleteAssetContact() {
  yield takeLatest(DELETE_ASSET_CONTACT, deleteAssetContact);
}

//get all contacts
function* getAllContacts(action) {
  try {
    const payload = yield call(Contact.getAllContacts, action.payload);
    yield put({ type: GET_ALL_CONTACTS_SUCCESS, payload });
  } catch (error) {
    // const err = error.response.body;
    // yield put({ type: DELETE_ASSET_CONTACT_FAILURE, err });
  }
}
export function* watchGetAllContacts() {
  yield takeLatest(GET_ALL_CONTACTS, getAllContacts);
}

export function* addContact(action) {
  try {
    const payload = yield call(Contact.addContact, action.payload);
    yield put({ type: ADD_CONTACT_SUCCESS, payload });
    showToast("s", "Contact Added Successfully");
  } catch (error) {
    const err = error.response.body;
    showToast("e", err.message || "Unable To Add Contact");
    yield put({ type: ADD_CONTACT_FAILURE, err });
  }
}
export function* watchAddContact() {
  yield takeEvery(ADD_CONTACT, addContact);
}

export function* deleteContact(action) {
  try {
    const payload = yield call(Contact.deleteContact, action.payload);
    yield put({ type: DELETE_CONTACT_SUCCESS, payload });
    showToast("s", "Contact deleted");
  } catch (error) {
    const err = error.response.body;
    showToast("e", err.message || "Unable to delete Contact");
    yield put({ type: DELETE_CONTACT_FAILURE, err });
  }
}
export function* watchDeleteContact() {
  yield takeEvery(DELETE_CONTACT, deleteContact);
}

function* updateContact(action) {
  try {
    const payload = yield call(Contact.updateContact, action.payload);
    yield put({ type: UPDATE_CONTACT_SUCCESS, payload });
    showToast("s", "Contact updated successfully");
  } catch (error) {
    const err = error.response.body;
    showToast("e", err.message || "Unable to update contact");
    yield put({ type: UPDATE_CONTACT_FAILURE, err });
  }
}

export function* watchUpdateContact() {
  yield takeLatest(UPDATE_CONTACT, updateContact);
}

function* getContactConversation(action) {
  try {
    const payload = yield call(Contact.getContactConversation, action.payload);
    yield put({ type: GET_CONTACT_CONVERSATION_SUCCESS, payload });
  } catch (error) {
    const errResp = { message: "Error while fetching contact conversation" };
    yield put({ type: GET_CONTACT_CONVERSATION_FAILURE, errResp });
  }
}

export function* watchGetContactConversation() {
  yield takeLatest(GET_CONTACT_CONVERSATION, getContactConversation);
}
