import {
  GET_OPPORTUNITY_DETAILS,
  GET_PARTNER_DETAILS,
  GET_NEIGHBORHOOD_HIGHLIGHTS,
  CREATE_OPPORTUNITY,
  GET_OPPORTUNITY_CONVERSATION
} from "../actionTypes";

export const getOpportunityDetails = (dispatch, slug) => {
  dispatch({
    type: GET_OPPORTUNITY_DETAILS,
    payload: {
      slug
    }
  });
};

export const getPartnerDetails = (dispatch, slug) => {
  dispatch({
    type: GET_PARTNER_DETAILS,
    payload: {
      slug
    }
  });
};

export const getNeighborhoodHighlights = (dispatch, zip) => {
  dispatch({
    type: GET_NEIGHBORHOOD_HIGHLIGHTS,
    payload: {
      zip
    }
  });
};

export const createOpportunity = (dispatch, data) => {
  dispatch({
    type: CREATE_OPPORTUNITY,
    payload: data
  });
};

export const getOpportunityConversation = (dispatch, data) => {
  dispatch({
    type: GET_OPPORTUNITY_CONVERSATION,
    payload: data
  });
};
