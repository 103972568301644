import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import {
  FormGroup,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import SlidingPane from "react-sliding-pane";
import FormField from "../../../shared/components/form/FormField";
import Textarea from "../../../shared/components/form/Textarea";
import CreatableSelect from "react-select/creatable";
import { getChecklistCategories } from "../../../helpers/constants";
import { requestDocument } from "../../../actions/attachments/attachmentAction";
import { isFetching, isSuccess } from "../../../reducers/reducerUtils";

const DocumentRequest = props => {
  const [request, setRequest] = useState({
    loading: false,
    error: null
  });

  useEffect(() => {
    const {
      asset: { slug },
      attachments
    } = props;
    if (slug) {
      if (request.loading && attachments.type === "REQUEST") {
        if (!isFetching(attachments)) {
          if (isSuccess(attachments)) {
            setRequest({ loading: false, error: null });
          } else {
            setRequest({ loading: false, error: attachments.error });
          }
          props.toggleModal();
        }
      }
    }
  });

  const onSubmit = values => {
    setRequest({ loading: true, error: null });
    const {
      asset: { slug }
    } = props;
    values.slug = slug;
    values.category = values.category.label;
    props.requestDocument(values);
  };

  const {
    asset: { assetType },
    userInfo,
    modalOpen,
    toggleModal
  } = props;

  const requiredCategories = getChecklistCategories(assetType);
  const validationSchema = Yup.object({
    email: Yup.string("")
      .email("Enter valid email")
      .required("Email is required")
      .max(100, "Email too long"),
    category: Yup.string("").required("Category is required"),
    subject: Yup.string("").required("Subject is required"),
    message: Yup.string("").required("Message is required")
  });
  const initialValues = { email: "", category: "", subject: "", message: "" };

  return (
    <SlidingPane
      isOpen={modalOpen}
      from="right"
      width="350px"
      hideHeader={true}
      className="d-flex justify-content-center modal-content"
      onRequestClose={toggleModal}
    >
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {props => {
          const { loading, handleSubmit, setFieldValue } = props;

          return (
            <form onSubmit={handleSubmit}>
              <ModalHeader toggle={toggleModal}>Request Document</ModalHeader>
              <ModalBody>
                <FormGroup>
                  <label htmlFor="staticEmail">Contact:</label>
                  <Field
                    name="email"
                    component={FormField}
                    type="text"
                    placeholder="Contact Email"
                  />
                </FormGroup>
                <FormGroup>
                  <label>Category:</label>
                  <CreatableSelect
                    isSearchable
                    name="category"
                    placeholder="Select document category or add your own"
                    searchable={true}
                    options={requiredCategories}
                    defaultValue={initialValues.tags}
                    theme={theme => ({
                      ...theme,
                      borderRadius: "0.29rem",
                      borderWidth: 1,
                      colors: {
                        ...theme.colors,
                        primary25: "rgba(60,68,177,0.15)",
                        primary50: "rgba(60,68,177,0.15)",
                        primary: "#3c44b1"
                      }
                    })}
                    onChange={option => {
                      setFieldValue("category", option);
                      const { value } = option;
                      let requestingUser = userInfo
                        ? userInfo.fullName.split(" ")[0]
                        : "";
                      setFieldValue(
                        "subject",
                        requestingUser +
                          " is requesting you to send the" +
                          (value === "Other" ? "a" : value) +
                          " document"
                      );

                      setFieldValue(
                        "message",
                        "Can you please send the " +
                          (value === "Other" ? "" : value) +
                          " document, please reply to this email with the necessary document"
                      );
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <label>Subject:</label>
                  <Field
                    name="subject"
                    component={FormField}
                    type="text"
                    placeholder="Subject"
                  />
                </FormGroup>
                <FormGroup>
                  <label>Message:</label>
                  <Field
                    name="message"
                    component={Textarea}
                    type="text"
                    placeholder="Message"
                  />
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  className="btn  btn-primary px-5  d-inline-block mx-2"
                  type="submit"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? "Requesting" : "Request"}
                </Button>
              </ModalFooter>
            </form>
          );
        }}
      </Formik>
    </SlidingPane>
  );
};

const mapStateToProps = state => ({
  attachments: state.attachments.attachments
});

const mapDispatchToProps = dispatch => ({
  requestDocument: data => requestDocument(data, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentRequest);
