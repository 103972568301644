import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import {
  getAssetRemindersList,
  updateReminder
} from "../../../actions/reminder";

import DeleteReminderModal from "./DeleteReminderModal";

import TableWithSearch from "../../../shared/components/TableWithSearch";

const AssetReminders = ({
  getAssetRemindersList,
  asset,
  assetReminders,
  updateReminder,
  refreshData
}) => {
  const [reminders, setReminders] = useState([]);
  useEffect(() => {
    if (asset) {
      const { slug } = asset;
      getAssetRemindersList(slug);
    }
  }, []);

  useEffect(
    () => {
      const { reminders, updateReminderSuccess } = assetReminders;
      if (updateReminderSuccess) {
        const { slug } = asset;
        getAssetRemindersList(slug);
        refreshData();
      }
      setReminders(reminders);
    },
    [assetReminders]
  );

  const performUpdateAction = (type, selectedData, data = {}) => {
    updateReminder({
      remId: selectedData._id,
      updateType: type,
      ...data
    });
  };

  const columnProps = [
    {
      dataField: "note",
      text: "Title",
      sort: true,
      style: {
        maxWidth: "200px"
      }
    },
    {
      dataField: "type",
      text: "Type",
      sort: true
    },
    {
      dataField: "frequency",
      text: "Frequency",
      sort: true,
      style: {
        fontWeight: "Semibold",
        fontSize: "14px",
        color: "#171725"
      }
    },
    {
      text: "Actions",
      dataField: "",
      isDummyField: true,
      formatter: (cell, row) => {
        return (
          <>
            <Button
              className={"btn-neutral-first  px-3 mr-2"}
              disabled={row.status === "resolved"}
              onClick={() => performUpdateAction("markCompleted", row)}
            >
              <FontAwesomeIcon icon={faCheck} className="font-size-lg" />
            </Button>
            <DeleteReminderModal assetData={row} />
          </>
        );
      }
    }
  ];
  const handleTableChange = attrs => {};
  return (
    <div className="px-4">
      <TableWithSearch
        keyField="_id"
        data={reminders}
        totalSize={reminders.length}
        page={1}
        sizePerPage={5}
        columns={columnProps}
        onTableChange={handleTableChange}
        enableCellEdit={false}
        remoteData={false}
        searchPlaceholder="Search Documents..."
        noDataIndicator="No Reminders found!"
      />
    </div>
  );
};

const mapStateToProps = state => ({
  assetReminders: state.reminders
});

const mapDispatchToProps = dispatch => ({
  updateReminder: data => updateReminder(data, dispatch),
  getAssetRemindersList: data => getAssetRemindersList(data, dispatch)
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssetReminders);
