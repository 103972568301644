/* eslint-disable no-param-reassign */
import React, { PureComponent } from "react";
import EntitySelect from "../form/EntitySelect";
import DropdownField from "../form/DropdownField";

import DateRangeFilter from "../DateRangeFilter";
import SlidingPane from "react-sliding-pane";

import {
  saveFilter,
  getCurrentFilter,
  setCurrentFilter,
} from "../../../actions/auth/authAction";

import { dateFilters } from "../../../helpers/constants";

import { Formik, Field } from "formik";
import { connect } from "react-redux";
import {
  Button,
  FormGroup,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Filter, XCircle } from "react-feather";

class DashFilter extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filterOpen: false,
    };
  }

  handleEntityChange = (value) => {
    let { currentFilter } = this.props;
    currentFilter.entity = value;
    this.handleChangeFilter(currentFilter);
  };

  handleTagChange = (value) => {
    let { currentFilter } = this.props;
    currentFilter.tag = value;
    this.handleChangeFilter(currentFilter);
  };

  handleDateChange = (filter) => {
    let { currentFilter } = this.props;
    currentFilter.fromDate = filter.fromDate;
    currentFilter.toDate = filter.toDate;
    currentFilter.name = filter.type;
    this.handleChangeFilter(currentFilter);
  };

  handleInvestmentStatusChange = (value) => {
    let { currentFilter } = this.props;
    currentFilter.status = value;
    this.handleChangeFilter(currentFilter);
  };

  handleTransactionTypeChange = (value) => {
    let { currentFilter } = this.props;
    currentFilter.txnType = value;
    this.handleChangeFilter(currentFilter);
  };

  handleChangeFilter = (values) => {
    this.setState({ filterOpen: false }, () => {
      this.props.setCurrentFilter(values);
      this.props.onChange(values);
    });
  };

  resetFilter = () => {
    this.setState({ filterOpen: false }, () => {
      const defaultValues = {
        filter: dateFilters.find((f) => f._id === "last_12_months"),
      };
      this.props.onChange(defaultValues);
    });
  };

  render() {
    const { currentFilter, showTxnType } = this.props;
    let initialValues = {
      filter: currentFilter,
      entity: currentFilter.entity || "All",
      status: currentFilter.status || "active",
      txnType: currentFilter.txnType || "current",
      tag: currentFilter.tag,
      integration: currentFilter.integration,
    };

    let filterApplied =
      currentFilter &&
      currentFilter !== dateFilters.find((f) => f._id === "last_12_months");

    if (
      currentFilter.entity ||
      currentFilter.tags ||
      currentFilter.status ||
      currentFilter.txnType ||
      currentFilter.integration
    ) {
      filterApplied = true;
    }
    return (
      <div className="d-flex align-items-center">
        <Button
          color={filterApplied ? "primary" : "neutral-primary"}
          className="btn-icon btn-animated-icon btn-transition-none btn-pill"
          onClick={() => this.setState({ filterOpen: true })}
        >
          <span className="btn-wrapper--icon">
            <Filter />
          </span>
          <span className="btn-wrapper--label">Filters</span>
        </Button>
        <SlidingPane
          isOpen={this.state.filterOpen}
          closeIcon={<XCircle />}
          from="right"
          width="350px"
          hideHeader={true}
          className="d-flex justify-content-center modal-content"
          onRequestClose={() => this.setState({ filterOpen: false })}
        >
          <ModalHeader toggle={() => this.setState({ filterOpen: false })}>
            Apply Filters
          </ModalHeader>
          <ModalBody>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={this.handleChangeFilter}
            >
              {(props) => {
                return (
                  <form onSubmit={props.handleSubmit}>
                    <FormGroup>
                      <label>Entity:</label>
                      <Field
                        name="entity"
                        component={EntitySelect}
                        className=""
                        readOnly={true}
                        allOption={true}
                        isTag={false}
                        onChange={this.handleEntityChange}
                        renderAsPill={true}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label>Status:</label>
                      <Field
                        name="status"
                        component={DropdownField}
                        options={[
                          { value: "active", label: "Active" },
                          { value: "for_sale", label: "For Sale" },
                          { value: "in_default", label: "In Default" },
                          { value: "in_forclosure", label: "In Foreclosure" },
                          { value: "closed", label: "Closed" },
                        ]}
                        placeholder="Investment Status"
                        classNamePrefix={"entity-select"}
                        defaultValue={"active"}
                        onChange={this.handleInvestmentStatusChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label>Period:</label>
                      <Field
                        name="filter"
                        component={DateRangeFilter}
                        onChange={this.handleDateChange}
                      />
                    </FormGroup>
                    {showTxnType && (
                      <FormGroup>
                        <label>Transaction Type:</label>
                        <Field
                          name="txnType"
                          component={DropdownField}
                          options={[
                            { value: "current", label: "Current" },
                            { value: "pending", label: "Pending" },
                            { value: "duplicate", label: "Duplicate" },
                          ]}
                          placeholder="Transaction Type"
                          classNamePrefix={"entity-select"}
                          defaultValue={"current"}
                          onChange={this.handleTransactionTypeChange}
                        />
                      </FormGroup>
                    )}
                    <FormGroup>
                      <label>Tags:</label>
                      <Field
                        name="tag"
                        component={EntitySelect}
                        className=""
                        readOnly={true}
                        allOption={true}
                        isTag={true}
                        onChange={this.handleTagChange}
                        renderAsPill={true}
                      />
                    </FormGroup>
                  </form>
                );
              }}
            </Formik>
          </ModalBody>
          {filterApplied && (
            <ModalFooter>
              <Button
                onClick={this.resetFilter}
                className="btn  px-5 d-inline-block mx-2"
              >
                Clear filter
              </Button>
            </ModalFooter>
          )}
        </SlidingPane>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userDetail: state.userDetail,
  dashboardFilter: state.dashboardFilter,
  integrations: state.integrations,
});

const mapDispatchToProps = (dispatch) => {
  return {
    saveFilter: (data) => saveFilter(data, dispatch),
    getCurrentFilter: (data) => getCurrentFilter(data, dispatch),
    setCurrentFilter: (data) => setCurrentFilter(data, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashFilter);
