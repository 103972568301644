import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "reactstrap";

import { getPartnerDetails } from "../../../actions/opportunity";

import { isFetching, isSuccess, isError } from "../../../reducers/reducerUtils";

import SectionHeader from "../../../shared/components/SectionHeader";
import LoadingIndicator from "../../../shared/components/LoadingIndicator";

import OpportunityCard from "./OpportunityCard";
import Testimonial from "./Testimonial";

import Piechart from "../PartnerDetails/Piechart";
import PieIcon from "../../../assets/images/pie-icon-01.png";
import PieIcon01 from "../../../assets/images/pie-icon-02.png";
import PieIcon02 from "../../../assets/images/pie-icon-03.png";
import PieIcon03 from "../../../assets/images/pie-icon-04.png";

const PartnerDetails = ({ partnerData, getPartnerDetails }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [partnerDetails, setPartnerDetails] = useState({});
  const [opportunities, setOpportunities] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  useEffect(() => {
    const slug = window.location.pathname.split("/")[2];
    getPartnerDetails(slug);
  }, []);

  useEffect(
    () => {
      if (isFetching(partnerData)) {
        setIsLoading(true);
      }

      if (!isFetching(partnerData) && isSuccess(partnerData)) {
        const { data } = partnerData;
        if (data.length > 0) {
          const {
            name,
            slug,
            logo,
            logoBackground,
            opportunities,
            sections,
            seo
          } = data[0];
          const details = {
            name,
            slug,
            logo,
            logoBackground,
            personalDetails: sections[0].content,
            otherDetails: seo.metaDescription
          };
          const { review_items } = sections[3];
          setPartnerDetails(details);
          setOpportunities(opportunities);
          setTestimonials(review_items);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setError(true);
        }
      }
      if (isError(partnerData)) {
        setIsLoading(false);
        setError(true);
      }
    },
    [partnerData]
  );
  if (isLoading) return <LoadingIndicator loading={isLoading} />;

  if (error) return <p>Something went wrong!!</p>;
  return (
    <main className="p-5">
      <SectionHeader />
      {partnerDetails ? (
        <Container className="mt-5 mb-5 Info">
          <h1>{partnerDetails.name}</h1>
          <p>Member since 2021</p>
          <Row className="details-row">
            <Col md={8}>
              <div className="mt-5">
                <div className="personal-details">
                  <h3>Personal Details </h3>
                  <p>{partnerDetails.personalDetails}</p>
                </div>
                <div className="other-details">
                  <h3>Other Details </h3>
                  <p>{partnerDetails.otherDetails}</p>
                </div>
              </div>
            </Col>
            <Col md={4} className="">
              {partnerDetails.logo && (
                <div className="provider-image">
                  <img
                    src={`https://cms.bettercapital.us${
                      partnerDetails.logo.url
                    }`}
                    alt="providerimage"
                  />
                </div>
              )}
              <button className="start-button">Start Conversation</button>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container className="mt-5 mb-5 Info" />
      )}
      <Container className="mt-5 mb-5 Info">
        <h3>Property Owned by {partnerDetails.name} </h3>
        {opportunities.length > 0 && (
          <Row className="mt-5">
            {opportunities.map(opportunity => (
              <Col key={opportunity.id} md={4} className="mb-4">
                <OpportunityCard {...opportunity} />
              </Col>
            ))}
          </Row>
        )}
      </Container>
      <Container className="mt-5 mb-5 Ratings">
        <h3>Provider Reviews and Ratings </h3>
        <p>Ratings on Service</p>
        <div className="d-flex justify-content-start align-items-start chart">
          <Piechart />
          <div className="d-flex justify-content-start align-items-start flex-column mt-5">
            <div className="d-flex justify-content-start align-items-start w-100">
              <img src={PieIcon} alt="icon" />
              <div className="d-flex justify-content-between align-items-start w-100">
                <h3 className="ml-3">Services </h3>
                <h4 className="ml-3">50%</h4>
              </div>
            </div>
            <div className="d-flex justify-content-start align-items-start  mt-3 w-100">
              <img src={PieIcon01} alt="icon" />
              <div className="d-flex justify-content-between align-items-start w-100">
                <h3 className="ml-3">Agreement formalities </h3>
                <h4 className="ml-3">25%</h4>
              </div>
            </div>
            <div className="d-flex justify-content-start align-items-start  mt-3 w-100">
              <img src={PieIcon02} alt="icon" />
              <div className="d-flex justify-content-between align-items-start w-100">
                <h3 className="ml-3">Provider findings </h3>
                <h4 className="ml-3">15%</h4>
              </div>
            </div>
            <div className="d-flex justify-content-start align-items-start mt-3 w-100">
              <img src={PieIcon03} alt="icon" />
              <div className="d-flex justify-content-between align-items-start w-100">
                <h3 className="ml-3">Location rating </h3>
                <h4 className="ml-3">10%</h4>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Container className="mt-5 mb-5 Testimonials">
        <h3>Testimonials </h3>
        {testimonials.length > 0 && (
          <>
            {testimonials.map((testimonial, index) => (
              <Testimonial
                {...testimonial}
                index={index}
                key={testimonial.id}
              />
            ))}
          </>
        )}
        <div className="d-flex justify-content-center align-items-center">
          <button className="Knowmore-button">View More</button>
        </div>
      </Container>
    </main>
  );
};

const mapStateToProps = state => ({
  partnerData: state.opportunity.partner
});

const mapDispatchToProps = dispatch => ({
  getPartnerDetails: slug => getPartnerDetails(dispatch, slug)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerDetails);
