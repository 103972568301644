import React, { useState } from "react";
import { connect } from "react-redux";
import SlidingPane from "react-sliding-pane";

import ToggleSwitch from "../../../shared/components/form/ToggleSwitch";

import {
  Form,
  FormGroup,
  Label,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Formik, Field } from "formik";

import { deleteIntegration } from "../../../actions/integrations";

const DeletePropertyManager = ({
  showModal,
  onToggle,
  integration,
  deleteIntegration,
}) => {
  const [deleting, setDeleting] = useState(false);

  const onDeleteIntegration = (data = {}) => {
    setDeleting(true);
    deleteIntegration(integration._id, data.deleteAssets);
  };

  let initialValues = {
    deleteAssets: false,
  };

  return (
    <SlidingPane
      isOpen={showModal}
      from="right"
      width="350px"
      hideHeader={true}
      className="d-flex justify-content-center modal-content"
      onRequestClose={onToggle}
    >
      <ModalHeader toggle={onToggle}>Delete Property Manager</ModalHeader>
      <Formik
        enableReinitialize
        onSubmit={onDeleteIntegration}
        initialValues={initialValues}
        render={(props) => {
          const { handleSubmit } = props;
          return (
            <ModalBody>
              <Form onSubmit={handleSubmit}>
                <div className="text-center p-3">
                  <h5 className="font-size-xxl mb-1 text-black">
                    {"Are you sure to delete this PM?"}
                  </h5>
                  <h5 className="text-black-50 font-size-lg mb-0">
                    {"You cannot undo this operation."}
                  </h5>
                </div>
                <div className="py-5">
                  <FormGroup>
                    <Label>Delete Assets</Label>
                    <Field
                      name="deleteAssets"
                      component={ToggleSwitch}
                      type={"number"}
                    />
                  </FormGroup>
                </div>
                <ModalFooter className="d-flex justify-content-end">
                  <Button
                    color="primary"
                    className="btn savebtns btn-primary px-5  mx-2"
                    type="submit"
                    disabled={deleting}
                  >
                    {deleting ? "Deleting" : "Delete"}
                  </Button>
                </ModalFooter>
              </Form>
            </ModalBody>
          );
        }}
      />
    </SlidingPane>
  );
};

const mapDispatchToProps = (dispatch) => ({
  deleteIntegration: (data) => deleteIntegration(dispatch, data),
});
export default connect(
  null,
  mapDispatchToProps
)(DeletePropertyManager);
