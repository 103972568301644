/* eslint-disable no-param-reassign */
import React, { PureComponent } from "react";

import { InputGroup, InputGroupAddon, InputGroupText, Input } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

class AssetFilter extends PureComponent {
  render() {
    return (
      <InputGroup size={"lg"} className="input-group-seamless mb-1">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <FontAwesomeIcon icon={faSearch} className="mx-auto" />
          </InputGroupText>
        </InputGroupAddon>{" "}
        <Input
          placeholder="Search asset..."
          type="search"
          onChange={this.props.handleSearch}
        />
      </InputGroup>
    );
  }
}

export default AssetFilter;
