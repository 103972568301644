// import PropTypes from 'prop-types';
import React from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const currencyMask = createNumberMask({
  prefix: '$',
  allowDecimal: true,
})

const CurrencyField = ({
  type, field, form: { touched, errors }, ...props
}) => (
    <MaskedInput
      {...field}
      mask={currencyMask}
      type={type}
      {...props}
      className="form-control"
    />  
);

export default CurrencyField;
