import {
  ADD_DEAL,
  GET_DEALS,
  GET_DEAL_LISTING,
  TRANSFER_ASSET,
} from '../actionTypes';

export const addDeal = (data, dispatch) => {
  dispatch({
      type: ADD_DEAL,
      payload: data,
  });
}

export const getDeals = (data, dispatch) => {
  dispatch({
      type: GET_DEALS,
      payload: data,
  });
}

export const getMyDeals = (data, dispatch) => {
  dispatch({
    type: GET_DEAL_LISTING,
    payload: data,
});
}

export const transferAsset = (data, dispatch) => {
  dispatch({
    type: TRANSFER_ASSET,
    payload: data,
});
}