import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Container } from "reactstrap";
import {
  faPlus,
  faCalendar,
  faHandsHelping
} from "@fortawesome/free-solid-svg-icons";
import AddAsset from "../../../components/Dashboard/AddAsset";
import { withRouter } from "react-router-dom";

const LaunchPad = ({ history, user }) => {
  const [showImportModal, setShowImportModal] = useState(false);
  const toggleImportModal = () => setShowImportModal(prevState => !prevState);

  const onAddAsset = slug => {
    toggleImportModal();
    history.push(`/assets/${slug}`);
  };

  return (
    <Container className="py-3 py-xl-5">
      <div className="text-center mb-5">
        <p className="font-size-xl mb-5 text-black-50">
          Institutional financial analytics for mom and pop investors
        </p>
      </div>
      <Row className="d-flex align-items-center">
        <Col md="6" xl="4">
          <a
            href="#/"
            onClick={() => toggleImportModal("rental")}
            className="card shadow-xxl card-border-top br-tl br-tr card-box-hover-rise border-primary text-center p-3 p-xl-4 mb-5 d-block"
          >
            <div className="d-50 btn-icon rounded-circle bg-primary text-white mb-4 mx-auto">
              <FontAwesomeIcon icon={faPlus} className="font-size-xl" />
            </div>
            <h5 className="font-weight-bold font-size-lg text-second mb-0">
              Add your asset
            </h5>
            <p className="text-second opacity-8 mt-3 mb-4">
              Add your asset and calculate the ROI with a few clicks.
            </p>
          </a>
          <AddAsset
            showModal={showImportModal}
            toggleModal={toggleImportModal}
            onAddAsset={onAddAsset}
            user_role="O"
            investment_type={"rental"}
            import_steps={[
              {
                label: "Summary",
                number: "1"
              },
              {
                label: "Investment",
                number: "2"
              },
              {
                label: "Financials",
                number: "3"
              }
            ]}
          />
        </Col>
        <Col md="6" xl="4">
          <a
            href="https://calendly.com/bayflip/30min-1"
            target="_blank"
            rel="noopener noreferrer"
            className="card shadow-xxl card-border-top br-tl br-tr card-box-hover-rise border-first text-center p-3 p-xl-4 mb-5 d-block"
          >
            <div className="d-50 btn-icon rounded-circle bg-first text-white mb-4 mx-auto">
              <FontAwesomeIcon icon={faCalendar} className="font-size-xl" />
            </div>
            <h5 className="font-weight-bold font-size-lg text-second mb-0">
              Book a demo
            </h5>
            <p className="text-second opacity-8 mt-3 mb-4">
              If you need a product demonstration, please reserve a time on our
              calendar.
            </p>
          </a>
        </Col>
        <Col md="6" xl="4">
          <a
            href="https://calendly.com/bayflip/30min-1"
            target="_blank"
            rel="noopener noreferrer"
            className="card shadow-xxl card-border-top br-tl br-tr card-box-hover-rise border-warning text-center p-3 p-xl-4 mb-5 d-block"
          >
            <div className="d-50 btn-icon rounded-circle bg-warning text-white mb-4 mx-auto">
              <FontAwesomeIcon icon={faHandsHelping} className="font-size-xl" />
            </div>
            <h5 className="font-weight-bold font-size-lg text-second mb-0">
              Hire a bookkeeper
            </h5>
            <p className="text-second opacity-8 mt-3 mb-4">
              We understand how busy you are, so we have low-cost bookkeepers to
              assist you
            </p>
          </a>
        </Col>
      </Row>
    </Container>
  );
};
export default withRouter(LaunchPad);
