import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import cubejs from "@cubejs-client/core";

import SectionHeader from "../../shared/components/SectionHeader";
// import InsightRenderer from "./InsightRenderer";
const Insights = props => {
  const [cubeApi, setCubeApi] = useState();
  useEffect(() => {
    const { insightsToken } = props.user;
    setCubeApi(
      cubejs(insightsToken, {
        apiUrl:
          process.env.REACT_APP_INSIGHTS_API_URL ||
          "http://localhost:4000/cubejs-api/v1"
      })
    );
  }, []);

  console.log(cubeApi);
  return (
    <main className="p-5">
      <SectionHeader titleHeading={"Insights"} />
      {/*cubeApi && <InsightRenderer cubeApi={cubeApi} /> */}
    </main>
  );
};

function mapStateToProps(state) {
  return {
    user: state.login.user
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Insights);
