import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
  getBasicInfo,
  getCashFlowGraph
} from "../../../actions/asset/assetAction";
import { getAssetFinancials } from "../../../actions/financial";
import _ from "lodash";

import LoadingIndicator from "../../../shared/components/LoadingIndicator";
import CashFlowGraph from "./CashFlowGraph";
import { isFetching, isSuccess } from "../../../reducers/reducerUtils";

class AssetOverview extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      assetSlug: props.match.params.asset,
      currentFilter: props.currentFilter,
      loadingGraph: false,
      filterChange: true,
      cashFlowActual: [],
      loadingAssetInfo: false,
      cashFlowProjected: [],
      refreshCashflow: false,
      basicInfo: {},
      fetchCashflow: false
    };
  }

  componentDidMount = () => {
    const {
      match: {
        params: { asset }
      }
    } = this.props;

    this.setState(
      {
        loadingAssetInfo: true,
        filterChange: false,
        loadingGraph: true
      },
      () => {
        this.props.getBasicInfo(asset);
        this.props.getAssetFinancials(asset);
        this.props.getCashFlowGraph({
          slug: asset,
          fromDate: this.state.currentFilter.fromDate,
          toDate: this.state.currentFilter.toDate
        });
      }
    );
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { loadingGraph } = prevState;
    const { cashflow, assetBasicInfo } = nextProps;

    if (loadingGraph) {
      if (!isFetching(cashflow)) {
        if (isSuccess(cashflow)) {
          return {
            loadingGraph: false,
            cashFlowActual: cashflow.data.cashFlowActual
          };
        }
      }
    } else if (!loadingGraph && isFetching(cashflow)) {
      return { loadingGraph: true };
    }
    if (prevState.loadingAssetInfo && !_.isEmpty(assetBasicInfo)) {
      if (!isFetching(assetBasicInfo)) {
        if (isSuccess(assetBasicInfo)) {
          return {
            basicInfo: assetBasicInfo.data.basicInfo,
            loadingAssetInfo: false,
            fetchCashflow: true
          };
        } else {
          return {
            basicInfo: assetBasicInfo.error,
            loadingAssetInfo: false
          };
        }
      }
    }

    return null;
  }

  onFilterChange = filter => {
    this.setState({ currentFilter: filter, loadingGraph: true }, () => {
      const {
        match: {
          params: { asset }
        }
      } = this.props;
      this.props.getCashFlowGraph({
        slug: asset,
        fromDate: filter.fromDate,
        toDate: filter.toDate
      });
    });
  };

  render() {
    const {
      basicInfo,
      cashFlowActual,
      loadingAssetInfo,
      loadingGraph
    } = this.state;
    let cashflow;

    if (loadingGraph || loadingAssetInfo) return <LoadingIndicator />;

    if (basicInfo && cashFlowActual) {
      cashflow = cashFlowActual;
    }

    return (
      <div>
        {cashflow ? (
          <CashFlowGraph {...this.props} data={cashflow} />
        ) : (
          <LoadingIndicator />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  cashflow: state.assetExpenses.cashflow,
  assetBasicInfo: state.assetBasicInfo,
  financial: state.financial,
  transactions: state.transactions,
  currentFilter: state.filter.currentFilter
});

const mapDispatchToProps = dispatch => ({
  getBasicInfo: slug => getBasicInfo(slug, dispatch),
  getCashFlowGraph: data => getCashFlowGraph(data, dispatch),
  getAssetFinancials: slug => getAssetFinancials(slug, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssetOverview);
