import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import { connect } from "react-redux";
import DealSidebar from "./DealSidebar";
import Rental from "./Rental";
import Note from "./Note";
import Wholesale from "./Wholesale";

import Syndication from "./Syndication";
import DealPermalink from "./DealPermalink";
import Flip from "./Flip";

const DealRoutes = () => (
  <Switch>
    <Route exact path="/deals" component={Rental} />
    <Route exact path="/deals/notes" component={Note} />
    <Route exact path="/deals/syndications" component={Syndication} />
    <Route exact path="/deals/flips" component={Flip} />
    <Route exact path="/deals/for_sale" component={Wholesale} />
    <Route exact path="/deals/:asset" component={DealPermalink} />
  </Switch>
);

class Deal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false
    };
  }

  render() {
    return (
      <main className="container-fluid p-5">
        <div className="row">
          <DealSidebar {...this.props} />
          <div className="col-sm-12 col-md-8 col-lg-8 col-xl-10">
            <DealRoutes />
          </div>
        </div>
      </main>
    );
  }
}

export default connect(
  null,
  null
)(Deal);
