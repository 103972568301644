/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { getAssetFinancials } from "../../actions/financial";
import { Row, Col, Card, CardHeader, CardBody, Button } from "reactstrap";
import DescriptionItem from "../../shared/components/form/DescriptionItem";
import NumberFormat from "react-number-format";
import OwnerCard from "./OwnerCard";
import DealAssumptions from "./DealAssumptions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { faDotCircle } from "@fortawesome/free-solid-svg-icons";
import { faHandHoldingUsd } from "@fortawesome/free-solid-svg-icons";
import { faWallet } from "@fortawesome/free-solid-svg-icons";
import { unmaskCurrency } from "../../helpers";
import { faTags } from "@fortawesome/free-solid-svg-icons";
import { faTools } from "@fortawesome/free-solid-svg-icons";
import { faFunnelDollar } from "@fortawesome/free-solid-svg-icons";
import { analyzeAsset } from "../../helpers/assetCalculator";
import _ from "lodash";
import LoadingIndicator from "../../shared/components/LoadingIndicator";

const assumptions = [
  {
    label: "purchase_cost",
    name: "Purchase Cost",
    addonText: "$",
    addonType: "prepend",
    type: "currency"
  },
  {
    label: "holding_cost",
    name: "Holding Cost",
    addonText: "$",
    addonType: "prepend",
    type: "currency"
  },
  {
    label: "selling_cost",
    name: "Selling Cost",
    addonText: "$",
    addonType: "prepend",
    type: "currency"
  }
];

class FlipAnalyzer extends React.PureComponent {
  constructor(props) {
    super(props);

    const {
      basicInfo: { dealSalesPrice, afterRepairValue }
    } = this.props;
    this.state = {
      investmentType: "flip",
      assumedValues: {
        purchase_cost: 0.03 * dealSalesPrice,
        holding_cost: 0.01 * dealSalesPrice,
        selling_cost: 0.06 * afterRepairValue
      },

      analysis: {}
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { asset }
      }
    } = this.props;
    this.props.getAssetFinancials(asset);
  }
  switchInvestmentType = investmentType => {
    this.setState({ investmentType: investmentType });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.analysis || Object.keys(prevState.analysis).length === 0) {
      const { basicInfo, investmentInfo, financial } = nextProps;
      const { assumedValues } = prevState;
      if (financial.getFinancialSuccess) {
        const { dealSalesPrice } = basicInfo;
        const {
          marketValue,
          marketRent,
          downPayment,
          afterRepairValue,
          rehabEstimate
        } = investmentInfo;
        const { financials } = financial;
        return {
          analysis: analyzeAsset({
            purchasePrice: assumedValues.purchasePrice || dealSalesPrice,
            dealSalesPrice: dealSalesPrice,
            marketValue: marketValue,
            marketRent: marketRent,
            financials: financials,
            downPayment: downPayment,
            afterRepairValue: afterRepairValue,
            rehabEstimate: rehabEstimate,
            downPaymentPercent: assumedValues.downPaymentPercent || 100,
            useMarketRent: assumedValues.useMarketRent || false
          })
        };
      }
    }
    return null;
  }

  toggle = tab => {
    if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
  };

  updateAssumptions = values => {
    this.setState({
      assumedValues: {
        purchase_cost: parseInt(unmaskCurrency(values.purchase_cost)),
        holding_cost: parseInt(unmaskCurrency(values.holding_cost)),
        selling_cost: parseInt(unmaskCurrency(values.selling_cost))
      }
    });
  };

  render() {
    const { assumedValues } = this.state;
    const {
      basicInfo,
      basicInfo: { dealSalesPrice, afterRepairValue, rehabEstimate }
    } = this.props;

    let totalInvested =
      dealSalesPrice +
      (assumedValues.purchase_cost + assumedValues.holding_cost);

    let profit = afterRepairValue - assumedValues.selling_cost - totalInvested;
    if (_.isEmpty(this.state.analysis)) {
      return <LoadingIndicator />;
    }
    const {
      current: { cashROI, totalCashFlow, totalROI }
    } = this.state.analysis;
    return (
      <Fragment>
        <Row>
          <Col xl="5">
            <Card className="card-box mb-5">
              <CardHeader>
                <div className="card-header--title">
                  <h4>Summary</h4>
                </div>
                <div className="card-header--actions">
                  <div className="btn-group btn-group-sm mr-4" role="group">
                    <Button
                      onClick={() => this.switchInvestmentType("flip")}
                      color={
                        this.state.investmentType === "flip" ? "first" : "light"
                      }
                      active={true}
                    >
                      Fix & Flip
                    </Button>
                    <Button
                      onClick={() => this.switchInvestmentType("rental")}
                      color={
                        this.state.investmentType === "rental"
                          ? "first"
                          : "light"
                      }
                      active={true}
                    >
                      Rental
                    </Button>
                  </div>
                  <DealAssumptions
                    updateAssumptions={this.updateAssumptions}
                    assumedValues={this.state.assumedValues}
                    assumptions={assumptions}
                  />
                </div>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col className="col-xl-6">
                    <div className="p-3 d-flex align-items-center">
                      <FontAwesomeIcon
                        icon={faTags}
                        className="font-size-xxl text-first mr-3"
                      />
                      <div>
                        <span className="d-block">Sale Price</span>
                        <span className="font-weight-bold font-size-lg">
                          <NumberFormat
                            value={dealSalesPrice}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                            decimalScale={2}
                          />
                        </span>
                      </div>
                    </div>
                    <div className="p-3 d-flex align-items-center">
                      <FontAwesomeIcon
                        icon={faTools}
                        className="font-size-xxl text-first mr-3"
                      />
                      <div>
                        <span className="d-block">Rehab Estimate</span>
                        <span className="font-weight-bold font-size-lg">
                          <NumberFormat
                            value={rehabEstimate}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                            decimalScale={2}
                          />
                        </span>
                      </div>
                    </div>
                    <div className="p-3 d-flex align-items-center">
                      <FontAwesomeIcon
                        icon={faFunnelDollar}
                        className="font-size-xxl text-first mr-3"
                      />
                      <div>
                        <span className="d-block">After Repair Value</span>
                        <span className="font-weight-bold font-size-lg">
                          <NumberFormat
                            value={afterRepairValue}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                            decimalScale={2}
                          />
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col className="col-xl-6">
                    {this.state.investmentType === "rental" ? (
                      <ul className="list-group list-group-flush">
                        <DescriptionItem
                          label="Cash ROI"
                          value={
                            <NumberFormat
                              value={cashROI}
                              displayType={"text"}
                              renderText={value => `${value}%`}
                              decimalScale={2}
                            />
                          }
                          icon={faDotCircle}
                          iconClass="text-primary mr-2"
                        />
                        <DescriptionItem
                          label="Cash Flow (Monthly)"
                          value={
                            <NumberFormat
                              value={totalCashFlow / 12}
                              displayType={"text"}
                              renderText={value => `${value}`}
                              prefix={"$"}
                              decimalScale={2}
                            />
                          }
                          icon={faHandHoldingUsd}
                          iconClass="text-primary mr-2"
                        />
                        <DescriptionItem
                          label="Total ROI"
                          value={
                            <NumberFormat
                              value={totalROI}
                              displayType={"text"}
                              renderText={value => `${value}%`}
                              decimalScale={2}
                            />
                          }
                          icon={faWallet}
                          iconClass="text-primary mr-2"
                        />
                      </ul>
                    ) : (
                      <ul className="list-group list-group-flush">
                        <DescriptionItem
                          label="ROI"
                          value={
                            <NumberFormat
                              value={(profit * 100) / totalInvested}
                              displayType={"text"}
                              renderText={value => `${value}%`}
                              decimalScale={2}
                            />
                          }
                          icon={faDotCircle}
                          iconClass="text-primary mr-2"
                        />
                        <DescriptionItem
                          label="Profit"
                          value={
                            <NumberFormat
                              value={profit}
                              displayType={"text"}
                              renderText={value => `${value}`}
                              decimalScale={2}
                            />
                          }
                          icon={faHandHoldingUsd}
                          iconClass="text-primary mr-2"
                        />
                      </ul>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xl={3}>
            <Card className="card-box mb-5">
              <CardHeader>
                <h4 className="font-size-lg mb-0 py-2 font-weight-bold">
                  Highlights
                </h4>
              </CardHeader>
              <CardBody>
                <ul className="list-unstyled text-left my-4 font-weight-bold">
                  {basicInfo.dealHighlights.length > 0 ? (
                    basicInfo.dealHighlights.map((highlight, idx) => {
                      return (
                        <li className="px-4 py-2" key={`deal-highlight-${idx}`}>
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="text-success font-size-lg mr-2"
                          />
                          {highlight}
                        </li>
                      );
                    })
                  ) : (
                    <div>No Highlight Provided</div>
                  )}
                </ul>
              </CardBody>
            </Card>
          </Col>
          <Col xl={3}>
            <OwnerCard basicInfo={basicInfo} />
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  financial: state.financial
});

const mapDispatchToProps = dispatch => ({
  getAssetFinancials: slug => getAssetFinancials(slug, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FlipAnalyzer);
