import React, { Component } from "react";
import { Button, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { connect } from "react-redux";
import "react-tagsinput/react-tagsinput.css";
import { isFetching, isSuccess } from "../../../reducers/reducerUtils";
import { addAttachmentTags } from "../../../actions/attachments/attachmentAction";
import { Formik } from "formik";
import * as Yup from "yup";
import CreatableSelect from "react-select/creatable";
import SlidingPane from "react-sliding-pane";
import { getChecklistCategories } from "../../../helpers/constants";

class AddTags extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showModal: false,
      tagsSaved: false,
      error: null,
      tags: [],
    };
  }

  handleChange = (tags) => {
    this.setState({ tags: tags });
  };

  closeModal = () => {
    const { toggleModal } = this.props;
    this.setState(
      { loading: false, tags: [], tagsSaved: false, error: null },
      () => {
        toggleModal({});
      }
    );
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { modalOpen, file } = this.props;
    if (!prevState.tagsSaved && this.state.tagsSaved) {
      this.closeModal();
    }
    // When the modal opens, lets set the tags
    if (!prevProps.modalOpen && modalOpen && file) {
      this.setState({ tags: file.tags });
    }
  };

  addTags = (values) => {
    const { assetSlug, file, addAttachmentTags } = this.props;
    addAttachmentTags({
      id: file._id,
      slug: assetSlug,
      tags: values.tags,
    });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { attachments } = nextProps;
    if (attachments.type === "ADD_TAGS") {
      if (isFetching(attachments)) {
        return { loading: true, tagsSaved: false };
      } else {
        if (isSuccess(attachments)) {
          return {
            selectedFileToView: prevState.selectedFileToView,
            loading: false,
            tagsSaved: true,
          };
        } else {
          return {
            selectedFileToView: prevState.selectedFileToView,
            loading: false,
            error: attachments.error,
          };
        }
      }
    }

    return null;
  }

  render() {
    const { file, modalOpen, asset } = this.props;
    const { loading } = this.state;
    if (!file) return null;

    const initialValues = {
      tags: file.tags
        ? file.tags.map((a) => {
            return { value: a, label: a };
          })
        : [],
    };

    const options = getChecklistCategories(asset.assetType);
    const validationSchema = Yup.object({
      tags: Yup.array(Yup.string().required("Tags is required")).min(1),
    });
    return (
      <Formik
        enableReinitialize4
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => this.addTags(values)}
      >
        {(props) => {
          const { setFieldValue, handleSubmit } = props;
          return (
            <SlidingPane
              isOpen={modalOpen}
              from="right"
              width="350px"
              hideHeader={true}
              className="d-flex justify-content-center modal-content"
              onRequestClose={() => this.closeModal()}
            >
              <ModalHeader toggle={this.closeModal}>
                {"Add Category"}
              </ModalHeader>
              <ModalBody>
                <CreatableSelect
                  isMulti
                  isSearchable
                  name="tags"
                  id="desiredInvestmentTypes"
                  placeholder="Select document category or add your own"
                  searchable={true}
                  options={options}
                  defaultValue={initialValues.tags}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: "0.29rem",
                    borderWidth: 1,
                    colors: {
                      ...theme.colors,
                      primary25: "rgba(60,68,177,0.15)",
                      primary50: "rgba(60,68,177,0.15)",
                      primary: "#3c44b1",
                    },
                  })}
                  onChange={(values) =>
                    setFieldValue("tags", values.map((tag) => tag.value))
                  }
                />
                <p>Press tab to enter more tags</p>
              </ModalBody>
              <ModalFooter>
                <Button
                  type="button"
                  className="btn btn-secondary px-5 d-inline-block mx-2"
                  onClick={this.closeModal}
                  disabled={loading}
                >
                  Cancel
                </Button>
                <Button
                  className="btn savebtns btn-primary px-5  d-inline-block mx-2"
                  type="submit"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? "Saving" : "Save"}
                </Button>
              </ModalFooter>
            </SlidingPane>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = (state) => ({
  attachments: state.attachments.attachments,
});

const mapDispatchToProps = (dispatch) => ({
  addAttachmentTags: (data) => addAttachmentTags(data, dispatch),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddTags);
