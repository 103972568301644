import React, { Component } from "react";
import PropTypes from "prop-types";
import CreatableSelect from "react-select/creatable";
import { connect } from "react-redux";
import { getEntities, addEntity } from "../../../actions/asset/assetAction";
import { getIn } from "formik";
import { isInit, isFetching, isSuccess } from "../../../reducers/reducerUtils";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import {
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  Nav,
  NavItem
} from "reactstrap";
import { NavLink as NavLinkStrap } from "reactstrap";

class EntitySelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      adding: false,
      entities: [],
      options: [],
      newEntityName: null,
      filterOpen: false
    };
  }
  toggleFilter = () => {
    this.setState({ filterOpen: !this.state.filterOpen });
  };

  handleCreate = value => {
    this.setState({ adding: true, newEntityName: value }, () => {
      this.props.addEntity({ name: value });
    });
  };

  componentDidMount() {
    const { entities, getEntities } = this.props;
    if (isInit(entities) && !this.state.loading) {
      this.setState({ loading: true }, () => {
        getEntities();
      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { loading, adding } = prevState;
    const { entities, isTag } = nextProps;

    if (adding && !loading) {
      return { loading: true, adding: false };
    }
    if (loading && !_.isEmpty(entities)) {
      if (!isFetching(entities)) {
        if (isSuccess(entities)) {
          let entityOptions = entities.data
            .filter(entity => entity.isTag === isTag)
            .map(entity => {
              return { label: entity.name, value: entity._id };
            });

          if (nextProps.allOption) {
            if (isTag)
              entityOptions.unshift({ label: "All Tags", value: "All" });
            else entityOptions.unshift({ label: "All Entities", value: "All" });
          }
          return {
            entities: entities.data,
            loading: false,
            options: entityOptions
          };
        } else {
          return {
            error: entities.error,
            loading: false
          };
        }
      }
    } else if (!_.isEmpty(entities) && !isFetching(entities)) {
      if (isSuccess(entities)) {
        let entityOptions = entities.data
          .filter(entity => entity.isTag === isTag)
          .map(entity => {
            return { label: entity.name, value: entity._id };
          });
        if (nextProps.allOption) {
          if (isTag) entityOptions.unshift({ label: "All Tags", value: "All" });
          else entityOptions.unshift({ label: "All Entities", value: "All" });
        }
        return {
          entities: entities.data,
          options: entityOptions
        };
      }
    }
    return null;
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { loading, newEntityName } = prevState;
    if (!this.state.loading && loading && newEntityName) {
      const { entities } = this.state;
      let newEntity = entities.find(e => e.name === newEntityName);
      if (newEntity)
        this.onChange({ value: newEntity._id, name: newEntity.name });
    }
  };

  onChange = option => {
    const {
      field,
      form: { setFieldValue },
      onChange
    } = this.props;
    this.setState({ newEntityName: null }, () => {
      setFieldValue(field.name, option && option.value);

      if (onChange) onChange(option.value);
    });
  };

  render() {
    const {
      field,
      form: { /*touched, errors, setFieldValue,*/ values },
      className
    } = this.props;
    const { loading, entities, options } = this.state;
    if (loading) return <div />;
    // const touch = getIn(touched, field.name);
    // const error = getIn(errors, field.name);
    const value = getIn(values, field.name);

    let defaultOption;
    if (entities && entities.length > 0) {
      defaultOption = options.find(option => {
        return option.value === value;
      });
    }

    if (!defaultOption) {
      if (this.props.readOnly && this.props.allOption && !this.props.isTag)
        defaultOption = { label: "All Entities", value: "All" };
      else if (this.props.readOnly && this.props.allOption && this.props.isTag)
        defaultOption = { label: "All Tags", value: "All" };
      else defaultOption = "undefined";
    }

    if (this.props.readOnly) {
      return (
        <InputGroupButtonDropdown
          addonType="append"
          isOpen={this.state.filterOpen}
          toggle={this.toggleFilter}
          className={className || "px-4"}
        >
          <DropdownToggle color="outline-primary" className="btn-pill" caret>
            {defaultOption.label}
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-xl dropdown-menu">
            <Nav className="nav-pills flex-column">
              {options.map((option, i) => {
                return (
                  <NavItem key={i}>
                    <NavLinkStrap
                      onClick={() => this.onChange(option)}
                      active={option.value === value}
                    >
                      <span> {option.label}</span>
                    </NavLinkStrap>
                  </NavItem>
                );
              })}
            </Nav>
          </DropdownMenu>
        </InputGroupButtonDropdown>
      );
    } else {
      return (
        <CreatableSelect
          isSearchable
          name="entity"
          placeholder="Select or Create Entity"
          options={options}
          value={defaultOption}
          onCreateOption={this.handleCreate}
          classNamePrefix={"entity-select"}
          formatCreateLabel={inputValue => {
            return (
              <div className="text-primary d-flex align-items-center">
                <div className="d-30 rounded-sm btn-icon bg-neutral-primary mr-2">
                  <FontAwesomeIcon icon={faPlus} />
                </div>
                <span className="pt-1 font-weight-bold">{inputValue}</span>
              </div>
            );
          }}
          theme={theme => ({
            ...theme,
            borderRadius: "0.29rem",
            borderWidth: 1,
            colors: {
              ...theme.colors,
              primary25: "rgba(60,68,177,0.15)",
              primary50: "rgba(60,68,177,0.15)",
              primary: "#3c44b1"
            }
          })}
          onChange={this.onChange}
        />
      );
    }
  }
}

EntitySelect.propTypes = {
  isTag: PropTypes.bool
};

EntitySelect.defaultProps = {
  isTag: false
};

const mapStateToProps = state => ({
  entities: state.asset.entities
});

const mapDispatchToProps = dispatch => {
  return {
    getEntities: () => getEntities(dispatch),
    addEntity: data => addEntity(data, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EntitySelect);
