import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import ConfirmModal from "../../../shared/components/ConfirmModal";

import { updateReminder } from "../../../actions/reminder";

const DeleteReminderModal = ({ assetData, assetReminders, updateReminder }) => {
  const [showModal, setShowModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const onDelete = (type, data = {}) => {
    setDeleting(true);
    updateReminder({
      remId: assetData._id,
      updateType: type,
      ...data
    });
  };

  useEffect(
    () => {
      const { updateReminderSuccess } = assetReminders;
      if (updateReminderSuccess) {
        setDeleting(false);
        setShowModal(false);
      }
    },
    [assetReminders]
  );
  return (
    <>
      <Button
        className={"btn-neutral-danger px-3 "}
        onClick={() => setShowModal(prevState => !prevState)}
      >
        <FontAwesomeIcon icon={faTrash} className="font-size-lg" />
      </Button>
      <ConfirmModal
        title={"Are you sure to delete this reminder?"}
        subtitle={"You cannot undo this operation."}
        type="danger"
        modalOpen={showModal}
        onCancel={() => setShowModal(prevState => !prevState)}
        onSubmit={() => onDelete("remove")}
        error={false}
        loading={deleting}
        btnText={"Delete"}
        btnTextOnLoading={"Deleting"}
      />
    </>
  );
};

const mapStateToProps = state => ({
  assetReminders: state.reminders
});

const mapDispatchToProps = dispatch => ({
  updateReminder: data => updateReminder(data, dispatch)
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteReminderModal);
