import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as Yup from "yup";
import {
  Button,
  Col,
  Row,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  FormGroup,
  Label,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import classNames from "classnames";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { Field, withFormik } from "formik";
import CreatableSelect from "react-select/creatable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import SectionHeader from "../../shared/components/SectionHeader";

import FormField from "../../shared/components/form/FormField";
import MaskedField from "../../shared/components/form/MaskedField";
import ToggleSwitch from "../../shared/components/form/ToggleSwitch";
import customSelect from "../../shared/components/form/customSelect";
import ZillowTOS from "../../shared/components/ZillowTOS";
import EntitySelect from "../../shared/components/form/EntitySelect";
import AssetTypeSelector from "../../shared/components/form/AssetTypeSelector";
import { sortAlphaNumeric } from "../../helpers";
// import showToast from "../../helpers/toastNotification";
import { lookupAsset, lookupParcel } from "../../actions/asset/assetAction";
import {
  resetAddAsset,
  addAssetAndFinancials,
} from "../../actions/asset/assetAction";
import { isFetching, isSuccess } from "../../reducers/reducerUtils";

const defaultAsset = {
  user_role: "",
  parcel_id: "",
  address: "",
  beds: "",
  baths: "",
  stories: "",
  units: "",
  co_ordinates: [],
  investment_type: "",
  management_platform: "",
  self_managed: false,
  county: "",
  lotSizeSquareFeet: "",
  yearBuilt: "",
  asset_type: "",
  purchase_date: "",
  purchase_price: "",
  down_payment: "",
  market_value: "",
  market_rent: "",
  property_tax: { amount: "", frequency: "Yearly", isPercent: false },
  insurance: { amount: "", frequency: "Yearly", isPercent: false },
  hoa_fee: { amount: "", frequency: "Monthly", isPercent: false },
  management_fee: { amount: "", frequency: "Monthly", isPercent: false },
  rent: { amount: "", frequency: "Monthly", isPercent: false },
  avg_daily_rate: { amount: "", frequency: "Avg Daily Rate", isPercent: false },
  vacancy_rate: { amount: "", frequency: "Yearly", isPercent: true },
  rents: [],
};

const rentalFields = () => {
  return (
    <Row className="p-4" xl={2}>
      <Col md={6}>
        <FormGroup>
          <Label>Purchase date</Label>
          <Field
            name="purchase_date"
            component={MaskedField}
            type="text"
            addonSize={"lg"}
            addonType={"prepend"}
            addonText={"$"}
            maskType="date"
          />
        </FormGroup>

        <FormGroup>
          <Label>Purchase Price</Label>
          <Field
            name="purchase_price"
            component={MaskedField}
            type="text"
            addonSize={"lg"}
            addonType={"prepend"}
            addonText={"$"}
            maskType="currency"
          />
        </FormGroup>
        <FormGroup>
          <Label>Down Payment</Label>
          <Field
            name="down_payment"
            component={MaskedField}
            type="text"
            addonSize={"lg"}
            addonType={"prepend"}
            addonText={"$"}
            maskType="currency"
          />
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup>
          <Label>Market Value</Label>
          <Field
            name="market_value"
            component={MaskedField}
            type="text"
            addonSize={"lg"}
            addonType={"prepend"}
            addonText={"$"}
            maskType="currency"
          />
        </FormGroup>
        <FormGroup>
          <Label>Market Rent</Label>
          <Field
            name="market_rent"
            component={MaskedField}
            type="text"
            addonSize={"lg"}
            addonType={"prepend"}
            addonText={"$"}
            maskType="currency"
          />
        </FormGroup>
        <ZillowTOS />
      </Col>
    </Row>
  );
};

const notesFields = () => {
  return (
    <Row className="p-4" xl={2}>
      <Col md={6}>
        <FormGroup>
          <Label>Investment Amount</Label>
          <Field
            name="investment_amount"
            component={MaskedField}
            addonSize={"lg"}
            addonType={"prepend"}
            addonText={"$"}
            maskType="currency"
          />
        </FormGroup>
        <FormGroup>
          <Label>Interest Rate</Label>
          <Field
            name="note_interest"
            component={MaskedField}
            addonSize={"lg"}
            addonType={"prepend"}
            addonText={"%"}
            maskType="percent"
          />
        </FormGroup>
        <FormGroup>
          <Label>Points</Label>
          <Field
            name="note_points"
            component={MaskedField}
            addonSize={"lg"}
            addonType={"prepend"}
            addonText={"%"}
            maskType="percent"
          />
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup>
          <Label>Investment Start Date</Label>{" "}
          <Field
            name="investment_start_date"
            component={MaskedField}
            type="text"
            addonSize={"lg"}
            addonType={"prepend"}
            addonText={"$"}
            maskType="date"
          />
        </FormGroup>
        <FormGroup>
          <Label>Investment End Date</Label>
          <Field
            name="investment_end_date"
            component={MaskedField}
            type="text"
            addonSize={"lg"}
            addonType={"prepend"}
            addonText={"$"}
            maskType="date"
          />
        </FormGroup>
        <FormGroup>
          <Label>Distribution Frequency</Label>
          <Field
            name="distribution_frequency"
            component={customSelect}
            options={[
              { value: "Monthly", label: "Monthly" },
              { value: "Quarterly", label: "Quarterly" },
            ]}
          />
        </FormGroup>
      </Col>{" "}
    </Row>
  );
};

const syndicationFields = () => {
  return (
    <Row className="p-4" xl={2}>
      <Col md={6}>
        <FormGroup>
          <Label>Syndication Name</Label>
          <Field name="address" component={FormField} />
        </FormGroup>
        <FormGroup>
          <Label>Entity Name</Label>
          <Field name="entity" component={EntitySelect} />
        </FormGroup>
        <FormGroup>
          <Label>Investment Amount</Label>
          <Field
            name="investment_amount"
            component={MaskedField}
            addonSize={"lg"}
            addonType={"prepend"}
            addonText={"$"}
            maskType="currency"
          />
        </FormGroup>

        <FormGroup>
          <Label>Investment Start Date</Label>{" "}
          <Field
            name="investment_start_date"
            component={MaskedField}
            type="text"
            addonSize={"lg"}
            addonType={"prepend"}
            addonText={"$"}
            maskType="date"
          />
        </FormGroup>
        <FormGroup>
          <Label>Investment End Date</Label>
          <Field
            name="investment_end_date"
            component={MaskedField}
            type="text"
            addonSize={"lg"}
            addonType={"prepend"}
            addonText={"$"}
            maskType="date"
          />
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup>
          <Label>Annual Rate (Projected)</Label>
          <Field
            name="annual_coc"
            component={MaskedField}
            addonSize={"lg"}
            addonType={"prepend"}
            addonText={"%"}
            maskType="percent"
          />
        </FormGroup>
        <FormGroup>
          <Label>Annualized Return (Projected)</Label>
          <Field
            name="annualized_irr"
            component={MaskedField}
            addonSize={"lg"}
            addonType={"prepend"}
            addonText={"%"}
            maskType="percent"
          />
        </FormGroup>
        <FormGroup>
          <Label>Distribution Frequency</Label>
          <Field
            name="distribution_frequency"
            component={customSelect}
            options={[
              { value: "Monthly", label: "Monthly" },
              { value: "Quarterly", label: "Quarterly" },
            ]}
          />
        </FormGroup>
      </Col>{" "}
    </Row>
  );
};
const flipField = () => {
  return (
    <Row className="p-4" xl={2}>
      <Col md={6}>
        <FormGroup>
          <Label>Purchase date</Label>
          <Field
            name="purchase_date"
            component={MaskedField}
            type="text"
            addonSize={"lg"}
            addonType={"prepend"}
            addonText={"$"}
            maskType="date"
          />
        </FormGroup>
        <FormGroup>
          <Label>Purchase Price</Label>
          <Field
            name="purchase_price"
            component={MaskedField}
            type="text"
            addonSize={"lg"}
            addonType={"prepend"}
            addonText={"$"}
            maskType="currency"
          />
        </FormGroup>
        <FormGroup>
          <Label>Down Payment</Label>
          <Field
            name="down_payment"
            component={MaskedField}
            type="text"
            addonSize={"lg"}
            addonType={"prepend"}
            addonText={"$"}
            maskType="currency"
          />
        </FormGroup>
        <FormGroup>
          <Label>Rehab Estimate</Label>
          <Field
            name="rehab_estimate"
            component={MaskedField}
            addonSize={"lg"}
            addonType={"prepend"}
            addonText={"$"}
            maskType="currency"
          />
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup>
          <Label>After Repair Value</Label>
          <Field
            name="after_repair_value"
            component={MaskedField}
            addonSize={"lg"}
            addonType={"prepend"}
            addonText={"$"}
            maskType="currency"
          />
        </FormGroup>
        <FormGroup>
          <Label>Market Value</Label>
          <Field
            name="market_value"
            component={MaskedField}
            type="text"
            addonSize={"lg"}
            addonType={"prepend"}
            addonText={"$"}
            maskType="currency"
          />
        </FormGroup>
        <FormGroup>
          <Label>Market Rent</Label>
          <Field
            name="market_rent"
            component={MaskedField}
            type="text"
            addonSize={"lg"}
            addonType={"prepend"}
            addonText={"$"}
            maskType="currency"
          />
        </FormGroup>
        <ZillowTOS />
      </Col>
    </Row>
  );
};

const AssetCreateForm = ({
  parcelLookup,
  assetLookup,
  assetAdded,
  resetAddAsset,
  getFieldMeta,
  setFieldValue,
  handleChange,
  errors,
  lookupAsset,
  lookupParcel,
  handleSubmit,
  values,
  history,
  location,
}) => {
  const [lookingUpAsset, setLookingUpAsset] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState({});
  const [assetMetadata, setAssetMetadata] = useState(null);
  const [parcelInfo, setParcelInfo] = useState(null);
  const [units, setUnits] = useState([]);

  let content;
  switch (values["investment_type"]) {
    case "syndication":
      content = syndicationFields();
      break;
    case "note":
      content = notesFields();
      break;
    case "flip":
      content = flipField();
      break;
    case "short_term_rental":
    case "rental":
    case "primary":
    default:
      content = rentalFields();
      break;
  }

  useEffect(() => {
    const investmentType = location.pathname.split("/")[3];
    if (investmentType && investmentType !== "") {
      setFieldValue(`investment_type`, investmentType);
    }
  }, []);

  const handleSelect = async (address) => {
    setFieldValue(`address`, address);
    try {
      const results = await geocodeByAddress(address);
      const { address_components } = results[0];
      address_components.forEach((component) => {
        if (component.types.includes("route"))
          setFieldValue(`street`, component.long_name);
        if (component.types.includes("locality"))
          setFieldValue(`city`, component.long_name);
        if (component.types.includes("administrative_area_level_1"))
          setFieldValue(`state`, component.short_name);
        if (component.types.includes("postal_code"))
          setFieldValue(`zip`, component.long_name);
      });
      const latLng = await getLatLng(results[0]);
      setFieldValue("co_ordinates", [latLng.lat, latLng.lng]);
    } catch (error) {
      console.log(error);
    }
    setLookingUpAsset(true);
    setIsLoading(true);
    lookupAsset(address);
  };

  useEffect(
    () => {
      if (!isFetching(assetAdded) && isSuccess(assetAdded)) {
        history.push(`/assets/${assetAdded.data.data.slug}/ledger`);
      }
    },
    [assetAdded]
  );

  const onChangeUnit = (option) => {
    if (assetMetadata) {
      let selectedAsset = assetMetadata[option.value];
      setSelectedAsset(selectedAsset);
    }
  };

  useEffect(
    () => {
      if (lookingUpAsset && !isFetching(assetLookup)) {
        if (isSuccess(assetLookup)) {
          let assets = assetLookup.data.data;
          if (assets.length === 0) {
            setLookingUpAsset(false);
            setAssetMetadata(assets);
            setSelectedAsset({
              parcelID: "",
              beds: "",
              baths: "",
              stories: "",
              units: "",
              imageUrl:
                "https://media.bettercapital.us/file/bettercapital/common/streetview.jpeg",
              self_managed: true,
              metadata: {
                parcelID: "",
                beds: "",
                baths: "",
                stories: "",
                units: "",
                imageUrl:
                  "https://media.bettercapital.us/file/bettercapital/common/streetview.jpeg",
              },
            });
          } else {
            setLookingUpAsset(false);
            setAssetMetadata(assets);
            setSelectedAsset(assets.length === 1 ? assets[0] : null);
          }
        }
      }
      if (isLoading && !isFetching(parcelLookup)) {
        if (isSuccess(parcelLookup)) {
          setIsLoading(false);
          setParcelInfo(parcelLookup.data);
        }
      }
    },
    [assetLookup, parcelLookup]
  );

  useEffect(
    () => {
      if (selectedAsset) {
        setFieldValue(`beds`, selectedAsset.beds || 0);
        setFieldValue(`stories`, selectedAsset.stories || 0);
        setFieldValue(`units`, selectedAsset.units || 1);
        setFieldValue(`baths`, selectedAsset.baths || 0);
        setFieldValue(`imageUrl`, selectedAsset.imageUrl);
        setFieldValue(`parcel_id`, selectedAsset.parcelID);
        setFieldValue(`areaSquareFeet`, selectedAsset.areaSquareFeet || "");
        setFieldValue(`asset_type`, selectedAsset.asset_type);
        setFieldValue(
          `lotSizeSquareFeet`,
          selectedAsset.lotSizeSquareFeet || 0
        );
        setFieldValue(`yearBuilt`, selectedAsset.yearBuilt || 0);
        setFieldValue(`apn`, selectedAsset.apn || "");
        setFieldValue(`county`, selectedAsset.county || "");
        if (selectedAsset.co_ordinates) {
          setFieldValue(`co_ordinates`, selectedAsset.co_ordinates);
        }

        if (selectedAsset.parcelID) {
          setIsLoading(true);
          lookupParcel(selectedAsset.parcelID, selectedAsset.zpid);
        }
      }
    },
    [selectedAsset]
  );

  useEffect(
    () => {
      if (parcelInfo) {
        setFieldValue(`purchase_date`, parcelInfo.purchase_date || "");
        setFieldValue(`purchase_price`, parcelInfo.purchase_price || "");
        setFieldValue(`down_payment`, parcelInfo.down_payment || "");
        setFieldValue(`market_value`, parcelInfo.market_value || "");
        setFieldValue(`market_rent`, parcelInfo.market_rent || 0);
        setFieldValue(`parcelInfo`, parcelInfo);
      }
    },
    [parcelInfo]
  );

  useEffect(
    () => {
      if (assetMetadata) {
        let units = assetMetadata
          .map((asset, idx) => {
            if (asset.unitType && asset.unit) {
              return {
                label: asset.unitType + " " + asset.unit,
                value: idx,
              };
            }
            return null;
          })
          .filter(Boolean)
          .sort((a, b) => sortAlphaNumeric(a.label, b.label));
        setUnits(units);
      }
    },
    [assetMetadata]
  );

  useEffect(
    () => {
      values.rents = [];
      if (values.units > 1) {
        for (var i = 1; i <= values.units; i++) {
          values.rents.push({ name: i, amount: "" });
        }
      }
    },
    [values.units]
  );

  useEffect(
    () => {
      switch (values["investment_type"]) {
        case "note":
        case "syndication":
        case "fund":
          setFieldValue(`user_role`, "I");
          break;
        case "short_term_rental":
        case "rental":
        case "flip":
        case "primary":
        default:
          setFieldValue(`user_role`, "O");
          break;
      }
    },
    [values.investment_type]
  );

  return (
    <main className="p-5">
      <SectionHeader
        pathItems={[
          { label: "Dashboard", path: "/dashboard", active: false },
          {
            label: "Add Asset",
            path: `add`,
            active: true,
          },
        ]}
      />
      <Col md={12} className="m-auto">
        <form onSubmit={handleSubmit}>
          <Field name="investment_type" component={AssetTypeSelector} />
          {values.investment_type !== "syndication" && (
            <Card className="mt-5">
              <CardHeader className="bg-light">
                <div className="text-left h3-title">
                  <h3>Property</h3>
                </div>
              </CardHeader>
              <CardBody>
                <Row className="p-4" xl={2}>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Address</Label>
                      <PlacesAutocomplete
                        value={getFieldMeta(`address`).value || ""}
                        onSelect={handleSelect}
                        onChange={handleChange(`address`)}
                        searchOptions={{
                          componentRestrictions: { country: "us" },
                        }}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                        }) => (
                          <>
                            <InputGroup className="input-group-seamless">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <FontAwesomeIcon
                                    icon={faSearch}
                                    className="mx-auto"
                                  />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                {...getInputProps({
                                  placeholder: "Enter your property address",
                                  className: "form-control",
                                })}
                                type="search"
                              />
                              {errors["summary"] &&
                              errors["summary"].address ? (
                                <p className={"text-danger mt-2"}>
                                  {errors["summary"].address}
                                </p>
                              ) : null}
                            </InputGroup>
                            {suggestions.length > 0 && (
                              <div className="autocomplete-suggestion-container ">
                                {suggestions.map((suggestion) => {
                                  const className = classNames(
                                    "autocomplete__suggestion-item",
                                    {
                                      "autocomplete__suggestion-item--active":
                                        suggestion.active,
                                    }
                                  );

                                  return (
                                    <div
                                      key={suggestion.id}
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                      })}
                                    >
                                      <strong>
                                        {
                                          suggestion.formattedSuggestion
                                            .mainText
                                        }
                                      </strong>{" "}
                                      <small>
                                        {
                                          suggestion.formattedSuggestion
                                            .secondaryText
                                        }
                                      </small>
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </>
                        )}
                      </PlacesAutocomplete>
                    </FormGroup>
                    {assetMetadata && assetMetadata.length > 1 && (
                      <FormGroup>
                        <Label>Select Unit</Label>
                        <CreatableSelect
                          name="unit"
                          placeholder="Select your unit"
                          options={units}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: "0.29rem",
                            borderWidth: 1,
                            colors: {
                              ...theme.colors,
                              primary25: "rgba(60,68,177,0.15)",
                              primary50: "rgba(60,68,177,0.15)",
                              primary: "#3c44b1",
                            },
                          })}
                          onChange={onChangeUnit}
                        />
                      </FormGroup>
                    )}
                    {selectedAsset && (
                      <FormGroup>
                        <Label>Self Managed</Label>
                        <Field
                          name="self_managed"
                          component={ToggleSwitch}
                          type={"number"}
                        />
                      </FormGroup>
                    )}
                    {selectedAsset && !getFieldMeta(`self_managed`).value && (
                      <FormGroup>
                        <Label>Management Platform</Label>
                        <Field
                          name="management_platform"
                          component={customSelect}
                          value={getFieldMeta(`management_platform`).value}
                          placeholder="Select Platform"
                          options={[
                            { value: "airbnb", label: "AirBnB" },
                            { value: "appfolio", label: "AppFolio" },
                            { value: "buildium", label: "Buildium" },
                            { value: "hemlane", label: "Hemlane" },
                            {
                              value: "propertyware",
                              label: "PropertyWare",
                            },
                            { value: "homeroom", label: "HomeRoom" },
                            { value: "rentmanager", label: "RentManager" },
                            { value: "quickbook", label: "QuickBooks" },
                            { value: "yardi", label: "Yardi" },
                            { value: "tenantcloud", label: "TenantCloud" },
                            { value: "cozy", label: "Cozy.co" },
                            { value: "other", label: "Other" },
                            { value: "none", label: "None" },
                          ]}
                        />
                      </FormGroup>
                    )}
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Beds</Label>
                      <Field
                        name="beds"
                        component={FormField}
                        type={"number"}
                        placeholder="beds"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Baths</Label>
                      <Field
                        name="baths"
                        component={FormField}
                        type={"number"}
                        placeholder="baths"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Stories</Label>
                      <Field
                        name="stories"
                        component={FormField}
                        type={"number"}
                        placeholder="stories"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Number of units</Label>
                      <Field
                        name="units"
                        component={FormField}
                        type={"number"}
                        placeholder="No. of units"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          )}
          {/* <Row className="mt-5">
              <Col lg={12}>
                <div className="text-left h2-title">
                  <h3>Investment</h3>
                </div>
              </Col>
            </Row> */}
          <Card className="mt-5">
            <CardHeader className="bg-light">
              <div className="text-left h2-title">
                <h3>Investment</h3>
              </div>
            </CardHeader>
            <CardBody>{content}</CardBody>
          </Card>
          {/* <Row className="mt-5">
              <Col lg={12}>
                <div className="text-left h2-title">
                  <h3>Financials</h3>
                </div>
              </Col>
            </Row> */}
          {/* values.investment_type !== "syndication" && (
            <Card className="mt-5">
              <CardHeader className="bg-light">
                <div className="text-left h2-title">
                  <h3>Financials</h3>
                </div>
              </CardHeader>
              <CardBody>
                <Row className="p-4" xl={2}>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Insurance</Label>
                      <Field
                        name="insurance"
                        component={FinancialInputField}
                        type="text"
                        addonSize={"lg"}
                        addonType={"prepend"}
                        addonText={"$"}
                        maskType="currency"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Property Taxes</Label>
                      <Field
                        name="property_tax"
                        component={FinancialInputField}
                        frequencies={["Yearly", "Half-Yearly"]}
                      />
                    </FormGroup>
                    {values.units === 1 &&
                      values.investment_type !== "short_term_rental" && (
                        <FormGroup>
                          <Label>Rent</Label>
                          <Field name="rent" component={FinancialInputField} />
                        </FormGroup>
                      )}
                    {values.units === 1 &&
                      values.investment_type === "short_term_rental" && (
                        <>
                          <FormGroup>
                            <Label>Average Daily Rate</Label>
                            <Field
                              name="avg_daily_rate"
                              component={FinancialInputField}
                              allowDaily={true}
                              frequencies={["Avg Daily Rate"]}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label>Vacancy Rate</Label>
                            <Field
                              name="vacancy_rate"
                              component={FinancialInputField}
                              onlyPercent={true}
                              frequencies={["Yearly"]}
                            />
                          </FormGroup>
                        </>
                      )}
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>HOA Fee</Label>
                      <Field name="hoa_fee" component={FinancialInputField} />
                    </FormGroup>
                    <FormGroup>
                      <Label>PM Fee</Label>
                      <Field
                        name="management_fee"
                        component={FinancialInputField}
                        allowPercent={true}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {values.units > 1 && (
                  <Row className="p-4">
                    <FieldArray
                      name="rents"
                      render={(arrayHelpers) => {
                        return (
                          <table className="table">
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  style={{ borderBottom: "none" }}
                                  className="ft"
                                >
                                  Unit
                                </th>
                                <th
                                  scope="col"
                                  style={{ borderBottom: "none" }}
                                >
                                  Rent
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {values &&
                                values.rents.map((r, i) => (
                                  <tr key={i}>
                                    <td className="ft">
                                      <Field
                                        name={`rents.${i}.name`}
                                        className="form-control"
                                      />
                                    </td>
                                    <td>
                                      <Field
                                        component={InlineCurrencyField}
                                        name={`rents.${i}.amount`}
                                        type="text"
                                        placeholder="Enter amount"
                                      />
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        );
                      }}
                    />
                  </Row>
                )}
              </CardBody>
            </Card>
                    ) */}
          <Row className="mt-5">
            <Col md={6}>
              <Button color={"primary"} type="submit" className="px-5 my-5">
                Add Asset
              </Button>
            </Col>
          </Row>
        </form>
      </Col>
    </main>
  );
};

const AddAsset = withFormik({
  mapPropsToValues: (props) => ({
    parcel_id: props.asset.parcel_id,
    investment_type: props.investment_type || "rental",
    user_role: props.user_role || "",
    address: props.asset.address,
    beds: props.asset.beds,
    baths: props.asset.baths,
    co_ordinates: props.asset.co_ordinates,
    units: props.asset.units,
    stories: props.asset.stories,
    asset_type: props.asset.asset_type,
    lotSizeSquareFeet: props.asset.lotSizeSquareFeet,
    areaSquareFeet: props.asset.areaSquareFeet,
    apn: props.asset.apn,
    county: props.asset.county,
    yearBuilt: props.asset.yearBuilt,
    imageUrl: props.asset.imageUrl,
    management_platform: props.asset.management_platform,
    purchase_date: props.asset.purchase_date,
    purchase_price: props.asset.purchase_price,
    down_payment: props.asset.down_payment,
    market_value: props.asset.market_value,
    market_rent: props.asset.market_rent,
    property_tax: props.asset.property_tax,
    insurance: props.asset.insurance,
    hoa_fee: props.asset.hoa_fee,
    self_managed: props.asset.self_managed,
    management_fee: props.asset.management_fee,
    rent: props.asset.rent,
    rents: props.asset.rents,
  }),

  validationSchema: Yup.object().shape({
    address: Yup.string().required("Address is required"),
  }),

  handleSubmit: async (values, { props }) => {
    props.addAssetAndFinancials(values);
  },
})(AssetCreateForm);

const mapStateToProps = (state) => ({
  asset: defaultAsset,
  assetLookup: state.asset.assetLookup,
  parcelLookup: state.asset.parcelLookup,
  assetAdded: state.asset.addAssetAndFinancials,
});

const mapDispatchToProps = (dispatch) => ({
  lookupAsset: (address) => lookupAsset(address, dispatch),
  lookupParcel: (parcelId, zpid) => lookupParcel(parcelId, zpid, dispatch),
  addAssetAndFinancials: (data) => addAssetAndFinancials(data, dispatch),
  resetAddAsset: () => resetAddAsset(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddAsset);
