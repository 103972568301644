import React, { Component } from "react";
import { connect } from "react-redux";
import MyDropZone from "../../../shared/components/DropZone";
import SlidingPane from "react-sliding-pane";
import { Button, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { saveAttachments } from "../../../actions/attachments/attachmentAction";
import { uploadDocuments } from "../../../actions/asset/assetAction";
import { isFetching, isSuccess } from "../../../reducers/reducerUtils";

class AddAttachment extends Component {
  constructor() {
    super();
    this.state = {
      files: [],
      loading: false,
      error: null,
      attachmentAdded: false
    };
  }

  onChange = newFiles => {
    this.setState({ files: newFiles });
  };

  handleSubmit = () => {
    const { files } = this.state;
    if (files.length > 0) {
      if (this.props.onSubmit) {
        this.props.onSubmit(files);
      } else {
        const { assetSlug } = this.props;
        this.setState({ loading: true, error: null }, () => {
          this.props.uploadFiles({
            slug: assetSlug || "",
            fileName: "attachments",
            files,
            params: {
              fileFolder: "documents"
            }
          });
        });
      }
    }
  };

  handleReset = () => {
    this.setState(
      { loading: false, attachmentAdded: false, error: null, files: [] },
      () => {
        this.props.toggleModal();
      }
    );
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (!prevState.attachmentAdded && this.state.attachmentAdded) {
      this.handleReset();
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { attachments, assetSlug, documents } = nextProps;
    if (assetSlug) {
      if (prevState.loading && attachments.type === "SAVE") {
        if (!isFetching(attachments)) {
          if (isSuccess(attachments)) {
            return {
              attachmentAdded: true,
              loading: false
            };
          } else {
            return {
              attachmentAdded: false,
              loading: false,
              error: attachments.error
            };
          }
        }
      }
    } else {
      if (prevState.loading && documents.type === "UPLOAD") {
        if (!isFetching(documents)) {
          if (isSuccess(documents)) {
            return {
              attachmentAdded: true,
              loading: false
            };
          } else {
            return {
              attachmentAdded: false,
              loading: false,
              error: documents.error
            };
          }
        }
      }
    }

    return null;
  }

  render() {
    const { modalOpen } = this.props;
    const { loading } = this.state;

    return (
      <SlidingPane
        isOpen={modalOpen}
        from="right"
        width="350px"
        hideHeader={true}
        className="d-flex justify-content-center modal-content"
        onRequestClose={this.props.toggleModal}
      >
        <ModalHeader toggle={this.handleReset}>Upload Documents</ModalHeader>
        <ModalBody>
          <MyDropZone value={this.state.files} onChange={this.onChange} />
        </ModalBody>
        <ModalFooter>
          <Button
            type="reset"
            className="btn btn-secondary px-5 d-inline-block mx-2"
            onClick={this.handleReset}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            className="btn savebtns btn-primary px-5  d-inline-block mx-2"
            type="submit"
            onClick={this.handleSubmit}
            disabled={loading}
          >
            {loading ? "Uploading" : "Upload"}
          </Button>
        </ModalFooter>
      </SlidingPane>
    );
  }
}

const mapStateToProps = state => ({
  attachments: state.attachments.attachments,
  documents: state.documents
});

const mapDispatchToProps = dispatch => ({
  uploadFiles: files => saveAttachments(files, dispatch),
  uploadDocuments: data => uploadDocuments(dispatch, data)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddAttachment);
