import React, { PureComponent, Fragment } from "react";
import {
  Button,
  FormGroup,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { connect } from "react-redux";
import { Formik, Field } from "formik";
import * as Yup from "yup";

import renderDatePickerField from "../../../shared/components/form/DatePicker";
import CurrencyField from "../../../shared/components/form/AddonCurrencyField";
import FormField from "../../../shared/components/form/FormField";
import CategorySelect from "../../../shared/components/form/CategorySelect";
import ToggleSwitch from "../../../shared/components/form/ToggleSwitch";
import MyDropZone from "../../../shared/components/DropZone";
import { formatTitle } from "../../../helpers";

import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isFetching, isSuccess } from "../../../reducers/reducerUtils";
import SlidingPane from "react-sliding-pane";

import {
  saveTransactions,
  saveTransaction
} from "../../../actions/transaction";
import { saveAttachments } from "../../../actions/attachments/attachmentAction";
import { unmaskCurrency, getTypeForCategory } from "../../../helpers";

let initialValues = {
  type: "INCOME",
  isIncome: true,
  isPaid: true,
  category: "",
  amount: "",
  date: "",
  name: "",
  payee: "",
  files: []
};
class AddTransaction extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: props.modalOpen,
      showMoreInfo: false,
      loading: false,
      error: null,
      saved: false,
      files: []
    };
  }

  toggleMoreInfo = () => {
    this.setState(prevState => ({
      showMoreInfo: !prevState.showMoreInfo
    }));
  };

  addTransaction = (value, { resetForm }) => {
    value.amount = unmaskCurrency(value.amount);
    value.isPending = !value.isPaid;
    value.type = value.isIncome ? "INCOME" : "EXPENSE";

    this.setState({ loading: true, files: [], error: null }, () => {
      let transformedTransaction = {};
      Object.keys(value).forEach(fieldName => {
        transformedTransaction[fieldName] = value[fieldName];
      });
      transformedTransaction.slug = this.props.assetSlug || "";
      transformedTransaction.fileName = "files";
      this.props.saveTransaction(transformedTransaction);
      resetForm();
    });
  };

  closeModal = () => {
    this.setState({ showModal: false, loading: false, error: null });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      transactions: { transactions }
    } = nextProps;

    if (
      prevState.loading &&
      !isFetching(transactions) &&
      transactions.type === "SAVE"
    ) {
      if (isSuccess(transactions)) {
        return { loading: false, showModal: false };
      } else {
        return { loading: false, error: transactions.error };
      }
    }
    return null;
  }

  toggleAddTransaction = () => {
    this.setState(prevState => ({
      showModal: !prevState.showAddTransaction
    }));
  };

  onAttachmentChange = newFiles => {
    this.setState({ files: newFiles });
  };

  render() {
    const validationSchema = Yup.object({
      category: Yup.string().required("Category is required"),
      amount: Yup.string()
        .required("Amount is required")
        .max(16, "Amount is too large"),
      date: Yup.date().required("Date is required"),
      payee: Yup.string().max(150, "Payee name too long"),
      type: Yup.string().required("Type is required"),
      isPaid: Yup.boolean().required("Select transaction status")
    });

    let type = this.props.type;
    let label = "Add";
    let title = "Add Transaction";
    let btnColor = this.props.btnColor || "primary";
    if (type) {
      label = formatTitle(type);
      title = "Add " + label;
      initialValues.type = type;
      initialValues.isIncome = type === "INCOME";
    } else {
      initialValues.type = "INCOME";
      initialValues.isIncome = true;
    }
    return (
      <Fragment>
        <Button
          color={btnColor}
          className="mx-2"
          onClick={this.toggleAddTransaction}
        >
          <span className="btn-wrapper--icon">
            <FontAwesomeIcon icon={faPlus} />
          </span>
          <span className="btn-wrapper--label">{label}</span>
        </Button>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={this.addTransaction}
        >
          {props => {
            const { handleSubmit, setFieldValue } = props;
            const { loading } = this.state;
            // if (values.category && values.date && !values.name) {
            //   setFieldValue(
            //     "name",
            //     getTransactionDescription(values.category, values.date)
            //   );
            // }
            // if (values.category && !values.payee) {
            //   setFieldValue(
            //     "payee",
            //     getTypeForCategory(values.category) === "income"
            //       ? "Tenant"
            //       : "Vendor"
            //   );
            // }

            return (
              <SlidingPane
                isOpen={this.state.showModal}
                from="right"
                width="350px"
                hideHeader={true}
                className="d-flex justify-content-center modal-content"
                onRequestClose={this.closeModal}
              >
                <ModalHeader toggle={this.closeModal}>{title}</ModalHeader>
                <form onSubmit={handleSubmit}>
                  <ModalBody>
                    <FormGroup>
                      <label>Amount:</label>
                      <Field
                        name="amount"
                        component={CurrencyField}
                        type="text"
                        placeholder="Amount"
                      />
                    </FormGroup>
                    <FormGroup>
                      <label>Category:</label>
                      <CategorySelect
                        name="category"
                        value={props.values.category}
                        categoryType={this.props.type}
                        onUpdate={value => {
                          setFieldValue("name", "");
                          setFieldValue("category", value);
                          setFieldValue(
                            "isIncome",
                            getTypeForCategory(value) === "income"
                          );
                        }}
                      />
                    </FormGroup>
                    {this.props.type === undefined && (
                      <FormGroup>
                        <label>Type:</label>
                        <div>
                          <Field
                            name="isIncome"
                            component={ToggleSwitch}
                            onLabel={"Income"}
                            offLabel={"Expense"}
                            className="w-100 switch-large rc-switch-custom toggle-switch-square toggle-switch-success"
                          />
                        </div>
                      </FormGroup>
                    )}
                    <FormGroup>
                      <label>Date:</label>
                      <Field
                        name="date"
                        component={renderDatePickerField}
                        placeholder="Date"
                      />
                    </FormGroup>
                    {/*}
                    <FormGroup>
                      <label>Status:</label>
                      <div>
                        <Field
                          name="isPaid"
                          component={ToggleSwitch}
                          onLabel={"Paid"}
                          offLabel={"Unpaid"}
                          className="w-100 switch-large rc-switch-custom toggle-switch-square toggle-switch-success"
                        />
                      </div>
                    </FormGroup>*/}
                    <FormGroup>
                      <label>Description:</label>
                      <Field
                        name="name"
                        component={FormField}
                        type="text"
                        placeholder="Transaction name"
                      />
                    </FormGroup>
                    <FormGroup>
                      <label>Documents:</label>
                      <Field>
                        {({ field }) => (
                          <MyDropZone
                            setFieldValue={setFieldValue}
                            value={this.state.files}
                            onChange={this.onAttachmentChange}
                            dragZoneSize={"sm"}
                            showFilePreview={false}
                          />
                        )}
                      </Field>
                    </FormGroup>

                    {/* TODO add contacts dropdown
                  or
                  <div className="form-group row">
                    <label
                      htmlFor="staticEmail"
                      className="col-sm-2 col-form-label"
                    >
                      Contact:
                    </label>
                    <div className="col-sm-10 inputblock">
                      <Field
                        name="payee"
                        component={FormField}
                        placeholder="Vendor Name"
                      />
                    </div>
                  </div>*/}
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      type="reset"
                      className="btn btn-secondary px-5 d-inline-block mx-2"
                      onClick={this.closeModal}
                      disabled={loading}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      className="btn savebtns btn-primary px-5  d-inline-block mx-2"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? "Saving" : "Save"}
                    </Button>
                  </ModalFooter>
                </form>
              </SlidingPane>
            );
          }}
        </Formik>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  transactions: state.transactions
});

const mapDispatchToProps = dispatch => ({
  saveTransactions: data => saveTransactions(data, dispatch),
  saveTransaction: data => saveTransaction(data, dispatch),
  saveAttachments: data => saveAttachments(data, dispatch)
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddTransaction);
