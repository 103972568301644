import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import Switch from "rc-switch";

class ToggleSwitch extends PureComponent {
  render() {
    const {
      field,
      form: { /* touched, errors,  */ setFieldValue },
      onLabel,
      offLabel,
      className
    } = this.props;
    return (
      <Switch
        checked={field.value}
        onClick={e => setFieldValue(field.name, !field.value)}
        className={className}
        checkedChildren={onLabel}
        unCheckedChildren={offLabel}
      />
    );
  }
}

ToggleSwitch.propTypes = {
  onLabel: PropTypes.string,
  offLabel: PropTypes.string,
  className: PropTypes.string
};

ToggleSwitch.defaultProps = {
  onLabel: "Yes",
  offLabel: "No",
  className: "switch-medium d-block"
};

export default ToggleSwitch;
