/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from "react";

import { Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import DealOverview from "./DealOverview";

export default class DealSummary extends React.PureComponent {
  render() {
    return (
      <Fragment>
        <Row className="deal-summary">
          <Col xl="8">
            <Card className="card-box mb-5">
              <CardHeader>
                <h4 className="font-size-lg mb-0 py-2 font-weight-bold">
                  Overview
                </h4>
              </CardHeader>
              <CardBody>
                <DealOverview {...this.props} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Fragment>
    );
  }
}
