import React from "react";
import PropTypes from "prop-types";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import CustomSearch from "../CustomSearch";
import cellEditFactory from "react-bootstrap-table2-editor";

const TableWithSearch = ({
  columns,
  data,
  page,
  keyField,
  sizePerPage,
  onPageChange,
  onTableChange,
  totalSize,
  onSelectRow,
  searchPlaceholder,
  noDataIndicator,
  selectedRows,
  enableCellEdit,
  location,
  resetQueryParams,
  children,
  remoteData,
  showSearch = true,
  expandRow
}) => {
  return (
    <PaginationProvider
      pagination={paginationFactory({
        totalSize,
        page,
        sizePerPage,
        onPageChange,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true
      })}
    >
      {({ paginationProps, paginationTableProps }) => (
        <ToolkitProvider
          keyField={keyField}
          data={data}
          columns={columns}
          defaultSorted={[
            {
              id: "date",
              desc: true
            }
          ]}
          bootstrap4
          search
        >
          {props => {
            return (
              <div className="bordered_table">
                {showSearch && (
                  <CustomSearch
                    placeholder={searchPlaceholder}
                    {...props.searchProps}
                    selectedRows={selectedRows}
                    resetQueryParams={resetQueryParams}
                    location={location}
                    normalize={true}
                  >
                    {children}
                  </CustomSearch>
                )}
                <BootstrapTable
                  {...props.baseProps}
                  {...paginationTableProps}
                  remote={
                    remoteData && {
                      search: true,
                      pagination: true,
                      cellEdit: enableCellEdit
                    }
                  }
                  onTableChange={onTableChange}
                  selectRow={onSelectRow}
                  expandRow={expandRow}
                  noDataIndication={() => <div>{noDataIndicator}</div>}
                  cellEdit={
                    enableCellEdit &&
                    cellEditFactory({ mode: "click", blurToSave: true })
                  }
                />
              </div>
            );
          }}
        </ToolkitProvider>
      )}
    </PaginationProvider>
  );
};

TableWithSearch.propTypes = {
  searchPlaceholder: PropTypes.string,
  noDataIndicator: PropTypes.string,
  keyField: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  totalSize: PropTypes.number.isRequired,
  sizePerPage: PropTypes.number.isRequired,
  onTableChange: PropTypes.func,
  onPageChange: PropTypes.func,
  onSelectRow: PropTypes.object,
  selectedRows: PropTypes.array,
  remoteData: PropTypes.bool
};

TableWithSearch.defaultProps = {
  searchPlaceholder: "Search Ledger...",
  noDataIndicator: "No ledger items found!",
  enableCellEdit: false,
  remoteData: true
};

export default TableWithSearch;
