import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import ReportsDetail from "./ReportsDetail";
import ReportsSidebar from "./ReportsSidebar";

import { asset_list, resetAssetList } from "../../actions/asset/assetAction";

const ReportRoutes = () => (
  <Switch>
    <Route exact path="/reports" component={ReportsDetail} />
    <Route exact path="/reports/summary" component={ReportsDetail} />
  </Switch>
);

class Reports extends Component {
  render() {
    return (
      <main className="container-fluid p-5">
        <div className="row">
          <ReportsSidebar {...this.props} />
          <div className="col-sm-12 col-md-8 col-lg-8 col-xl-10">
            <ReportRoutes {...this.props} />
          </div>
        </div>
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {
    asset: state.assetList
  };
}

function mapDispatchToProps(dispatch) {
  return {
    asset_list: data => asset_list(data, dispatch),
    resetAssetList: () => resetAssetList(dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Reports);
