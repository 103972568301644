import React from "react";
import { Container, Row, Col } from "reactstrap";
import SectionHeader from "../../../shared/components/SectionHeader";
import BookmarkCards from "./BookmarkCards";

const Bookmarks = () => {
  return (
    <main className="p-5">
      <SectionHeader />
      <Container className="mt-5">
        <Row>
          <Col md={4}>
            <BookmarkCards />
          </Col>
          <Col md={4}>
            <BookmarkCards />
          </Col>
          <Col md={4}>
            <BookmarkCards />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col md={4}>
            <BookmarkCards />
          </Col>
          <Col md={4}>
            <BookmarkCards />
          </Col>
          <Col md={4}>
            <BookmarkCards />
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default Bookmarks;
