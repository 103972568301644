/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import TransactionListing from "./TransactionListing";
import LoadingIndicator from "../../../shared/components/LoadingIndicator";
import queryString from "query-string";
import { isFetching, isSuccess } from "../../../reducers/reducerUtils";

import {
  getAccountDetail,
  getAccounts
} from "../../../actions/accounts/accountActions";

class AssetTransaction extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      assetSlug: props.match.params.asset,
      showAddTransaction: false,
      showExport: false,
      showImportFromBank: false,
      showSelectBank: false,
      showBankImport: false,
      assetAccountDetail: {},
      showImportPastTransaction: false,
      error: null,
      currentTransactions: [],
      duplicateTransactions: [],
      selectedRowData: [],
      pendingTransactions: [],
      loadingAccountDetails: true,
      loadingTransactions: true,
      refreshAccounts: false,
      transactionsCount: 0,
      queryParams: "offset=0&limit=10&sortBy=date&orderBy=desc",
      page: 1,
      sizePerPage: 10
    };
  }

  toggleSelectBank = () => {
    this.setState(prevState => ({ showSelectBank: !prevState.showSelectBank }));
  };
  toggleImportFromBank = () => {
    this.setState(prevState => ({
      showImportFromBank: !prevState.showImportFromBank
    }));
  };
  toggleBankImport = () => {
    this.setState(prevState => ({ showBankImport: !prevState.showBankImport }));
  };

  refreshAccountDetails = () => {
    if (!this.state.loadingAccountDetails) {
      const { assetSlug } = this.state;
      this.setState({ refreshAccounts: true }, () => {
        this.props.getAccountDetail(assetSlug);
      });
    }
  };
  selectedRow = data => {
    this.setState({ selectedRowData: data });
  };

  componentDidMount() {
    const { getAccounts, getAccountDetail } = this.props;
    this.setState(
      {
        loadingAsset: true,
        loadingAccountDetails: true
      },
      () => {
        getAccounts();
        getAccountDetail(this.state.assetSlug);
      }
    );
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      // transactions: { transactions },
      assetAccountDetail
    } = nextProps;

    if (prevState.loadingAccountDetails && !isFetching(assetAccountDetail)) {
      if (isSuccess(assetAccountDetail)) {
        return {
          loadingAccountDetails: false,
          accountDetail: assetAccountDetail.data.accountDetail
        };
      } else {
        return {
          loadingAccountDetails: false,
          accountDetail: [],
          error: assetAccountDetail.error
        };
      }
    } else if (prevState.refreshAccounts && isFetching(assetAccountDetail)) {
      return {
        loadingAccountDetails: true,
        refreshAccounts: false
      };
    }

    return null;
  }

  render() {
    const {
      accountDetail,
      loadingAccountDetails,
      loadingAsset,
      assetSlug,
      queryParams
    } = this.state;

    const { asset, location } = this.props;
    const searchParams = queryString.parse(this.props.location.search);

    if (loadingAccountDetails)
      return (
        <LoadingIndicator loading={loadingAccountDetails || loadingAsset} />
      );

    return (
      <div>
        <TransactionListing
          assetSlug={assetSlug}
          accountDetail={accountDetail}
          asset={asset}
          searchParams={searchParams}
          queryParams={queryParams}
          location={location}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  accountLink: state.accountLink,
  plaid: state.plaid,
  assetAccountDetail: state.assetAccountDetail
});

const mapDispatchToProps = dispatch => ({
  getAccounts: () => getAccounts(dispatch),
  getAccountDetail: data => getAccountDetail(data, dispatch)
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssetTransaction);
