import React, { useState, useEffect } from "react";
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";
import superagent from "superagent";

import {
  Container,
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Pagination as ReactPagination,
  PaginationItem,
  PaginationLink
} from "reactstrap";
import {
  InstantSearch,
  connectSearchBox,
  connectHits,
  Pagination,
  Configure
} from "react-instantsearch-dom";
import { times } from "lodash";
import FeatherIcon from "feather-icons-react";

import { numberFormatter } from "../../helpers";

import OpportunityCard from "./OpportunityCard";
import SectionHeader from "../../shared/components/SectionHeader";
import LoadingIndicator from "../../shared/components/LoadingIndicator";

const searchClient = instantMeiliSearch(
  "https://search.bettercapital.us",
  "ja#y0wo64FKRQP6TrDUpjo^xLGbseFbA@Ykt26I0Yr2@fMh5D1Iz"
);
const BASE_URL = process.env.REACT_APP_CMS_API_ROOT;

const Hits = ({ hits }) => {
  return (
    <Row>
      {hits.map(hit => (
        <Col md={4} key={hit.id} className="mb-4 opportunity-card-list">
          <OpportunityCard {...hit} />
        </Col>
      ))}
    </Row>
  );
};

const SearchBox = ({ currentRefinement, isSearchStalled, refine }) => {
  return (
    <InputGroup>
      <Input
        type="text"
        className="form-control border-end-0 border"
        placeholder="enter Landmark, Location or Project"
        value={currentRefinement}
        onChange={e => refine(e.target.value)}
      />
      <InputGroupText className="bg-white border-start-0 border">
        <FeatherIcon icon="search" className="fea icon-sm heart-icon" />
      </InputGroupText>
    </InputGroup>
  );
};

const CustomSearchBox = connectSearchBox(SearchBox);
const CustomHits = connectHits(Hits);

const memo = callback => {
  const cache = new Map();

  return (...args) => {
    const key = JSON.stringify(args);

    if (cache.has(key)) return cache.get(key);

    const value = callback(...args);

    cache.set(key, value);
    return value;
  };
};

const memoizedAxiosGet = memo(superagent.get);

const Opportunities = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [filteredOpportunities, setFilteredOpportunities] = useState([]);
  const [filters, setFilters] = useState({});
  const [priceDropdownOpen, setPriceDropdownOpen] = useState(false);
  const [
    neighbourhoodRatingdropdownOpen,
    setNeighbourhoodRatingDropdownOpen
  ] = useState(false);
  const [capRateDropdownOpen, setCapRateDropdownOpen] = useState(false);
  const [priceValue, setPriceValue] = useState(25000);
  const [capRate, setCapRate] = useState(0);
  const [neighbourhoodRating, setNeighbourhoodRating] = useState(0);
  const [locationState, setLocationState] = useState("Location");
  const [stateList, setStateList] = useState([]);

  useEffect(() => {
    const getStateList = async () => {
      const { body } = await superagent.get(`${BASE_URL}/opportunities/locations`);

      setStateList(body);
    };

    getStateList();
  }, []);

  useEffect(
    () => {
      const getFilteredOpportunities = async () => {
        if (Object.keys(filters).length !== 0) {
          let filterQueryArray = [];
          for (let [key, value] of Object.entries(filters)) {
            filterQueryArray.push(`${key}=${value}`);
          }
          const queryUrl =
            `${BASE_URL}/opportunities?property_filter=` + filterQueryArray.join();
          setIsLoading(true);

          const { body } = await memoizedAxiosGet(queryUrl);
          setNumberOfPages(Math.round(body.length / 12));

          let filteredOpportunities = body.slice(
            currentPage * 12,
            (currentPage + 1) * 12
          );
          setFilteredOpportunities(filteredOpportunities);
          setIsLoading(false);
        }
      };

      getFilteredOpportunities();
    },
    [filters, currentPage]
  );

  return (
    <main className="p-5">
      <SectionHeader titleHeading={"Opportunities"} />
      <InstantSearch searchClient={searchClient} indexName="opportunity">
        <Container className="mt-4">
          <div className="search-container">
            <Row>
              <Col sm={12} md={5}>
                <div className="d-flex justify-content-between align-items-center search-container-main">
                  <CustomSearchBox />
                </div>
              </Col>
              <Col sm={12} md={7} className="px-0">
                <div className="d-flex justify-content-between align-items-center search-container-sort">
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <Input
                      type="select"
                      className="form-select filter-action-sort sort-left"
                      value={locationState}
                      onChange={e => {
                        setLocationState(e.target.value);
                        setFilters({ ...filters, state: e.target.value });
                      }}
                    >
                      <option disabled>Location</option>
                      {stateList.map((state, index) => (
                        <option key={index}>{state.state}</option>
                      ))}
                    </Input>
                    <Dropdown
                      isOpen={priceDropdownOpen}
                      toggle={() => setPriceDropdownOpen(!priceDropdownOpen)}
                      className="filter-action-sort"
                    >
                      <DropdownToggle
                        tag="a"
                        className="form-select form-control"
                      >
                        Price
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu">
                        <DropdownItem header>
                          Price {numberFormatter(priceValue)}
                        </DropdownItem>
                        <DropdownItem toggle={false}>
                          <Input
                            type="range"
                            value={priceValue}
                            min={25000}
                            max={2000000}
                            step={1}
                            className="form-range position-relative"
                            onChange={e => setPriceValue(e.target.value)}
                          />
                        </DropdownItem>
                        <DropdownItem header>
                          <div
                            className="btn btn-outline-primary"
                            onClick={() =>
                              setFilters({ ...filters, sale_price: priceValue })
                            }
                          >
                            Apply
                          </div>
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                  <div className="d-flex justify-content-between align-items-center w-100 sort-bottom">
                    <Dropdown
                      isOpen={capRateDropdownOpen}
                      toggle={() =>
                        setCapRateDropdownOpen(!capRateDropdownOpen)
                      }
                      className="filter-action-sort sort-left"
                    >
                      <DropdownToggle
                        tag="a"
                        className="form-select form-control"
                      >
                        Cap Rate
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu">
                        <DropdownItem header>Cap Rate {capRate} %</DropdownItem>
                        <DropdownItem toggle={false}>
                          <Input
                            type="range"
                            value={capRate}
                            min={0}
                            max={5}
                            step={0.1}
                            className="form-range position-relative"
                            onChange={e => setCapRate(e.target.value)}
                          />
                        </DropdownItem>
                        <DropdownItem header>
                          <div
                            className="btn btn-outline-primary"
                            onClick={() =>
                              setFilters({ ...filters, cap_rate: capRate })
                            }
                          >
                            Apply
                          </div>
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                    <Dropdown
                      isOpen={neighbourhoodRatingdropdownOpen}
                      toggle={() =>
                        setNeighbourhoodRatingDropdownOpen(
                          !neighbourhoodRatingdropdownOpen
                        )
                      }
                      className="filter-action-sort"
                    >
                      <DropdownToggle
                        tag="a"
                        className="form-select form-control"
                      >
                        Neighborhood Rating
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu">
                        <DropdownItem header>
                          Neighborhood Rating {neighbourhoodRating} %
                        </DropdownItem>
                        <DropdownItem toggle={false}>
                          <Input
                            type="range"
                            value={neighbourhoodRating}
                            min={0}
                            max={5}
                            step={0.1}
                            className="form-range position-relative"
                            onChange={e =>
                              setNeighbourhoodRating(e.target.value)
                            }
                          />
                        </DropdownItem>
                        <DropdownItem header>
                          <div
                            className="btn btn-outline-primary"
                            onClick={() =>
                              setFilters({
                                ...filters,
                                neighborhood_rating: neighbourhoodRating
                              })
                            }
                          >
                            Apply
                          </div>
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <Container className="mt-5">
          {Object.keys(filters).length > 0 ? (
            <>
              {isLoading ? (
                <LoadingIndicator loading={isLoading}/>
              ) : filteredOpportunities.length > 0 ? (
                <>
                  <Row>
                    {filteredOpportunities.map(opportinity => (
                      <Col md={4} key={opportinity.id} className="mb-4 opportunity-card-list">
                        <OpportunityCard {...opportinity} />
                      </Col>
                    ))}
                  </Row>

                  <ReactPagination
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem
                      disabled={currentPage === 0}
                    >
                      <PaginationLink
                        onClick={() => setCurrentPage(currentPage - 1)}
                        aria-label="Previous"
                      >
                        Prev
                      </PaginationLink>
                    </PaginationItem>
                    {times(numberOfPages, page => {
                      return (
                        <PaginationItem
                          key={page}
                          active={currentPage === page}
                        >
                          <PaginationLink
                            onClick={() => setCurrentPage(page)}
                            active={currentPage === page ? "true" : "false"}
                          >
                            {page + 1}
                          </PaginationLink>
                        </PaginationItem>
                      );
                    })}
                    <PaginationItem
                      disabled={currentPage >= numberOfPages - 1}
                    >
                      <PaginationLink
                        onClick={() => setCurrentPage(currentPage + 1)}
                        aria-label="Next"
                      >
                        Next
                      </PaginationLink>
                    </PaginationItem>
                  </ReactPagination>
                </>
              ) : (
                <div>No Records Found</div>
              )}
            </>
          ) : (
            <>
              <Configure
                hitsPerPage={12}
                analytics={false}
                enablePersonalization={true}
              />
              <CustomHits />
              <Pagination
                padding={2}
                showNext={true}
                showPrevious={true}
                translations={{
                  previous: (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                    >
                      <g
                        fill="none"
                        fillRule="evenodd"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.143"
                      >
                        <path d="M9 5H1M5 9L1 5l4-4" />
                      </g>
                    </svg>
                  ),
                  next: (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                    >
                      <g
                        fill="none"
                        fillRule="evenodd"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.143"
                      >
                        <path d="M1 5h8M5 9l4-4-4-4" />
                      </g>
                    </svg>
                  )
                }}
              />
            </>
          )}
        </Container>
      </InstantSearch>
    </main>
  );
};

export default Opportunities;
