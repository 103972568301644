import React, { Component } from "react";

import { Card, Badge, Nav, NavItem } from "reactstrap";

import { NavLink as NavLinkStrap } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isFetching, isSuccess } from "../../../reducers/reducerUtils";
import { addAttachmentTags } from "../../../actions/attachments/attachmentAction";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { getChecklistCategories } from "../../../helpers/constants";

import { CheckSquare } from "react-feather";

class DocumentChecklist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      attachments: [],
      error: null,
      tags: []
    };
  }

  handleChange = tags => {
    this.setState({ tags: tags });
  };

  getChecklistStyle = checklistCount => {
    return checklistCount > 0 ? "success" : "dark";
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { attachments } = nextProps;
    if (attachments.type === "GET") {
      if (isFetching(attachments)) {
        return { loading: true };
      } else {
        if (isSuccess(attachments)) {
          return {
            attachments: attachments.data.data,
            loading: false,
            tagsSaved: true
          };
        } else {
          return {
            loading: false,
            error: attachments.error
          };
        }
      }
    }

    return null;
  }

  getCountByCategory = assetType => {
    const { attachments } = this.state;
    let categoryCounts = {};
    const requiredCategories = getChecklistCategories(assetType);
    // eslint-disable-next-line
    requiredCategories.map(category => {
      categoryCounts[category.value] = categoryCounts[category.value] || 0;
    });

    attachments.reduce((r, doc) => {
      doc.tags.map(tag => (r[tag] = (r[tag] || 0) + 1));
      return r;
    }, categoryCounts);

    return categoryCounts;
  };

  render() {
    const { asset } = this.props;

    const categoryCounts = this.getCountByCategory(asset.assetType);
    return (
      <Card className="rounded-lg shadow-xxl">
        <div className="d-flex px-4 py-3 align-items-center">
          <div className="d-40 rounded-lg border-0 card-icon-wrapper bg-dark btn-icon text-center shadow-dark mr-3">
            <div className="d-30 text-white">
              <CheckSquare />
            </div>
          </div>
          <div className="display-5 font-weight-bold">Checklist</div>
        </div>
        <PerfectScrollbar>
          <Nav
            pills
            className="nav-neutral-first nav-lg nav-alt flex-column pr-3"
          >
            {categoryCounts &&
              Object.keys(categoryCounts).map((category, category_idx) => {
                let categoryCount = categoryCounts[category];
                return (
                  <NavItem key={category_idx}>
                    <NavLinkStrap
                      href="#/"
                      onClick={e => e.preventDefault()}
                      className="br-tl br-bl pl-4 pr-3 rounded-pill"
                    >
                      <span>{category}</span>
                      <div className="ml-auto">
                        <Badge
                          color={this.getChecklistStyle(categoryCount)}
                          className="mr-3"
                        >
                          {categoryCount}
                        </Badge>
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          className="font-size-xs opacity-3"
                        />
                      </div>
                    </NavLinkStrap>
                  </NavItem>
                );
              })}
          </Nav>
        </PerfectScrollbar>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  attachments: state.attachments.attachments
});

const mapDispatchToProps = dispatch => ({
  addAttachmentTags: data => addAttachmentTags(data, dispatch)
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentChecklist);
