import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DescriptionItem = props => {
  // TODO: Add React Tooltip
  return (
    <div className="py-2">
      <div className="text-uppercase ">{props.label}</div>
      <div className="d-flex align-items-center pt-3 font-weight-bold display-4">
        <FontAwesomeIcon icon={props.icon} className={props.iconClass} />
        {props.value}
      </div>
    </div>
  );
};

export default DescriptionItem;
