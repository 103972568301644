import React, { useState } from "react";
import { Badge } from "reactstrap";

import PlaidLinkButton from "./PlaidLinkButton";

const PlaidUpdateLinkButton = props => {
  const [showLink, setShowLink] = useState(false);

  if (showLink)
    return (
      <PlaidLinkButton
        linkToken={props.linkToken}
        key={props.key}
        updateLink={true}
        onSuccess={props.onUpdate}
      />
    );

  return (
    <Badge
      color="warning"
      className="mx-2"
      onClick={() => setShowLink(true)}
      key={props.key}
      style={{ cursor: "pointer" }}
    >
      Token Expired
    </Badge>
  );
};

export default PlaidUpdateLinkButton;
