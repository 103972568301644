import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  CardBody,
  CardImg
} from "reactstrap";

import SectionHeader from "../../../shared/components/SectionHeader";
import LoadingIndicator from "../../../shared/components/LoadingIndicator";
import ProjectionGraph from "../ProjectionGraph";
import NeighborhoodReview from "../NeighborhoodReview";

import {
  getOpportunityDetails,
  getOpportunityConversation
} from "../../../actions/opportunity";
import { isFetching, isSuccess, isError } from "../../../reducers/reducerUtils";

import {
  createPropertyAssumptions,
  getCashFlowDetails
} from "../../../helpers/assumptionUtils";
import { numberFormatter } from "../../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentAlt } from "@fortawesome/free-solid-svg-icons";

import CarImage from "../../../assets/images/car-icon.png";
import BathImage from "../../../assets/images/bath-icon.png";
import CalendarImage from "../../../assets/images/calender-icon.png";
import SqftImage from "../../../assets/images/sqft-icon.png";
import PriceImage from "../../../assets/images/price-icon.png";
import RentImage from "../../../assets/images/rent-icon.png";
import StarImage from "../../../assets/images/star-icon.png";

const defaultMetadata = {
  amortized_years: {
    type: "amortized_years",
    value: 30,
    display: "Number of Years"
  },
  down_payment: {
    type: "down_payment",
    value: 25,
    value_type: "percent",
    display: "Down Payment"
  },
  hoa_dues: {
    type: "hoa_dues",
    value: 0,
    value_type: "dollars",
    frequency: "monthly",
    display: "Homeowners Association"
  },
  insurance: {
    type: "insurance",
    value: 0,
    value_type: "dollars",
    frequency: "monthly",
    display: "Insurance Cost"
  },
  mortgage_interest: {
    type: "mortgage_interest",
    value: 3.8,
    value_type: "percent",
    display: "Mortgage Interest"
  },
  property_management: {
    type: "property_management",
    value: 0,
    value_type: "dollars",
    frequency: "monthly",
    display: "Property Management"
  },
  property_tax: {
    type: "property_tax",
    value: 0,
    value_type: "dollars",
    frequency: "monthly",
    display: "Property Tax"
  },
  purchase_price: {
    type: "purchase_price",
    value: 0,
    value_type: "dollars",
    display: "Purchase Price"
  },
  rent: {
    type: "rent",
    value: 0,
    frequency: "monthly",
    value_type: "dollars",
    display: "Rent"
  },
  vacancy_rate: {
    type: "vacancy_rate",
    value: 10,
    value_type: "percent",
    display: "Vacancy"
  }
};

const OpportunityDetails = ({
  opportunityData,
  getOpportunityDetails,
  conversationData,
  getOpportunityConversation,
  history
}) => {
  const [opportunity, setOpportunity] = useState({});
  const [mainImage, setMainImage] = useState("");
  const [metadata, setMetadata] = useState(defaultMetadata);
  const [downPaymentPercent, setDownPaymentPercent] = useState(0);
  const [cashflow, setCashflow] = useState(0);
  const [cashROI, setCashROI] = useState(0);
  const [totalROI, setTotalROI] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingConversation, setLoadingConversation] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const slug = window.location.pathname.split("/")[2];
    getOpportunityDetails(slug);
  }, []);

  useEffect(
    () => {
      if (isFetching(opportunityData)) {
        setIsLoading(true);
      }

      if (!isFetching(opportunityData) && isSuccess(opportunityData)) {
        const { data } = opportunityData;
        setOpportunityData(data);
      }

      if (isError(opportunityData)) {
        setIsLoading(false);
        setError(true);
      }

      if (isFetching(conversationData)) {
        setLoadingConversation(true);
      }

      if (loadingConversation) {
        if (!isFetching(conversationData) && isSuccess(conversationData)) {
          const { data } = conversationData;
          setLoadingConversation(false);
          history.push(`/chats/${data._id}`);
        } else if (isError(conversationData)) {
          setLoadingConversation(false);
        }
      }
    },
    [opportunityData, conversationData]
  );

  const startConversation = opportunity => {
    // setLoadingConversation(true);
    getOpportunityConversation({
      slug: opportunity.slug,
      name: opportunity.title,
      users: [opportunity.posted_by.userId]
    });
  };

  const setOpportunityData = async data => {
    if (data.length > 0) {
      const assumptionCreationData = {
        price: numberFormatter(data[0].property.market_value),
        rent: data[0].property.rent,
        insurance: numberFormatter(data[0].property.insurance),
        taxes: numberFormatter(data[0].property.property_tax),
        hoa_dues: numberFormatter(data[0].property.hoa_fees)
      };
      const propertyAssumptions = await createPropertyAssumptions(
        assumptionCreationData
      );
      let { totalCashflow, totalROI, cashROI } = await getCashFlowDetails(
        propertyAssumptions
      );
      const downPaymentPercent = propertyAssumptions.down_payment.value;
      setMetadata(propertyAssumptions);
      setDownPaymentPercent(downPaymentPercent);
      setCashflow(totalCashflow);
      setCashROI(cashROI);
      setTotalROI(totalROI);
      setOpportunity(data[0]);
      setMainImage(`https://cms.bettercapital.us${data[0].medias[0].url}`);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setError(true);
    }
  };

  useEffect(
    () => {
      const updateAssumptions = async () => {
        let { totalCashflow, totalROI, cashROI } = await getCashFlowDetails(
          metadata
        );
        setCashflow(totalCashflow);
        setCashROI(cashROI);
        setTotalROI(totalROI);
      };

      updateAssumptions();
    },
    [metadata]
  );

  const setNewDownPayment = value => {
    setDownPaymentPercent(value);
    setAssumptionsField("down_payment", "value", value);
  };

  const setAssumptionsField = (assumption, key, value, ...restOfKeyValues) => {
    const metaObj = { ...metadata[assumption] };
    metaObj[key] = value;
    let counter = 0;
    while (counter < restOfKeyValues.length) {
      metaObj[restOfKeyValues[counter]] = restOfKeyValues[counter + 1];
      counter += 2;
    }
    setMetadata({
      ...metadata,
      [assumption]: metaObj
    });
  };
  if (isLoading) return <LoadingIndicator loading={isLoading} />;

  if (error) return <p>Something went wrong!!</p>;

  return (
    <main className="p-5">
      <SectionHeader />
      {opportunity && opportunity.property ? (
        <>
          <Container className="opportunity-container mt-100 mt-60">
            <div className="main-title">
              <h2>{opportunity.title} </h2>
              <div className="d-flex justify-content-start align-items-center sub-main-title">
                {opportunity.property.status === "sold" ? (
                  <div className="label-sold">Sold Out</div>
                ) : (
                  <div className="label-available">Available</div>
                )}
              </div>
            </div>
            <Row>
              <Col md={8}>
                {opportunity.medias ? (
                  <div className="main-image-div ">
                    <div className="main-image-container">
                      <img
                        src={mainImage}
                        className="main-image "
                        alt="main_image"
                      />
                    </div>
                    <div className="small-image  image">
                      {opportunity.medias.map((media, index) =>
                        index < 4 ? (
                          <img
                            src={`https://cms.bettercapital.us${media.url}`}
                            className={
                              index > 0
                                ? "sub-images sub-image-margin"
                                : "sub-images"
                            }
                            alt="sub_image"
                            key={index}
                            onClick={() =>
                              setMainImage(
                                `https://cms.bettercapital.us${media.url}`
                              )
                            }
                          />
                        ) : null
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="main-image-div " />
                )}
                <div className="d-flex justify-content-start  icons">
                  <div className="d-flex justify-content-center align-items-center details">
                    <img src={CarImage} alt="" />
                    <span className="details-number">
                      {opportunity.property.beds}
                    </span>
                  </div>
                  <div className="d-flex justify-content-center align-items-center details">
                    <img src={BathImage} alt="" />
                    <span className="details-number">
                      {" "}
                      {opportunity.property.baths}{" "}
                    </span>
                  </div>
                  <div className="d-flex justify-content-center align-items-center details">
                    <img src={SqftImage} alt="" />
                    <span className="details-number">
                      {opportunity.property.lot_size}
                    </span>
                  </div>
                  <div className="d-flex justify-content-center align-items-center details">
                    <img src={CalendarImage} alt="" />
                    <span className="details-number">
                      {opportunity.property.year_build}
                    </span>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                {opportunity.posted_by ? (
                  <Card className="blog owner rounded border-0 card p-3 mt-3">
                    <CardImg
                      src={`https://cms.bettercapital.us${
                        opportunity.posted_by.logo.url
                      }`}
                      top
                      className="rounded-top content-card-image-01 d-flex justify-content-center"
                      alt=""
                    />
                    <CardBody>
                      <div className="d-flex justify-content-center align-items-center ">
                        <h6>{opportunity.posted_by.name}</h6>
                      </div>
                      {/* <div className="d-flex justify-content-center align-items-center">
												<p>Posted 4 deals</p>
											</div> */}
                      <div className="d-flex justify-content-center align-items-center p-3">
                        <p className="size">
                          {opportunity.posted_by.seo
                            ? opportunity.posted_by.seo.metaDescription
                            : ""}
                        </p>
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        <Button
                          color="first"
                          className="mr-3 shadow-none hover-scale-lg"
                          onClick={() => startConversation(opportunity)}
                        >
                          <span className="btn-wrapper--icon">
                            <FontAwesomeIcon icon={faCommentAlt} />
                          </span>
                          <span className="btn-wrapper--label">Message</span>
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                ) : (
                  <Card className="blog owner rounded border-0 card p-3 mt-3" />
                )}
              </Col>
            </Row>
          </Container>
          <Container className="mt-5">
            <div className="text-01">
              <h4>Property Highlights</h4>
            </div>
            <div className="d-flex justify-content-between align-items-center highlights-container tags mb-5 mt-3 mx-auto">
              <div className="tags-01">
                <div className="d-flex justify-content-center align-items-start flex-column highlight-tag">
                  <div className="highlights-details">
                    <img src={PriceImage} className="hightlight-icons" alt="" />
                    Price
                  </div>
                  <h5 className="mb-0 bold-text">
                    {numberFormatter(opportunity.property.sale_price)}
                  </h5>
                </div>
                <div className="d-flex justify-content-center align-items-start flex-column highlight-tag">
                  <div className="highlights-details">
                    <img src={RentImage} className="hightlight-icons" alt="" />
                    Occupancy{" "}
                  </div>
                  <h6 className="mb-0">{opportunity.property.status}</h6>
                </div>
              </div>
              <div className="tags-01">
                <div className="d-flex justify-content-center align-items-start flex-column highlight-tag">
                  <div className="highlights-details">
                    <img src={RentImage} className="hightlight-icons" alt="" />
                    Expected Use{" "}
                  </div>
                  <h6 className="mb-0">Long Term</h6>
                </div>
                <div className="d-flex justify-content-center align-items-start flex-column highlight-tag">
                  <div className="highlights-details">
                    <img src={RentImage} className="hightlight-icons" alt="" />
                    Rent
                  </div>
                  <h6 className="mb-0">
                    {numberFormatter(opportunity.property.rent)}/m
                  </h6>
                </div>
              </div>
              <div className="tags-02">
                <div className="d-flex justify-content-center align-items-start flex-column highlight-tag">
                  <div className="highlights-details">
                    <img src={RentImage} className="hightlight-icons" alt="" />
                    Gross Yeild
                  </div>
                  <h6 className="mb-0">10%</h6>
                </div>
              </div>
            </div>
          </Container>
          <Container className="mt-5 mb-5">
            <div className="text-01">
              <h4>Overview</h4>
            </div>
            <div className="paragraph">
              <p>{opportunity.description}.</p>
            </div>
          </Container>
          <Container className="mt-5 mb-5 cashflow-main">
            <div className="text-01">
              <h4>Cashflow</h4>
            </div>
            <Row>
              <Col md={6}>
                <div className="d-flex justify-content-between align-items-center highlights-container cashflow mb-5 mt-3 py-5 px-2">
                  <div className="d-flex justify-content-center align-items-start flex-column bold-text cashflow-1 ">
                    {numberFormatter(cashflow)}
                    <p className="highlights-details">CASHFLOW</p>
                  </div>
                  <div className="d-flex justify-content-center align-items-start  flex-column bold-text cashflow-1">
                    {cashROI} %
                    <p className="highlights-details">CASH ON CASH ROI</p>
                  </div>
                  <div className="d-flex justify-content-center align-items-start flex-column bold-text cashflow-1">
                    {totalROI.toFixed(2)} %
                    <p className="highlights-details">TOTAL ROI</p>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="d-flex justify-content-between align-items-center highlights-container mb-5 mt-3 p-5">
                  <div className="progress-box ">
                    <h6 className="progress-title  text-dark title-dark cashflow-2 ">
                      Down Payment {downPaymentPercent}%
                    </h6>
                    <div className="progress-input">
                      <input
                        type="range"
                        value={downPaymentPercent}
                        min={0}
                        max={100}
                        step={1}
                        className="form-control position-relative"
                        onChange={e => setNewDownPayment(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <Container className="mt-5 mb-5 ">
            <div className="text-01">
              <h4>Price Insights</h4>
            </div>
            <div className="price-insights">
              <div className="d-flex justify-content-start  align-items-center price-highlights-container price-tags mt-3">
                <div className="price-tags-01">
                  <div className="d-flex justify-content-center align-items-start flex-column estimated-value">
                    <div className="highlights-details">
                      Estimated Market Value
                    </div>
                    <h6 className="mb-0">
                      {numberFormatter(opportunity.property.market_value)}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="price-insights-01">
                <div className="d-flex justify-content-between align-items-center price-highlights-container price-tags">
                  <div className="price-tags-01">
                    <div className="d-flex justify-content-center align-items-start flex-column price">
                      <div className="highlights-details">
                        Estimated Market Value
                      </div>
                      <h6 className="mb-0">
                        {numberFormatter(opportunity.property.property_tax)}/
                        month
                      </h6>
                    </div>
                    <div className="d-flex justify-content-center align-items-start flex-column price">
                      <div className="highlights-details long-text">
                        Insurance Cost
                      </div>
                      <h6 className="mb-0">
                        {numberFormatter(opportunity.property.insurance)}/ month
                      </h6>
                    </div>
                  </div>
                  <div className="price-tags-01">
                    <div className="d-flex justify-content-start align-items-start flex-column price">
                      <div className="highlights-details">
                        Mortgage interest
                      </div>
                      <h6 className="mb-0">3.8%</h6>
                    </div>
                    <div className="d-flex justify-content-center align-items-start flex-column price">
                      <div className="highlights-details">No. of years</div>
                      <h6 className="mb-0">30 years</h6>
                    </div>
                  </div>
                  <div className="price-tags-01">
                    <div className="d-flex justify-content-center align-items-start flex-column price">
                      <div className="highlights-details long-text">
                        Property Management
                      </div>
                      <h6 className="mb-0">
                        {numberFormatter(opportunity.property.management_fee)} /
                        month
                      </h6>
                    </div>
                    <div className="d-flex justify-content-center align-items-start flex-column price">
                      <div className="highlights-details">
                        Home Owner's Association
                      </div>
                      <h6 className="mb-0">
                        {numberFormatter(opportunity.property.hoa_fees)} / month
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="price-insights-02">
                <div className="d-flex justify-content-start align-items-center price-highlights-container  mb-5">
                  <div className="d-flex justify-content-center align-items-start flex-column price">
                    <div className="highlights-details">Rent: </div>
                    <h6 className="mb-0">
                      {numberFormatter(opportunity.property.rent)}/month
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </Container>
          <Container className="mt-5 mb-5">
            <Row>
              <Col sm={12} md={6}>
                <ProjectionGraph assumptions={metadata} />
              </Col>
              <Col sm={12} md={6}>
                <ProjectionGraph assumptions={metadata} overall={false} />
              </Col>
            </Row>
          </Container>
          <Container className="mt-5 mb-5">
            <div className="text-01">
              <h4>Neighbourhood Review</h4>
              <div className="rating-star">
                <img src={StarImage} alt="starImage" />
                <div className="rating-text">
                  {opportunity.property.neighborhood_rating}
                </div>
              </div>
            </div>

            <NeighborhoodReview zip={opportunity.property.zip} />
          </Container>
        </>
      ) : (
        <></>
      )}
    </main>
  );
};

const mapStateToProps = state => ({
  opportunityData: state.opportunity.opportunity,
  conversationData: state.opportunity.conversation
});

const mapDispatchToProps = dispatch => ({
  getOpportunityDetails: slug => getOpportunityDetails(dispatch, slug),
  getOpportunityConversation: data => getOpportunityConversation(dispatch, data)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OpportunityDetails));
