import React from "react"

import { Card, CardBody, CardImg } from "reactstrap"
import CarImage from "../../../assets/images/car-icon.png"
import BathImage from "../../../assets/images/bath-icon.png"
import CalendarImage from "../../../assets/images/calender-icon.png"
import StarImage from "../../../assets/images/star-icon.png"
import SqftImage from "../../../assets/images/sqft-icon.png"
import CardImage from "../../../assets/images/card-image.png"
import DeleteImage from "../../../assets/images/delete-icon.png"

const BookmarkCards = () => {
  return (
    <>
    <Card className="bookmark-card rounded border-0 ">
        <div className="position-relative">
            <CardImg
                top
                src={CardImage}
                className="rounded-top content-card-image"
                alt=""
            />
            <div className="overlay rounded-top bg-dark"></div>
            <div className="date-posted">
                <img src={StarImage} alt="starimage" />
                <div className="star-text">
                    3.5
                </div>
            </div>
            <div className="button-available">Available</div>
        </div>
        <CardBody className="card-content">
            <div>
                <h6>
                170 Calhoun Rd, Stony Point, NC, 28678  
                </h6>
            </div>
            {/* <div className="d-flex justify-content-start align-items-center">
                <div className="sale-indicator"></div>
                <p className="sale-text mb-0">For sale</p>
                </div> */}
            <hr className="card-margin-ruler" />
            <div className="d-flex justify-content-between align-items-center price-container">
                <div className="d-flex justify-content-center align-items-start flex-column">
                <div className="price-details">Price</div>
                <h5 className="mb-0 font-weight-bold">$169,000</h5>
                </div>
                <div className="d-flex justify-content-center align-items-start flex-column">
                <div className="price-details">Rent</div>
                <h6 className="mb-0">$1,500/m</h6>
                </div>
                <div className="d-flex justify-content-center align-items-start flex-column">
                <div className="price-details">Gross Yeild</div>
                <h6 className="mb-0">10%</h6>
                </div>
            </div>
            <hr className="card-margin-ruler" />
            <div className="d-flex justify-content-center align-items-center">
                <div className="d-flex justify-content-center align-items-center sale-details">
                <img src={CarImage} alt="bed"/>
                <span className="sale-details-number">6</span>
                </div>
                <div className="d-flex justify-content-center align-items-center sale-details">
                <img src={BathImage} alt="bath"/>
                <span className="sale-details-number">6</span>
                </div>
                <div className="d-flex justify-content-center align-items-center sale-details">
                <img src={SqftImage} alt="sqft"/>
                <span className="sale-details-number">2,670</span> 
                </div>
                <div className="d-flex justify-content-center align-items-center sale-details">
                <img src={CalendarImage} alt="calendar"/>
                <span className="sale-details-number">2018</span>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center">
                <button className="delete-button"><img src={DeleteImage} alt="delete_image"/>&nbsp;&nbsp;Remove</button>
            </div>
        </CardBody>
    </Card>
    </>
  )
}

export default BookmarkCards