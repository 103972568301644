import React, { PureComponent } from "react";

import { Button } from "reactstrap";

import Dropzone from "react-dropzone";
import { Check, X, User, Edit2 } from "react-feather";
import PropTypes from "prop-types";

class ImageField extends PureComponent {
  constructor(props) {
    super(props);
    const { field } = props;

    this.state = {
      files: [{ name: field && field.name, preview: field && field.value }],
      fileChanged: false
    };
  }

  onDrop = files => {
    if (files.length > 0) {
      this.setState(
        {
          files: files.map(file =>
            Object.assign(file, {
              preview: URL.createObjectURL(file)
            })
          )
        },
        () => {
          this.props.handleUploadFile(files);
        }
      );
    }
  };

  render() {
    return (
      <div className="py-4 d-flex align-items-center justify-content-center">
        <div className="dropzone shadow-sm">
          <Dropzone
            onDrop={filesToUpload => {
              this.onDrop(filesToUpload);
            }}
            noClick={true}
            noKeyboard={true}
            multiple={false}
            accept={"image/*"}
            minSize={0}
            maxSize={5242880} // maximum size we want to allow is 5 mb
            onFileDialogCancel={this.onCancel}
          >
            {({
              open,
              getRootProps,
              getInputProps,
              acceptedFiles,
              isDragActive,
              isDragAccept,
              isDragReject
            }) => (
              <div {...getRootProps({ className: "dropzone-upload-wrapper" })}>
                <input {...getInputProps()} />
                <div className="dropzone-inner-wrapper d-100 dropzone-avatar">
                  <div className="avatar-icon-wrapper d-100 m-2">
                    <Button
                      color="link"
                      onClick={open}
                      className="avatar-button badge shadow-xxl btn-icon badge-position badge-position--top-right border-2 text-indent-0 d-30 badge-circle badge-success hover-scale-lg text-white"
                    >
                      <Edit2 className="w-50" />
                    </Button>
                    <div>
                      {isDragAccept && (
                        <div className="overflow-hidden d-100 bg-success text-center font-weight-bold text-white d-flex justify-content-center align-items-center">
                          <Check className="d-30" />
                        </div>
                      )}
                      {isDragReject && (
                        <div className="overflow-hidden d-100 bg-danger text-center font-weight-bold text-white d-flex justify-content-center align-items-center">
                          <X className="d-40" />
                        </div>
                      )}
                      {!isDragActive && (
                        <div className="overflow-hidden d-100 bg-second text-center font-weight-bold text-white-50 d-flex justify-content-center align-items-center">
                          <User className="d-30" />
                        </div>
                      )}
                    </div>
                    {this.state.files.map(file => (
                      <div
                        key={file.name}
                        className=" avatar-image overflow-hidden d-100 bg-neutral-success text-center font-weight-bold text-success d-flex justify-content-center align-items-center"
                      >
                        <img
                          className="img-fluid img-fit-container"
                          src={file.preview}
                          alt="..."
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </Dropzone>
        </div>
      </div>
    );
  }
}

ImageField.propTypes = {
  handleUploadFile: PropTypes.func.isRequired
};

export default ImageField;
