import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import {
  FormGroup,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import SlidingPane from "react-sliding-pane";
import FormField from "../../../shared/components/form/FormField";
import { linkGoogleFolder } from "../../../actions/attachments/attachmentAction";
import { isFetching, isSuccess } from "../../../reducers/reducerUtils";

const LinkGoogleFolder = props => {
  const [request, setRequest] = useState({
    loading: false,
    error: null
  });

  useEffect(() => {
    const {
      asset: { slug },
      attachments
    } = props;
    if (slug) {
      if (request.loading && attachments.type === "LINK_GOOGLE_FOLDER") {
        if (!isFetching(attachments)) {
          if (isSuccess(attachments)) {
            setRequest({ loading: false, error: null });
          } else {
            setRequest({ loading: false, error: attachments.error });
          }
          props.toggleModal();
        }
      }
    }
  });

  const onSubmit = values => {
    setRequest({ loading: true, error: null });
    const {
      asset: { slug }
    } = props;
    values.slug = slug;
    props.linkGoogleFolder(values);
  };

  const { modalOpen, toggleModal } = props;

  const validationSchema = Yup.object({
    folderUrl: Yup.string("").required("Google Drive URL is required"),
    folderName: Yup.string("").required("Folder Name is required")
  });
  const initialValues = { folderUrl: "", folderName: "" };

  return (
    <SlidingPane
      isOpen={modalOpen}
      from="right"
      width="350px"
      hideHeader={true}
      className="d-flex justify-content-center modal-content"
      onRequestClose={toggleModal}
    >
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {props => {
          const { loading, handleSubmit } = props;

          return (
            <form onSubmit={handleSubmit}>
              <ModalHeader toggle={toggleModal}>Link Google Folder</ModalHeader>
              <ModalBody>
                <FormGroup>
                  <label htmlFor="staticEmail">Google Drive Url:</label>
                  <Field
                    name="folderUrl"
                    component={FormField}
                    type="text"
                    placeholder="Google Drive Url"
                  />
                </FormGroup>
                <FormGroup>
                  <label htmlFor="staticEmail">Folder Name:</label>
                  <Field
                    name="folderName"
                    component={FormField}
                    type="text"
                    placeholder="Folder Name"
                  />
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  className="btn  btn-primary px-5  d-inline-block mx-2"
                  type="submit"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? "Linking" : "Link"}
                </Button>
              </ModalFooter>
            </form>
          );
        }}
      </Formik>
    </SlidingPane>
  );
};

const mapStateToProps = state => ({
  attachments: state.attachments.attachments
});

const mapDispatchToProps = dispatch => ({
  linkGoogleFolder: data => linkGoogleFolder(data, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkGoogleFolder);
