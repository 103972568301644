// import PropTypes from 'prop-types';
import React, { PureComponent } from "react";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "./DatePicker";
import { getIn } from "formik";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { faPercentage } from "@fortawesome/free-solid-svg-icons";

import clsx from "clsx";

const currencyMask = createNumberMask({
  prefix: "",
  allowDecimal: true,
  decimalLimit: 3
});

class MaskedText extends PureComponent {
  getAddonText = maskType => {
    switch (maskType) {
      case "date":
        return <FontAwesomeIcon icon={faCalendarAlt} />;
      case "currency":
        return <FontAwesomeIcon icon={faDollarSign} />;
      case "percent":
        return <FontAwesomeIcon icon={faPercentage} />;
      default:
        return maskType;
    }
  };
  render() {
    const {
      type,
      touched,
      field,
      error,
      addonType,
      addonText,
      addonSize,
      maskType,
      ...otherProps
    } = this.props;

    return (
      <InputGroup size={addonSize}>
        {addonType === "prepend" && (
          <InputGroupAddon addonType={addonType}>
            <InputGroupText>{this.getAddonText(maskType)}</InputGroupText>
          </InputGroupAddon>
        )}
        {maskType === "date" ? (
          <DatePicker
            {...this.props}
            className={clsx("form-control form-control-lg ", {
              "is-invalid": touched && error
            })}
          />
        ) : (
          <MaskedInput
            {...field}
            mask={currencyMask}
            type={type}
            {...otherProps}
            className={clsx("form-control", {
              "is-invalid": touched && error
            })}
          />
        )}
        {addonType === "append" && (
          <InputGroupAddon addonType={addonType}>
            <InputGroupText>{this.getAddonText(maskType)}</InputGroupText>
          </InputGroupAddon>
        )}
        <div
          className={clsx("invalid-feedback", {
            "d-block": touched && error && maskType === "date"
          })}
        >
          {error}
        </div>
      </InputGroup>
    );
  }
}

class MaskedField extends PureComponent {
  render() {
    const {
      field,
      form: { touched, errors }
    } = this.props;
    const touch = getIn(touched, field.name);
    const error = getIn(errors, field.name);
    return <MaskedText touched={touch} error={error} {...this.props} />;
  }
}
export default MaskedField;
