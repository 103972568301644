import React, { Component } from "react";
import { Formik, Field } from "formik";

import * as Yup from "yup";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import { inviteUser } from "../../actions/invite";
import { connect } from "react-redux";
import TextareaField from "../../shared/components/form/Textarea";

const validationSchema = Yup.object({
  note: Yup.string("").required("Note is required")
});

class InviteContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      success: null,
      closeModal: false
    };
  }
  resetForm = null;

  sendInvite = values => {
    const { contact } = this.props;
    this.setState({ loading: true, error: null }, () => {
      this.props.inviteUser({
        slug: contact.slug,
        personalNote: values.note
      });
    });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { inviteProp } = nextProps;
    if (prevState.loading && inviteProp.inviteSuccess === true) {
      return {
        loading: false,
        closeModal: true,
        success: "User Invited Successfully",
        error: null
      };
    } else if (prevState.loading && inviteProp.inviteSuccess === false) {
      return {
        loading: false,
        closeModal: true,
        error: inviteProp.message,
        success: null
      };
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.closeModal && !prevState.closeModal) {
      this.props.toggleModal();
    }
  };
  toggleModal = () => {
    this.setState({ modalOpen: !this.state.modalOpen });
  };

  render() {
    const { loading } = this.state;
    const {
      contact,
      auth: { user }
    } = this.props;

    if (!contact || !user) return <div />;
    let initialValues = {
      note: `${
        user.fullName
      } would like to invite you to BetterCapital.us. This is a redefined investor portal that helps you track and measure your investment.`
    };
    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={this.sendInvite}
      >
        {props => {
          const { handleSubmit } = props;
          return (
            <Modal
              isOpen={this.props.modalOpen}
              className={"modal-dialog modal-dialog-centered"}
              contentClassName={"newexpensemodal"}
              toggle={this.props.toggleModal}
            >
              <ModalHeader toggle={this.props.toggleModal}>
                Invite User : {contact.first_name}
              </ModalHeader>
              <form onSubmit={handleSubmit}>
                <ModalBody>
                  <Field
                    name="note"
                    component={TextareaField}
                    placeholder="Enter your Personal Note"
                    row={8}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="primary"
                    className="btn savebtns btn-primary px-5  d-inline-block mx-2"
                    type="submit"
                    disabled={loading}
                  >
                    Invite
                  </Button>
                </ModalFooter>
              </form>
            </Modal>
          );
        }}
      </Formik>
    );
  }
}
const mapStateToProps = state => ({
  inviteProp: state.invite,
  auth: state.login
});

const mapDispatchToProps = dispatch => ({
  inviteUser: email => inviteUser(email, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InviteContact);
