import React, { useState, useEffect } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { Button, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { connect } from "react-redux";
import SlidingPane from "react-sliding-pane";

import SelectField from "../../../shared/components/form/SelectField";
import FormField from "../../../shared/components/form/FormField";
import { addAssetContact } from "../../../actions/contacts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const AddContactModal = ({
  modalOpen,
  handleSubmit,
  handleReset,
  loading,
  error
}) => (
  <SlidingPane
    from="right"
    width="350px"
    hideHeader={true}
    className="d-flex justify-content-center modal-content"
    isOpen={modalOpen}
    onRequestClose={handleReset}
  >
    <ModalHeader toggle={handleReset}>Add Contact</ModalHeader>
    <form onSubmit={handleSubmit}>
      <ModalBody>
        <div className="form-group row">
          <label htmlFor="staticEmail" className="col-sm-2 col-form-label">
            Email Address:
          </label>
          <div className="col-sm-10 inputblock">
            <Field
              name="email"
              component={FormField}
              type="text"
              placeholder="Email Address"
            />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="staticEmail" className="col-sm-2 col-form-label">
            First Name:
          </label>
          <div className="col-sm-10 inputblock">
            <Field
              name="first_name"
              component={FormField}
              type="text"
              placeholder="First name"
            />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="staticEmail" className="col-sm-2 col-form-label">
            Last Name:
          </label>
          <div className="col-sm-10 inputblock">
            <Field
              name="last_name"
              component={FormField}
              type="text"
              placeholder="Last name"
            />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="staticEmail" className="col-sm-2 col-form-label">
            Select Role:
          </label>
          <div className="col-sm-10 inputblock">
            <Field
              name="role"
              component={SelectField}
              options={[
                { value: "O", label: "Owner" },
                { value: "R", label: "Real Estate Agent" },
                { value: "PM", label: "Property Manager" },
                { value: "I", label: "Investor" },
                { value: "IA", label: "Insurance Agent" },
                { value: "T", label: "Tenant" },
                { value: "C", label: "Contractor" },
                { value: "U", label: "Other" }
              ]}
              placeholder="Select Role"
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          type="reset"
          className="btn btn-secondary px-5 d-inline-block mx-2"
          onClick={handleReset}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          className="btn savebtns btn-primary px-5  d-inline-block mx-2"
          type="submit"
          disabled={loading}
        >
          {loading ? "Saving" : "Save"}
        </Button>
      </ModalFooter>
    </form>
  </SlidingPane>
);

const validationSchema = Yup.object({
  email: Yup.string("")
    .email("Enter valid email")
    .required("Email is required")
    .max(100, "Email too long"),
  role: Yup.string("").required("Role is required")
});

const AddContact = props => {
  const [showModal, setShowModal] = useState(false);
  const toggle = () => setShowModal(prevState => !prevState);
  const [request, setRequest] = useState({
    loading: false,
    error: null
  });

  const addContact = (values, { resetForm }) => {
    setRequest({ loading: true, error: null });
    props.addAssetContact({
      email: values.email,
      role: values.role,
      slug: props.assetSlug,
      first_name: values.first_name,
      last_name: values.last_name
    });
  };

  const closeModal = () => {
    setRequest({ loading: false, error: null });
    setShowModal(false);
  };

  useEffect(() => {
    const { contacts } = props;
    if (request.loading) {
      if (contacts.addingSuccess === true) {
        setRequest({ loading: false, error: null });
        setShowModal(false);
      } else {
        setRequest({ loading: false, error: contacts.error });
        setShowModal(false);
      }
    }
  });

  const initialValues = { email: "", role: "O" };
  return (
    <>
      <Button
        color={props.btnColor || "primary"}
        className="mx-2"
        onClick={toggle}
      >
        <span className="btn-wrapper--icon">
          <FontAwesomeIcon icon={faPlus} />
        </span>
        <span className="btn-wrapper--label">
          {props.btnLabel || "Add Contact"}
        </span>
      </Button>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={addContact}
      >
        {props => (
          <AddContactModal
            handleSubmit={props.handleSubmit}
            handleReset={closeModal}
            modalOpen={showModal}
            loading={request.loading}
            error={request.error}
          />
        )}
      </Formik>
    </>
  );
};

const mapStateToProps = state => ({
  contacts: state.contacts
});

const mapDispatchToProps = dispatch => {
  return {
    addAssetContact: data => addAssetContact(data, dispatch)
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddContact);
