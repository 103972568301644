import {
  ADD_INTEGRATION,
  UPDATE_INTEGRATION,
  GET_INTEGRATIONS,
  DELETE_INTEGRATION,
  SYNC_INTEGRATION,
} from "../actionTypes";

export const addIntegration = (dispatch, data, page) => {
  dispatch({
    type: ADD_INTEGRATION,
    payload: data,
  });
};

export const updateIntegration = (dispatch, data, page) => {
  dispatch({
    type: UPDATE_INTEGRATION,
    payload: data,
  });
};

export const getIntegrations = (dispatch, page) => {
  dispatch({
    type: GET_INTEGRATIONS,
    payload: {
      page,
    },
  });
};

export const deleteIntegration = (dispatch, id, deleteAsset) => {
  dispatch({
    type: DELETE_INTEGRATION,
    payload: {
      id,
      deleteAsset,
    },
  });
};

export const syncIntegration = (dispatch, data) => {
  dispatch({
    type: SYNC_INTEGRATION,
    payload: data,
  });
};
