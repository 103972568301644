import React, { useState, useEffect } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import valid from "card-validator";
import { Button, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { connect } from "react-redux";
import SlidingPane from "react-sliding-pane";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import FormField from "../../../shared/components/form/FormField";
import { createPaymentMethod } from "../../../actions/subscriptions";
import { isFetching, isSuccess } from "../../../reducers/reducerUtils";

const AddCardModal = ({
  modalOpen,
  handleSubmit,
  handleReset,
  loading,
  error
}) => (
  <SlidingPane
    from="right"
    width="350px"
    hideHeader={true}
    className="d-flex justify-content-center modal-content"
    isOpen={modalOpen}
    onRequestClose={handleReset}
  >
    <ModalHeader toggle={handleReset}>Add Credit Card</ModalHeader>
    <form onSubmit={handleSubmit}>
      <ModalBody className="mt-3">
        <div className="form-group row">
          <div className="col-sm-12 inputblock">
            <Field
              name="card_number"
              component={FormField}
              type="text"
              placeholder="Credit Card Number"
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-4 inputblock">
            <Field
              name="month"
              component={FormField}
              type="text"
              placeholder="MM"
            />
          </div>
          <div className="col-sm-8 inputblock">
            <Field
              name="year"
              component={FormField}
              type="text"
              placeholder="YYYYY"
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-12 inputblock">
            <Field
              name="cvv"
              component={FormField}
              type="text"
              placeholder="CVV"
            />
          </div>
        </div>

        <div className="form-group row mt-5">
          <div className="col-sm-12 inputblock">
            <Field
              name="street"
              component={FormField}
              type="text"
              placeholder="Street"
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-6 inputblock">
            <Field
              name="city"
              component={FormField}
              type="text"
              placeholder="City"
            />
          </div>
          <div className="col-sm-6 inputblock">
            <Field
              name="state"
              component={FormField}
              type="text"
              placeholder="State"
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-6 inputblock">
            <Field
              name="country"
              component={FormField}
              type="text"
              placeholder="Country"
            />
          </div>
          <div className="col-sm-6 inputblock">
            <Field
              name="zip_code"
              component={FormField}
              type="text"
              placeholder="Zip Code"
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          type="reset"
          className="btn btn-secondary px-5 d-inline-block mx-2"
          onClick={handleReset}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          className="btn savebtns btn-primary px-5  d-inline-block mx-2"
          type="submit"
          disabled={loading}
        >
          {loading ? "Saving" : "Save"}
        </Button>
      </ModalFooter>
    </form>
  </SlidingPane>
);

const validationSchema = Yup.object({
  card_number: Yup.string("")
    .test(
      "test-number",
      "Credit Card number is invalid",
      value => valid.number(value).isValid
    )
    .required("Credit card number is required"),
  month: Yup.string("")
    .test(
      "test-month",
      "Expiration month is not valid",
      value => valid.expirationMonth(value).isValid
    )
    .required("Expiration Month is required"),
  year: Yup.string("")
    .test(
      "test-year",
      "Expiration year is not valid",
      value => valid.expirationYear(value).isValid
    )
    .required("Expiration Year is required"),
  cvv: Yup.string("")
    .test("test-cvv", "CVV is not valid", value => valid.cvv(value).isValid)
    .required("CVV is required"),
  street: Yup.string(""),
  city: Yup.string(""),
  state: Yup.string(""),
  country: Yup.string(""),
  zip_code: Yup.string().test(
    "test-zip",
    "Zip code is not valid",
    value => valid.postalCode(value).isValid
  )
});

const AddCard = props => {
  const [showModal, setShowModal] = useState(false);
  const toggle = () => setShowModal(prevState => !prevState);
  const [request, setRequest] = useState({
    loading: false,
    error: null
  });

  const addContact = (values, { resetForm }) => {
    setRequest({ loading: true, error: null });
    const data = {
      paymentDetails: {
        card_number: values.card_number,
        month: values.month,
        year: values.year,
        cvv: values.cvv,
        gateway_type: "stripe"
      },
      street: values.street,
      city: values.city,
      state: values.state,
      country: values.country,
      zip_code: values.zip_code
    };
    props.createPaymentMethod(data);
  };

  const closeModal = () => {
    setRequest({ loading: false, error: null });
    setShowModal(false);
  };

  useEffect(
    () => {
      const { paymentMethod } = props;
      if (request.loading) {
        if (!isFetching(paymentMethod) && isSuccess(paymentMethod)) {
          setRequest({ loading: false, error: null });
          setShowModal(false);
        } else {
          setRequest({ loading: false });
          setShowModal(false);
        }
      }
    },
    [props.paymentMethod]
  );

  const initialValues = {
    card_number: "",
    month: "",
    year: "",
    cvv: "",
    street: "",
    city: "",
    state: "",
    country: "",
    zip_code: ""
  };
  return (
    <>
      <div onClick={toggle} className="m-3 btn-input-select">
        <div className="d-30 d-flex align-items-center justify-content-center rounded-pill bg-secondary text-primary">
          <FontAwesomeIcon icon={faPlus} />
        </div>
        <div className="font-weight-bold text-primary opacity-6 mt-2">
          {props.btnLabel || "Add Credit Card"}
        </div>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={addContact}
      >
        {props => (
          <AddCardModal
            handleSubmit={props.handleSubmit}
            handleReset={closeModal}
            modalOpen={showModal}
            loading={request.loading}
            error={request.error}
          />
        )}
      </Formik>
    </>
  );
};

const mapStateToProps = state => ({
  paymentMethod: state.subscriptions.paymentMethod
});

const mapDispatchToProps = dispatch => {
  return {
    createPaymentMethod: data => createPaymentMethod(dispatch, data)
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddCard);
