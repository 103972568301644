import React from "react";
import { Fab, Action } from "react-tiny-fab";
import "react-tiny-fab/dist/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Button } from "reactstrap";
import AddTransaction from "../Transaction/AddTransaction";
import AddContact from "../Contacts/AddContact";
import AddAttachment from "../Documents/AddAttachment";
import { useToggle } from "../../../helpers";

const AssetAction = ({ asset }) => {
  const [showAddDocument, setShowAddDocument] = useToggle();
  return (
    <Fab
      mainButtonStyles={{
        backgroundColor: "#070919",
        color: "#fff"
      }}
      icon={<FontAwesomeIcon icon={faPlus} className="font-size-xl" />}
      event={"hover"}
      alwaysShowTitle={true}
    >
      <Action
        style={{
          backgroundColor: "transparent",
          boxShadow: "none"
        }}
      >
        <AddTransaction
          type="INCOME"
          assetSlug={asset.slug}
          asset={asset}
          btnColor="second"
        />
      </Action>
      <Action
        style={{
          backgroundColor: "transparent",
          boxShadow: "none"
        }}
      >
        <AddTransaction
          type="EXPENSE"
          assetSlug={asset.slug}
          asset={asset}
          btnColor="second"
        />
      </Action>
      <Action
        style={{
          backgroundColor: "transparent",
          boxShadow: "none"
        }}
      >
        <AddContact
          assetSlug={asset.slug}
          btnLabel="Contact"
          btnColor="second"
        />
      </Action>
      <Action
        style={{
          backgroundColor: "transparent",
          boxShadow: "none"
        }}
      >
        <Button color="second" className="mx-2" onClick={setShowAddDocument}>
          <span className="btn-wrapper--icon">
            <FontAwesomeIcon icon={faPlus} />
          </span>
          <span className="btn-wrapper--label">Document</span>
        </Button>
        <AddAttachment
          modalOpen={showAddDocument}
          toggleModal={setShowAddDocument}
          assetSlug={asset.slug}
        />
      </Action>
    </Fab>
  );
};

export default AssetAction;
