import React, { useState, useEffect } from "react";

import SlidingPane from "react-sliding-pane";
import moment from "moment";
import DatePicker from "../../../shared/components/form/DatePicker";

import { Formik, Field } from "formik";
import { connect } from "react-redux";
import { Button, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { XCircle } from "react-feather";
import { syncIntegration } from "../../../actions/integrations";
import { isFetching, isSuccess } from "../../../reducers/reducerUtils";
import ToggleSwitch from "../../../shared/components/form/ToggleSwitch";

const TransactionSync = ({
  integration,
  integrations,
  syncIntegration,
  isOpen,
  setIsOpen,
}) => {
  const [request, setRequest] = useState({
    loading: false,
    error: null,
  });

  useEffect(() => {
    if (request.loading) {
      if (!isFetching(integrations)) {
        if (isSuccess(integrations)) {
          setRequest({ loading: false, error: null });
        } else {
          setRequest({ loading: false, error: integrations.error });
        }
        setIsOpen(false);
      }
    }
  });

  const onSubmit = (values) => {
    setRequest({ loading: true, error: null });
    syncIntegration(values);
  };

  let initialValues = {
    id: integration._id,
    type: "transaction",
    startDate: moment()
      .startOf("month")
      .toDate(),
    endDate: moment()
      .endOf("month")
      .toDate(),
    forceSync: false,
  };
  return (
    <SlidingPane
      isOpen={isOpen}
      closeIcon={<XCircle />}
      from="right"
      width="350px"
      hideHeader={true}
      className="d-flex justify-content-center modal-content"
      onRequestClose={() => setIsOpen(false)}
    >
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {(props) => {
          const { loading, handleSubmit } = props;
          return (
            <form onSubmit={props.handleSubmit}>
              <ModalHeader toggle={() => this.setState({ filterOpen: false })}>
                Sync Transactions
              </ModalHeader>
              <ModalBody>
                <div className="form-group">
                  <label htmlFor="formGroupTitleInput">From Date</label>
                  <Field
                    name="startDate"
                    component={DatePicker}
                    className="form-control"
                    maxDate={moment()
                      .subtract(1, "months")
                      .startOf("month")
                      .format("MM/DD/YYYY")}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="formGroupTitleInput">To Date</label>
                  <Field
                    name="endDate"
                    component={DatePicker}
                    className="form-control"
                    maxDate={moment()
                      .subtract(1, "months")
                      .endOf("month")
                      .format("MM/DD/YYYY")}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="formGroupTitleInput">Force Sync</label>
                  <Field
                    name="forceSync"
                    component={ToggleSwitch}
                    onLabel={"Yes"}
                    offLabel={"No"}
                    className="form-control switch-large rc-switch-custom toggle-switch-square toggle-switch-success"
                  />
                </div>
                <ModalFooter>
                  <Button
                    color="primary"
                    className="btn  btn-primary px-5  d-inline-block mx-2"
                    type="submit"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    {loading ? "Syncing" : "Sync"}
                  </Button>
                </ModalFooter>
              </ModalBody>
            </form>
          );
        }}
      </Formik>
    </SlidingPane>
  );
};

const mapStateToProps = (state) => ({
  integrations: state.integrations,
});

const mapDispatchToProps = (dispatch) => ({
  syncIntegration: (data) => syncIntegration(dispatch, data),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionSync);
