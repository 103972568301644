import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCreditCard } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import clsx from "clsx";

import AddCardModal from "./AddCardModal";

const Cards = ({
  paymentMethods,
  setCurrentPaymentMethod,
  currentPaymentMethod
}) => {
  return (
    <>
      <div className="pricing-plan">
        <div className="pricing-plan">
          {paymentMethods.length > 0 &&
            paymentMethods.map(paymentMethod => (
              <div
                onClick={() => {
                  setCurrentPaymentMethod(paymentMethod);
                }}
                className={clsx("m-3 btn-input-select", {
                  active:
                    currentPaymentMethod &&
                    paymentMethod.id === currentPaymentMethod.id
                })}
                key={paymentMethod.id}
              >
                <div
                  className={clsx("selected-icon", {
                    "d-none":
                      currentPaymentMethod &&
                      paymentMethod.id !== currentPaymentMethod.id
                  })}
                >
                  <FontAwesomeIcon icon={faCheck} />
                </div>
                <div className="d-30 text-dark d-flex align-items-center justify-content-center rounded-pill bg-light">
                  <FontAwesomeIcon icon={faCreditCard} />
                </div>
                <div className="font-weight-bold mt-2">{`Card Ending in ${
                  paymentMethod.last_four_digits
                }`}</div>
                <div className="opacity-6">
                  {`Expires on ${moment(
                    `${paymentMethod.expiry_month}-${
                      paymentMethod.expiry_year
                    }`,
                    "MM-YYYY"
                  ).format("MMMM YYYY")}`}
                </div>

                {/* <div className="mt-2 d-flex justify-content-center align-items-center w-100">
                  <UpdateCardModal
                    activePaymentMethodId={activePaymentMethodId}
                  />
                </div> */}
              </div>
            ))}
          <AddCardModal />
        </div>
      </div>
    </>
  );
};

export default Cards;
