import React, { Component } from "react";
import PropTypes from "prop-types";
import { categoryOptions } from "../../../helpers/constants";
import CreatableSelect from "react-select/creatable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

export default class CategorySelect extends Component {
  render() {
    const { value, onUpdate, categoryType } = this.props;
    let options = categoryOptions;
    if (categoryType) {
      options = options.filter(c =>
        c.options.find(o => o.type === categoryType.toLowerCase())
      );
    }
    let defaultOption = options
      .flatMap(cat => cat.options)
      .find(option => option.value === value.toLowerCase());

    if (!defaultOption && value) {
      defaultOption = { label: value, value: value };
    }
    return (
      <CreatableSelect
        isSearchable
        name="category"
        placeholder="Select Category"
        options={options}
        value={defaultOption}
        formatCreateLabel={inputValue => {
          return (
            <div className="text-primary d-flex align-items-center">
              <div className="d-30 rounded-sm btn-icon bg-neutral-primary mr-2">
                <FontAwesomeIcon icon={faPlus} />
              </div>
              <span className="pt-1 font-weight-bold">{inputValue}</span>
            </div>
          );
        }}
        theme={theme => ({
          ...theme,
          borderRadius: "0.29rem",
          borderWidth: 1,
          colors: {
            ...theme.colors,
            primary25: "rgba(60,68,177,0.15)",
            primary50: "rgba(60,68,177,0.15)",
            primary: "#3c44b1"
          }
        })}
        onChange={option => onUpdate(option.value)}
      />
    );
  }
}

CategorySelect.propTypes = {
  value: PropTypes.string,
  onUpdate: PropTypes.func.isRequired
};
