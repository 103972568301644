import React, { Component } from "react";
import { Col, Row, Button, Badge } from "reactstrap";
import { connect } from "react-redux";
import "react-tagsinput/react-tagsinput.css";

import { getAttachments } from "../../../actions/attachments/attachmentAction";
import { isFetching, isSuccess } from "../../../reducers/reducerUtils";
import LoadingIndicator from "../../../shared/components/LoadingIndicator";
import DeleteAttachment from "./DeleteAttachment";
import AddDocument from "./AddDocument";
import AddTags from "./AddTags";
import ViewDocument from "./ViewDocument";
import DocumentChecklist from "./DocumentChecklist";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faExternalLinkAlt,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import TableWithSearch from "../../../shared/components/TableWithSearch";

import _ from "lodash";

class DocumentListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      attachments: [],
      cpyAttachments: [],
      selectedFileToDelete: null,
      selectedFileToView: null,
      selectedFileToAddTags: {},
      showDeleteFileModal: false,
      showAddTagsModal: false,
      showViewFileModal: false,
      page: 1,
      sizePerPage: 5,
      search: "",
      selectedIds: []
    };
  }

  selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect, rowIndex) => {
      if (isSelect) {
        this.setState({ selectedIds: [...this.state.selectedIds, row._id] });
      } else {
        this.setState({
          selectedIds: this.state.selectedIds.filter(id => id !== row._id)
        });
      }
    },
    onSelectAll: (isSelect, rows) => {
      const ids = rows.map(r => r._id);
      if (isSelect) {
        this.setState({ selectedIds: ids });
      } else {
        this.setState({ selectedIds: [] });
      }
    }
  };

  unSelectOnDelete = () => {
    this.setState({ selectedIds: [] });
  };

  columnProps = [
    {
      dataField: "originalName",
      text: "Name",
      sort: true
    },

    {
      text: "Tags",
      dataField: "tags",
      formatter: (cell, row) => {
        if (cell.length > 0)
          return cell.map(tag => {
            return (
              <Badge
                color="badge badge-first m-2 cursor-pointer"
                onClick={() => this.toggleAddTagsModal(row)}
              >
                {tag}
              </Badge>
            );
          });
        else
          return (
            <Badge
              className="mx-1 cursor-pointer"
              color="danger"
              onClick={() => this.toggleAddTagsModal(row)}
            >
              + Add Category
            </Badge>
          );
      }
    },
    {
      text: "Actions",
      dataField: "",
      isDummyField: true,
      formatter: (cell, row) => {
        return (
          <>
            <Button
              className={"btn-sm btn-neutral-primary mr-2"}
              onClick={() => {
                if (row.storage === "drive") return this.openFile(row);
                else return this.toggleViewFileModal(row);
              }}
            >
              <FontAwesomeIcon
                icon={row.storage === "drive" ? faExternalLinkAlt : faEye}
              />
            </Button>
            <Button
              className={"btn-sm btn-neutral-danger"}
              onClick={() =>
                this.toggleDeleteFileModal(
                  this.state.selectedIds.length > 0
                    ? this.state.selectedIds
                    : row._id
                )
              }
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </>
        );
      }
    }
  ];

  toggleDeleteFileModal = id => {
    this.setState(prevState => ({
      selectedFileToDelete: id,
      showDeleteFileModal: !prevState.showDeleteFileModal
    }));
  };

  toggleAddTagsModal = file => {
    this.setState(prevState => ({
      selectedFileToAddTags: file,
      showAddTagsModal: !prevState.showAddTagsModal
    }));
  };

  openFile = file => {
    window.open(file.webUrl, "_blank");
  };

  toggleViewFileModal = file => {
    this.setState(prevState => ({
      selectedFileToView: file,
      showViewFileModal: !prevState.showViewFileModal
    }));
  };
  handleSearch = ({ target }) => {
    this.setState({ search: target.value });
  };
  componentDidMount() {
    this.setState({ loading: true, error: null }, () => {
      this.props.getAttachments(this.props.assetSlug);
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { attachments } = nextProps;

    if (!isFetching(attachments)) {
      if (attachments.type === "GET") {
        if (isSuccess(attachments)) {
          return {
            loading: false,
            attachments: attachments.data.data
          };
        } else {
          return {
            loading: false,
            attachments: [],
            error: attachments.error
          };
        }
      } else if (attachments.type === "ADD_TAGS") {
        if (isSuccess(attachments)) {
          let existingAttachments = prevState.attachments;
          const updatedAttachment = attachments.data.data;
          let updatedAttachmentIndex = existingAttachments.findIndex(
            a => a._id === updatedAttachment._id
          );

          if (updatedAttachmentIndex >= 0) {
            existingAttachments[updatedAttachmentIndex] = updatedAttachment;
            return {
              loading: false,
              attachments: existingAttachments
            };
          }
        }
      } else if (
        attachments.type === "SAVE" ||
        attachments.type === "LINK_GOOGLE_FOLDER"
      ) {
        if (isSuccess(attachments)) {
          let existingAttachments = prevState.attachments;
          const newAttachments = attachments.data.data;
          existingAttachments = _.unionBy(
            existingAttachments,
            newAttachments,
            "_id"
          );
          return {
            loading: false,
            attachments: existingAttachments
          };
        }
      } else if (attachments.type === "DELETE") {
        if (isSuccess(attachments)) {
          let existingAttachments = prevState.attachments;
          const updatedAttachment = attachments.data.data;
          existingAttachments = existingAttachments.filter(
            a => a._id !== updatedAttachment._id
          );
          return {
            loading: false,
            attachments: existingAttachments
          };
        }
      } else if (attachments.type === "DELETE_MULTIPLE") {
        if (isSuccess(attachments)) {
          return {
            loading: false,
            attachments: attachments.data.data
          };
        } else {
          return {
            loading: false,
            attachments: [],
            error: attachments.error
          };
        }
      }
    } else if (
      !prevState.loading &&
      (attachments.type === "ADD_TAGS" ||
        attachments.type === "SAVE" ||
        attachments.type === "DELETE")
    ) {
      return {
        loading: true
      };
    }

    return null;
  }

  handlePageChange = page => {
    this.setState({ page });
  };

  render() {
    const {
      attachments,
      selectedFileToDelete,
      selectedFileToView,
      showDeleteFileModal,
      showAddTagsModal,
      showViewFileModal,
      selectedFileToAddTags,
      loading
    } = this.state;
    const { assetSlug, asset, toggleAddModal } = this.props;

    return (
      <Row>
        <Col xl="9">
          {loading ? (
            <LoadingIndicator loading={loading} />
          ) : (
            <TableWithSearch
              keyField="_id"
              data={attachments}
              totalSize={attachments.length}
              page={this.state.page}
              sizePerPage={this.state.sizePerPage}
              columns={this.columnProps}
              onPageChange={this.handlePageChange}
              searchPlaceholder="Search Documents..."
              noDataIndicator="No Documents found!"
              enableCellEdit={false}
              remoteData={false}
              onSelectRow={this.selectRow}
            >
              <AddDocument toggleAddModal={toggleAddModal} />
              <Button
                color="danger"
                className="mx-2"
                disabled={this.state.selectedIds.length === 0}
                onClick={() =>
                  this.toggleDeleteFileModal(this.state.selectedIds)
                }
              >
                <span className="btn-wrapper--icon">
                  <FontAwesomeIcon icon={faTrash} />
                </span>
                <span className="btn-wrapper--label">Delete</span>
              </Button>
            </TableWithSearch>
          )}
          <DeleteAttachment
            fileId={selectedFileToDelete}
            toggleModal={this.toggleDeleteFileModal}
            modalOpen={showDeleteFileModal}
            assetSlug={assetSlug}
            unSelectOnDelete={this.unSelectOnDelete}
          />
          <AddTags
            file={selectedFileToAddTags}
            toggleModal={this.toggleAddTagsModal}
            modalOpen={showAddTagsModal}
            assetSlug={assetSlug}
            asset={asset}
          />
          {showViewFileModal && (
            <ViewDocument
              selectedFileToView={selectedFileToView}
              toggleModal={this.toggleViewFileModal}
              assetSlug={assetSlug}
              modalOpen={showViewFileModal}
            />
          )}
        </Col>
        <Col xl="3">
          <DocumentChecklist asset={asset} />
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  attachments: state.attachments.attachments,
  user: state.userDetail
});

const mapDispatchToProps = dispatch => ({
  getAttachments: data => getAttachments(data, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentListing);
