/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Button,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu
} from "reactstrap";
import { NavLink as NavLinkStrap } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmModal from "../../shared/components/ConfirmModal";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import {
  faEnvelope,
  faPlus,
  faUpload
} from "@fortawesome/free-solid-svg-icons";
import AddAttachment from "../AssetDetails/Documents/AddAttachment";
import LinkToAssetModal from "./LinkToAssetModal";

import ViewDocument from "../AssetDetails/Documents/ViewDocument";
import DocumentsTable from "./DocumentsTable";
import { isFetching, isSuccess } from "../../reducers/reducerUtils";
import SectionHeader from "../../shared/components/SectionHeader";

import {
  deleteDocument,
  deleteMultipleDocuments,
  getAllDocuments,
  uploadDocuments,
  updateDocument
} from "../../actions/asset/assetAction";

import { getDocumentUrl } from "../../actions/attachments/attachmentAction";

class Document extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      tableChanged: false,
      deleting: false,
      uploading: false,
      showAddAttachmentModal: false,
      showAddViaEmailModal: false,
      fetching: false,
      updating: false,
      fileUrl: null,
      selectedFile: null,
      showDeleteFileModal: false,
      showViewFileModal: false,
      showLinkToAssetModal: false,
      selectedAsset: null,
      documents: [],
      totalCount: 0,
      showModal: null
    };
  }

  toggleAddModal = type => {
    this.setState({ showModal: type });
  };

  toggleAddAttachmentModal = () => {
    this.setState(prevState => ({
      showAddAttachmentModal: !prevState.showAddAttachmentModal
    }));
  };

  toggleAddViaEmailModal = () => {
    this.setState(prevState => ({
      showAddViaEmailModal: !prevState.showAddViaEmailModal
    }));
  };

  toggleLinkToAssetModal = row => {
    this.setState(prevState => ({
      selectedFile: row && row._id,
      showLinkToAssetModal: !prevState.showLinkToAssetModal
    }));
  };

  copyEmail = () => {
    toast.info("Email copied to clipboard", {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false
    });
    this.toggleAddViaEmailModal();
  };

  toggleDeleteFileModal = (documentId, assetSlug) => {
    this.setState(prevState => ({
      selectedFile: documentId,
      selectedAsset: assetSlug,
      showDeleteFileModal: !prevState.showDeleteFileModal
    }));
  };

  handleDeleteDocument = () => {
    this.setState({ deleting: true }, () => {
      if (Array.isArray(this.state.selectedFile)) {
        this.props.deleteMultipleDocuments({
          attachmentIds: this.state.selectedFile
        });
      } else this.props.deleteDocument(this.state.selectedFile);
    });
  };

  toggleViewFileModal = (documentId, assetSlug) => {
    this.setState(prevState => ({
      selectedFile: documentId,
      selectedAsset: assetSlug,
      showViewFileModal: !prevState.showViewFileModal
    }));
  };

  handleOpenFile = (documentId, assetSlug) => {
    this.setState(
      prevState => ({
        selectedFile: documentId,
        selectedAsset: assetSlug,
        fetching: true
      }),
      () => {
        this.props.getDocumentUrl({ slug: assetSlug, id: documentId });
      }
    );
  };

  handleSubmit = values => {
    this.props.searchDocument(values.searchValue);
  };

  handleUploadDocuments = files => {
    if (files.length > 0) {
      this.setState({ uploading: true, error: null }, () => {
        this.props.uploadDocuments({
          fileName: "documents",
          files,
          params: {
            fileFolder: "documents"
          }
        });
      });
    }
  };

  handleTableChange = (type, attrs) => {
    const { page, sizePerPage, sortField, sortOrder, searchText } = attrs;
    this.setState({ tableChanged: true }, () => {
      let queryParams = [];
      queryParams.push(`skip=${page - 1} * sizePerPage`);
      queryParams.push(`limit=${sizePerPage}`);
      if (sortField) queryParams.push(`sortBy=${sortField}`);
      if (sortOrder) queryParams.push(`orderBy=${sortOrder}`);
      if (searchText) queryParams.push(`search=${searchText}`);
      this.props.getAllDocuments(queryParams.join("&"));
    });
  };

  linkToAsset = values => {
    const { asset_slug, documentId } = values;
    this.setState({ updating: true }, () => {
      this.props.updateDocument(
        {
          asset_slug: asset_slug
        },
        documentId
      );
    });
  };

  componentDidMount() {
    this.setState({ loading: true }, () => {
      this.props.getAllDocuments("skip=0&limit=5");
    });
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { fileUrl } = this.state;
    if (!prevState.fileUrl && fileUrl) {
      this.setState({ fileUrl: null }, () => {
        window.open(fileUrl, "_blank");
      });
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { documents, document } = nextProps;

    if (prevState.fetching && document.type === "GET_URL") {
      if (!isFetching(document)) {
        if (isSuccess(document)) {
          return {
            selectedFileToView: prevState.selectedFileToView,
            fetching: false,
            fileUrl: document.data.attachment
          };
        } else {
          return {
            selectedFileToView: prevState.selectedFileToView,
            loading: false,
            error: document.error
          };
        }
      }
    } else if (
      !prevState.fetching &&
      document.type === "GET_URL" &&
      isFetching(document)
    ) {
      return {
        fetching: true,
        fileUrl: null
      };
    }
    if (prevState.tableChanged || !documents) {
      return {
        loading: true,
        tableChanged: false
      };
    }

    if (prevState.uploading || !documents) {
      return {
        loading: true,
        uploading: false
      };
    }
    if (prevState.deleting || !documents) {
      return {
        loading: true,
        deleting: false
      };
    }

    if (prevState.updating || !documents) {
      return {
        loading: true,
        updating: false
      };
    }

    if (prevState.loading) {
      if (!isFetching(documents) && documents.type === "DELETE") {
        // if delete type, we need to update the current documents and total
        if (isSuccess(documents)) {
          const deletedDocument = documents.data;
          return {
            loading: false,
            documents: prevState.documents.filter(
              a => a._id !== deletedDocument._id
            ),
            totalCount: prevState.totalCount - 1,
            selectedFile: null,
            showDeleteFileModal: false
          };
        }
      }

      if (!isFetching(documents) && documents.type === "DELETE_MULTIPLE") {
        if (isSuccess(documents)) {
          const { attachments, totalCount } = documents.data;

          return {
            loading: false,
            documents: attachments,
            totalCount: totalCount,
            selectedFile: null,
            showDeleteFileModal: false
          };
        } else
          return {
            loading: false,
            error: documents.error,
            selectedFile: null,
            showDeleteFileModal: false
          };
      }

      if (!isFetching(documents) && documents.type === "UPLOAD") {
        if (isSuccess(documents)) {
          let newAttachments = documents.data;
          let currentAttachments = prevState.documents;
          let currentAttachmentCount = prevState.totalCount;
          return {
            loading: false,
            showAddAttachmentModal: false,
            documents: currentAttachments.concat(newAttachments),
            totalCount: currentAttachmentCount + newAttachments.length,
            showModal: null
          };
        } else
          return {
            loading: false,
            showAddAttachmentModal: false,
            error: documents.error,
            showModal: null
          };
      }
      if (!isFetching(documents) && documents.type === "GET") {
        if (isSuccess(documents)) {
          const { attachments, totalCount } = documents.data;

          return {
            loading: false,
            documents: attachments,
            totalCount: totalCount
          };
        } else
          return {
            loading: false,
            error: documents.error
          };
      }
      if (!isFetching(documents) && documents.type === "UPDATE") {
        if (isSuccess(documents)) {
          let updatedDocument = documents.data;
          let updatedDocuments = prevState.documents;
          let updatedDocumentIndex = updatedDocuments.findIndex(
            d => d._id === updatedDocument._id
          );
          updatedDocuments[updatedDocumentIndex] = updatedDocument;
          return {
            loading: false,
            documents: updatedDocuments,
            totalCount: updatedDocuments.length
          };
        } else
          return {
            loading: false,
            error: documents.error
          };
      }
    }
  }

  render() {
    const {
      documents,
      totalCount,
      showViewFileModal,
      selectedFile,
      selectedAsset
    } = this.state;

    return (
      <div className="p-5">
        <SectionHeader titleHeading="Documents">
          <UncontrolledDropdown className="m-2">
            <DropdownToggle className="btn-pill" color="primary">
              <span className="btn-wrapper--icon">
                <FontAwesomeIcon icon={faPlus} className="font-size-sm" />
              </span>
              <span className="btn-wrapper--label">Add Document</span>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu">
              <Nav className="flex-column">
                <NavItem>
                  <NavLinkStrap
                    href="#/"
                    onClick={() => this.toggleAddModal("upload")}
                  >
                    <div className="nav-link-icon">
                      <FontAwesomeIcon icon={faUpload} />
                    </div>
                    <span>Upload</span>
                  </NavLinkStrap>
                </NavItem>
                <li className="dropdown-divider" />
                <NavItem>
                  <NavLinkStrap
                    href="#/"
                    onClick={() => this.toggleAddModal("email")}
                  >
                    <div className="nav-link-icon">
                      <FontAwesomeIcon icon={faEnvelope} />
                    </div>
                    <span>Email</span>
                  </NavLinkStrap>
                </NavItem>
              </Nav>
            </DropdownMenu>
          </UncontrolledDropdown>
        </SectionHeader>
        <ConfirmModal
          type="info"
          modalOpen={this.state.showModal === "email"}
          onSubmit={this.toggleAddModal}
          btnText={"Ok"}
          hideCancel={true}
        >
          <h4 className="font-weight-bold mt-4">
            Just email your documents to:
            <CopyToClipboard
              text={`document@upload.bettercapital.us`}
              onCopy={this.copyEmail}
            >
              <Button>{`document@upload.bettercapital.us`}</Button>
            </CopyToClipboard>
          </h4>
        </ConfirmModal>
        <DocumentsTable
          documents={documents}
          totalCount={totalCount}
          handleTableChange={this.handleTableChange}
          toggleDeleteFileModal={this.toggleDeleteFileModal}
          toggleViewFileModal={this.toggleViewFileModal}
          openFile={this.handleOpenFile}
          toggleLinkToAssetModal={this.toggleLinkToAssetModal}
        />

        <ConfirmModal
          title={"Are you sure to delete this document?"}
          subtitle={"You cannot undo this operation."}
          type="danger"
          modalOpen={this.state.showDeleteFileModal}
          onCancel={this.toggleDeleteFileModal}
          onSubmit={this.handleDeleteDocument}
          btnText={"Delete"}
          btnTextOnLoading={"Deleting"}
        />
        <AddAttachment
          toggleModal={this.toggleAddModal}
          modalOpen={this.state.showModal === "upload"}
          onSubmit={this.handleUploadDocuments}
        />
        {showViewFileModal && selectedFile && selectedFile.storage === "s3" && (
          <ViewDocument
            selectedFileToView={selectedFile}
            toggleModal={this.toggleViewFileModal}
            assetSlug={selectedAsset}
            modalOpen={showViewFileModal}
          />
        )}
        <LinkToAssetModal
          isShow={this.state.showLinkToAssetModal}
          onClose={this.toggleLinkToAssetModal}
          handleSubmit={this.linkToAsset}
          selectedFile={selectedFile}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    documents: state.documents,
    document: state.attachments.document
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAllDocuments: query => getAllDocuments(dispatch, query),
    uploadDocuments: data => uploadDocuments(dispatch, data),
    deleteDocument: documentId => deleteDocument(dispatch, documentId),
    deleteMultipleDocuments: data => deleteMultipleDocuments(dispatch, data),
    getDocumentUrl: data => getDocumentUrl(data, dispatch),
    updateDocument: (data, id) => updateDocument(dispatch, data, id)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Document);
