/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { Card, Nav, NavItem, NavLink } from "reactstrap";
import queryString from "query-string";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const reports = [
  {
    type: "detail",
    label: "Transaction Detail",
    icon: faFileInvoiceDollar,
    enabled: true
  },
  {
    type: "missing-data",
    label: "Missing Data",
    icon: faExclamationTriangle,
    enabled: false
  },
  {
    type: "tax-report",
    label: "Tax Report",
    icon: faBook,
    enabled: false
  }
];

class ReportsSidebar extends PureComponent {
  render() {
    const type = queryString.parse(this.props.location.search).type;
    return (
      <div className="col-sm-12 col-md-4 col-lg-4 col-xl-2 font-size-l">
        <Card className="card-box mb-5">
          <div className="bg-composed-wrapper bg-primary">
            <div className="bg-composed-wrapper--content text-light p-4">
              <h4 className="mb-4">Reports</h4>
              <p className="mb-0 opacity-7">Analyze and Drilldown</p>
            </div>
          </div>
          <Nav pills className="nav-primary flex-column p-3">
            <NavItem>
              {reports.map(report => {
                return (
                  <NavLink
                    tag={Link}
                    to={`/reports?type=${report.type}`}
                    active={report.type === (type || "summary")}
                    disabled={!report.enabled}
                  >
                    <div className="nav-link-icon">
                      <FontAwesomeIcon
                        icon={report.icon}
                        color={type === report.type ? "#fff" : "#7a7b97"}
                      />
                    </div>
                    <span>{report.label}</span>
                  </NavLink>
                );
              })}
            </NavItem>
          </Nav>
        </Card>
      </div>
    );
  }
}

export default ReportsSidebar;
