import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, Button } from "reactstrap";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";
import LoadingIndicator from "../../../shared/components/LoadingIndicator";
import { faVideo } from "@fortawesome/free-solid-svg-icons";

class VideoPlayer extends Component {
  static propTypes = {
    ctaBtnLabel: PropTypes.string,
    ctaBtnIcon: PropTypes.object,
    ctaBtnColor: PropTypes.string,
    ctaBtnTag: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string
  };

  static defaultProps = {
    ctaBtnLabel: "Intro",
    ctaBtnColor: "second",
    ctaBtnTag: "a",
    ctaBtnIcon: faVideo
  };

  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      videoLoading: true
    };
  }

  toggleModal = () => {
    this.setState({ modalOpen: !this.state.modalOpen });
  };
  videoReady = () => {
    this.setState({ videoLoading: false });
  };
  renderVideoPlayer = () => {
    if (this.props.url && this.props.url.includes("media.bettercapital.us")) {
      return (
        <div>
          <div
            style={{
              left: 0,
              width: "100%",
              height: 0,
              position: "relative",
              paddingBottom: "56.25%"
            }}
          >
            <figure
              style={{
                left: 0,
                width: "100%",
                height: 0,
                position: "relative",
                paddingBottom: "56.25%",
                marginBlockEnd: 0,
                marginBlockStart: 0,
                marginInlineStart: 0,
                marginInlineEnd: 0
              }}
            >
              <iframe
                title={this.props.title}
                src={this.props.url}
                scrolling="no"
                style={{
                  border: 0,
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  overflow: "hidden"
                }}
                allowFullScreen
              />
            </figure>
          </div>
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content-center">
          {this.state.videoLoading && (
            <div className="d-table-cell align-middle">
              <LoadingIndicator loading={this.state.videoLoading} />
            </div>
          )}
          <ReactPlayer url={this.props.url} onReady={this.videoReady} />
        </div>
      );
    }
  };

  render() {
    return (
      <Fragment>
        <Button
          tag={this.props.ctaBtnTag}
          color={this.props.ctaBtnColor}
          onClick={this.toggleModal}
          className={this.props.className}
        >
          <span className="btn-wrapper--icon">
            <FontAwesomeIcon icon={this.props.ctaBtnIcon} />
          </span>
          <span className="btn-wrapper--label">{this.props.ctaBtnLabel}</span>
        </Button>

        <Modal
          isOpen={this.state.modalOpen}
          toggle={this.toggleModal}
          className={"modal-dialog modal-dialog-centered"}
          contentClassName={"newexpensemodal"}
        >
          {this.renderVideoPlayer()}
        </Modal>
      </Fragment>
    );
  }
}

export default VideoPlayer;
