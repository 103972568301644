import { RUN_REPORT, EXPORT_TO_EXCEL } from "../actionTypes";

export const runReport = (data, dispatch) => {
  dispatch({
    type: RUN_REPORT,
    payload: data
  });
};

export const exportToExcel = (data, dispatch) => {
  dispatch({
    type: EXPORT_TO_EXCEL,
    payload: data
  });
};
