import React, { PureComponent } from "react";
import {
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown
} from "reactstrap";

class renderInputGroupButtonDropdown extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false
    };
  }

  getItems = () => {
    const {
      options,
      field,
      form: { setFieldValue }
    } = this.props;
    return options.map(({ value, label }, i) => (
      <DropdownItem
        className="custom-dropdown-item"
        value={value}
        onClick={({ target }) => setFieldValue(field.name, target.value)}
        key={i}
      >
        {label}
      </DropdownItem>
    ));
  };

  toggleDropDown = props => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  };

  render() {
    const { field } = this.props;

    const { dropdownOpen } = this.state;
    return (
      <UncontrolledDropdown
        tag="span"
        className="m-2"
        isOpen={dropdownOpen}
        toggle={this.toggleDropDown}
      >
        <DropdownToggle caret>{field.value}</DropdownToggle>
        <DropdownMenu>{this.getItems()}</DropdownMenu>
      </UncontrolledDropdown>
    );
  }
}

export default renderInputGroupButtonDropdown;
