import React from "react";
import { deleteContact } from "../../actions/contacts";
import { connect } from "react-redux";
import ConfirmModal from "../../shared/components/ConfirmModal";

const DeleteContactModal = ({
  modalOpen,
  toggleModal,
  contact,
  deleteContact,
  refreshContacts
}) => {
  if (!contact) return <div />;
  return (
    <ConfirmModal
      title={"Are you sure to delete this contact?"}
      subtitle={"You cannot undo this operation."}
      type="danger"
      modalOpen={modalOpen}
      onCancel={toggleModal}
      onSubmit={() => {
        deleteContact(contact.slug);
        refreshContacts();
      }}
      btnText={"Delete"}
      btnTextOnLoading={"Deleting"}
    />
  );
};

const mapDispatchToProps = dispatch => ({
  deleteContact: slug => deleteContact(slug, dispatch)
});
export default connect(
  null,
  mapDispatchToProps
)(DeleteContactModal);
