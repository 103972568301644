import React, { PureComponent } from "react";
import { Card, Col, Badge } from "reactstrap";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandHoldingUsd } from "@fortawesome/free-solid-svg-icons";
import { faWallet } from "@fortawesome/free-solid-svg-icons";
import { faFunnelDollar } from "@fortawesome/free-solid-svg-icons";

import { CircularProgressbar } from "react-circular-progressbar";

import { formatPercent, formatCurrency } from "../../../helpers";

export default class DashWidget extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired
  };

  render() {
    const { title, data } = this.props;
    if (!data) return <div />;
    if (title === "Equity") {
      let recentDate = Object.keys(data)
        .sort((a, b) => new Date(a) - new Date(b))
        .pop();

      let recentEquity = data[recentDate] ? data[recentDate].equity : 0;
      return (
        <Col md={6} xl={3} lg={3} xs={12}>
          <Card className="py-2 px-4">
            <div className="font-weight-bold font-size-sm text-uppercase text-second mt-2">
              Overall Equity
            </div>
            <div className="my-3">
              <div className="d-flex align-items-center">
                <div className="d-50 rounded border-0 card-icon-wrapper flex-shrink-0 bg-danger text-white btn-icon text-center shadow-danger mr-2">
                  <FontAwesomeIcon
                    icon={faHandHoldingUsd}
                    className="display-5"
                  />
                </div>
                <div className="display-5 font-weight-bold ml-1">
                  {formatCurrency.format(recentEquity || 0)}
                </div>
              </div>
            </div>
          </Card>
        </Col>
      );
    } else if (title === "Cash Flow") {
      let overallCashflow = Object.values(data).reduce(
        (r, a) => r + parseInt(a.cashflow || 0),
        0
      );
      return (
        <Col md={6} xl={3} lg={3} xs={12}>
          <Card className="py-2 px-4">
            <div className="font-weight-bold font-size-sm text-uppercase text-second mt-2">
              Cash Flow
            </div>
            <div className="my-3">
              <div className="d-flex align-items-center">
                <div className="d-50 rounded border-0 card-icon-wrapper flex-shrink-0 bg-first text-white btn-icon text-center shadow-first mr-2">
                  <FontAwesomeIcon icon={faWallet} className="display-5" />
                </div>
                <div className="font-weight-bold">
                  <div className="display-5 font-weight-bold mb-0">
                    {formatCurrency.format(overallCashflow || 0)}
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </Col>
      );
    } else if (title === "Net Operating Income") {
      let overallNOI = Object.values(data).reduce(
        (r, a) => r + parseInt(a.noi || 0),
        0
      );

      return (
        <Col md={6} xl={3} lg={3} xs={12}>
          <Card className="py-2 px-4">
            <div className="font-weight-bold font-size-sm text-uppercase text-second mt-2">
              Net Operating Income
            </div>
            <div className="my-3">
              <div className="d-flex align-items-center">
                <div className="d-50 rounded border-0 card-icon-wrapper flex-shrink-0 bg-primary text-white btn-icon text-center shadow-primary mr-2">
                  <FontAwesomeIcon
                    icon={faFunnelDollar}
                    className="display-5"
                  />
                </div>
                <div className="font-weight-bold">
                  <div className="display-5 font-weight-bold mb-0">
                    {formatCurrency.format(overallNOI || 0)}
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </Col>
      );
    } else if (title === "Investment") {
      let recentDate = Object.keys(data)
        .sort((a, b) => new Date(a) - new Date(b))
        .pop();

      let investmentTotal = data[recentDate] ? data[recentDate].investment : 0;
      return (
        <Col md={6} xl={3} lg={3} xs={12}>
          <Card className="py-2 px-4">
            <div className="font-weight-bold font-size-sm text-uppercase text-second mt-2">
              Total Investment
            </div>
            <div className="my-3">
              <div className="d-flex align-items-center">
                <div className="d-50 rounded border-0 card-icon-wrapper flex-shrink-0 bg-danger text-white btn-icon text-center shadow-danger mr-2">
                  <FontAwesomeIcon
                    icon={faHandHoldingUsd}
                    className="display-5"
                  />
                </div>
                <div className="display-5 font-weight-bold ml-1">
                  {formatCurrency.format(investmentTotal || 0)}
                </div>
              </div>
            </div>
          </Card>
        </Col>
      );
    } else if (title === "Performance") {
      let recentDate = Object.keys(data)
        .sort((a, b) => new Date(a) - new Date(b))
        .pop();

      let current = 0,
        pastdue = 0,
        percentCurrent = 0;
      if (data[recentDate]) {
        current = data[recentDate].current;
        pastdue = data[recentDate].pastdue;
        let totalAssets = parseInt(current || 0) + parseInt(pastdue || 0);
        percentCurrent = current / totalAssets;
      }

      return (
        <Col md={6} xl={3} lg={3} xs={12}>
          <Card className="py-2 px-4">
            <div className="font-weight-bold font-size-sm text-uppercase text-second mt-2">
              Performance
            </div>
            <div className="my-2">
              <div className="d-flex align-items-center">
                <CircularProgressbar
                  value={formatPercent.format(percentCurrent).replace("%", "")}
                  text={formatPercent.format(percentCurrent)}
                  strokeWidth={8}
                  className="circular-progress-sm circular-progress-primary"
                />
                <div className="mx-auto text-uppercase justify-content-center text-black-50 font-size-lg font-weight-bold">
                  <div className="d-flex align-items-center mb-2">
                    <Badge color="success" className="mx-2">
                      {current}
                    </Badge>
                    <span className="d-10 w-auto">Current</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <Badge color="danger" className=" mx-2">
                      {pastdue}
                    </Badge>
                    <span className="d-10 w-auto">Behind</span>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </Col>
      );
    }
  }
}
