import React, { Component } from "react";
import { Row, Col, Card, Button, UncontrolledTooltip } from "reactstrap";
import CountUp from "react-countup";

import { unmaskCurrency } from "../../../helpers/";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";

import cashROITip from "../../../assets/images/tooltips/cash_roi.png";
import equityTip from "../../../assets/images/tooltips/equity.png";
import noiTip from "../../../assets/images/tooltips/noi.png";
import purchaseCapTip from "../../../assets/images/tooltips/purchase_cap.png";
import totalROITip from "../../../assets/images/tooltips/total_roi.png";

const colorCodes = [
  "border-success card-shadow-success",
  "border-first card-shadow-first",
  "border-warning card-shadow-warning",
  "border-info card-shadow-info",
  "border-primary card-shadow-primary"
];

class AssetKPIs extends Component {
  renderTooltip = name => {
    switch (name) {
      case "noi":
        return <img src={noiTip} alt="..." />;
      case "cash_roi":
        return <img src={cashROITip} alt="..." />;
      case "total_roi":
        return <img src={totalROITip} alt="..." />;
      case "purchase_cap":
        return <img src={purchaseCapTip} alt="..." />;
      case "equity":
        return <img src={equityTip} alt="..." />;
      default:
        return null;
    }
  };
  renderKPI = (idx, name, value, slug) => {
    let cta = null;
    if (!name) return;

    if (!isFinite(value)) value = 0;

    let prefix = "$";
    let suffix = "";
    let decimalDigits = 2;
    let decimals = ".";
    let intValue = 0;

    if (
      [
        "loan_to_value",
        "interest_rate",
        "total_roi",
        "purchase_cap",
        "cash_roi",
        "annual_coc",
        "projected_annualized_irr"
      ].includes(name)
    ) {
      prefix = "";
      suffix = "%";
      intValue = unmaskCurrency(value);
    } else if (["payments_remaining"].includes(name)) {
      prefix = "";
      decimalDigits = 0;
      intValue = unmaskCurrency(value);
    } else {
      intValue = unmaskCurrency(value);
    }

    let topMargin = "mt-1";
    if (
      [
        "interest_rate",
        "total_roi",
        "purchase_cap",
        "cash_roi",
        "equity",
        "noi",
        "monthly_payment"
      ].includes(name)
    ) {
      topMargin = "mt-4";
    }
    cta = (
      <h3
        className={`font-weight-bold display-5 ${topMargin} text-black text-center`}
      >
        <span>
          <CountUp
            start={(intValue || 0) * 0.8}
            end={intValue || 0}
            duration={1}
            deplay={1}
            separator=","
            decimals={decimalDigits}
            decimal={decimals}
            prefix={prefix}
            suffix={suffix}
          />
        </span>
        <small className="opacity-6 pl-1 text-black-50" />
      </h3>
    );

    const style = colorCodes[idx];

    return (
      <Col xl="2" md="6" key={`kpi-index-${idx}`}>
        <Card
          className={`card-box card-box-border-bottom ${style} mb-5 card`}
          style={{ minHeight: "100px" }}
        >
          <div className="card-tr-actions">
            <Button
              color="link"
              className="btn-link-primary p-0 font-size-xl text-primary shadow-none"
            >
              {this.renderTooltip(name) && (
                <FontAwesomeIcon
                  icon={faInfo}
                  className="font-size-sm"
                  id={`kpi-${name}-tooltip`}
                />
              )}
              {this.renderTooltip(name) && (
                <UncontrolledTooltip
                  trigger="hover"
                  placement="top"
                  container="body"
                  target={`kpi-${name}-tooltip`}
                  popperClassName="tooltip-md tooltip-secondary"
                >
                  {this.renderTooltip(name)}
                </UncontrolledTooltip>
              )}
            </Button>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="p-3">
              <div className=" text-uppercase font-size-sm">
                {name.replace(/_/g, " ")}
              </div>
            </div>
          </div>
          {cta}
        </Card>
      </Col>
    );
  };

  render() {
    const { data, slug } = this.props;
    return (
      <Row className="mx-5 justify-content-between align-items-center no-gutters">
        {Object.keys(data).map((key, idx) => {
          return this.renderKPI(idx, key, data[key], slug);
        })}
      </Row>
    );
  }
}
export default AssetKPIs;
