import React, { PureComponent } from "react";
import { CustomInput } from "reactstrap";
// import { getIn } from "formik";

class CustomInputField extends PureComponent {
  render() {
    const {
      field
      // form: { touched, errors }
    } = this.props;
    // const touch = getIn(touched, field.name);
    // const error = getIn(errors, field.name);
    return <CustomInput {...this.props} onChange={field.onChange} />;
  }
}
export default CustomInputField;
