import React, { PureComponent } from "react";

import { GridLoader } from "react-spinners";

export default class LoadingIndicator extends PureComponent {
  render() {
    return (
      <div className="d-flex container d-flex h-100 w-100 justify-content-center">
        <div
          className="d-flex align-self-center justify-content-center"
          style={{ width: "150px", height: "80px" }}
        >
          <GridLoader color={"#3c44b1"} loading={this.props.loading} />
        </div>
      </div>
    );
  }
}
