import React, { useState, useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { isEmpty } from "lodash";

import AddSubscription from "./AddSubcription";
import SectionHeader from "../../shared/components/SectionHeader";
import LoadingIndicator from "../../shared/components/LoadingIndicator";
import ConfirmModal from "../../shared/components/ConfirmModal";

import {
  getPlans,
  getPaymentMethods,
  createSubscription,
  updateSubscription,
  cancelSubscription
} from "../../actions/subscriptions";
import { isFetching, isSuccess, isError } from "../../reducers/reducerUtils";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PRODUCT_ID = "612d430943d3f446f5b79c68";
const Subscriptions = ({
  plans,
  allPaymentMethods,
  paymentMethod,
  getPaymentMethods,
  getPlans,
  subscription,
  history,
  createSubscription,
  updateSubscription,
  cancelSubscription
}) => {
  const query = useQuery();
  const [pricingPlans, setPricingPlans] = useState([]);
  const [addOns, setAddons] = useState({});
  const [activePlan, setActivePlan] = useState({});
  const [activeSubscription, setActiveSubscription] = useState({});
  const [selectedPlan, setSelectedPlan] = useState({});
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loadingPlan, setLoadingPlan] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  useEffect(() => {
    getPlans(PRODUCT_ID);
    getPaymentMethods();
  }, []);
  useEffect(
    () => {
      if (isFetching(plans) || isFetching(allPaymentMethods)) {
        setIsLoading(true);
        setLoadingPlan(true);
      }
      if (!isFetching(plans) && isSuccess(plans)) {
        const { data } = plans;
        if (
          Object.keys(data).includes("plans") &&
          Object.keys(data).includes("addOns") &&
          Object.keys(data).includes("activePlan")
        ) {
          const { plans, addOns, activePlan, subscription } = data;
          if (Object.keys(activePlan).length > 0) {
            setActivePlan(activePlan);
            setSelectedPlan({
              id: activePlan.id,
              price:
                activePlan.plan_code === "pro" && activePlan.price === 0
                  ? plans.data[1].price
                  : activePlan.price,
              plan_code: activePlan.plan_code
            });
            setActiveSubscription(subscription);
            setLoadingPlan(false);
          } else {
            let defaultPlan = plans.data[0];
            if (query.get("type") === "pro") {
              defaultPlan = plans.data.find(p => p.plan_code === "pro");
            }
            setActivePlan({});
            setActiveSubscription({});
            setSelectedPlan({
              id: defaultPlan.id,
              price: defaultPlan.price,
              plan_code: defaultPlan.plan_code
            });
            setLoadingPlan(false);
          }
          if (addOns.data.length > 0) {
            let addOnData = addOns.data.map(addOn => {
              return {
                checked: false,
                quantity: 1,
                id: addOn.id,
                name: addOn.name,
                price: addOn.price
              };
            });
            setAddons(addOnData);
          }
          let allPlans = [];
          plans.data.forEach(plan => {
            if (plan.plan_active === "true") {
              allPlans.push(plan);
            }
          });
          setPricingPlans(allPlans);
          setIsLoading(false);
        }
      }

      if (!isFetching(allPaymentMethods) && isSuccess(allPaymentMethods)) {
        const { data } = allPaymentMethods;
        if (Object.keys(data).includes("paymentMethods")) {
          const { paymentMethods } = data;
          setPaymentMethods(paymentMethods);
          setCurrentPaymentMethod(paymentMethods[0]);
        }
      }

      if (isError(plans) || isError(allPaymentMethods)) {
        setLoadingPlan(false);
        setIsLoading(false);
      }
    },
    [plans, allPaymentMethods]
  );

  useEffect(
    () => {
      if (isFetching(subscription)) setLoadingPlan(true);
      if (!isFetching(subscription) && isSuccess(subscription)) {
        const { data, type } = subscription;
        if (type === "DELETE") {
          setDeleting(false);
          setShowModal(false);
          getPlans(PRODUCT_ID);
        } else {
          if (Object.keys(data).includes("data")) {
            const { plan, subscription } = data.data;
            setActivePlan(plan);
            setActiveSubscription(subscription);
            setSelectedPlan({
              id: plan.id,
              price:
                plan.plan_code === "pro" && plan.price === 0
                  ? 29.97
                  : plan.price,
              plan_code: plan.plan_code
            });
            setLoadingPlan(false);
          }
        }
      }
      if (isError(subscription)) setLoadingPlan(false);
    },
    [subscription]
  );

  useEffect(
    () => {
      if (!isFetching(paymentMethod) && isSuccess(paymentMethod)) {
        const { type, data } = paymentMethod;
        if (type === "ADD") {
          setPaymentMethods(prevMethods => [...prevMethods, data.data]);
          setCurrentPaymentMethod(data.data);
        }
      }
    },
    [paymentMethod]
  );

  const updateAddOn = (id, type, value) => {
    const addOnData = addOns.map(addOn => {
      if (addOn.id === id) {
        switch (type) {
          case "checked":
            addOn.checked = value;
            break;
          case "quantity":
            addOn.quantity = value;
            break;
          default:
            break;
        }
      }
      return addOn;
    });

    setAddons(addOnData);
  };

  const createNewSubscription = () => {
    if (paymentMethods.length > 0) {
      let isAddOnIncluded = false;
      addOns.forEach(addOn =>
        addOn.checked ? (isAddOnIncluded = true) : isAddOnIncluded
      );

      let gatewayInfo = currentPaymentMethod.gateway;
      if (!gatewayInfo) {
        gatewayInfo = {
          type: currentPaymentMethod.type,
          id: currentPaymentMethod.id
        };
      }

      const data = {
        planId: selectedPlan.id,
        addOns: isAddOnIncluded ? addOns : [],
        productId: PRODUCT_ID,
        price: selectedPlan.price,
        gatewayInfo
      };

      if (isEmpty(activeSubscription)) {
        createSubscription(data);
      } else {
        data.subscriptionId = activeSubscription.id;
        data.paymentMethodId = currentPaymentMethod.id;
        updateSubscription(data);
      }
    }
  };

  const cancelUserSubscription = () => {
    const data = {
      cancel_at_end: "true"
    };
    if (Object.keys(activePlan).length !== 0) {
      setDeleting(true);
      cancelSubscription(data);
    }
  };
  if (isLoading) return <LoadingIndicator />;
  return (
    <main className="p-5">
      <SectionHeader titleHeading={"Subscriptions"} />
      <AddSubscription
        pricingPlans={pricingPlans}
        selectedPlan={selectedPlan}
        setSelectedPlan={setSelectedPlan}
        activePlan={activePlan}
        loadingPlans={loadingPlan}
        addOns={addOns}
        updateAddOn={updateAddOn}
        createNewSubscription={createNewSubscription}
        cancelUserSubscription={cancelUserSubscription}
        paymentMethods={paymentMethods}
        showDeleteModal={setShowModal}
        currentPaymentMethod={currentPaymentMethod}
        setCurrentPaymentMethod={setCurrentPaymentMethod}
      />

      <ConfirmModal
        title={"Are you sure you want to cancel your subscription?"}
        subtitle={
          "All the data and integrations that doesn't meet the free subscription will be deleted."
        }
        type="danger"
        modalOpen={showModal}
        onCancel={() => setShowModal(prevState => !prevState)}
        onSubmit={() => cancelUserSubscription()}
        error={false}
        loading={deleting}
        btnText={"Yes"}
        btnTextOnLoading={"Cancelling"}
      />
    </main>
  );
};

const mapStateToProps = state => {
  return {
    plans: state.subscriptions.plans,
    subscription: state.subscriptions.subscription,
    allPaymentMethods: state.subscriptions.paymentMethods,
    paymentMethod: state.subscriptions.paymentMethod,
    auth: state.login
  };
};

const mapDispatchToProps = dispatch => ({
  getPlans: productId => getPlans(dispatch, productId),
  getPaymentMethods: () => getPaymentMethods(dispatch),
  createSubscription: data => createSubscription(dispatch, data),
  updateSubscription: data => updateSubscription(dispatch, data),
  cancelSubscription: data => cancelSubscription(dispatch, data)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Subscriptions));
