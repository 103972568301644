import React, { Component } from "react";
import { connect } from "react-redux";
import { getEntities, addEntity } from "../../actions/asset/assetAction";
import { isInit, isFetching, isSuccess } from "../../reducers/reducerUtils";
import _ from "lodash";
import LoadingIndicator from "../../shared/components/LoadingIndicator";
import DeleteEntity from "./DeleteEntity";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import TableWithSearch from "../../shared/components/TableWithSearch";

class Entities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      adding: false,
      entities: [],
      options: [],
      newEntityName: null,
      filterOpen: false,
      selectedRow: null,
      actionType: null
    };
  }

  columnProps = [
    {
      dataField: "name",
      text: "Entity Name",
      sort: true
    },
    {
      dataField: "assets",
      text: "No of assets",
      sort: true,
      formatter: (cell, row) => {
        return cell && cell.length;
      }
    },
    {
      text: "Actions",
      dataField: "",
      editable: false,
      formatter: (cell, row) => {
        if (row.assets && row.assets.length === 0)
          return (
            <Button
              color="neutral-danger"
              className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center"
              onClick={() => this.handleAction("delete", row)}
            >
              <FontAwesomeIcon icon={faTimes} className="font-size-sm" />
            </Button>
          );
      }
    }
  ];

  componentDidMount() {
    const { entities, getEntities } = this.props;
    if (isInit(entities) && !this.state.loading) {
      this.setState({ loading: true }, () => {
        getEntities();
      });
    }
  }

  handleAction = (type, row) => {
    this.setState({ loading: true, actionType: type, selectedRow: row });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { loading, adding } = prevState;
    const { entities, isTag } = nextProps;

    if (adding && !loading) {
      return { loading: true, adding: false };
    }

    if (loading && !_.isEmpty(entities)) {
      if (!isFetching(entities)) {
        if (isSuccess(entities)) {
          let entityOptions = entities.data
            .filter(entity => entity.isTag === isTag)
            .map(entity => {
              return { label: entity.name, value: entity._id };
            });

          if (nextProps.allOption) {
            if (isTag)
              entityOptions.unshift({ label: "All Tags", value: "All" });
            else entityOptions.unshift({ label: "All Entities", value: "All" });
          }
          return {
            entities: entities.data,
            loading: false,
            options: entityOptions
          };
        } else {
          return {
            error: entities.error,
            loading: false
          };
        }
      }
    } else if (!_.isEmpty(entities) && !isFetching(entities)) {
      if (isSuccess(entities)) {
        let entityOptions = entities.data
          .filter(entity => entity.isTag === isTag)
          .map(entity => {
            return { label: entity.name, value: entity._id };
          });
        if (nextProps.allOption) {
          if (isTag) entityOptions.unshift({ label: "All Tags", value: "All" });
          else entityOptions.unshift({ label: "All Entities", value: "All" });
        }

        let returnJson = {
          entities: entities.data,
          options: entityOptions
        };
        if (entities.type === "DELETE" && prevState.actionType === "delete") {
          returnJson.actionType = null;
          returnJson.selectedRow = null;
        }
        return returnJson;
      }
    }
    return null;
  }

  handleTableChange = props => {};
  handleResetQueryParams = props => {};
  render() {
    const { loading, entities } = this.state;

    if (loading) return <LoadingIndicator />;
    else
      return (
        <>
          <TableWithSearch
            keyField="id"
            data={entities}
            totalSize={entities.length}
            columns={this.columnProps}
            onTableChange={this.handleTableChange}
            resetQueryParams={this.handleResetQueryParams}
            enableCellEdit={false}
            remoteData={false}
            searchPlaceholder={"Search Entity..."}
            noDataIndicator={"No entity found!"}
          />
          <DeleteEntity
            entity={this.state.selectedRow}
            modalOpen={this.state.actionType === "delete"}
            toggleModal={() => this.handleAction(null, null)}
          />
        </>
      );
  }
}

const mapStateToProps = state => ({
  entities: state.asset.entities
});

const mapDispatchToProps = dispatch => {
  return {
    getEntities: () => getEntities(dispatch),
    addEntity: data => addEntity(data, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Entities);
