/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Badge } from "reactstrap";
import AddContact from "./AddContact";
import UpdateContact from "./UpdateContact";
import DeleteContact from "./DeleteContact";
import ContactAction from "./ContactAction";

import { connect } from "react-redux";
import ScaleLoader from "../../../shared/components/ScaleLoader";
import TableWithSearch from "../../../shared/components/TableWithSearch";
import { isFetching, isSuccess } from "../../../reducers/reducerUtils";

import {
  getAssetContacts,
  getContactConversation
} from "../../../actions/contacts";
import { getUserRole } from "../../../helpers/constants";

class Contacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 10,
      loading: true,
      assetSlug: props.match.params.asset,
      contacts: [],
      allContacts: [],
      searchText: null,
      selectedRow: null,
      actionType: null,
      loadingConversation: false,
      conversationId: null,
      redirectToChat: false
    };
  }

  componentDidMount() {
    const { getAssetContacts } = this.props;
    const { assetSlug } = this.state;
    getAssetContacts({ slug: assetSlug });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.redirectToChat && this.state.redirectToChat) {
      this.props.history.push(`/chats/${this.state.conversationId}`);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { contacts, conversation } = nextProps;
    if (prevState.selectedRow) {
      if (!prevState.loadingConversation && isFetching(conversation)) {
        return {
          loadingConversation: true,
          redirectToChat: false,
          conversationId: null
        };
      } else {
        if (isSuccess(conversation)) {
          return {
            conversationId: conversation.data._id,
            loadingConversation: false,
            redirectToChat: true
          };
        }
      }
    }
    if (
      contacts.loadingContactsSuccess === true ||
      contacts.length !== prevState.contacts.length
    ) {
      return {
        loading: false,
        contacts: contacts.contacts,
        allContacts: contacts.contacts
      };
    }
  }
  handleResetQueryParams = () => {};
  handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, searchText, data, cellEdit }
  ) => {
    this.setState({ page, sizePerPage });
  };

  columnProps = [
    {
      dataField: "email",
      text: "Email",
      sort: true
    },
    {
      dataField: "first_name",
      text: "First Name",
      sort: true
    },
    {
      dataField: "last_name",
      text: "Last Name",
      sort: true
    },
    {
      dataField: "role",
      text: "Role",
      sort: true,
      formatter: (cell, row) => {
        return <Badge color={"success"}>{getUserRole(row.role)}</Badge>;
      }
    },
    {
      text: "Actions",
      dataField: "",
      editable: false,
      formatter: (cell, row) => {
        return (
          <ContactAction
            row={row}
            asset={this.state.assetSlug}
            onSelect={type => this.handleAction(type, row)}
            startConversation={() => this.startConversation(row)}
          />
        );
      }
    }
  ];

  startConversation = row => {
    this.setState({ selectedRow: row }, () => {
      this.props.getContactConversation({ slug: row.contactSlug });
    });
  };

  handleAction = (type, row) => {
    this.setState({ actionType: type, selectedRow: row });
  };

  closeModal = () => {
    this.setState({ selectedRow: null, actionType: null });
  };

  render() {
    const {
      loading,
      assetSlug,
      contacts,
      actionType,
      selectedRow,
      page,
      sizePerPage
    } = this.state;

    return (
      <div className="px-4">
        {loading ? (
          <ScaleLoader />
        ) : (
          <TableWithSearch
            keyField="_id"
            data={contacts}
            totalSize={contacts.length}
            page={page}
            sizePerPage={sizePerPage}
            columns={this.columnProps}
            onTableChange={this.handleTableChange}
            resetQueryParams={this.handleResetQueryParams}
            enableCellEdit={false}
            remoteData={false}
            searchPlaceholder={"Search Contacts..."}
            noDataIndicator={"No contacts found!"}
          >
            <AddContact assetSlug={assetSlug} />
          </TableWithSearch>
        )}
        {selectedRow && (
          <>
            <UpdateContact
              modalOpen={actionType === "edit"}
              toggleModal={this.closeModal}
              assetSlug={assetSlug}
              contact={selectedRow}
            />
            <DeleteContact
              modalOpen={actionType === "delete"}
              toggleModal={this.closeModal}
              assetSlug={assetSlug}
              contact={selectedRow}
            />
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  checkAsset: state.checkAsset,
  contacts: state.contacts,
  auth: state.login,
  conversation: state.conversation.conversation
});

const mapDispatchToProps = dispatch => ({
  getAssetContacts: data => getAssetContacts(data, dispatch),
  getContactConversation: data => getContactConversation(data, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Contacts);
