import React, { PureComponent } from "react";
import { Card } from "reactstrap";
import PropTypes from "prop-types";
import Chart from "react-apexcharts";
import { formatCurrency } from "../../../helpers";

export default class AreaChartWidget extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    type: PropTypes.oneOf(["income", "expense"])
  };

  getChartOptions = (color, labels) => {
    return {
      colors: [color],
      chart: {
        toolbar: {
          show: false
        },
        sparkline: {
          enabled: true
        }
      },
      dataLabels: {
        enabled: false
      },
      markers: {
        size: 0
      },
      stroke: {
        color: color,
        width: 3
      },
      legend: {
        show: false
      },
      xaxis: {
        crosshairs: {
          width: 0
        }
      },
      yaxis: {
        min: 0
      },
      labels: labels
    };
  };

  render() {
    const { title, data, labels } = this.props;

    const borderClass =
      this.props.type === "expense" ? "border-danger" : "border-success";
    const textClass =
      this.props.type === "expense" ? "text-danger" : "text-success";
    const chartColor = this.props.type === "expense" ? "#f83245" : "#1bc943";
    const total = data.reduce((a, b) => a + b, 0);
    return (
      <Card
        className={`card-box shadow-lg card-box-border-bottom rounded hover-scale-sm ${borderClass}`}
      >
        <div className="p-4 text-left">
          <div className={`display-3 ${textClass} font-weight-bold`}>
            {formatCurrency.format(total)}
          </div>
          <div className="font-size-xxl font-weight-bold text-black">
            {title}
          </div>
        </div>
        <div className="rounded-bottom overflow-hidden ">
          <Chart
            options={this.getChartOptions(chartColor, labels)}
            series={[{ name: title, data: data }]}
            type="area"
            height={80}
          />
        </div>
      </Card>
    );
  }
}
