/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import holderImage from "../../img/property_placeholder.jpg";
import {
  capAllWords,
  formatCurrency,
  getAssetStyleClass,
  getAssetTypeLabel
} from "../../helpers";
import { getDeals } from "../../actions/deal";

class Note extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPostDeal: false
    };
  }
  url = window.location.origin;

  componentWillMount() {
    this.props.getDeals("note");
  }

  toggleShowPostDeal = () => {
    this.setState(prevState => ({ showPostDeal: !prevState.showPostDeal }));
  };

  render() {
    const { dealProp } = this.props;
    const deals = dealProp.deals;

    return (
      <div>
        <div className="tab-content border-top border-0" id="myTabContent">
          <div
            className="tab-pane fade show active border-0"
            id="resentblog"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            {/* <h3 className="pb-3 webinartitle">
              Properties you plan to buy, rehab, rent, refinance and repeat (BRRRR method).</h3> */}
            <div className="row">
              {deals.length === 0 && <p>No Notes available</p>}

              {deals.map((asset, i) => (
                <Link
                  className="col-sm-12 col-md-6 col-lg-6 col-xl-6"
                  to={`/deals/${asset.slug}`}
                  key={i}
                >
                  <div className="assets_block">
                    <div className="assets_info">
                      <div className="assets_img">
                        <img
                          src={asset.images}
                          alt=""
                          onError={e => {
                            e.target.src = holderImage;
                          }}
                        />
                        <div
                          className={`sidebar-image-overlay ${getAssetStyleClass(
                            asset.assetType
                          )}`}
                        >
                          <div className="image-overlay-type">
                            {getAssetTypeLabel(asset.dealType)}
                          </div>
                        </div>
                      </div>
                      <div className="assets_name">
                        <h4 className=" pt-0 pt-md-2">
                          {capAllWords(asset.asset_name)}
                        </h4>
                        <h5 className=" pt-0 pt-md-2">
                          Investment Amount:{" "}
                          {formatCurrency.format(asset.investmentAmount)}
                        </h5>

                        <p>
                          <i className="fas fa-map-marker-alt pr-1" />
                          {asset.address}
                        </p>
                      </div>
                    </div>
                    <div className="assets_card_info">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="pl-4">Cash Flow</th>
                            <th>Interest Rate</th>
                            <th>Loan to Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="pl-4">
                              {" "}
                              {formatCurrency.format(
                                (asset.basicInfo.investmentAmount *
                                  asset.basicInfo.interestRate) /
                                  100
                              )}
                            </td>
                            <td>{asset.basicInfo.interestRate}%</td>

                            <td>
                              {(asset.basicInfo.marketValue * 100) /
                                asset.basicInfo.investmentAmount}
                              %
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Link>
              ))}
              {/* <PostDealPlaceholder toggleShowPostDeal={this.toggleShowPostDeal}/> */}
            </div>
          </div>
        </div>
        {/* <PostDeal
          modalOpen={this.state.showPostDeal}
          toggleModal={this.toggleShowPostDeal}
        />*/}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  dealProp: state.deal
});

const mapDispatchToProps = dispatch => ({
  getDeals: query => getDeals(query, dispatch)
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Note);
