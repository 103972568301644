import React, { useState, useEffect } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { getLinkToken } from "../../actions/accounts/accountActions";
import { isFetching, isSuccess } from "../../reducers/reducerUtils";
import PlaidLinkButton from "./PlaidLinkButton";

const AddAccount = ({ getLinkToken, accountLink, onSuccess, disabled }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const [fetching, setFetching] = useState(false);
  const [linkToken, setLinkToken] = useState(null);

  const fetchLinkToken = type => {
    setFetching(true);
    getLinkToken({
      liabilities: type === "liabilities"
    });
  };

  useEffect(
    () => {
      const { linkToken } = accountLink;
      if (fetching && !isFetching(linkToken)) {
        if (isSuccess(linkToken)) {
          setDropdownOpen(false);
          setFetching(false);
          setLinkToken(linkToken.data.userLinkToken);
        }
      }
    },
    [accountLink]
  );

  return (
    <>
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle
          color="primary"
          className="btn-pill no-caret"
          disabled={disabled}
        >
          <span className="btn-wrapper--icon">
            <FontAwesomeIcon icon={faPlus} />
          </span>
          <span className="btn-wrapper--label">Add Account</span>
        </DropdownToggle>
        <DropdownMenu right className="overflow-hidden p-0">
          <Nav pills className="nav-neutral-first flex-column pt-2 pb-3">
            <NavItem>
              <NavLink
                href="#/"
                onClick={() => {
                  fetchLinkToken("banks");
                }}
              >
                <span>Bank Account</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#/"
                onClick={() => {
                  fetchLinkToken("liabilities");
                }}
              >
                <span>Loan Account</span>
              </NavLink>
            </NavItem>
          </Nav>
        </DropdownMenu>
      </Dropdown>

      {linkToken && (
        <PlaidLinkButton
          linkToken={linkToken}
          autoOpen={true}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  accountLink: state.accountLink
});

const mapDispatchToProps = dispatch => ({
  getLinkToken: data => getLinkToken(data, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddAccount);
