import { put, call, takeEvery, takeLatest } from "redux-saga/effects";
import { Integration } from "../api/agent";
import showToast from "../helpers/toastNotification";

import {
  ADD_INTEGRATION,
  ADD_INTEGRATION_SUCCESS,
  ADD_INTEGRATION_FAILURE,
  UPDATE_INTEGRATION,
  UPDATE_INTEGRATION_SUCCESS,
  UPDATE_INTEGRATION_FAILURE,
  GET_INTEGRATIONS,
  GET_INTEGRATIONS_SUCCESS,
  GET_INTEGRATIONS_FAILURE,
  DELETE_INTEGRATION,
  DELETE_INTEGRATION_SUCCESS,
  DELETE_INTEGRATION_FAILURE,
  SYNC_INTEGRATION,
  SYNC_INTEGRATION_SUCCESS,
  SYNC_INTEGRATION_FAILURE
} from "../actions/actionTypes";

function* getIntegrations(action) {
  try {
    const payload = yield call(Integration.getIntegrations, action.payload);
    yield put({ type: GET_INTEGRATIONS_SUCCESS, payload });
  } catch (error) {
    const err = error.response.body;
    yield put({ type: GET_INTEGRATIONS_FAILURE, err });
  }
}
export function* watchGetIntegrations() {
  yield takeLatest(GET_INTEGRATIONS, getIntegrations);
}

export function* addIntegration(action) {
  try {
    const payload = yield call(Integration.addIntegration, action.payload);
    yield put({ type: ADD_INTEGRATION_SUCCESS, payload });
    showToast("s", "Integration Added Successfully");
  } catch (error) {
    const err = error.response.body;
    showToast("e", err.message || "Unable To Add Integration");
    yield put({ type: ADD_INTEGRATION_FAILURE, err });
  }
}
export function* watchAddIntegration() {
  yield takeEvery(ADD_INTEGRATION, addIntegration);
}

export function* updateIntegration(action) {
  try {
    const payload = yield call(Integration.updateIntegration, action.payload);
    yield put({ type: UPDATE_INTEGRATION_SUCCESS, payload });
    showToast("s", "Integration Updated Successfully");
  } catch (error) {
    const err = error.response.body;
    showToast("e", err.message || "Unable To Add Integration");
    yield put({ type: UPDATE_INTEGRATION_FAILURE, err });
  }
}
export function* watchUpdateIntegration() {
  yield takeEvery(UPDATE_INTEGRATION, updateIntegration);
}

function* deleteIntegration(action) {
  try {
    const payload = yield call(Integration.deleteIntegration, action.payload);
    showToast("s", "Integration Deleted Successfully");
    yield put({ type: DELETE_INTEGRATION_SUCCESS, payload });
  } catch (error) {
    const err = error.response.body;
    showToast("e", err.message || "Unable To Delete Integration");
    yield put({ type: DELETE_INTEGRATION_FAILURE, err });
  }
}
export function* watchDeleteIntegration() {
  yield takeLatest(DELETE_INTEGRATION, deleteIntegration);
}

function* syncIntegration(action) {
  try {
    const payload = yield call(Integration.syncIntegration, action.payload);
    showToast("s", "Integration Synced Successfully");
    yield put({ type: SYNC_INTEGRATION_SUCCESS, payload });
  } catch (error) {
    const err = error.response.body;
    showToast("e", err.message || "Unable To Sync Integration");
    yield put({ type: SYNC_INTEGRATION_FAILURE, err });
  }
}
export function* watchSyncIntegration() {
  yield takeLatest(SYNC_INTEGRATION, syncIntegration);
}
