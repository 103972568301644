import React from "react";

import { Row } from "reactstrap";
import PMCard from "./PMCard";
import AddPropertyManager from "./AddPropertyManager";

const PropertyManagers = ({
  integrations,
  currentFilter,
  setCurrentFilter,
  syncIntegration
}) => {
  const pms = ["rentmanager", "propertyware"];

  const { data } = integrations;
  const pmIntegrations = data.filter(i => pms.includes(i.type));
  return pmIntegrations.length > 0 ? (
    <div className="my-5">
      <div className="text-center">
        <AddPropertyManager />
      </div>

      <Row className="p-3">
        {pmIntegrations.map((integration, idx) => {
          return (
            <PMCard
              key={idx}
              integration={integration}
              currentFilter={currentFilter}
              setCurrentFilter={setCurrentFilter}
              syncIntegration={syncIntegration}
            />
          );
        })}
      </Row>
    </div>
  ) : (
    <div className="text-center my-5">
      <AddPropertyManager />
    </div>
  );
};

export default PropertyManagers;
