import React from "react";
import { Card, CardBody, CardImg } from "reactstrap";

const OpportunityCard = ({ title, medias }) => {
	return (
		<>
			<Card className="owned-card">
				<CardImg
					top
					src={`https://cms.bettercapital.us${medias[0].url}`}
					className="card-img"
					alt=""
				/>
				<CardBody>
					<h6>{title}</h6>
				</CardBody>
			</Card>
		</>
	);
};

export default OpportunityCard;
