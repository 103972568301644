import React, { Component } from "react";
import { Modal, ModalHeader } from "reactstrap";
import FileViewer from "react-file-viewer";
import {
  getAttachmentUrl,
  getDocumentUrl
} from "../../../actions/attachments/attachmentAction";
import PerfectScrollbar from "react-perfect-scrollbar";
import { isFetching, isSuccess } from "../../../reducers/reducerUtils";

import { connect } from "react-redux";

class ViewDocument extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
      fetchUrl: false,
      ext: null
    };
  }

  onError = () => {
    this.setState({
      error: "An error occured while loading this image. Try again"
    });
  };

  sort = () => {};

  componentWillUnmount() {
    // Make sure to revoke the data uris to avoid memory leaks
    URL.revokeObjectURL(this.state.fileUrl);
  }

  componentDidMount() {
    const { selectedFileToView } = this.props;
    this.setState(
      {
        error: null,
        fileUrl: null,
        selectedFileToView: selectedFileToView,
        ext: selectedFileToView.originalName.substr(
          selectedFileToView.originalName.lastIndexOf(".") + 1
        )
      },
      () => {
        this.getAttachmentUrl(
          this.props.assetSlug,
          this.props.selectedFileToView._id
        );
      }
    );
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { document } = nextProps;
    if (prevState.loading && document.type === "GET_URL") {
      if (!isFetching(document)) {
        if (isSuccess(document)) {
          return {
            selectedFileToView: prevState.selectedFileToView,
            loading: false,
            fileUrl: document.data.attachment
          };
        } else {
          return {
            selectedFileToView: prevState.selectedFileToView,
            loading: false,
            error: document.error
          };
        }
      }
    } else if (
      !prevState.loading &&
      document.type === "GET_URL" &&
      isFetching(document)
    ) {
      return {
        loading: true,
        fileUrl: null
      };
    }

    return null;
  }

  getAttachmentUrl = (slug, id) => {
    this.props.getDocumentUrl({ slug, id });
  };

  render() {
    if (this.state.loading || !this.state.fileUrl) return <div />;

    return (
      <Modal
        zIndex={2000}
        centered
        size="xl"
        isOpen={this.props.modalOpen}
        toggle={this.props.toggleModal}
      >
        <ModalHeader toggle={this.props.toggleModal}>
          {"View Attachment"}
        </ModalHeader>
        {this.state.loading === false && !this.state.error ? (
          <PerfectScrollbar
            key={`attachment-scrollbar-${this.props.selectedFileToView._id}`}
            className="scroll-area-lg view-attachment"
          >
            <FileViewer
              key={`attachement-viewer-${this.props.selectedFileToView._id}`}
              fileType={this.state.ext}
              filePath={this.state.fileUrl}
              onError={this.onError}
            />
          </PerfectScrollbar>
        ) : (
          <p>Loading</p>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  attachments: state.attachments.attachments,
  document: state.attachments.document
});

const mapDispatchToProps = dispatch => ({
  getAttachmentUrl: data => getAttachmentUrl(data, dispatch),
  getDocumentUrl: data => getDocumentUrl(data, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewDocument);
