import React from "react"
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
   ResponsiveContainer
} from "recharts"

import { yearlyProjection } from "../../helpers/assumptionUtils"

const ProjectionGraph = ({ assumptions, overall = true }) => {
  let projectionsRaw = {
    "Year 1": yearlyProjection(assumptions, 1),
    "Year 2": yearlyProjection(assumptions, 2),
    "Year 5": yearlyProjection(assumptions, 5),
    "Year 10": yearlyProjection(assumptions, 10),
  }

  let projections = Object.keys(projectionsRaw).reduce((r, k) => {
    r.push({
      year: k,
      cashflow: projectionsRaw[k].totalCashflow,
      income: projectionsRaw[k].netIncome,
      expense: projectionsRaw[k].netExpense,
      mortgage: projectionsRaw[k].netDebt,
      equity: projectionsRaw[k].equityAccrued,
    })
    return r
  }, [])

  let projectionsCumm = Object.keys(projectionsRaw).reduce((r, k) => {
    let data = {}
    data = {
      year: k,
      "total return": parseInt(projectionsRaw[k].totalReturn),
      cashflow: parseInt(projectionsRaw[k].totalCashflow),
      equity: parseInt(projectionsRaw[k].equityAccrued),
    }

    r.push(data)
    return r
  }, [])
  if (overall) {
    return (
      <>
      <h5>Overall</h5>
          <ResponsiveContainer  width="120%" height={400} className="composedChart">
        <ComposedChart
          width={650}
          height={345}
          data={projections}
          margin={{
            right: 100,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="year" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar stackId="a" dataKey="cashflow" fill="#8884d8" />
          <Bar stackId="a" dataKey="expense" fill="#82ca9d" />
          <Bar stackId="a" dataKey="mortgage" fill="#ffc658" />
          <Line type="monotone" dataKey="income" stroke="#ff7300" />
        </ComposedChart>
      </ResponsiveContainer>
      </>
    )
  } else {
    return (
      <>
      <h5>Cashflow</h5>
      <ResponsiveContainer  width="120%" height={400} className="lineChart">
        <ComposedChart
          width={650}
          height={345}
          data={projections}
          margin={{
            right: 100,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="year" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar stackId="a" dataKey="cashflow" fill="#8884d8" />
          <Bar stackId="a" dataKey="expense" fill="#82ca9d" />
          <Bar stackId="a" dataKey="mortgage" fill="#ffc658" />
          <Line type="monotone" dataKey="income" stroke="#ff7300" />
        </ComposedChart>
      </ResponsiveContainer>
      </>
    )
  }
}

export default ProjectionGraph
