import React, { PureComponent } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Collapse
} from "reactstrap";
import { connect } from "react-redux";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import clsx from "clsx";

import renderDatePickerField from "../../../shared/components/form/DatePicker";
import CustomInputField from "../../../shared/components/form/CustomInputField";
import CategorySelect from "../../../shared/components/form/CategorySelect";
import ToggleSwitch from "../../../shared/components/form/ToggleSwitch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

import FormField from "../../../shared/components/form/FormField";
// import CurrencyField from '../../../shared/components/form/CurrencyField';
import CurrencyField from "../../../shared/components/form/AddonCurrencyField";
import { isFetching, isSuccess } from "../../../reducers/reducerUtils";

import { updateTransaction } from "../../../actions/transaction";
import { unmaskCurrency, getTypeForCategory } from "../../../helpers";

const validationSchema = Yup.object({
  category: Yup.string().required("Category is required"),
  name: Yup.string()
    .max(250, "Transaction name too long")
    .required("Transaction name is required"),
  type: Yup.string().required("Type is required"),
  amount: Yup.string().required("Amount is required"),
  date: Yup.date().required("Date is required"),
  payee: Yup.string().max(150, "Payee name too long"),
  isPaid: Yup.boolean().required("Select transaction status")
});

class UpdateTransaction extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      closeModal: false,
      showMoreInfo: false
    };
  }

  toggleMoreInfo = () => {
    this.setState(prevState => ({
      showMoreInfo: !prevState.showMoreInfo
    }));
  };
  updateTransaction = (values, { resetForm }) => {
    const {
      transaction: { _id },
      assetSlug,
      updateTransaction
    } = this.props;
    values.amount = unmaskCurrency(values.amount);
    values._id = _id;
    values.isPending = !values.isPaid;
    values.type = values.isIncome ? "INCOME" : "EXPENSE";
    this.setState({ loading: true, error: null }, () => {
      updateTransaction({ slug: assetSlug, transaction: values });
    });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      transactions: { transactions }
    } = nextProps;

    if (
      prevState.loading &&
      !isFetching(transactions) &&
      transactions.type === "UPDATE"
    ) {
      if (isSuccess(transactions)) {
        return { loading: false, closeModal: true };
      } else {
        return { loading: false, error: transactions.error };
      }
    }
    return null;
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (!prevState.closeModal && this.state.closeModal) {
      this.props.closeModal();
    }
  };

  render() {
    const {
      transaction,
      transaction: {
        category,
        type,
        amount,
        date,
        name,
        payee,
        isPending,
        duplicate
      }
    } = this.props;

    let initialValues = {
      isIncome: true,
      isPaid: false,
      category: "",
      amount: "",
      date,
      name: "",
      payee: "",
      markAsReviewed: "",
      categoryName: ""
    };
    if (Object.keys(transaction).length > 0) {
      initialValues = {
        category,
        amount,
        type,
        isIncome: type.toLowerCase() === "income",
        date,
        name,
        payee: payee || "",
        isPaid: isPending ? false : true,
        markAsReviewed: false
      };
    }

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={this.updateTransaction}
      >
        {props => {
          const { handleSubmit, setFieldValue } = props;
          const { error, loading } = this.state;
          return (
            <Modal
              isOpen={this.props.showModal}
              className={"modal-dialog modal-dialog-centered"}
              contentClassName={"newexpensemodal"}
              // toggle={this.toggleModal}
            >
              <ModalHeader toggle={this.props.closeModal}>
                Update Transaction
              </ModalHeader>

              <form onSubmit={handleSubmit}>
                <ModalBody>
                  {error && (
                    // <Alert color="danger" className="alert--bordered" icon>
                    <p>
                      <span className="bold-text">Error! </span>
                      {error}
                    </p>
                  )
                  // </Alert>
                  }
                  <div className="form-group row">
                    <label
                      htmlFor="inputPassword"
                      className="col-sm-2 col-form-label"
                    >
                      Amount:
                    </label>
                    <div className="col-sm-10">
                      <div className=" inputfilds-block">
                        <div className="input-group">
                          <Field
                            name="amount"
                            component={CurrencyField}
                            type="text"
                            placeholder="Amount"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="staticEmail"
                      className="col-sm-2 col-form-label"
                    >
                      Category:
                    </label>
                    <div className="col-sm-10 inputblock">
                      <CategorySelect
                        name="category"
                        value={props.values.category}
                        onUpdate={value => {
                          setFieldValue("category", value);
                          setFieldValue("type", getTypeForCategory(value));
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      htmlFor="staticEmail"
                      className="col-sm-2 col-form-label"
                    >
                      Type:
                    </label>
                    <div className="col-sm-10 inputblock">
                      <Field
                        name="isIncome"
                        component={ToggleSwitch}
                        onLabel={"Income"}
                        offLabel={"Expense"}
                        className="w-50 switch-large rc-switch-custom toggle-switch-square toggle-switch-success"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="staticEmail"
                      className="col-sm-2 col-form-label"
                    >
                      Date:
                    </label>
                    <div className="col-sm-10 inputblock">
                      <Field
                        name="date"
                        component={renderDatePickerField}
                        placeholder="Date"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="staticEmail"
                      className="col-sm-2 col-form-label"
                    >
                      Status:
                    </label>
                    <div className="col-sm-10 inputblock">
                      <Field
                        name="isPaid"
                        component={ToggleSwitch}
                        onLabel={"Paid"}
                        offLabel={"Unpaid"}
                        className="w-50 switch-large rc-switch-custom toggle-switch-square toggle-switch-success"
                      />
                    </div>
                  </div>

                  {duplicate === "T" && (
                    <div className="form-group row">
                      <label
                        htmlFor="staticEmail"
                        className="col-sm-2 col-form-label"
                      />
                      <div className="col-sm-10 inputblock">
                        <Field
                          id="markAsReviewed"
                          name="markAsReviewed"
                          component={CustomInputField}
                          className="colored"
                          type="checkbox"
                          inline
                          label="Mark as Reviewed"
                        />
                      </div>
                    </div>
                  )}

                  <div>
                    <div
                      className={clsx(
                        "d-flex container justify-content-center more-info-panel",
                        {
                          "panel-open": this.state.showMoreInfo
                        }
                      )}
                    >
                      <Button
                        color="link"
                        size="lg"
                        onClick={this.toggleMoreInfo}
                      >
                        <span className="btn-wrapper--label">More Info</span>
                        <span className="btn-wrapper--icon">
                          <FontAwesomeIcon
                            icon={faAngleDown}
                            className="font-size-xl accordion-icon"
                          />
                        </span>
                      </Button>
                    </div>
                    <Collapse isOpen={this.state.showMoreInfo}>
                      <div className="form-group row">
                        <label
                          htmlFor="staticEmail"
                          className="col-sm-2 col-form-label"
                        >
                          Description:
                        </label>
                        <div className="col-sm-10 inputblock">
                          <Field
                            name="name"
                            component={FormField}
                            type="text"
                            placeholder="Transaction name"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="staticEmail"
                          className="col-sm-2 col-form-label"
                        >
                          Payee:
                        </label>
                        <div className="col-sm-10 inputblock">
                          <Field
                            name="payee"
                            component={FormField}
                            placeholder="Payee Name"
                          />
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    type="reset"
                    className="btn btn-secondary px-5 d-inline-block mx-2"
                    onClick={this.props.closeModal}
                    disabled={loading}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn savebtns btn-primary px-5  d-inline-block mx-2"
                    type="submit"
                    color="primary"
                    disabled={loading}
                  >
                    {loading ? "Updating" : "Update"}
                  </Button>
                </ModalFooter>
              </form>
            </Modal>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = state => ({
  transactions: state.transactions
});

const mapDispatchToProps = dispatch => ({
  updateTransaction: data => updateTransaction(data, dispatch)
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateTransaction);
