import React, { Component } from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import Loki from "react-loki";
import { withRouter } from "react-router-dom";
import SummaryForm from "./steps/SummaryForm";
import InvestmentForm from "./steps/Investment";
import Financials from "./steps/Financials";
import { Modal, ModalHeader, ModalBody, Badge } from "reactstrap";
import { isFetching, isSuccess } from "../../../reducers/reducerUtils";
import { getAssetStyleClass, getAssetTypeLabel } from "../../../helpers";

import {
  resetAddAsset,
  addAssetAndFinancials
} from "../../../actions/asset/assetAction";

class AddAsset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      page: 1,
      loading: false,
      addingAsset: false,
      assetSlug: null,
      asset: {
        entity: "",
        user_role: "",
        parcel_id: "",
        address: "",
        beds: "",
        baths: "",
        stories: "",
        units: "",
        co_ordinates: [],
        imageUrl: "",
        investment_type: "",
        management_platform: "",
        self_managed: true,
        county: "",
        lotSizeSquareFeet: "",
        yearBuilt: "",
        asset_type: "",
        purchase_date: "",
        purchase_price: "",
        down_payment: "",
        market_value: "",
        market_rent: "",
        property_tax: { amount: "", frequency: "Yearly", isPercent: false },
        insurance: { amount: "", frequency: "Yearly", isPercent: false },
        hoa_fee: { amount: "", frequency: "Monthly", isPercent: false },
        management_fee: { amount: "", frequency: "Monthly", isPercent: false },
        rent: { amount: "", frequency: "Monthly", isPercent: false },
        rents: []
      }
    };
  }

  _mergeValues = values => {
    this.setState({
      asset: {
        ...this.state.asset,
        ...values
      }
    });
  };

  _finishWizard = values => {
    this.setState({ assetSlug: null, addingAsset: true }, () => {
      this.props.addAssetAndFinancials(values);
    });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      asset: { addAssetAndFinancials }
    } = nextProps;
    if (prevState.addingAsset && !isFetching(addAssetAndFinancials)) {
      if (isSuccess(addAssetAndFinancials)) {
        return {
          addingAsset: false,
          assetSlug: addAssetAndFinancials.data.data.slug,
          redirect: false
        };
      }
    }
    return null;
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { addingAsset, assetSlug } = this.state;
    if (!addingAsset && assetSlug && !prevState.assetSlug) {
      this.props.onAddAsset(assetSlug);
      this.props.resetAddAsset();
    }
  };

  _customStepRenderer = ({ currentStep }) => {
    const steps = this.props.import_steps.map((step, index) => {
      const isActive = currentStep === index + 1;
      return (
        <li key={index} className={clsx("card-box", { current: isActive })}>
          <a href="#/" onClick={e => e.preventDefault()}>
            <div className="step-indicator">
              <div className="d-flex align-items-center">
                <div className="font-size-lg d-30 rounded-sm mr-2 stepper-wrapper text-center">
                  <span className="font-weight-bold">{step.number}</span>
                </div>
                <div>
                  <div className="font-weight-bold">{step.label}</div>
                </div>
              </div>
            </div>
          </a>
        </li>
      );
    });

    return (
      <div className="horizontal">
        <ul className="steps-indicator">{steps}</ul>
      </div>
    );
  };

  _customComponentRenderer = props => {
    switch (props.currentStep) {
      case 2:
        return (
          <InvestmentForm
            asset={this.state.asset}
            investment_type={this.props.investment_type}
            user_role={this.props.user_role}
            {...props}
          />
        );
      case 3:
        return (
          <Financials
            asset={this.state.asset}
            investment_type={this.props.investment_type}
            user_role={this.props.user_role}
            {...props}
          />
        );
      case 1:
      default:
        if (this.props.investment_type === "syndication")
          return (
            <InvestmentForm
              asset={this.state.asset}
              investment_type={this.props.investment_type}
              user_role={this.props.user_role}
              {...props}
            />
          );
        else
          return (
            <SummaryForm
              investment_type={this.props.investment_type}
              user_role={this.props.user_role}
              asset={this.state.asset}
              {...props}
            />
          );
    }
  };

  render() {
    return (
      <Modal
        zIndex={2000}
        size={"xl"}
        isOpen={this.props.showModal}
        toggle={this.props.toggleModal}
        contentClassName={"asset-lookup"}
      >
        <ModalHeader toggle={this.props.toggleModal}>
          Add your{" "}
          <Badge
            className="p-2 h-auto font-size-md"
            color={getAssetStyleClass(this.props.investment_type)}
          >
            {getAssetTypeLabel(this.props.investment_type)}
          </Badge>
        </ModalHeader>
        <ModalBody className="wizard-alternate-2 horizontal bg-neutral-dark">
          <Loki
            steps={this.props.import_steps}
            renderSteps={this._customStepRenderer}
            renderComponents={this._customComponentRenderer}
            onNext={this._mergeValues.bind(this)}
            onBack={this._mergeValues.bind(this)}
            onFinish={this._finishWizard.bind(this)}
            noActions
            backLabel={"Previous"}
            nextLabel={"Next"}
            finishLabel={"Submit"}
          />
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  asset: state.asset
});

const mapDispatchToProps = dispatch => ({
  addAssetAndFinancials: data => addAssetAndFinancials(data, dispatch),
  resetAddAsset: () => resetAddAsset(dispatch)
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AddAsset)
);
