import React, { Component } from "react";
import { Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import queryString from "query-string";
import { getCategoryValueFromLabel } from "../../../helpers/constants";

class CustomSearch extends Component {
  constructor(props) {
    super(props);
    let searchText = null;
    if (props.location && props.location.search) {
      searchText = queryString.parse(props.location.search).search;
    }
    this.timeout = 0;
    this.state = {
      resetQueryParams: false,
      searchText: searchText
    };
  }

  doSearch(evt) {
    var searchText = evt.target.value;
    if (!searchText && this.state.searchText) {
      this.props.resetQueryParams && this.props.resetQueryParams();
    }

    // we only need to normalize if the search is transaction related
    const normalizedSearchParams = this.props.normalize
      ? getCategoryValueFromLabel(searchText)
      : searchText;
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({ searchText: normalizedSearchParams }, () => {
        this.props.onSearch(normalizedSearchParams);
      });
    }, 300);
  }

  render() {
    const { placeholder, className } = this.props;

    return (
      <div className="d-flex justify-content-between px-1 py-2">
        <div className={"search-wrapper search-wrapper--grow"}>
          <span className="icon-wrapper text-black">
            <FontAwesomeIcon icon={faSearch} className="mx-auto" />
          </span>
          <Input
            placeholder={placeholder}
            type="search"
            className={className}
            defaultValue={this.state.searchText}
            onChange={e => this.doSearch(e)}
          />
        </div>
        <div className="d-flex align-items-center">{this.props.children}</div>
      </div>
    );
  }
}

export default CustomSearch;
