import React, { PureComponent } from "react";
import { Card, CardHeader, CardBody, Button, Badge } from "reactstrap";
import { formatCurrency } from "../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFunnelDollar } from "@fortawesome/free-solid-svg-icons";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { faRedo } from "@fortawesome/free-solid-svg-icons";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import ImportConfirmModal from "./ImportConfirmModal";
import TableWithSearch from "../../shared/components/TableWithSearch";

class BankTransactions extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: null,

      fetchingBankData: false,
      transactions: [],
      selectedRows: [],
      //transaction state
      transactionSaving: false,

      selectedAccount: "",
      showSaveModal: false,
      selectedAsset: "",
      createFilter: false,
      editFilter: false,
      showImport: false
    };
  }

  columnProps = [
    { dataField: "txnId", hidden: true },
    {
      dataField: "category",
      text: "Category",
      sort: true
    },
    {
      dataField: "name",
      text: "Description",
      sort: true
    },
    {
      dataField: "date",
      text: "Date",
      sort: true
    },
    {
      dataField: "type",
      text: "Type",
      sort: true,
      formatter: (cell, row) => {
        return (
          <Badge color={cell === "INCOME" ? "success" : "danger"}>{cell}</Badge>
        );
      }
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
      formatter: (cell, row) => {
        if (row.amount) {
          return formatCurrency.format(row.amount);
        }
      }
    },
    {
      dataField: "",
      text: "Action",
      align: "center",
      headerStyle: { width: "25em" },
      formatter: (cell, row) => {
        if (row.imported || row.hasFilter)
          return (
            <div className="d-flex align-items-center">
              {row.imported && (
                <Badge
                  color="success"
                  className="h-auto  py-2 px-4 font-size-md mr-2"
                >
                  <FontAwesomeIcon icon={faCheckSquare} className="mr-1" />
                  <span> Imported</span>
                </Badge>
              )}
              {row.hasFilter && (
                <Button
                  className={"btn-neutral-second"}
                  onClick={() => this.toggleEditFilterModal(row)}
                >
                  <span className="btn-wrapper--icon">
                    <FontAwesomeIcon icon={faFunnelDollar} />
                  </span>
                  <span className="btn-wrapper--label">Filter</span>
                </Button>
              )}
            </div>
          );
        return (
          <div className="d-flex align-items-center">
            <Button
              className={"btn-neutral-second mr-2"}
              onClick={() => this.toggleAddFilterModal(row)}
            >
              <span className="btn-wrapper--icon">
                <FontAwesomeIcon icon={faRedo} />
              </span>
              <span className="btn-wrapper--label">Reccuring</span>
            </Button>
            <Button
              className={"btn-neutral-first mr-2"}
              onClick={() => this.toggleImportModal(row)}
            >
              <span className="btn-wrapper--icon">
                <FontAwesomeIcon icon={faDownload} />
              </span>
              <span className="btn-wrapper--label">One-Time</span>
            </Button>
          </div>
        );
      }
    }
  ];

  toggleImportModal = row => {
    this.setState(prevState => ({
      showImport: !prevState.showImport,
      selectedRows: [row]
    }));
  };

  toggleAddFilterModal = row => {
    this.setState(prevState => ({
      createFilter: !prevState.createFilter,
      selectedRows: [row]
    }));
  };

  toggleEditFilterModal = row => {
    this.setState(prevState => ({
      editFilter: !prevState.editFilter,
      selectedRows: [row]
    }));
  };

  handleOnSelect = (row, isSelect) => {
    const { selectedRows } = this.state;
    let rows = selectedRows || [];
    if (isSelect) {
      rows.push(row);
    } else {
      rows = rows.filter(r => r.txnId !== row.txnId);
    }
    this.setState({ selectedRows: rows });
    return true;
  };

  handleOnSelectAll = (isSelect, rows) => {
    this.setState({ selectedRows: isSelect ? rows : [] });
    return true;
  };

  handleAssetChange = ({ target }) => {
    this.setState({
      selectedAsset: target.value
    });
  };

  updateSavedTransactions = () => {
    const { selectedRows, editFilter, createFilter } = this.state;

    let updateType = "import";
    if (editFilter || createFilter)
      updateType = createFilter ? "create_filter" : "update_filter";

    this.setState(
      {
        error: null,
        createFilter: false
      },
      () => {
        this.props.onImport(selectedRows, updateType);
      }
    );
  };

  cancelImport = () => {
    this.setState({
      selectedRows: [],
      createFilter: false,
      editFilter: false,
      showImport: false
    });
  };
  handleResetQueryParams = () => {};
  handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, searchText, data, cellEdit }
  ) => {};

  render() {
    const { parsedTransactions, account } = this.props;
    if (parsedTransactions.length <= 0) return <div />;
    return (
      <Card className="card-box mb-5">
        <CardHeader>
          <div className="card-header--title">
            <h4 className="font-size-lg mb-0 py-2 font-weight-bold">
              Transactions for {account.accountName}
            </h4>
            <p className="text-black-50 mb-0">
              Select relevant transactions to import
            </p>
          </div>
          <div className="card-header--actions">
            <ImportConfirmModal
              assets={this.props.assets}
              onImport={this.updateSavedTransactions}
              onCancel={this.cancelImport}
              selectedAsset={this.state.selectedAsset}
              selectedTrans={this.state.selectedRows}
              accountId={account._id}
              handleAssetChange={this.handleAssetChange}
              bankImport={true}
              createFilter={this.state.createFilter}
              editFilter={this.state.editFilter}
              showImport={this.state.showImport}
            />
          </div>
        </CardHeader>
        <CardBody>
          <TableWithSearch
            keyField="txnId"
            columns={this.columnProps}
            data={parsedTransactions}
            totalSize={parsedTransactions.length}
            searchPlaceholder={"Search Transaction..."}
            noDataIndicator={"No transaction found!"}
            onTableChange={this.handleTableChange}
            resetQueryParams={this.handleResetQueryParams}
            enableCellEdit={false}
            remoteData={false}
          />
        </CardBody>
      </Card>
    );
  }
}

export default BankTransactions;
