import React, { useState } from "react";

import PerfectScrollbar from "react-perfect-scrollbar";
import clsx from "clsx";
import { Collapse, Nav, NavItem, NavLink, Badge } from "reactstrap";
import { Link } from "react-router-dom";
import { getAssetTypeLabel } from "../../../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGlobeAmericas,
  faUniversity,
} from "@fortawesome/free-solid-svg-icons";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { faChartPie } from "@fortawesome/free-solid-svg-icons";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { faComments } from "@fortawesome/free-solid-svg-icons";
import { faAddressBook } from "@fortawesome/free-solid-svg-icons";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";

import queryString from "query-string";

import { ChevronRight } from "react-feather";

const SidebarContent = (props) => {
  const [dashboardOpen, setDashboardOpen] = useState(true);
  const toggleDashboard = (event) => {
    setDashboardOpen(!dashboardOpen);
    if (!dashboardOpen) event.preventDefault();
  };
  const renderNavLink = (id, groupedAsset) => {
    const assetType = groupedAsset[0];
    const assets = groupedAsset[1];
    const { location } = props;
    const searchParams = queryString.parse(location.search);
    return (
      <NavItem key={`dashboard-asset-nav-${id}`}>
        <NavLink
          tag={Link}
          to={`/dashboard?type=${assetType}`}
          className={clsx("nav-link-simple", {
            active: searchParams.type === assetType,
          })}
        >
          <span>{getAssetTypeLabel(assetType)}</span>
          <span className="sidebar-item-label">
            <Badge
              color={`${assets.length > 0 ? "first" : "warning"}`}
              className="mr-5"
            >
              {assets.length}
            </Badge>
          </span>
          <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
            <ChevronRight />
          </span>
        </NavLink>
      </NavItem>
    );
  };

  const groupedAssets = props.assets.reduce((r, a) => {
    r[a.assetType] = [...(r[a.assetType] || []), a];
    return r;
  }, {});

  const { location } = props;

  return (
    <PerfectScrollbar>
      <div className="sidebar-navigation">
        <Nav pills className="nav-neutral-secondary nav-lg nav-alt flex-column">
          <NavItem>
            <NavLink
              tag={Link}
              onClick={toggleDashboard}
              to={`/dashboard`}
              className={clsx("nav-link-simple", {
                active: location.pathname === "/dashboard" || dashboardOpen,
              })}
            >
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={faChartPie} />
              </span>
              <span className="sidebar-item-label">
                Dashboard
                <Badge
                  color={`${props.assets.length > 0 ? "primary" : "danger"}`}
                  className="mr-4"
                >
                  {props.assets.length}
                </Badge>
              </span>
              <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                <ChevronRight />
              </span>
            </NavLink>

            <Collapse isOpen={dashboardOpen}>
              <Nav
                pills
                className="list-unstyled nav-neutral-secondary nav-lg nav-alt flex-column"
              >
                {Object.entries(groupedAssets)
                  .sort((a, b) => b[1].length - a[1].length)
                  .map((groupedAsset, idx) => {
                    return renderNavLink(idx, groupedAsset);
                  })}
              </Nav>
            </Collapse>
          </NavItem>
          {process.env.REACT_APP_SHOW_MARKETPLACE === true && (
            <NavItem>
              <NavLink
                tag={Link}
                className={clsx("nav-link-simple", {
                  active: location.pathname === "/opportunities",
                })}
                to={"/opportunities"}
              >
                <span className="sidebar-icon">
                  <FontAwesomeIcon icon={faGlobeAmericas} />
                </span>
                <span className="sidebar-item-label">
                  Opportunities
                  {/* <Badge color="first" className="mr-4">
                  {props.assets.length}
                </Badge>*/}
                </span>
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRight />
                </span>
              </NavLink>
            </NavItem>
          )}
          <NavItem>
            <NavLink
              tag={Link}
              className={clsx("nav-link-simple", {
                active: location.pathname === "/banks",
              })}
              to="/banks"
            >
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={faUniversity} />
              </span>
              <span className="sidebar-item-label">
                Bank Accounts
                <Badge color="first" className="mr-4">
                  Pro
                </Badge>
              </span>
              <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                <ChevronRight />
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              tag={Link}
              className={clsx("nav-link-simple", {
                active: location.pathname === "/documents",
              })}
              to="/documents"
            >
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={faFolder} />
              </span>
              <span className="sidebar-item-label">
                Documents
                {/* <Badge color="first" className="mr-4">
                  {props.assets.length}
                </Badge>*/}
              </span>
              <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                <ChevronRight />
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              tag={Link}
              className={clsx("nav-link-simple", {
                active: location.pathname === "/reminders",
              })}
              to="/reminders"
            >
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={faBell} />
              </span>
              <span className="sidebar-item-label">
                Reminders
                {/* <Badge color="first" className="mr-4">
                  {props.assets.length}
                </Badge>*/}
              </span>
              <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                <ChevronRight />
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              tag={Link}
              className={clsx("nav-link-simple", {
                active: location.pathname === "/integrations",
              })}
              to="/integrations"
            >
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={faLink} />
              </span>
              <span className="sidebar-item-label">
                Integrations
                {/*<Badge color="first" className="mr-4">
                  Pro
            </Badge>*/}
                {/* <Badge color="first" className="mr-4">
                  {props.assets.length}
                </Badge>*/}
              </span>
              <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                <ChevronRight />
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              tag={Link}
              className={clsx("nav-link-simple", {
                active: location.pathname === "/contacts",
              })}
              to="/contacts"
            >
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={faAddressBook} />
              </span>
              <span className="sidebar-item-label">
                Contacts
                {/* <Badge color="first" className="mr-4">
                  {props.assets.length}
                </Badge>*/}
              </span>
              <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                <ChevronRight />
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              tag={Link}
              className={clsx("nav-link-simple", {
                active: location.pathname === "/chats",
              })}
              to="/chats"
            >
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={faComments} />
              </span>
              <span className="sidebar-item-label">
                Chats
                {/* <Badge color="first" className="mr-4">
                  {props.assets.length}
                </Badge>*/}
              </span>
              <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                <ChevronRight />
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              tag={Link}
              className={clsx("nav-link-simple", {
                active: location.pathname === "/subscriptions",
              })}
              to="/subscriptions"
            >
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={faDollarSign} />
              </span>
              <span className="sidebar-item-label">
                Subscriptions
                {/* <Badge color="first" className="mr-4">
                  {props.assets.length}
                </Badge>*/}
              </span>
              <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                <ChevronRight />
              </span>
            </NavLink>
          </NavItem>
        </Nav>
      </div>
    </PerfectScrollbar>
  );
};

export default SidebarContent;
