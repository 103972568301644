/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListAlt, faAddressCard } from "@fortawesome/free-regular-svg-icons";
import AssetSummary from "./AssetSummary";
import AssetTable from "./AssetTable";
import AssetFilter from "./AssetFilter";
import SectionHeader from "../../shared/components/SectionHeader";

class AssetListingTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assets: props.assets,
      showAssetLookup: false,
      currentFilter: "active",
      assetView: "card"
    };
  }

  handleClick = a => {
    this.props.history.push(`/assets/${a.slug}`);
  };

  handleSearch = ({ target }) => {
    this.props.searchAsset(target.value);
  };

  toggleAssetView = view => {
    this.setState({ assetView: view });
  };

  changeFilter = filter => {
    this.setState({ currentFilter: filter }, () => {});
  };
  render() {
    let assets = [];
    const { currentType } = this.props;
    if (currentType) {
      assets = this.props.assets.filter(a => a.assetType === currentType);
    } else {
      assets = this.props.assets;
    }
    return (
      <>
        <SectionHeader
          titleHeading="Assets"
          innerSection={true}
          showUserbox={false}
        >
          <div className="btn-group btn-group-md mr-2" role="group">
            <Button
              color={
                this.state.assetView === "card" ? "primary" : "neutral-primary"
              }
              onClick={() => this.toggleAssetView("card")}
            >
              <FontAwesomeIcon icon={faAddressCard} />
            </Button>
            <Button
              color={
                this.state.assetView === "table" ? "primary" : "neutral-primary"
              }
              onClick={() => this.toggleAssetView("table")}
            >
              <FontAwesomeIcon icon={faListAlt} />
            </Button>
          </div>
          <div className="d-flex flex-row justify-content-end dash-filter">
            <AssetFilter
              handleSearch={this.handleSearch}
              changeFilter={this.changeFilter}
              currentFilter={this.state.currentFilter}
            />
          </div>
        </SectionHeader>
        {this.state.assetView === "card" && (
          <AssetSummary
            assets={assets}
            toggleAssetLookup={this.props.toggleAssetLookup}
            viewAsset={this.handleClick}
            currentAssetType={currentType}
          />
        )}
        {this.state.assetView === "table" && (
          <AssetTable
            assets={assets}
            viewAsset={this.handleClick}
            currentAssetType={currentType}
          />
        )}
      </>
    );
  }
}

export default AssetListingTable;
