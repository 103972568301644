import { put, call, takeEvery, takeLatest } from "redux-saga/effects";
import { Subscriptions } from "../api/agent";
import showToast from "../helpers/toastNotification";

import {
    GET_PLANS,
    GET_PLANS_SUCCESS,
    GET_PLANS_FAILURE,
	GET_PAYMENT_METHODS,
	GET_PAYMENT_METHODS_SUCCESS,
	GET_PAYMENT_METHODS_FAILURE,
	CREATE_PAYMENT_METHOD,
	CREATE_PAYMENT_METHOD_SUCCESS,
	CREATE_PAYMENT_METHOD_FAILURE,
	CREATE_SUBSCRIPTION,
	CREATE_SUBSCRIPTION_SUCCESS,
	CREATE_SUBSCRIPTION_FAILURE,
	UPDATE_PAYMENT_METHOD,
	UPDATE_PAYMENT_METHOD_SUCCESS,
	UPDATE_PAYMENT_METHOD_FAILURE,
	UPDATE_SUBSCRIPTION,
	UPDATE_SUBSCRIPTION_SUCCESS,
	UPDATE_SUBSCRIPTION_FAILURE,
	CANCEL_SUBSCRIPTION,
	CANCEL_SUBSCRIPTION_SUCCESS,
	CANCEL_SUBSCRIPTION_FAILURE
} from "../actions/actionTypes";

function* getPlans(action) {
	try {
		const payload = yield call(Subscriptions.getPlans, action.payload);
		yield put({ type: GET_PLANS_SUCCESS, payload });
	} catch (error) {
		const err = error.response.body;
		yield put({ type: GET_PLANS_FAILURE, err });
	}
}
export function* watchGetPlans() {
	yield takeLatest(GET_PLANS, getPlans);
}

function* getPaymentMethods(action) {
	try {
		const payload = yield call(Subscriptions.getPaymentMethods);
		yield put({ type: GET_PAYMENT_METHODS_SUCCESS, payload });
	} catch (error) {
		const err = error.response.body;
		yield put({ type: GET_PAYMENT_METHODS_FAILURE, err });
	}
}
export function* watchGetPaymentMethods() {
	yield takeLatest(GET_PAYMENT_METHODS, getPaymentMethods);
}

function* createPaymentMethod(action) {
	try {
		const payload = yield call(Subscriptions.addPaymentMethod, action.payload);
		showToast("s", "Payment Method Added Successfully");
		yield put({ type: CREATE_PAYMENT_METHOD_SUCCESS, payload });
	} catch (error) {
		const err = error.response.body;
		showToast("e", err.message || "Unable To Add Payment Method");
		yield put({ type: CREATE_PAYMENT_METHOD_FAILURE, err });
	}
}
export function* watchCreatePaymentMethod() {
	yield takeEvery(CREATE_PAYMENT_METHOD, createPaymentMethod);
}

function* createSubscription(action) {
	try {
		const payload = yield call(Subscriptions.addSubscription, action.payload);
		showToast("s", "Subscription Added Successfully");
		yield put({ type: CREATE_SUBSCRIPTION_SUCCESS, payload });
	} catch (error) {
		const err = error.response.body;
		showToast("e", err.message || "Unable To Subscription");
		yield put({ type: CREATE_SUBSCRIPTION_FAILURE, err });
	}
}
export function* watchCreateSubscription() {
	yield takeEvery(CREATE_SUBSCRIPTION, createSubscription);
}

function* updatePaymentMethod(action) {
	try {
		const payload = yield call(Subscriptions.updatePaymentMethod, action.payload);
		showToast("s", "Payment Method Updated Successfully");
		yield put({ type: UPDATE_PAYMENT_METHOD_SUCCESS, payload });
	} catch (error) {
		const err = error.response.body;
		showToast("e", err.message || "Unable To Update Payment Method");
		yield put({ type: UPDATE_PAYMENT_METHOD_FAILURE, err });
	}
}
export function* watchUpdatePaymentMethod() {
	yield takeEvery(UPDATE_PAYMENT_METHOD, updatePaymentMethod);
}

function* updateSubscription(action) {
	try {
		const payload = yield call(Subscriptions.updateSubscription, action.payload);
		showToast("s", "Subscription Updated Successfully");
		yield put({ type: UPDATE_SUBSCRIPTION_SUCCESS, payload });
	} catch (error) {
		const err = error.response.body;
		showToast("e", err.message || "Unable To Update Subscription");
		yield put({ type: UPDATE_SUBSCRIPTION_FAILURE, err });
	}
}
export function* watchUpdateSubscription() {
	yield takeEvery(UPDATE_SUBSCRIPTION, updateSubscription);
}

function* cancelSubscription(action) {
	try {
		const payload = yield call(Subscriptions.cancelSubscription, action.payload);
		showToast("s", "Subscription Cancelled Successfully");
		yield put({ type: CANCEL_SUBSCRIPTION_SUCCESS, payload });
	} catch (error) {
		const err = error.response.body;
		showToast("e", err.message || "Unable To Cancel Subscription");
		yield put({ type: CANCEL_SUBSCRIPTION_FAILURE, err });
	}
}
export function* watchCancelSubscription() {
	yield takeEvery(CANCEL_SUBSCRIPTION, cancelSubscription);
}
