import React, { Component } from "react";
import { ZiggeoRecorder } from "react-ziggeo";
import { Card, Button } from "reactstrap";

export default class VideoRecorder extends Component {
  cancelVideo = () => {
    const {
      field,
      form: { setFieldValue }
    } = this.props;
    this.child.reset();
    setFieldValue(field.name, "");
  };

  render() {
    const {
      field,
      form: { setFieldValue }
    } = this.props;

    return (
      <Card className="shadow-xxl mb-5">
        <div className="d-flex align-items-center p-3">
          <ZiggeoRecorder
            style={{ margin: "auto" }}
            apiKey={process.env.REACT_APP_ZIGGEO_API_KEY}
            height={180}
            width={320}
            onRef={ref => (this.child = ref)}
            theme={"modern"}
            onUploaded={e => setFieldValue(field.name, e.video)}
          />
        </div>
        <div className="d-flex p-3 justify-content-between">
          <Button size="sm" color="neutral-danger" onClick={this.cancelVideo}>
            Cancel
          </Button>
        </div>
      </Card>
    );
  }
}
