import React, { Fragment, Component } from "react";
import * as Yup from "yup";

import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  FormGroup,
  Label
} from "reactstrap";
import classNames from "classnames";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import { Field, withFormik } from "formik";
import FormField from "../../../../shared/components/form/FormField";
import ToggleSwitch from "../../../../shared/components/form/ToggleSwitch";
import customSelect from "../../../../shared/components/form/customSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import CreatableSelect from "react-select/creatable";
import { isFetching, isSuccess } from "../../../../reducers/reducerUtils";
import ZillowTOS from "../../../../shared/components/ZillowTOS";
import EntitySelect from "../../../../shared/components/form/EntitySelect";
import { connect } from "react-redux";
import { sortAlphaNumeric } from "../../../../helpers";

import { lookupAsset } from "../../../../actions/asset/assetAction";

class Summary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addressChanged: false,
      lookingUpAsset: false,
      assetMetadata: null,
      selectedAsset: null,
      multipleAssetsFound: false
    };
  }
  handleSelect = address => {
    this.props.setFieldValue(`address`, address);
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng =>
        this.props.setFieldValue("co_ordinates", [latLng.lat, latLng.lng])
      )
      .catch(error => console.error("Error", error));

    this.setState({ addressChanged: true }, () => {
      this.props.lookupAsset(address);
    });
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.addressChanged) {
      return {
        lookingUpAsset: true,
        selectedAsset: null,
        addressChanged: false
      };
    }
    const { assetLookup } = nextProps;

    if (prevState.lookingUpAsset && !isFetching(assetLookup)) {
      if (isSuccess(assetLookup)) {
        let assets = assetLookup.data.data;
        if (assets.length === 0) {
          return {
            lookingUpAsset: false,
            assetMetadata: assets,
            selectedAsset: {
              parcelID: "",
              beds: "",
              baths: "",
              stories: "",
              units: "",
              imageUrl:
                "https://media.bettercapital.us/file/bettercapital/common/streetview.jpeg",
              self_managed: true,
              metadata: {
                parcelID: "",
                beds: "",
                baths: "",
                stories: "",
                units: "",
                imageUrl:
                  "https://media.bettercapital.us/file/bettercapital/common/streetview.jpeg"
              }
            }
          };
        } else {
          return {
            lookingUpAsset: false,
            assetMetadata: assets,
            selectedAsset: assets.length === 1 ? assets[0] : null
          };
        }
      }
    }
  }
  componentDidUpdate = (prevProps, prevState) => {
    const { selectedAsset } = this.state;
    if (selectedAsset && !prevState.selectedAsset) {
      this.props.setFieldValue(`beds`, selectedAsset.beds);
      this.props.setFieldValue(`stories`, selectedAsset.stories);
      this.props.setFieldValue(`units`, selectedAsset.units || 1);
      this.props.setFieldValue(`baths`, selectedAsset.baths);
      this.props.setFieldValue(`imageUrl`, selectedAsset.imageUrl);
      this.props.setFieldValue(`parcel_id`, selectedAsset.parcelID);
      this.props.setFieldValue(`areaSquareFeet`, selectedAsset.areaSquareFeet);
      this.props.setFieldValue(`asset_type`, selectedAsset.asset_type);
      this.props.setFieldValue(
        `lotSizeSquareFeet`,
        selectedAsset.lotSizeSquareFeet
      );
      this.props.setFieldValue(`yearBuilt`, selectedAsset.yearBuilt);
      this.props.setFieldValue(
        `lotSizeSquareFeet`,
        selectedAsset.lotSizeSquareFeet
      );
      this.props.setFieldValue(`apn`, selectedAsset.apn);
      this.props.setFieldValue(`county`, selectedAsset.county);
      this.props.setFieldValue(`co_ordinates`, selectedAsset.co_ordinates);
    }
  };

  onChangeUnit = option => {
    const { assetMetadata } = this.state;
    if (assetMetadata) {
      let selectedAsset = assetMetadata[option.value];
      this.setState({ selectedAsset: selectedAsset });
    }
  };
  render() {
    const { assetMetadata } = this.state;
    const {
      getFieldMeta,
      handleChange,
      handleSubmit,
      errors,
      values
    } = this.props;
    let selectedAsset = this.state.selectedAsset;
    if (!selectedAsset && values) {
      selectedAsset = values;
    }

    let units;
    if (assetMetadata)
      units = assetMetadata
        .map((asset, idx) => {
          if (asset.unitType && asset.unit) {
            return {
              label: asset.unitType + " " + asset.unit,
              value: idx
            };
          }
          return null;
        })
        .filter(Boolean)
        .sort((a, b) => sortAlphaNumeric(a.label, b.label));

    return (
      <div className="wizard-steps horizontal">
        <form onSubmit={handleSubmit} className="px-2">
          <Row className="p-4" xl={2}>
            <Col md={6}>
              <Card className="card-box">
                <CardBody>
                  <FormGroup>
                    <Label>Address</Label>
                    <PlacesAutocomplete
                      value={getFieldMeta(`address`).value}
                      onSelect={this.handleSelect}
                      onChange={handleChange(`address`)}
                      searchOptions={{
                        componentRestrictions: { country: "us" }
                      }}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps
                      }) => (
                        <Fragment>
                          <InputGroup className="input-group-seamless">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <FontAwesomeIcon
                                  icon={faSearch}
                                  className="mx-auto"
                                />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              {...getInputProps({
                                placeholder: "Enter your property address",
                                className: "form-control"
                              })}
                              type="search"
                            />
                            {errors["summary"] && errors["summary"].address ? (
                              <p className={"text-danger mt-2"}>
                                {errors["summary"].address}
                              </p>
                            ) : null}
                          </InputGroup>
                          {suggestions.length > 0 && (
                            <div className="autocomplete-suggestion-container ">
                              {suggestions.map(suggestion => {
                                const className = classNames(
                                  "autocomplete__suggestion-item",
                                  {
                                    "autocomplete__suggestion-item--active":
                                      suggestion.active
                                  }
                                );

                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className
                                    })}
                                  >
                                    <strong>
                                      {suggestion.formattedSuggestion.mainText}
                                    </strong>{" "}
                                    <small>
                                      {
                                        suggestion.formattedSuggestion
                                          .secondaryText
                                      }
                                    </small>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </Fragment>
                      )}
                    </PlacesAutocomplete>
                  </FormGroup>
                  {assetMetadata && assetMetadata.length > 1 && (
                    <FormGroup>
                      <Label>Select Unit</Label>
                      <CreatableSelect
                        name="unit"
                        placeholder="Select your unit"
                        options={units}
                        theme={theme => ({
                          ...theme,
                          borderRadius: "0.29rem",
                          borderWidth: 1,
                          colors: {
                            ...theme.colors,
                            primary25: "rgba(60,68,177,0.15)",
                            primary50: "rgba(60,68,177,0.15)",
                            primary: "#3c44b1"
                          }
                        })}
                        onChange={this.onChangeUnit}
                      />
                    </FormGroup>
                  )}
                  <FormGroup>
                    <Label>Entity Name</Label>
                    <Field name="entity" component={EntitySelect} />
                  </FormGroup>

                  {selectedAsset &&
                    ["rental", "flip", "primary"].includes(
                      getFieldMeta(`investment_type`).value
                    ) && (
                      <FormGroup>
                        <Label>Self Managed</Label>
                        <Field
                          name="self_managed"
                          component={ToggleSwitch}
                          type={"number"}
                        />
                      </FormGroup>
                    )}
                  {selectedAsset && (
                    <FormGroup>
                      <Label>Management Platform</Label>
                      <Field
                        name="management_platform"
                        placeholder="Select Platform"
                        component={customSelect}
                        value={getFieldMeta(`management_platform`).value}
                        options={[
                          { value: "airbnb", label: "AirBnB" },
                          { value: "appfolio", label: "AppFolio" },
                          { value: "buildium", label: "Buildium" },
                          { value: "hemlane", label: "Hemlane" },
                          { value: "propertyware", label: "PropertyWare" },
                          { value: "rentmanager", label: "RentManager" },
                          { value: "homeroom", label: "HomeRoom" },
                          { value: "quickbook", label: "QuickBooks" },
                          { value: "yardi", label: "Yardi" },
                          { value: "tenantcloud", label: "TenantCloud" },
                          { value: "cozy", label: "Cozy.co" },
                          { value: "other", label: "Other" },
                          { value: "none", label: "None" }
                        ]}
                      />
                    </FormGroup>
                  )}
                </CardBody>
              </Card>
            </Col>
            {selectedAsset && (
              <Col md={6}>
                <Card className="card-box mb-5">
                  <CardBody>
                    <FormGroup>
                      <Label>Beds</Label>
                      <Field
                        name="beds"
                        component={FormField}
                        type={"number"}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Baths</Label>
                      <Field
                        name="baths"
                        component={FormField}
                        type={"number"}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Stories</Label>
                      <Field
                        name="stories"
                        component={FormField}
                        type={"number"}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Number of Units</Label>
                      <Field
                        name="units"
                        component={FormField}
                        type={"number"}
                      />
                    </FormGroup>
                    <ZillowTOS />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>

          <div className={"d-flex justify-content-end col-12 py-3"}>
            <Button color={"primary"} type="submit">
              Next
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

const SummaryForm = withFormik({
  mapPropsToValues: props => ({
    parcel_id: props.asset.parcel_id,
    investment_type: props.investment_type || "rental",
    entity: props.asset.entity,
    user_role: props.user_role,
    address: props.asset.address,
    beds: props.asset.beds,
    baths: props.asset.baths,
    co_ordinates: props.asset.co_ordinates,
    units: props.asset.units,
    stories: props.asset.stories,
    asset_type: props.asset.asset_type,
    lotSizeSquareFeet: props.asset.lotSizeSquareFeet,
    areaSquareFeet: props.asset.areaSquareFeet,
    apn: props.asset.apn,
    county: props.asset.county,
    yearBuilt: props.asset.yearBuilt,
    imageUrl: props.asset.imageUrl,
    management_platform: props.asset.management_platform,
    purchase_date: props.asset.purchase_date,
    purchase_price: props.asset.purchase_price,
    down_payment: props.asset.down_payment,
    market_value: props.asset.market_value,
    market_rent: props.asset.market_rent,
    property_tax: props.asset.property_tax,
    insurance: props.asset.insurance,
    hoa_fee: props.asset.hoa_fee,
    self_managed: props.asset.self_managed,
    management_fee: props.asset.management_fee,
    rent: props.asset.rent,
    rents: props.asset.rents
  }),

  validationSchema: Yup.object().shape({
    address: Yup.string().required("Address is required")
  }),

  handleSubmit: (values, { props }) => {
    props.nextHandler(values);
  }
})(Summary);

const mapStateToProps = state => ({
  assetLookup: state.asset.assetLookup
});

const mapDispatchToProps = dispatch => ({
  lookupAsset: address => lookupAsset(address, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SummaryForm);
