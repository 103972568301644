/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from "react";
import Dropzone from "react-dropzone";
import { Row, Button } from "reactstrap";
import { Upload, Check, X } from "react-feather";
import PropTypes from "prop-types";
import PreviewFile from "./PreviewFile";
import _ from "lodash";

class MyDropZone extends PureComponent {
  constructor() {
    super();
    this.state = {
      files: []
    };
    this.onDrop = this.onDrop.bind(this);
  }

  onDrop(files) {
    const { setFieldValue } = this.props;
    // setFieldValue only exists in Formik forms
    if (!_.isUndefined(setFieldValue)) {
      setFieldValue("files", files);
    }
    this.setState({ files });
    this.props.onChange(files);
  }

  onCancel() {
    this.setState({
      files: []
    });
  }

  removeFile = (index, e) => {
    const { value, onChange } = this.props;
    e.preventDefault();
    const files = value.filter((val, i) => i !== index);
    this.setState({ files });
    onChange(files);
  };

  render() {
    const {
      value,
      listFiles,
      allowedExtensions,
      showDragZone,
      dragZoneSize,
      showFilePreview
    } = this.props;
    const rootClassName = showDragZone ? "dropzone-upload-wrapper" : "";
    return (
      <div>
        <div className="dropzone">
          <Dropzone
            onDrop={filesToUpload => {
              this.onDrop(value ? value.concat(filesToUpload) : filesToUpload);
            }}
            accept={allowedExtensions}
            maxFiles={5}
            minSize={0}
            maxSize={5242880} // maximum size we want to allow is 5 mb
            onFileDialogCancel={this.onCancel.bind(this)}
          >
            {({
              getRootProps,
              getInputProps,
              acceptedFiles,
              isDragActive,
              isDragAccept,
              isDragReject
            }) => (
              <div {...getRootProps({ className: rootClassName })}>
                <input {...getInputProps()} />
                <div
                  className={`dropzone-inner-wrapper bg-white dropzone-inner-wrapper-${dragZoneSize}`}
                >
                  {!showDragZone && !this.props.children ? (
                    <div>
                      <Button
                        color="primary"
                        className="hover-scale-sm font-weight-bold btn-pill px-4"
                      >
                        <span className="px-2">Upload file</span>
                      </Button>
                    </div>
                  ) : (
                    this.props.children
                  )}
                  {showDragZone && isDragAccept && (
                    <div>
                      <div className="d-60 hover-scale-lg icon-blob icon-blob-animated btn-icon text-success mx-auto">
                        <svg
                          className="d-60 opacity-2"
                          viewBox="0 0 600 600"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g transform="translate(300,300)">
                            <path
                              d="M170.4,-137.2C213.2,-82.3,234.8,-11.9,223.6,56.7C212.4,125.2,168.5,191.9,104.3,226.6C40.2,261.3,-44.1,264,-104,229.8C-163.9,195.7,-199.4,124.6,-216.2,49.8C-233,-25.1,-231,-103.9,-191.9,-158C-152.7,-212.1,-76.4,-241.6,-6.3,-236.6C63.8,-231.6,127.7,-192.2,170.4,-137.2Z"
                              fill="currentColor"
                            />
                          </g>
                        </svg>
                        <div className="blob-icon-wrapper">
                          <Check className="d-50" />
                        </div>
                      </div>
                      <div className="font-size-sm text-success">
                        All files will be uploaded!
                      </div>
                    </div>
                  )}
                  {showDragZone && isDragReject && (
                    <div>
                      <div className="d-60 hover-scale-lg icon-blob icon-blob-animated btn-icon text-danger mx-auto">
                        <svg
                          className="d-60 opacity-2"
                          viewBox="0 0 600 600"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g transform="translate(300,300)">
                            <path
                              d="M169,-144C206.7,-87.5,216.5,-18,196.9,35.7C177.3,89.4,128.3,127.1,75.2,150.7C22,174.2,-35.4,183.5,-79.7,163.1C-124,142.7,-155.1,92.6,-164.1,40.9C-173.1,-10.7,-160.1,-64,-129,-118.9C-98,-173.8,-49,-230.4,8.3,-237.1C65.7,-243.7,131.3,-200.4,169,-144Z"
                              fill="currentColor"
                            />
                          </g>
                        </svg>
                        <div className="blob-icon-wrapper">
                          <X className="d-50" />
                        </div>
                      </div>
                      <div className="font-size-sm text-danger">
                        Some files will be rejected!
                      </div>
                    </div>
                  )}
                  {showDragZone && !isDragActive && (
                    <div>
                      <div className="d-60 hover-scale-lg icon-blob btn-icon text-first mx-auto">
                        <svg
                          className="d-60 opacity-2"
                          viewBox="0 0 600 600"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g transform="translate(300,300)">
                            <path
                              d="M171.2,-128.5C210.5,-87.2,223.2,-16.7,205.1,40.4C186.9,97.5,137.9,141.1,81.7,167.2C25.5,193.4,-38,202.1,-96.1,181.2C-154.1,160.3,-206.7,109.7,-217.3,52.7C-227.9,-4.4,-196.4,-68,-153.2,-110.2C-110,-152.4,-55,-173.2,5.5,-177.5C65.9,-181.9,131.9,-169.8,171.2,-128.5Z"
                              fill="currentColor"
                            />
                          </g>
                        </svg>
                        <div className="blob-icon-wrapper">
                          <Upload className="d-50" />
                        </div>
                      </div>
                      <div className="font-size-sm">Drag files here!</div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </Dropzone>
        </div>
        {listFiles && (
          <div>
            <div className="font-weight-bold my-4 text-uppercase text-dark font-size-sm text-center">
              Uploaded Files
            </div>
            {this.state.files.length <= 0 && (
              <div className="text-info text-center font-size-sm">
                Uploaded files will appear here!
              </div>
            )}
            {this.state.files.length > 0 && (
              <div>
                <Row className="p-2">
                  {this.state.files.map(file => (
                    <PreviewFile
                      file={file}
                      key={file.name}
                      asCard={showFilePreview}
                    />
                  ))}
                </Row>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

MyDropZone.propTypes = {
  listFiles: PropTypes.bool,
  showDragZone: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  allowedExtensions: PropTypes.string,
  dragZoneSize: PropTypes.string,
  showFilePreview: PropTypes.bool
};

MyDropZone.defaultProps = {
  listFiles: true,
  showDragZone: true,
  allowedExtensions: "",
  dragZoneSize: "lg",
  showFilePreview: true
};

export default MyDropZone;
