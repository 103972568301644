import React from "react";
import Icon01 from "../../../assets/images/icon-01.png";

const Testimonial = ({ author, content, avatar, index }) => {
	return (
		<div className={index > 0 ? "data mt-5" : "data"}>
			<p>
				{content}
			</p>
			<div className="d-flex justify-content-start align-items-start flex-row">
				<img src={Icon01} alt="icon" />
				<h6>{author}</h6>
			</div>
		</div>
	);
};

export default Testimonial;
