import React from "react";
import PropTypes from "prop-types";

class FileInput extends React.Component {
  constructor(props) {
    super(props);
    this._validate = this._validate.bind(this);
  }

  _validate(files) {
    const {onError, onSuccess, maxSize, extensions} = this.props;
    let allFilesValid = true;

    // make sure a file was provided in the first place
    const filesLength = Object.keys(files).length;

    if (filesLength < 0) {
      onError("Select file to upload.");
      return;
    }

    if (filesLength > 5) {
      onError("Cannot upload more than 5 files at once");
      return;
    }

    let lwrCaseExtensions;

    if (extensions) {
      lwrCaseExtensions = extensions.map(ext => ext.toLowerCase());
    }

    Object.values(files).forEach(file => {
      // if we care about file extensions
      if (lwrCaseExtensions) {
        const uploadedFileExt = file.name
          .split(".")
          .pop()
          .toLowerCase();
        const isValidFileExt = lwrCaseExtensions.includes(uploadedFileExt);

        if (!isValidFileExt) {
          allFilesValid = false;
          onError(`File must be of type: ${extensions.join(" or ")}`);
          return;
        }
      }

      // convert maxSize from megabytes to bytes
      const maxBytes = maxSize * 1000000;

      if (file.size > maxBytes) {
        allFilesValid = false;
        onError(`File size must be less than ${maxSize} MB.`);
        return;
      }
    });

    // call on change if only all files are valid
    if (allFilesValid) onSuccess(files);
  }

  render() {
    const {multiple, disabled, id, className} = this.props;
    let classNames = `form-control-file pt-2 ${className}`;
    return (
      <input
        id={id || "file"}
        name="document"
        type="file"
        disabled={disabled}
        className={classNames}
        multiple={multiple}
        onChange={({currentTarget: {files}}) => {
          this._validate(files);
        }}
      />
    );
  }
}

FileInput.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  // max file size in MB
  maxSize: PropTypes.number,
  multiple: PropTypes.bool,
  extensions: PropTypes.array,
  disabled: PropTypes.bool
};

FileInput.defaultProps = {
  maxSize: 5,
  multiple: true,
  disabled: false
};

export default FileInput;
