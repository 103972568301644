import React, { useCallback, useEffect } from "react";
import { usePlaidLink } from "react-plaid-link";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const PlaidLinkButton = props => {
  const onSuccess = useCallback((token, metadata) => {
    const plaidData = {
      public_token: token,
      metadata: metadata
    };
    props.onSuccess(plaidData);
  }, []);

  const config = {
    token: props.linkToken,
    onSuccess
  };

  const { open, ready } = usePlaidLink(config);

  useEffect(
    () => {
      if (ready && props.autoOpen) {
        open();
      }
    },
    [ready, open]
  );

  let btnLabel = props.updateLink ? "Update" : "Add Bank";
  let btnColor = props.updateLink ? "first" : "primary";

  if (props.autoOpen) return <div />;

  return (
    <Button
      color={btnColor}
      className="btn-pill"
      onClick={() => open()}
      disabled={!ready || props.disabled}
    >
      <span className="btn-wrapper--icon">
        <FontAwesomeIcon icon={faPlus} />
      </span>
      <span className="btn-wrapper--label">{btnLabel}</span>
    </Button>
  );
};

export default PlaidLinkButton;
