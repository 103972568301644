import { put, call, takeLatest } from "redux-saga/effects";
import { Partner } from "../api/agent";
import showToast from "../helpers/toastNotification";

import {
  SIGNUP_PARTNER,
  SIGNUP_PARTNER_SUCCESS,
  SIGNUP_PARTNER_FAILURE,
  UPDATE_PARTNER,
  UPDATE_PARTNER_SUCCESS,
  UPDATE_PARTNER_FAILURE,
  GET_PARTNER,
  GET_PARTNER_FAILURE,
  GET_PARTNER_SUCCESS,
  LIST_PARTNERS,
  LIST_PARTNERS_SUCCESS,
  LIST_PARTNERS_FAILURE,
  SUBMIT_TESTIMONIAL,
  SUBMIT_TESTIMONIAL_SUCCESS,
  SUBMIT_TESTIMONIAL_FAILURE,
  GRANT_PERMISSION,
  GRANT_PERMISSION_SUCEESS,
  GRANT_PERMISSION_FAILURE
} from "../actions/actionTypes";

import { SET_CURRENT_USER } from "../actions/auth/authActionTypes";

function* signupPartner(action) {
  try {
    const payload = yield call(Partner.signup, action.payload);
    yield put({ type: SIGNUP_PARTNER_SUCCESS, payload });
    yield put({
      type: SET_CURRENT_USER,
      payload: {
        partner: payload.partner._id,
        partnerStatus: payload.partner.status
      }
    });

    showToast("s", "Submission successfull");
  } catch (error) {
    const err = error.response.body;
    showToast("e", "Error while saving");
    yield put({ type: SIGNUP_PARTNER_FAILURE, err });
  }
}
export function* watchSignupPartner() {
  yield takeLatest(SIGNUP_PARTNER, signupPartner);
}

function* updatePartner(action) {
  try {
    const payload = yield call(Partner.updatePartner, action.payload);
    yield put({ type: UPDATE_PARTNER_SUCCESS, payload });
    showToast("s", "Successfull. Changes will be reflected once approved.");
  } catch (error) {
    const err = error.response.body;
    showToast("e", "Error while saving");
    yield put({ type: UPDATE_PARTNER_FAILURE, err });
  }
}
export function* watchUpdatePartner() {
  yield takeLatest(UPDATE_PARTNER, updatePartner);
}

function* getPartner(action) {
  try {
    const payload = yield call(Partner.getPartner, action.payload);
    yield put({ type: GET_PARTNER_SUCCESS, payload });
    // showToast('s', "Submission successfull")
  } catch (error) {
    const err = error.response.body;
    // showToast('e', "Error while saving");
    yield put({ type: GET_PARTNER_FAILURE, err });
  }
}

export function* watchGetPartner() {
  yield takeLatest(GET_PARTNER, getPartner);
}

function* listPartners(action) {
  try {
    const payload = yield call(Partner.listPartners, action.payload);
    yield put({ type: LIST_PARTNERS_SUCCESS, payload });
    // showToast('s', "Submission successfull")
  } catch (error) {
    const err = error.response.body;
    // showToast('e', "Error while saving");
    yield put({ type: LIST_PARTNERS_FAILURE, err });
  }
}

export function* watchListPartners() {
  yield takeLatest(LIST_PARTNERS, listPartners);
}

function* submitTestimonial(action) {
  try {
    const payload = yield call(Partner.submitTestimonial, action.payload);
    yield put({ type: SUBMIT_TESTIMONIAL_SUCCESS, payload });
    // showToast('s', "Submission successfull")
  } catch (error) {
    const err = error.response.body;
    // showToast('e', "Error while saving");
    yield put({ type: SUBMIT_TESTIMONIAL_FAILURE, err });
  }
}

export function* watchSubmitTestimonial() {
  yield takeLatest(SUBMIT_TESTIMONIAL, submitTestimonial);
}

function* grantPermission(action) {
  try {
    const payload = yield call(Partner.grantPermission, action.payload);
    yield put({ type: GRANT_PERMISSION_SUCEESS, payload });
    showToast("s", "Partner permission updated");
  } catch (error) {
    const err = error.response.body;
    // showToast('e', "Error while saving");
    yield put({ type: GRANT_PERMISSION_FAILURE, err });
  }
}

export function* watchGrantPermission() {
  yield takeLatest(GRANT_PERMISSION, grantPermission);
}
