import React, { Component } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";
import { faHandHoldingUsd } from "@fortawesome/free-solid-svg-icons";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import moment from "moment-timezone";

import { exportToExcel } from "../../../actions/export";
import { runReport } from "../../../actions/export";
import PivotTable from "../../../shared/components/PivotTable";

import { reportFilters } from "../../../helpers/constants";
import { isEmpty } from "lodash";

import { Col, Row, Card } from "reactstrap";
import { isFetching, isSuccess } from "../../../reducers/reducerUtils";

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingAssetInfo: true,
      period: { label: reportFilters[0].name, value: reportFilters[0]._id },
      exportType: null,
      currentFilter: props.currentFilter,
      pivotTableInstance: null,
      reportData: null,
      googlechartsData: null
    };
  }

  reportComplete = () => {};

  runReport = type => {
    const { asset } = this.props;
    const { currentFilter } = this.state;
    this.setState(
      {
        loading: true,
        type: type,
        reportData: null
      },
      () => {
        let fromDate = currentFilter.fromDate;
        let toDate = currentFilter.toDate;
        if (!moment.isMoment(fromDate))
          fromDate = moment(currentFilter.fromDate);
        if (!moment.isMoment(toDate)) toDate = moment(currentFilter.toDate);
        this.props.runReport({
          type: type,
          fromDate: fromDate.utcOffset(0, true),
          toDate: toDate.utcOffset(0, true),
          assetSlug: asset.slug
        });
      }
    );
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { reportData } = nextProps;
    if (prevState.loading && !isEmpty(reportData)) {
      if (!isFetching(reportData)) {
        if (isSuccess(reportData)) {
          return {
            loading: false,
            reportData: reportData.data
          };
        } else {
          return {
            loading: false,
            error: reportData.data
          };
        }
      }
    } else if (!prevState.loading && isFetching(reportData)) {
      return {
        loading: true
      };
    }
    return null;
  }

  onPeriodChange = period => {
    this.setState(period);
  };

  onFilterChange = filter => {
    this.setState({ currentFilter: filter });
  };
  setPivotTableInstance = instance => {
    this.setState({ pivotTableInstance: instance });
  };
  //
  // customizeCellFunction = (cell, data) => {
  //   if (data.isGrandTotal) {
  //     if (data.label === "Grand Total") cell.text = "Net Operating Income";
  //   }
  // };
  render() {
    const { reportData, loading } = this.state;

    return (
      <div className="px-4">
        <Row>
          <Col xl="3">
            <Card
              className="btn rounded text-left mb-5 p-3 "
              onClick={() => this.runReport("summary")}
            >
              <div className="d-flex align-items-center">
                <div className="d-70 rounded-circle bg-success text-white m-2 text-center">
                  <FontAwesomeIcon
                    size="2x"
                    style={{ margin: "-5px" }}
                    icon={faHandHoldingUsd}
                  />
                </div>
                <div className="flex-grow-1 font-size-lg">
                  <div>Year to Date</div>
                </div>
                <div className="font-size-lg opacity-5">
                  <FontAwesomeIcon icon={faArrowRight} />
                </div>
              </div>
            </Card>
          </Col>
          <Col xl="3">
            <Card
              className="btn rounded text-left mb-5 p-3"
              onClick={() => this.runReport("detail")}
            >
              <div className="d-flex align-items-center">
                <div className="d-70 rounded-circle bg-first text-white m-2 text-center">
                  <FontAwesomeIcon
                    size="2x"
                    style={{ margin: "-5px" }}
                    icon={faFileInvoiceDollar}
                  />
                </div>
                <div className="flex-grow-1 font-size-lg">
                  <div>Transaction Detail</div>
                </div>
                <div className="font-size-lg opacity-5">
                  <FontAwesomeIcon icon={faArrowRight} />
                </div>
              </div>
            </Card>
          </Col>
          <Col xl="3">
            <Card
              className="btn rounded text-left mb-5 p-3"
              onClick={() => this.runReport("missing")}
            >
              <div className="d-flex align-items-center">
                <div className="d-70 rounded-circle bg-danger text-white m-2 text-center">
                  <FontAwesomeIcon
                    size="2x"
                    style={{ margin: "-5px" }}
                    icon={faExclamationTriangle}
                  />
                </div>
                <div className="flex-grow-1 font-size-lg">
                  <div>Missing Data</div>
                </div>
                <div className="font-size-lg opacity-5">
                  <FontAwesomeIcon icon={faArrowRight} />
                </div>
              </div>
            </Card>
          </Col>
          <Col xl="3">
            <Card className="btn rounded text-left mb-5 p-3 disabled ">
              <div className="d-flex align-items-center">
                <div className="d-70 rounded-circle bg-warning text-white m-2 text-center">
                  <FontAwesomeIcon
                    size="2x"
                    style={{ margin: "-5px" }}
                    icon={faBook}
                  />
                </div>
                <div className="flex-grow-1 font-size-lg">
                  <div>Tax Report (Last Year)</div>
                </div>
                <div className="font-size-lg opacity-5">
                  <FontAwesomeIcon icon={faArrowRight} />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        {!loading && reportData && (
          <PivotTable
            toolbar={false}
            report={reportData[0]}
            reportcomplete={this.reportComplete}
            setInstance={this.setPivotTableInstance}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  exportProp: state.exportToExcel,
  currentFilter: state.filter.currentFilter,
  reportData: state.report
});

const mapDispatchToProps = dispatch => ({
  exportToExcel: data => exportToExcel(data, dispatch),
  runReport: data => runReport(data, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Reports);
