import React, { Component } from "react";
import { connect } from "react-redux";
import MyDropZone from "../../../shared/components/DropZone";

import { Button, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { addAssetMedias } from "../../../actions/asset/assetAction";
import SlidingPane from "react-sliding-pane";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

class AddMedia extends Component {
  constructor() {
    super();
    this.state = {
      files: [],
      loading: false,
      error: null,
      showModal: false
    };
  }

  onChange = newFiles => {
    this.setState({ files: newFiles });
  };

  handleSubmit = e => {
    const { files } = this.state;
    if (files.length > 0) {
      const { assetSlug } = this.props;
      this.setState({ loading: true, error: null }, () => {
        this.props.addAssetMedias({
          slug: assetSlug,
          tags: "asset, media",
          fileName: "attachments",
          files: files,
          folder: "photos"
        });
      });
    }
  };

  toggleModal = () => {
    this.setState(prevState => ({
      showModal: !prevState.showModal,
      loading: false,
      error: null,
      files: []
    }));
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { assetMedia } = nextProps;
    if (prevState.loading && assetMedia) {
      return { loading: false, showModal: false };
    }
  }

  render() {
    const { loading, showModal } = this.state;

    return (
      <>
        <Button color={"primary"} className="mx-2" onClick={this.toggleModal}>
          <span className="btn-wrapper--icon">
            <FontAwesomeIcon icon={faPlus} />
          </span>
          <span className="btn-wrapper--label">{"Add Media"}</span>
        </Button>
        <SlidingPane
          isOpen={showModal}
          from="right"
          width="350px"
          hideHeader={true}
          className="d-flex justify-content-center modal-content"
          onRequestClose={this.toggleModal}
        >
          <ModalHeader toggle={this.toggleModal}>Add Media Files</ModalHeader>
          <ModalBody>
            <MyDropZone value={this.state.files} onChange={this.onChange} />
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              className="btn savebtns btn-primary px-5  d-inline-block mx-2"
              type="submit"
              onClick={e => this.handleSubmit(e)}
              disabled={loading}
            >
              {loading ? "Uploading" : "Upload"}
            </Button>
          </ModalFooter>
        </SlidingPane>
      </>
    );
  }
}

const mapStateToProps = state => ({
  assetMedia: state.assetMedia
});

const mapDispatchToProps = dispatch => ({
  addAssetMedias: files => addAssetMedias(files, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddMedia);
