import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import ConfirmModal from "../../../shared/components/ConfirmModal";

import { deleteAsset } from "../../../actions/asset/assetAction";

class DeleteAsset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      hasDeleted: false
    };
  }

  closeModal = () => {
    const { toggleModal } = this.props;
    this.setState({ loading: false, error: null }, () => {
      toggleModal({});
    });
  };

  deleteAsset = () => {
    const { deleteAsset, assetSlug } = this.props;
    this.setState({ loading: true, error: null }, () => {
      deleteAsset(assetSlug);
    });
  };

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps && this.state.loading) {
      const { deletedAsset } = nextProps;
      if (deletedAsset.deletingSuccess === true) {
        this.setState({ loading: false, error: null, hasDeleted: true });
      }
      // this.closeModal();
      else if (deletedAsset.deletingSuccess === false)
        this.setState({ loading: false, error: deleteAsset.error });
    }
  }

  render() {
    const { modalOpen } = this.props;
    if (this.state.hasDeleted) {
      return <Redirect to={"/dashboard"} />;
    } else
      return (
        <ConfirmModal
          title={"Are you sure to delete this Asset?"}
          subtitle={"You cannot undo this operation."}
          type="danger"
          modalOpen={modalOpen}
          onCancel={this.closeModal}
          onSubmit={this.deleteAsset}
          error={this.state.error}
          loading={this.state.loading}
          btnText={"Delete"}
          btnTextOnLoading={"Deleting"}
        />
      );
  }
}

const mapStateToProps = state => ({
  deletedAsset: state.deleteAssetReducer
});

const mapDispatchToProps = dispatch => ({
  deleteAsset: slug => deleteAsset(slug, dispatch)
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteAsset);
