/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import holderImage from "../../img/property_placeholder.jpg";
import { capAllWords, formatCurrency, getAssetStyleClass } from "../../helpers";
// import LinesEllipsis from 'react-lines-ellipsis'
import { yearlyProjection } from "../../helpers/assetCalculator";

import { getDeals } from "../../actions/deal";

class Rental extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showPostDeal: false,
      dealAnalysis: []
    };
  }

  url = window.location.origin;

  componentWillMount() {
    this.props.getDeals("rental");
  }

  toggleShowPostDeal = () => {
    this.setState(prevState => ({ showPostDeal: !prevState.showPostDeal }));
  };

  /*   getLineElipsis = (text) => {
    return (<LinesEllipsis
      text={text}
      maxLine='1'
      ellipsis='...'
      trimRight
      basedOn='letters'
    />);
  } */

  componentWillReceiveProps = nextProps => {
    const {
      dealProp: { getDealsSuccess, deals }
    } = nextProps;

    if (getDealsSuccess) {
      const dealAnalysis = deals.map((deal, index) => {
        let projection = yearlyProjection(
          {
            purchasePrice: deal.basicInfo.dealSalesPrice,
            dealSalesPrice: deal.basicInfo.dealSalesPrice,
            marketValue: deal.basicInfo.marketValue,
            marketRent: deal.basicInfo.marketRent,
            financials: deal.financials,
            useMarketRent: true,
            downPaymentPercent: 100
          },
          1
        );

        return {
          slug: deal.slug,
          images: deal.images,
          asset_name: deal.asset_name,
          user: deal.user,
          dealSalesPrice: deal.dealSalesPrice,
          address: deal.address,
          projection: projection
        };
      });

      this.setState({ dealAnalysis });
    }
  };

  render() {
    const { dealAnalysis } = this.state;
    return (
      <div className="row">
        {dealAnalysis.length === 0 && <p>No Rental available</p>}

        {dealAnalysis.map((asset, i) => (
          <Link
            className="col-sm-12 col-md-6 col-lg-6 col-xl-6"
            to={`/deals/${asset.slug}`}
            key={i}
          >
            <div className="assets_block">
              <div className="assets_info">
                <div className="assets_img">
                  <img
                    src={asset.images}
                    alt=""
                    onError={e => {
                      e.target.src = holderImage;
                    }}
                  />
                  <div
                    className={`sidebar-image-overlay ${getAssetStyleClass(
                      "rental"
                    )}`}
                  >
                    <div className="image-overlay-type">{asset.dealType}</div>
                  </div>
                </div>
                <div className="assets_name">
                  <h4 className=" pt-0 pt-md-2">
                    {capAllWords(asset.asset_name)}
                  </h4>
                  <h5 className=" pt-0 pt-md-2">
                    Sales Price: {formatCurrency.format(asset.dealSalesPrice)}
                  </h5>
                  <p>
                    <i className="fas fa-map-marker-alt pr-1" />
                    {asset.address}
                  </p>
                </div>
              </div>
              <div className="assets_card_info">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="pl-4">Cash Flow</th>
                      <th>CAP Rate</th>
                      <th>Gross Yield</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="pl-4">
                        {" "}
                        {formatCurrency.format(
                          asset.projection.netOperatingIncome
                        )}
                      </td>
                      <td>{asset.projection.cashROI}%</td>

                      <td>{asset.projection.totalROI}%</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Link>
        ))}
        {/*<PostDeal
          modalOpen={this.state.showPostDeal}
          toggleModal={this.toggleShowPostDeal}
        />*/}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  dealProp: state.deal
});

const mapDispatchToProps = dispatch => ({
  getDeals: query => getDeals(query, dispatch)
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Rental);
