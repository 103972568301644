import React, { useState } from "react";
import {
  Nav,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu
} from "reactstrap";
import { NavLink as NavLinkStrap } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faEnvelope,
  faUpload,
  faReply,
  faLink
} from "@fortawesome/free-solid-svg-icons";

const AddDocument = props => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = type => {
    setDropdownOpen(prevState => !prevState);
    if (type) props.toggleAddModal(type);
  };
  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle color="primary">
        <span className="btn-wrapper--icon">
          <FontAwesomeIcon icon={faPlus} className="font-size-sm" />
        </span>
        <span className="btn-wrapper--label">Add Document</span>
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-md overflow-hidden p-0">
        <Nav pills className="nav-neutral-first flex-column pt-2 pb-3">
          <NavItem>
            <NavLinkStrap href="#/" onClick={() => toggle("upload")}>
              <div className="nav-link-icon">
                <FontAwesomeIcon icon={faUpload} />
              </div>
              <span>Upload</span>
            </NavLinkStrap>
          </NavItem>
          <li className="dropdown-divider" />
          <NavItem>
            <NavLinkStrap href="#/" onClick={() => toggle("email")}>
              <div className="nav-link-icon">
                <FontAwesomeIcon icon={faEnvelope} />
              </div>
              <span>Email</span>
            </NavLinkStrap>
          </NavItem>
          <li className="dropdown-divider" />
          <NavItem>
            <NavLinkStrap href="#/" onClick={() => toggle("request")}>
              <div className="nav-link-icon">
                <FontAwesomeIcon icon={faReply} />
              </div>
              <span>Request</span>
            </NavLinkStrap>
          </NavItem>
          <li className="dropdown-divider" />
          <NavItem>
            <NavLinkStrap
              href="#/"
              onClick={() => toggle("link_google_folder")}
            >
              <div className="nav-link-icon">
                <FontAwesomeIcon icon={faLink} />
              </div>
              <span>Link Google Folder</span>
            </NavLinkStrap>
          </NavItem>
        </Nav>
      </DropdownMenu>
    </Dropdown>
  );
};
export default AddDocument;
