/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from "react";
import { Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import DescriptionItem from "../../shared/components/form/DescriptionItem";
import NumberFormat from "react-number-format";
import LoadingIndicator from "../../shared/components/LoadingIndicator";
import { connect } from "react-redux";
import OwnerCard from "./OwnerCard";
import Nouislider from "nouislider-react";
import Switch from "rc-switch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import _ from "lodash";
import { analyzeAsset } from "../../helpers/assetCalculator";
import { getAssetFinancials } from "../../actions/financial";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { faDotCircle } from "@fortawesome/free-solid-svg-icons";
import { faHandHoldingUsd } from "@fortawesome/free-solid-svg-icons";
import { faWallet } from "@fortawesome/free-solid-svg-icons";

class RentalAnalyzer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      analysis: {},
      overrides: {}
    };
  }

  componentDidMount() {
    this.props.getAssetFinancials(this.props.assetSlug);
  }
  componentWillReceiveProps = nextProps => {
    if (!this.state.analysis || Object.keys(this.state.analysis).length === 0) {
      this.updateProjections(nextProps, {});
    }
  };
  updateProjections = (props, overrides = {}) => {
    const { basicInfo, investmentInfo, financial } = props;
    if (financial.getFinancialSuccess) {
      const { dealSalesPrice } = basicInfo;
      const {
        marketValue,
        marketRent,
        downPayment,
        afterRepairValue,
        rehabEstimate
      } = investmentInfo;
      const { financials } = financial;
      this.setState({
        analysis: analyzeAsset({
          purchasePrice: overrides.purchasePrice || dealSalesPrice,
          dealSalesPrice: dealSalesPrice,
          marketValue: marketValue,
          marketRent: marketRent,
          financials: financials,
          downPayment: downPayment,
          afterRepairValue: afterRepairValue,
          rehabEstimate: rehabEstimate,
          downPaymentPercent: overrides.downPaymentPercent || 20,
          useMarketRent: overrides.useMarketRent || false
        })
      });
    }
  };

  onSliderChange = (value, type) => {
    let overrides = this.state.overrides;
    if (type === "purchase") {
      overrides.purchasePrice = value[0];
    } else if (type === "downPayment") {
      overrides.downPaymentPercent = value[0];
    }
    this.setState(overrides);
    // this.setState(analysis);
    this.updateProjections(this.props, overrides);
  };

  rentTypeChange = value => {
    this.updateProjections(this.props, { useMarketRent: value });
  };
  render() {
    if (_.isEmpty(this.state.analysis)) return <LoadingIndicator />;
    const {
      current: {
        dealSalesPrice,
        purchasePrice,
        useMarketRent,
        downPaymentPercent,
        rentalIncome,
        cashROI,
        totalCashFlow,
        totalROI
      }
    } = this.state.analysis;
    const { basicInfo } = this.props;
    return (
      <Fragment>
        <Row>
          <Col xl="5">
            <Card className="card-box mb-5">
              <CardHeader>
                <h4 className="font-size-lg mb-0 py-2 font-weight-bold">
                  Summary
                </h4>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col className="col-xl-6">
                    <div className="inputblock py-2">
                      <label htmlFor="staticEmail" className="col-form-label">
                        Purchase Price:{" "}
                        <NumberFormat
                          value={purchasePrice}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                          decimalScale={2}
                        />
                      </label>
                      <div className="inputblock pt-5">
                        <Nouislider
                          connect
                          range={{
                            min: dealSalesPrice * 0.75,
                            max: dealSalesPrice * 1.25
                          }}
                          start={purchasePrice}
                          step={100}
                          tooltips
                          onEnd={a => this.onSliderChange(a, "purchase")}
                        />
                      </div>
                    </div>
                    <div className="inputblock  py-2">
                      <label htmlFor="staticEmail" className="col-form-label">
                        Down Payment:{" "}
                        <NumberFormat
                          value={(dealSalesPrice * downPaymentPercent) / 100}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                          decimalScale={2}
                        />
                      </label>
                      <div className="inputblock pt-5">
                        <Nouislider
                          connect
                          range={{
                            min: 0,
                            max: 100
                          }}
                          start={downPaymentPercent}
                          step={5}
                          tooltips
                          onEnd={a => this.onSliderChange(a, "downPayment")}
                        />
                      </div>
                    </div>
                    <div className="inputblock  py-2">
                      <label htmlFor="staticEmail" className="col-form-label">
                        Rent:{" "}
                        <NumberFormat
                          value={rentalIncome / 12}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                          decimalScale={2}
                        />
                      </label>
                      <div className="inputblock  pt-2">
                        <Switch
                          checked={useMarketRent}
                          onClick={this.rentTypeChange}
                          className={"switch-medium d-block"}
                          checkedChildren={"Market"}
                          unCheckedChildren={"Current"}
                          style={{ width: "110px" }}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col className="col-xl-6">
                    <ul className="list-group list-group-flush">
                      <DescriptionItem
                        label="Cash ROI"
                        value={
                          <NumberFormat
                            value={cashROI}
                            displayType={"text"}
                            renderText={value => `${value}%`}
                            decimalScale={2}
                          />
                        }
                        icon={faDotCircle}
                        iconClass="text-primary mr-2"
                      />
                      <DescriptionItem
                        label="Cash Flow (Monthly)"
                        value={
                          <NumberFormat
                            value={totalCashFlow}
                            displayType={"text"}
                            renderText={value => `${value}`}
                            prefix={"$"}
                            decimalScale={2}
                          />
                        }
                        icon={faHandHoldingUsd}
                        iconClass="text-primary mr-2"
                      />
                      <DescriptionItem
                        label="Total ROI"
                        value={
                          <NumberFormat
                            value={totalROI}
                            displayType={"text"}
                            renderText={value => `${value}%`}
                            decimalScale={2}
                          />
                        }
                        icon={faWallet}
                        iconClass="text-primary mr-2"
                      />
                    </ul>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xl={3}>
            <Card className="card-box mb-5">
              <CardHeader>
                <h4 className="font-size-lg mb-0 py-2 font-weight-bold">
                  Highlights
                </h4>
              </CardHeader>
              <CardBody>
                <ul className="list-unstyled text-left my-4 font-weight-bold">
                  {basicInfo.dealHighlights &&
                    basicInfo.dealHighlights.map((highlight, idx) => {
                      return (
                        <li className="px-4 py-2" key={`deal-highlight-${idx}`}>
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="text-success font-size-lg mr-2"
                          />
                          {highlight}
                        </li>
                      );
                    })}
                </ul>
              </CardBody>
            </Card>
          </Col>
          <Col xl={3}>
            <OwnerCard basicInfo={basicInfo} />
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  financial: state.financial
});

const mapDispatchToProps = dispatch => ({
  getAssetFinancials: slug => getAssetFinancials(slug, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RentalAnalyzer);
