import React, { Component } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { Button, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { connect } from "react-redux";
import SlidingPane from "react-sliding-pane";

import SelectField from "../../../shared/components/form/SelectField";
import FormField from "../../../shared/components/form/FormField";
import Alert from "../../../shared/components/Alert";

import { updateAssetContact } from "../../../actions/contacts";

const UpdateContactModal = ({
  modalOpen,
  handleSubmit,
  handleReset,
  closeModal,
  loading,
  error
}) => (
  <SlidingPane
    from="right"
    width="350px"
    hideHeader={true}
    className="d-flex justify-content-center modal-content"
    isOpen={modalOpen}
    onRequestClose={handleReset}
  >
    <ModalHeader toggle={closeModal}>{"Update Contact"}</ModalHeader>
    <form onSubmit={handleSubmit}>
      <ModalBody>
        {error && (
          <Alert color="danger" className="alert--bordered" icon>
            <p>Error! {error}</p>
          </Alert>
        )}
        <div className="form-group row">
          <label htmlFor="staticEmail" className="col-sm-2 col-form-label">
            Email Address:
          </label>
          <div className="col-sm-10 inputblock">
            <Field
              name="email"
              component={FormField}
              placeholder="Email Address"
              disabled
            />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="staticEmail" className="col-sm-2 col-form-label">
            First Name:
          </label>
          <div className="col-sm-10 inputblock">
            <Field
              name="first_name"
              component={FormField}
              type="text"
              placeholder="First name"
            />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="staticEmail" className="col-sm-2 col-form-label">
            Last Name:
          </label>
          <div className="col-sm-10 inputblock">
            <Field
              name="last_name"
              component={FormField}
              type="text"
              placeholder="Last name"
            />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="staticEmail" className="col-sm-2 col-form-label">
            Select Role
          </label>
          <div className="col-sm-10 inputblock">
            <Field
              name="role"
              component={SelectField}
              options={[
                { value: "O", label: "Owner" },
                { value: "R", label: "Real Estate Agent" },
                { value: "PM", label: "Property Manager" },
                { value: "I", label: "Investor" },
                { value: "IA", label: "Insurance Agent" },
                { value: "T", label: "Tenant" },
                { value: "C", label: "Contractor" },
                { value: "U", label: "Other" }
              ]}
              placeholder="Select Role"
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          type="reset"
          className="btn btn-secondary px-5 d-inline-block mx-2"
          onClick={closeModal}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          className="btn savebtns btn-primary px-5  d-inline-block mx-2"
          type="submit"
          disabled={loading}
        >
          {loading ? "Updating" : "Update"}
        </Button>
      </ModalFooter>
    </form>
  </SlidingPane>
);

const validationSchema = Yup.object({
  email: Yup.string("")
    .email("Enter valid email")
    .required("Email is required")
    .max(100, "Enter valid length email"),
  role: Yup.string("").required("Role is required")
});

class UpdateContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null
    };
  }

  fomrikResetForm;

  closeModal = () => {
    const { toggleModal } = this.props;
    this.setState({ loading: false, error: null }, () => {
      toggleModal({});
    });
  };

  updateContact = (values, { resetForm }) => {
    this.setState({ loading: true, error: null }, () => {
      this.fomrikResetForm = resetForm;
      this.props.updateAssetContact({
        email: values.email,
        role: values.role,
        slug: this.props.assetSlug,
        first_name: values.first_name,
        last_name: values.last_name
      });
    });
  };

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps && this.state.loading) {
      const { contacts } = nextProps;
      if (contacts.updatingSuccess === true) this.closeModal();
      else if (contacts.updatingSuccess === false)
        this.setState({ loading: false, error: contacts.error });
    }
  }

  render() {
    const { contact } = this.props;

    const initialValues = contact;
    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={this.updateContact}
      >
        {props => (
          <UpdateContactModal
            handleSubmit={props.handleSubmit}
            {...this.props}
            loading={this.state.loading}
            error={this.state.error}
            closeModal={this.closeModal}
          />
        )}
      </Formik>
    );
  }
}

const mapStateToProps = state => ({
  contacts: state.contacts
});

const mapDispatchToProps = dispatch => ({
  updateAssetContact: data => updateAssetContact(data, dispatch)
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateContact);
