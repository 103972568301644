import React, { PureComponent } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { connect } from "react-redux";
import { Formik, FieldArray } from "formik";
import PerfectScrollbar from "react-perfect-scrollbar";
import { hideMissingImport } from "../../actions/asset/assetAction";
import _ from "lodash";
import { formatCurrency } from "../../helpers";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";

import { isFetching, isSuccess } from "../../reducers/reducerUtils";

import * as Yup from "yup";
import {
  saveTransactions,
  getTransactionsFromBank,
  downloadPastSampleXLSX
} from "../../actions/transaction";
import { propParser } from "../../actions/propParser";

class BankTransactionImport extends PureComponent {
  constructor(props) {
    super(props);

    const { asset } = props;
    const missingYear =
      asset && asset.missingTransactionYears.length > 0
        ? new Date(asset.missingTransactionYears[0], 0, 1)
        : "";

    let t = [
      {
        date: missingYear,
        category: "REAL_ESTATE_TAXES",
        amount: "",
        frequency: "yearly",
        vendor: "County"
      },
      {
        date: missingYear,
        category: "RENT",
        amount: "",
        frequency: "monthly",
        vendor: "Tenant"
      },
      {
        date: missingYear,
        category: "INSURANCE",
        amount: "",
        frequency: "yearly",
        vendor: "Insurance Company"
      }
    ];

    this.state = {
      loading: false,
      error: null,

      showImport: false,

      fileUploaded: false,
      fileUploading: false,
      fetchingBankData: false,
      selectedExcelProp: "",
      transactions: [],
      selectedRows: [],
      //transaction state
      transactionSaving: false,
      transactionSavingErr: "",

      respParseType: "",

      manualTransactions: t,

      selectedAccount: ""
    };
  }

  formikResetForm;

  addTransaction = values => {
    // let transactions = values.transactions;
    //
    // if (transactions.length > 0) {
    //   const mappedTran = transactions.map(t => {
    //     return {
    //       category: t.category,
    //       amount: unmaskCurrency(t.amount),
    //       payee: t.vendor,
    //       frequency: t.frequency,
    //       date: t.date
    //     };
    //   });
    //   // return
    //   this.setState({ transactionSaving: true, error: null }, () => {
    //     this.props.saveTransactions({
    //       slug: this.props.assetSlug,
    //       transactions: mappedTran
    //     });
    //   });
    // }
  };

  fetchBankTransactions = accountId => {
    this.setState({ error: null, fetchingBankData: true }, () => {
      this.props.getTransactionsFromBank({
        account_id: accountId
      });
    });
  };

  handleReset = ({ resetForm }) => {
    this.props.toggleBankImportModal();
  };

  componentDidMount = () => {
    const { accountToFetch } = this.props;
    if (accountToFetch) {
      this.fetchBankTransactions(accountToFetch);
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { accountToFetch } = this.props;
    if (prevProps.accountToFetch !== accountToFetch) {
      this.fetchBankTransactions(accountToFetch);
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      transactions: { bankTransactions }
    } = nextProps;

    if (prevState.transactionSaving) {
      const { transactions } = nextProps;
      if (transactions.isSaveTransactionsSuccess === true) {
        // this.removeUploadedExcelProperty();
        return {
          transactions: [],
          transactionSaving: false,
          fileUploaded: true
        };
      } else if (transactions.isSaveTransactionsSuccess === false)
        return {
          transactionSavingErr: transactions.error,
          transactionSaving: false,
          fileUploaded: true
        };
    }

    if (prevState.fetchingBankData && !_.isEmpty(bankTransactions)) {
      if (!isFetching(bankTransactions)) {
        if (isSuccess(bankTransactions)) {
          return {
            transactions: bankTransactions.data,
            fetchingBankData: false
          };
        } else {
          return {
            transactions: [],
            error: bankTransactions.error,
            fetchingBankData: false
          };
        }
      }
    }
    return null;
  }
  columnProps = [
    { dataField: "txnId", hidden: true },
    {
      dataField: "category",
      text: "Category",
      sort: true
    },
    {
      dataField: "date",
      text: "Date",
      sort: true
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
      formatter: (cell, row) => {
        if (row.amount) {
          return formatCurrency.format(row.amount);
        }
      }
    },
    {
      dataField: "asset",
      text: "Linked Asset",
      editor: {
        type: Type.SELECT,
        getOptions: (setOptions, { row, column }) => {
          const { assets } = this.props;
          return assets.map(asset => {
            return { value: asset.slug, label: asset.address };
          });
        }
      }
    }
  ];
  handleOnSelect = (row, isSelect) => {
    const { selectedRows } = this.state;
    let rows = selectedRows || [];
    if (isSelect) {
      rows.push(row);
    } else {
      rows = rows.filter(r => r.txnId !== row.txnId);
    }
    this.setState({ selectedRows: rows });
    return true;
  };

  handleOnSelectAll = (isSelect, rows) => {
    this.setState({ selectedRows: isSelect ? rows : [] });
    return true;
  };
  onTableChange = props => {};

  render() {
    const selectRow = {
      mode: "checkbox",
      clickToSelect: false,
      onSelect: this.handleOnSelect,
      onSelectAll: this.handleOnSelectAll
    };
    const validationSchema = Yup.object().shape({
      transactions: Yup.array().of(
        Yup.object({
          category: Yup.string().required("Category is required"),
          amount: Yup.string()
            .required("Amount is required")
            .max(16, "Amount is too large")
        })
      )
    });

    const { transactions, fetchingBankData, transactionSaving } = this.state;
    const loading = fetchingBankData || transactionSaving;
    return (
      <Formik
        enableReinitialize
        initialValues={{
          transactions: transactions
        }}
        validationSchema={validationSchema}
        onSubmit={this.addTransaction}
      >
        {props => {
          const { handleSubmit, values } = props;
          return (
            <Modal
              zIndex={2000}
              centered
              isOpen={this.props.showModal}
              toggle={this.props.toggleBankImportModal}
            >
              <ModalHeader toggle={this.props.toggleBankImportModal}>
                Import Bank Transactions
              </ModalHeader>
              <form onSubmit={handleSubmit}>
                <ModalBody>
                  <FieldArray
                    name="transactions"
                    render={arrayHelpers => (
                      <div>
                        {values.transactions && values.transactions.length > 0 && (
                          <PerfectScrollbar className="scroll-area-lg">
                            <BootstrapTable
                              keyField="txnId"
                              columns={this.columnProps}
                              data={values.transactions}
                              bootstrap4
                              headerClasses="thead-light"
                              selectRow={selectRow}
                              cellEdit={cellEditFactory({
                                mode: "click",
                                blurToSave: true
                              })}
                              onTableChange={this.onTableChange}
                            />
                          </PerfectScrollbar>
                        )}
                      </div>
                    )}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button
                    type="reset"
                    className="btn mb-3 btn-secondary px-5 d-inline-block mx-2"
                    onClick={() => this.handleReset(props)}
                    disabled={transactionSaving}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    className="btn mb-3 savebtns px-5  d-inline-block mx-2"
                    type="submit"
                    disabled={transactionSaving}
                  >
                    {loading ? "Saving" : "Save"}
                  </Button>
                </ModalFooter>
              </form>
            </Modal>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = state => ({
  propParser: state.propParser,
  transactions: state.transactions
});

const mapDispatchToProps = dispatch => {
  return {
    saveTransactions: data => saveTransactions(data, dispatch),
    propertyParser: file => propParser(file, dispatch),
    hideMissingImport: () => hideMissingImport(dispatch),
    downloadPastSampleXLSX: data => downloadPastSampleXLSX(data, dispatch),
    getTransactionsFromBank: data => getTransactionsFromBank(data, dispatch)
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BankTransactionImport);
