import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Sidebar from "./topbar_with_navigation/sidebar";
import { getUserDetail } from "../../actions/auth/authAction";
import { getAccounts } from "../../actions/accounts/accountActions";
import { asset_list } from "../../actions/asset/assetAction";
import {
  changeMobileSidebarVisibility,
  changeSidebarVisibility,
} from "../../actions/sidebar";
import HeaderUserbox from "../../shared/components/HeaderUserbox";
import { SidebarProps } from "../../shared/prop-types/ReducerProps";
import clsx from "clsx";

class Layout extends Component {
  constructor(props) {
    super(props);
    let queryParams = new URLSearchParams("status=active");
    if (props.currentFilter.entity) {
      queryParams.set("entity", props.currentFilter.entity);
    }
    if (props.currentFilter.status) {
      queryParams.set("status", props.currentFilter.status);
    }
    if (props.currentFilter.integration) {
      queryParams.set("integration", props.currentFilter.integration);
    }
    this.state = {
      location: {},
      redirectTo: "/dashboard",
      loading: false,
      assets: [],
      queryParams: queryParams.toString(),
    };
  }

  componentDidMount() {
    // We want to load common state api's in the layout
    this.setState(
      { loading: true, statsLoading: true, initialLoad: true, error: null },
      () => {
        this.props.getUserDetail();
        this.props.asset_list(this.state.queryParams);
        this.props.getAccounts();
      }
    );
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!nextProps.auth.isAuthenticated) {
      let a = window.location.pathname;
      const search = window.location.search;
      if (search) a += search;
      nextProps.history.push(`/login?redirectTo=${a}`);
      return {
        location: nextProps.location,
        redirectTo: a,
      };
    }
    let asst = nextProps.asset.data;
    const assetNextProps = nextProps.asset;
    let newAssetLength = asst ? asst.length : 0;
    let currentAssetLength = prevState.assets ? prevState.assets.length : 0;

    if (
      prevState.loading ||
      (newAssetLength !== currentAssetLength && !prevState.searchText)
    ) {
      if (assetNextProps.getAssetListSuccess === true) {
        return {
          assets: asst ? [...asst] : [],
          loading: false,
        };
      } else if (assetNextProps.getAssetListSuccess === false) {
        return { loading: false, error: assetNextProps.error };
      }
    }
    return null;
  }

  static propTypes = {
    sidebar: SidebarProps.isRequired,
    isShare: PropTypes.bool,
  };

  changeSidebarVisibility = () => {
    this.props.changeSidebarVisibility();
  };

  changeMobileSidebarVisibility = () => {
    this.props.changeMobileSidebarVisibility();
  };

  render() {
    const { sidebar } = this.props;
    const { assets } = this.state;

    return (
      <Fragment>
        <div>
          <Sidebar
            sidebar={this.props.sidebar}
            assets={assets}
            changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
            {...this.props}
          />
        </div>

        <div className="app-main">
          <div
            className={clsx(
              "app-header app-header--shadow app-header--opacity-bg font-size-xl d-none"
            )}
          >
            <div className="app-header--pane">
              <button
                className={clsx(
                  "navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn",
                  { "is-active": sidebar.show }
                )}
                onClick={this.changeMobileSidebarVisibility}
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner" />
                </span>
              </button>
            </div>
            <div>
              <HeaderUserbox onlyImage={true} />
            </div>
          </div>
          {this.props.children}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  asset: state.assetList,
  currentFilter: state.filter.currentFilter,
  sidebar: state.sidebar,
  userDetail: state.userDetail,
  auth: state.login,
  plaid: state.plaid,
});

const mapDispatchToProps = (dispatch) => ({
  asset_list: (query) => asset_list(query, dispatch),

  getUserDetail: () => {
    getUserDetail(dispatch);
  },
  getAccounts: () => {
    getAccounts(dispatch);
  },
  changeSidebarVisibility: () => {
    dispatch(changeSidebarVisibility());
  },
  changeMobileSidebarVisibility: () => {
    dispatch(changeMobileSidebarVisibility());
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Layout)
);
