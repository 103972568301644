import React from "react";
import { Route, Switch } from "react-router-dom";
import Layout from "./components/Layout/index";

import Activateuser from "./components/Activation/activation";
import ResetPassword from "./components/ResetPassword/";
import AddTestimonial from "./components/Testimonial/AddTestimonial";

import Dashboard from "./components/Dashboard";
import Settings from "./components/Settings/";
import Login from "./components/Login/Login";
import Auth from "./components/Auth";

import Signup from "./components/Signup/Signup";
import NotFound from "./components/NotFound/NotFound";
import Deal from "./components/Deals";

import AddAsset from "./components/AddAsset";

import AssetDetails from "./components/AssetDetails";
import Chat from "./components/Chat";
import Transactions from "./components/Transactions";
import BankAccounts from "./components/BankAccounts";
import Integrations from "./components/Integrations";
import Insights from "./components/Insights";

import Subscriptions from "./components/Subscriptions";
import Opportunities from "./components/Opportunities";
import OpportunityDetails from "./components/Opportunities/OpportunityDetails";
import OpportunityCreate from "./components/Opportunities/OpportunityCreate";
import Bookmarks from "./components/Opportunities/Bookmarks";
import PartnerDetails from "./components/Opportunities/PartnerDetails";

import Reports from "./components/Reports";
import Reminders from "./components/Reminders";
import Documents from "./components/Documents";
import Contacts from "./components/Contacts";

const Pages = (props) => {
  return (
    <Switch>
      <Route exact path="/" component={Dashboard} />
      <Route exact path="/dashboard" component={Dashboard} />
      <Route exact path="/settings" component={Settings} />
      <Route path="/assets/add" component={AddAsset} />
      <Route path="/assets/:asset" component={AssetDetails} />
      <Route exact path="/chats" component={Chat} />
      <Route exact path="/chats/:conversationId" component={Chat} />
      {/* <Route exact path="/chats/:userId/:asset" component={Chat} /> */}
      <Route path="/transactions" component={Transactions} />
      <Route path="/banks" component={BankAccounts} />
      <Route path="/documents" component={Documents} />
      <Route path="/reports/" component={Reports} />
      <Route path="/reminders" component={Reminders} />
      <Route path="/contacts" component={Contacts} />
      <Route path="/integrations/:type" component={Integrations} />
      <Route path="/integrations" component={Integrations} />
      <Route path="/insights" component={Insights} />
      <Route path="/subscriptions" component={Subscriptions} />
      <Route path="/opportunities/create" component={OpportunityCreate} />
      <Route path="/opportunities/:slug" component={OpportunityDetails} />
      <Route path="/opportunities" component={Opportunities} />
      <Route path="/bookmarks" component={Bookmarks} />
      <Route path="/partner/:slug" component={PartnerDetails} />

      <Route path="*" exact={true} component={NotFound} />
    </Switch>
  );
};

const wrappedRoutes = () => (
  <Layout isShare={false}>
    <div className="app-content">
      <Route path="/" component={Pages} />
    </div>
  </Layout>
);

const shareRoutes = (props) => (
  <Layout isShare={true}>
    <div className="app-content">
      <Route path="/deals" component={Deal} {...props} />
    </div>
  </Layout>
);

const Router = () => (
  <Switch>
    <Route exact path="/signup" component={Signup} />
    <Route exact path="/login" component={Login} />
    <Route exact path="/auth" component={Auth} />
    <Route exact path="/resetpassword" component={ResetPassword} />
    <Route exact path="/activateuser" component={Activateuser} />
    <Route exact path="/add-testimonial" component={AddTestimonial} />

    <Route path="/deals" component={shareRoutes} />
    <Route path="/" component={wrappedRoutes} />
    <Route path="*" exact={true} component={NotFound} />
  </Switch>
);

export default Router;
