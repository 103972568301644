import React, { Component } from "react";
import {
  numberFormatter,
  formatPercent,
  getLabelForCategory
} from "../../../helpers";
import _ from "lodash";
import {
  Row,
  Col,
  Card,
  Progress,
  CardHeader,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";
import { Line } from "react-chartjs-2";

const chartOptions = {
  layout: {
    padding: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0
    }
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true
        },
        gridLines: {
          display: false,
          drawBorder: false
        }
      }
    ],
    xAxes: [
      {
        ticks: {
          beginAtZero: true
        },
        gridLines: {
          display: true,
          color: "#eeeff8",
          drawBorder: true
        }
      }
    ]
  },
  legend: {
    display: true
  },
  responsive: true,
  maintainAspectRatio: false
};

const xaxisStyle = {
  backgroundColor: "rgba(27, 201, 67, 0.3)",
  borderCapStyle: "round",
  borderDash: [],
  borderWidth: 3,
  borderColor: "#1bc943",
  borderDashOffset: 0.0,
  borderJoinStyle: "round",
  pointBorderColor: "#1bc943",
  pointBackgroundColor: "#ffffff",
  pointBorderWidth: 3,
  pointHoverRadius: 6,
  pointHoverBorderWidth: 3,
  pointRadius: 4,
  pointHoverBackgroundColor: "#ffffff",
  pointHoverBorderColor: "#1bc943"
};

const yaxisStyle = {
  backgroundColor: "rgba(248, 50, 69, 0.1)",
  borderCapStyle: "round",
  borderDash: [],
  borderWidth: 3,
  borderColor: "#f83245",
  borderDashOffset: 0.0,
  borderJoinStyle: "round",
  pointBorderColor: "#f83245",
  pointBackgroundColor: "#ffffff",
  pointBorderWidth: 3,
  pointHoverRadius: 6,
  pointHoverBorderWidth: 3,
  pointRadius: 4,
  pointHoverBackgroundColor: "#ffffff",
  pointHoverBorderColor: "#f83245"
};

class CashFlowGraph extends Component {
  getSplitChartData = () => {
    return this.props.data.reduce((result, row) => {
      if (_.isEmpty(result)) {
        result = {
          income: {},
          expense: {}
        };
      }
      Object.keys(row.expenseByCategory).map(category => {
        let categoryLabel = getLabelForCategory(category);
        result["expense"]["total"] = result["expense"]["total"] || 0;
        result["expense"][categoryLabel] =
          result["expense"][categoryLabel] || 0;
        result["expense"]["total"] += row.expenseByCategory[category];
        result["expense"][categoryLabel] += row.expenseByCategory[category];
        return result["expense"];
      });
      Object.keys(row.incomeByCategory).map(category => {
        let categoryLabel = getLabelForCategory(category);
        result["income"][categoryLabel] = result["income"][categoryLabel] || 0;
        result["income"]["total"] = result["income"]["total"] || 0;
        result["income"][categoryLabel] += row.incomeByCategory[category];
        result["income"]["total"] += row.incomeByCategory[category];
        return result["income"];
      });

      return result;
    }, {});
  };

  getLineChartData = () => {
    const result = this.props.data.reduce((result, row) => {
      if (_.isEmpty(result)) {
        result = {
          labels: [],
          xaxis: [],
          yaxis: []
        };
      }
      result["labels"].push(row["date"]);
      result["xaxis"].push(row["income"]);
      result["yaxis"].push(row["expense"]);
      return result;
    }, {});

    return {
      labels: result["labels"] || [],
      datasets: [
        {
          ...xaxisStyle,
          data: result["xaxis"] || [],
          datalebels: {
            display: false
          },
          label: "Income"
        },
        {
          ...yaxisStyle,
          data: result["yaxis"] || [],
          datalabels: {
            display: false
          },
          label: "Expense"
        }
      ]
    };
  };

  filterTransactions(filterTerm) {
    this.props.history.push(
      `${this.props.location.pathname}/ledger?search=${filterTerm}`
    );
  }

  renderCategoryPercentProgress = (category, data, isIncome, index) => {
    if (category === "total") return;
    const totalCount = data["total"];
    const categoryCount = data[category];
    const categoryPercent = (categoryCount * 100) / totalCount;

    return (
      <div className="mb-4" key={`${category}-category-item-${index}`}>
        <div className="line-height-1">
          <Nav>
            <NavItem>
              <NavLink
                onClick={() => this.filterTransactions(category)}
                style={{ padding: "0px" }}
              >
                <span className="font-size-md pr-3">{category}</span>
                <span className="font-size-lg font-weight-bold">
                  {numberFormatter(categoryCount)}
                </span>
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <div className="flex-grow-1">
            <Progress
              value={categoryPercent}
              className="progress-bar-rounded progress-sm"
              color={isIncome ? "success" : "danger"}
            />
          </div>
          <div className="text-dark font-weight-bold pl-3">
            {formatPercent.format(categoryPercent / 100)}
          </div>
        </div>
      </div>
    );
  };
  render() {
    const splitChartData = this.getSplitChartData();
    return (
      <Col xl="12">
        <Card className="card-box mb-5">
          <CardHeader>
            <div className="card-header--title">
              <h4 className="font-size-lg mb-0 py-2 font-weight-bold">
                Cash Flow
              </h4>
            </div>
            <div className="card-header--actions" />
          </CardHeader>
          <Row>
            <Col xl="6">
              <div className="sparkline-full-wrapper sparkline-full-wrapper--xxl px-4 mx-3">
                <Line data={this.getLineChartData()} options={chartOptions} />
              </div>
            </Col>
            <Col xl="6">
              <Row className="no-gutters my-4 font-weight-bold text-uppercase font-size-lg text-center">
                <Col lg="6" className="px-4">
                  Expense
                  <div className="divider bg-danger border-2 mt-2 h-auto border-danger" />
                </Col>
                <Col lg="6" className="px-4">
                  Income
                  <div className="divider bg-danger border-2 mt-2 h-auto border-success" />
                </Col>
              </Row>

              <Row className="no-gutters">
                <Col lg="6" className="px-4">
                  <div className="p-4 mb-4 rounded bg-secondary">
                    {splitChartData.expense &&
                      Object.keys(splitChartData.expense).map(
                        (expenseType, idx) => {
                          return this.renderCategoryPercentProgress(
                            expenseType,
                            splitChartData.expense,
                            false,
                            idx
                          );
                        }
                      )}
                  </div>
                </Col>
                <Col lg="6" className="px-4">
                  <div className="p-4 mb-4 rounded bg-secondary">
                    {splitChartData.income &&
                      Object.keys(splitChartData.income).map(
                        (incomeType, idx) => {
                          return this.renderCategoryPercentProgress(
                            incomeType,
                            splitChartData.income,
                            true,
                            idx
                          );
                        }
                      )}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Col>
    );
  }
}

export default CashFlowGraph;
