import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Button,
  CustomInput,
  FormGroup
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faAngleUp,
  faPlus,
  faMinus,
  faCheckCircle
} from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import Cards from "../Cards";
import { isEmpty } from "lodash";
import LoadingIndicator from "../../../shared/components/LoadingIndicator";

import { numberFormatter } from "../../../helpers";

const AddSubscription = ({
  activePlan,
  pricingPlans,
  addOns,
  selectedPlan,
  setSelectedPlan,
  loadingPlans,
  updateAddOn,
  createNewSubscription,
  cancelUserSubscription,
  paymentMethods,
  showDeleteModal,
  setCurrentPaymentMethod,
  currentPaymentMethod
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const toggle = () => setIsOpen(prevState => !prevState);

  useEffect(
    () => {
      let price = selectedPlan.price;
      if (addOns.length > 0) {
        addOns.forEach(addOn =>
          addOn.checked ? (price += addOn.quantity * addOn.price) : price
        );
      }
      setTotalPrice(price);
    },
    [selectedPlan, addOns]
  );

  const planDescriptionToArray = description => {
    return description
      .replace(/<ul>|<\/ul>|<li>/g, "")
      .split("</li>")
      .filter(Boolean);
  };

  return (
    <Card className="card-box mb-5">
      <CardHeader>Subscriptions</CardHeader>
      <CardBody>
        <div className="pricing-plan">
          <Row className="d-flex justify-content-center pricing-plan-details">
            {pricingPlans.length > 0 ? (
              pricingPlans.map(pricingPlan => (
                <Col
                  onClick={() =>
                    setSelectedPlan({
                      id: pricingPlan.id,
                      price: pricingPlan.price
                    })
                  }
                  className={
                    selectedPlan.id === pricingPlan.id
                      ? "active m-3 btn-input-select"
                      : "m-3 btn-input-select"
                  }
                  key={pricingPlan.id}
                >
                  <div className="selected-icon">
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                  <div className="card-body px-4 pb-4 pt-3 text-center">
                    <h3 className="font-size-xxl text-uppercase my-4 font-weight-bold ">
                      {pricingPlan.plan_name}
                    </h3>
                    <div className="bg-neutral-primary p-3 rounded mb-4">
                      <span className="display-2 font-weight-bold">
                        {numberFormatter(pricingPlan.price)}
                      </span>
                      <div className="text-black-50">Per month</div>
                    </div>
                    <ul className="list-unstyled text-lg-left mb-4 font-size-sm">
                      {planDescriptionToArray(pricingPlan.plan_description).map(
                        (description, idx) => {
                          return (
                            <li
                              className="px-4 py-2"
                              key={`${pricingPlan.id}-${idx}`}
                            >
                              <FontAwesomeIcon
                                icon={faCheckCircle}
                                className="text-success mr-2"
                              />
                              {description}
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </div>
                </Col>
              ))
            ) : (
              <LoadingIndicator />
            )}
          </Row>
        </div>
        <div className="pricing-plan mt-5">
          <div className="accordion mb-5 ml-2 w-50">
            <Card className={clsx("card-box", { "panel-open": isOpen })}>
              <Card>
                <CardHeader>
                  <div className="panel-title">
                    <div className="accordion-toggle">
                      <Button
                        color="link"
                        size="lg"
                        className="d-flex align-items-center justify-content-between"
                        onClick={toggle}
                      >
                        <span>Add-on Services</span>
                        <FontAwesomeIcon
                          icon={faAngleUp}
                          className="font-size-xl accordion-icon"
                        />
                      </Button>
                    </div>
                  </div>
                </CardHeader>
                <Collapse isOpen={isOpen}>
                  {addOns.length > 0 &&
                    addOns.map(addOn => (
                      <div
                        className="p-5 d-flex justify-content-start align-items-start flex-column"
                        key={addOn.id}
                      >
                        <FormGroup>
                          <div className="d-flex justify-content-start align-items-center w-100">
                            <CustomInput
                              className="mb-2"
                              type="checkbox"
                              id="exampleCustomCheckbox"
                              onChange={e =>
                                updateAddOn(addOn.id, "checked", !addOn.checked)
                              }
                            />
                            <h4>{addOn.name}</h4>
                          </div>
                        </FormGroup>
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <div className="d-flex justify-content-start align-items-center">
                            <h4>{numberFormatter(addOn.price)}</h4>
                            <div className="opacity-6">/hr</div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <Button
                              className="btn btn-primary"
                              disabled={addOn.quantity === 1}
                              onClick={() =>
                                updateAddOn(
                                  addOn.id,
                                  "quantity",
                                  addOn.quantity - 1
                                )
                              }
                            >
                              <FontAwesomeIcon icon={faMinus} />
                            </Button>
                            <div>{addOn.quantity}</div>
                            <Button
                              className="btn btn-primary"
                              onClick={() =>
                                updateAddOn(
                                  addOn.id,
                                  "quantity",
                                  addOn.quantity + 1
                                )
                              }
                            >
                              <FontAwesomeIcon icon={faPlus} />
                            </Button>
                          </div>
                        </div>
                      </div>
                    ))}
                </Collapse>
              </Card>
            </Card>
          </div>
        </div>
        <Cards
          paymentMethods={paymentMethods}
          currentPaymentMethod={currentPaymentMethod}
          setCurrentPaymentMethod={setCurrentPaymentMethod}
        />
        <div className="divider my-3" />
        <div className="payment-details">
          <div className="d-flex justify-content-center">
            <h5 className="mt-2">Pricing</h5>
            <div className="d-flex justify-content-center align-items-center ml-5">
              <h2>{numberFormatter(totalPrice)}</h2>/m
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-2">
            <Button
              className="btn mx-2"
              color="second"
              disabled={loadingPlans || isEmpty(activePlan)}
              onClick={() => cancelUserSubscription()}
            >
              Cancel
            </Button>
            <Button
              className="btn mx-2"
              color="primary"
              disabled={loadingPlans || isEmpty(currentPaymentMethod)}
              onClick={() => createNewSubscription()}
            >
              Subscribe
            </Button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default AddSubscription;
