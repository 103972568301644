import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import queryString from "query-string";

import { runReport } from "../../actions/export";
import DashFilter from "../../shared/components/DashFilter";
import SectionHeader from "../../shared/components/SectionHeader";

import { Card } from "reactstrap";
import { isFetching, isSuccess } from "../../reducers/reducerUtils";
import { isEmpty } from "lodash";
import PivotTable from "../../shared/components/PivotTable";

class ReportsDetail extends Component {
  constructor(props) {
    super(props);
    const currentType = new URLSearchParams(window.location.search).get("type");

    this.state = {
      loadingAssetInfo: true,
      currentFilter: props.currentFilter,
      dropdownOpen: false,
      showModal: false,
      type: currentType || "summary",
      reportData: {}
    };
  }

  componentDidMount = () => {
    const currentType = new URLSearchParams(window.location.search).get("type");
    const { currentFilter } = this.props;
    this.runReport({ type: currentType || "summary", filter: currentFilter });
  };

  componentDidUpdate = (prevProps, prevState) => {
    const currentType =
      queryString.parse(this.props.location.search).type || "summary";
    const { type, currentFilter } = this.state;
    if (currentType !== type) {
      this.runReport({ type: currentType, filter: currentFilter });
    }
  };

  toggleReport = type => {
    this.setState(
      prevState => ({ dropdownOpen: !prevState.dropdownOpen, type: type }),
      () => {
        if (type) this.runReport({ type: type, filter: this.state.filter });
      }
    );
  };
  onFilterChange = filter => {
    this.runReport({ type: this.state.type, filter: filter });
  };

  runReport = ({ type, filter }) => {
    this.setState(
      {
        loading: true,
        error: null,
        dropdownOpen: false,
        type: type,
        filter: filter
      },
      () => {
        let fromDate = filter.fromDate;
        let toDate = filter.toDate;
        if (!moment.isMoment(fromDate)) fromDate = moment(filter.fromDate);
        if (!moment.isMoment(toDate)) toDate = moment(filter.toDate);
        this.props.runReport({
          type: type,
          fromDate: fromDate.utcOffset(0, true),
          toDate: toDate.utcOffset(0, true)
        });
      }
    );
  };
  //
  static getDerivedStateFromProps(nextProps, prevState) {
    const { reportData } = nextProps;

    if (prevState.loading && !isEmpty(reportData)) {
      if (!isFetching(reportData)) {
        if (isSuccess(reportData)) {
          return {
            loading: false,
            reportData: reportData.data
          };
        } else {
          return {
            loading: false,
            error: reportData.data
          };
        }
      }
    }
    return null;
  }

  onPeriodChange = period => {
    this.setState(period);
  };

  reportComplete = () => {};

  setPivotTableRef = ref => {
    this.setState({ pivotTableRef: ref });
  };
  render() {
    const { reportData } = this.state;

    return (
      <div>
        <SectionHeader titleHeading="Report">
          <DashFilter
            onChange={this.onFilterChange}
            currentFilter={this.state.currentFilter}
          />
        </SectionHeader>
        <Card>
          {reportData && (
            <PivotTable
              toolbar={true}
              report="https://cdn.webdatarocks.com/reports/report.json"
              reportcomplete={this.reportComplete}
              setRef={this.setPivotTableRef}
            />
          )}
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  reportData: state.report,
  currentFilter: state.filter.currentFilter
});

const mapDispatchToProps = dispatch => ({
  runReport: data => runReport(data, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportsDetail);
