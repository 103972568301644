import React, { Component } from "react";
import { Badge } from "reactstrap";
import { Link } from "react-router-dom";
import TableWithSearch from "../../shared/components/TableWithSearch";
import { getLocalDate, formatCurrency } from "../../helpers";
import TransactionAction from "../AssetDetails/Transaction/TransactionAction";
import { categoryOptions } from "../../helpers/constants";
import { Type } from "react-bootstrap-table2-editor";

import UpdateTransaction from "../../components/AssetDetails/Transaction/UpdateTransaction";
import DeleteTransaction from "../../components/AssetDetails/Transaction/DeleteTransaction";
import DuplicateTransaction from "../../components/AssetDetails/Transaction/DuplicateTransaction";
import CategorySelect from "../../shared/components/form/CategorySelect";

class TransactionListing extends Component {
  columnProps = [
    {
      dataField: "category",
      text: "Category",
      sort: true,
      editable: true,
      formatter: (cell, row) => {
        let matchedOption = categoryOptions
          .flatMap(cat => cat.options)
          .find(option => option.value === cell.toLowerCase());
        return matchedOption ? matchedOption.label : cell;
      },
      editorRenderer: (
        editorProps,
        value,
        row,
        column,
        rowIndex,
        columnIndex
      ) => <CategorySelect {...editorProps} value={value} />
    },
    {
      dataField: "payee",
      text: "Payee",
      sort: true,
      editable: true
    },
    {
      dataField: "name",
      text: "Description",
      sort: true,
      editable: true
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
      formatter: (cell, row) => {
        if (row.amount) {
          return formatCurrency.format(row.amount);
        }
      }
    },
    {
      dataField: "date",
      text: "Date",
      sort: true,
      editor: {
        type: Type.DATE
      },
      formatter: (cell, row) => {
        if (row.date) {
          return getLocalDate(row.date);
        }
      }
    },
    {
      dataField: "type",
      text: "Type",
      sort: true,
      editable: true,
      editor: {
        type: Type.SELECT,
        options: [
          {
            value: "INCOME",
            label: "INCOME"
          },
          {
            value: "EXPENSE",
            label: "EXPENSE"
          }
        ]
      },
      formatter: (cell, row) => {
        return (
          <Badge color={row.type === "INCOME" ? "success" : "danger"}>
            {row.type}
          </Badge>
        );
      }
    },
    {
      text: "Asset",
      dataField: "asset",
      editable: false,

      formatter: (cell, row) => {
        return (
          <Link to={`/assets/${row.asset.slug}/ledger`}>
            {row.asset.asset_name}
          </Link>
        );
      }
    },
    {
      text: "Actions",
      dataField: "",
      editable: false,

      formatter: (cell, row) => {
        return (
          <TransactionAction
            onSelect={type => this.handleAction(type, row)}
            isReview={row.isPending || row.duplicate === "T"}
          />
        );
      }
    }
  ];

  handleAction = (type, row) => {
    this.setState({ actionType: type, selectedRow: row }, () => {});
  };

  closeModal = () => {
    this.setState({ selectedRow: null, actionType: null });
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedRow: null,
      actionType: null
    };
  }

  render() {
    const { transactions, totalCount } = this.props;
    if (!transactions) return <div />;
    const { actionType, selectedRow, queryParams } = this.state;
    return (
      <div>
        {selectedRow && (
          <DuplicateTransaction
            transaction={selectedRow}
            assetSlug={selectedRow.asset.slug}
            asset={selectedRow.asset}
            showModal={actionType === "duplicate"}
            closeModal={this.closeModal}
            queryParams={queryParams}
          />
        )}
        {selectedRow && (
          <DeleteTransaction
            transaction={selectedRow}
            assetSlug={selectedRow.asset.slug}
            showModal={actionType === "delete"}
            closeModal={this.closeModal}
            queryParams={queryParams}
          />
        )}
        {selectedRow && (
          <UpdateTransaction
            transaction={selectedRow}
            assetSlug={selectedRow.asset.slug}
            asset={selectedRow.asset}
            showModal={actionType === "edit"}
            closeModal={this.closeModal}
            queryParams={queryParams}
            {...this.props}
          />
        )}
        <TableWithSearch
          keyField="_id"
          data={transactions}
          totalSize={totalCount}
          page={this.props.page}
          sizePerPage={this.props.sizePerPage}
          columns={this.columnProps}
          onTableChange={this.props.handleTableChange}
          resetQueryParams={this.props.handleResetQueryParams}
          enableCellEdit={true}
          // location={this.props.location}
        />
      </div>
    );
  }
}
//
// const mapStateToProps = state => ({
//   transactions: state.transactions
// });
//
// const mapDispatchToProps = dispatch => ({
//   markAllPaid: data => markAllPaid(data, dispatch),
//   removeAllDuplicates: data => removeAllDuplicates(data, dispatch),
//   getAllTransactions: data => getAllTransactions(data, dispatch),
//   updateTransaction: data => updateTransaction(data, dispatch)
// });
//
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(TransactionListing);

export default TransactionListing;
