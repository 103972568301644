import React, { PureComponent, Fragment } from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";
// import Alert from '../../../shared/components/Alert';
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ConfirmModal from "../../../shared/components/ConfirmModal";

import { deleteMultiTransaction } from "../../../actions/transaction";

class DeleteSelectedTransaction extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      open: false,
      ids: []
    };
  }

  closeModal = () => {
    this.setState({ loading: false, error: null, open: false }, () => {});
  };

  deleteTransactions = () => {
    this.setState({ loading: false, error: null, open: true }, () => {
      let arr = [];
      for (let transaction of this.props.transactionsList) {
        arr.push(transaction);
      }
      this.setState({
        ids: arr
      });
    });
  };
  deleteTransactionNow = () => {
    this.setState({ loading: true, error: null, open: false }, () => {
      const { deleteTransaction, asset, unSelectOnDelete } = this.props;
      deleteTransaction({
        slug: asset.slug,
        data: this.state.ids
      });

      unSelectOnDelete();
    });
  };
  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps && this.state.loading) {
      const {
        transactions: { isMultiDeleteTransactionSuccess, error }
      } = nextProps;
      if (isMultiDeleteTransactionSuccess === true) {
        this.closeModal();
        // this.props.checkDupli();
      } else if (isMultiDeleteTransactionSuccess === false)
        this.setState({ loading: false, error });
    }
  }

  render() {
    return (
      <Fragment>
        <Button
          color="danger"
          className="mx-2"
          disabled={this.props.transactionsList.length === 0}
          onClick={this.deleteTransactions}
        >
          <span className="btn-wrapper--icon">
            <FontAwesomeIcon icon={faTrash} />
          </span>
          <span className="btn-wrapper--label">Delete</span>
        </Button>

        <ConfirmModal
          title={"Are you sure to delete this transaction?"}
          subtitle={"You cannot undo this operation."}
          type="danger"
          modalOpen={this.state.open}
          onCancel={this.closeModal}
          onSubmit={this.deleteTransactionNow}
          error={this.state.error}
          loading={this.state.loading}
          btnText={"Delete"}
          btnTextOnLoading={"Deleting"}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  transactions: state.transactions
});

const mapDispatchToProps = dispatch => {
  return {
    deleteTransaction: data => deleteMultiTransaction(data, dispatch)
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteSelectedTransaction);
