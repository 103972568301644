import React, { PureComponent, Fragment } from "react";
import { Link } from "react-router-dom";
import Alert from "../Alert";

import { Button, Modal } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";

import PropTypes from "prop-types";

export default class ConfirmModal extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    type: PropTypes.oneOf(["warning", "danger", "info", "export"]),
    modalOpen: PropTypes.bool,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    submitLink: PropTypes.string,
    error: PropTypes.string,
    loading: PropTypes.bool,
    btnTextOnLoading: PropTypes.string,
    btnText: PropTypes.string,
    hideCancel: PropTypes.bool
  };

  render() {
    const {
      modalOpen,
      onCancel,
      onSubmit,
      submitLink,
      title,
      subtitle,
      loading,
      btnTextOnLoading,
      btnText,
      error,
      hideCancel
    } = this.props;

    let icon;
    let type = this.props.type;
    switch (type) {
      case "warning":
        icon = faExclamation;
        break;
      case "info":
        icon = faInfo;
        break;
      case "export":
        icon = faFileExport;
        type = "primary";
        break;
      default:
        icon = faTimes;
    }
    return (
      <Modal zIndex={2000} centered isOpen={modalOpen} toggle={onCancel}>
        <div className="text-center p-5">
          <div className="avatar-icon-wrapper rounded-circle m-0">
            <div
              className={`d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-${type} text-${type} m-0 d-130`}
            >
              <FontAwesomeIcon
                icon={icon}
                className="d-flex align-self-center display-3"
              />
            </div>
          </div>

          {error && (
            <Alert color="danger" className="alert--bordered" icon>
              <p className="warning-font">
                <span className="bold-text">Error! </span>
                {error}
              </p>
            </Alert>
          )}
          {this.props.children ? (
            this.props.children
          ) : (
            <Fragment>
              <h4 className="font-weight-bold mt-4">{title}</h4>
              <p className="mb-0 font-size-lg text-muted">{subtitle}</p>
            </Fragment>
          )}
          <div className="pt-4">
            {!hideCancel && (
              <Button
                onClick={onCancel}
                disabled={loading}
                color="neutral-secondary"
                className="btn-pill mx-1"
              >
                <span className="btn-wrapper--label">Cancel</span>
              </Button>
            )}
            {submitLink ? (
              <Button
                to={submitLink}
                tag={Link}
                disabled={loading}
                color={type}
                className="btn-pill mx-1"
              >
                <span className="btn-wrapper--label">
                  {loading ? btnTextOnLoading : btnText}
                </span>
              </Button>
            ) : (
              <Button
                onClick={onSubmit}
                disabled={loading}
                color={type}
                className="btn-pill mx-1"
              >
                <span className="btn-wrapper--label">
                  {loading ? btnTextOnLoading : btnText}
                </span>
              </Button>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}
