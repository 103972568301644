import {
  SIGNUP_PARTNER,
  GET_PARTNER,
  LIST_PARTNERS,
  UPDATE_PARTNER,
  SUBMIT_TESTIMONIAL,
  GRANT_PERMISSION
} from "../actionTypes";

export const signupPartner = (data, dispatch) => {
  dispatch({
    type: SIGNUP_PARTNER,
    payload: data
  });
};

export const getPartner = (data, dispatch) => {
  dispatch({
    type: GET_PARTNER,
    payload: data
  });
};

export const updatePartner = (data, dispatch) => {
  dispatch({
    type: UPDATE_PARTNER,
    payload: data
  });
};

export const listPartners = (data, dispatch) => {
  dispatch({
    type: LIST_PARTNERS,
    payload: data
  });
};

export const submitTestimonial = (data, dispatch) => {
  dispatch({
    type: SUBMIT_TESTIMONIAL,
    payload: data
  });
};

export const grantPermission = (data, dispatch) => {
  dispatch({
    type: GRANT_PERMISSION,
    payload: data
  });
};
