import {
	GET_PLANS,
	GET_PAYMENT_METHODS,
	CREATE_PAYMENT_METHOD,
	CREATE_SUBSCRIPTION,
	UPDATE_PAYMENT_METHOD,
	UPDATE_SUBSCRIPTION,
	CANCEL_SUBSCRIPTION,
} from "../actionTypes";

export const getPlans = (dispatch, data) => {
	dispatch({
		type: GET_PLANS,
		payload: data,
	});
};

export const getPaymentMethods = (dispatch, data) => {
	dispatch({
		type: GET_PAYMENT_METHODS,
		payload: data,
	});
};

export const createPaymentMethod = (dispatch, data) => {
	dispatch({
		type: CREATE_PAYMENT_METHOD,
		payload: data,
	});
};

export const createSubscription = (dispatch, data) => {
	dispatch({
		type: CREATE_SUBSCRIPTION,
		payload: data,
	});
};

export const updatePaymentMethod = (dispatch, data) => {
	dispatch({
		type: UPDATE_PAYMENT_METHOD,
		payload: data,
	});
};

export const updateSubscription = (dispatch, data) => {
	dispatch({
		type: UPDATE_SUBSCRIPTION,
		payload: data,
	});
};

export const cancelSubscription = (dispatch, data) => {
	dispatch({
		type: CANCEL_SUBSCRIPTION,
		payload: data,
	});
};
