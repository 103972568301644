import React, { PureComponent } from "react";
import { connect } from "react-redux";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ButtonGroup
} from "reactstrap";
import { Formik, Field, FieldArray } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faFunnelDollar,
  faPlus,
  faDownload
} from "@fortawesome/free-solid-svg-icons";
import SelectField from "../../shared/components/form/SelectField";
import { isFetching, isSuccess } from "../../reducers/reducerUtils";
import CategorySelect from "../../shared/components/form/CategorySelect";

import { saveTransactions } from "../../actions/transaction";
import { getTxnFilterPattern, getTypeForCategory } from "../../helpers";

import {
  addAccountFilter,
  updateAccountFilter,
  importBankTransaction
} from "../../actions/accounts/accountActions";

const validationSchema = Yup.object().shape({
  assets: Yup.array().of(
    Yup.object({
      asset: Yup.string().required("Asset is required"),
      split: Yup.number()
        .required("Split is required")
        .max(100, "Amount is too large")
    })
  )
});

class ImportConfirmModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      showModal: false,
      imported: false,
      addingFilter: false,
      importingTransactions: false,
      importingFile: false
    };
  }

  toggleModal = () => {
    this.setState(prevState => ({
      showModal: !prevState.showModal,
      imported: false
    }));
  };

  importTransaction = values => {
    if (this.props.bankImport) {
      if (this.props.createFilter || this.props.editFilter) {
        let filter = getTxnFilterPattern(values.transactions[0]);
        let splits = values.assets.reduce((result, a) => {
          result[a.asset] = a.split;
          return result;
        }, {});

        let txnByAsset = {};
        values.assets.map(asset => {
          txnByAsset[asset.asset] = values.transactions.map(txn => {
            return {
              category: values.category || txn.category,
              type:
                values.type.toLowerCase() === "income" ? "INCOME" : "EXPENSE",
              date: txn.date,
              amount: (asset.split * txn.amount) / 100,
              name: txn.name,
              bankTxnId: txn.txnId
            };
          });
          return txnByAsset;
        });

        if (this.props.createFilter) {
          this.setState(
            {
              imported: false,
              addingFilter: true,
              importingTransactions: true
            },
            () => {
              this.props.addAccountFilter({
                accountId: this.props.accountId,
                pattern: filter.pattern,
                amount: filter.amount,
                splits: splits,
                category: values.category,
                type:
                  values.type.toLowerCase() === "income" ? "INCOME" : "EXPENSE"
              });
              this.props.importBankTransaction(txnByAsset);
            }
          );
        } else {
          this.setState({ imported: false, addingFilter: true }, () => {
            this.props.updateAccountFilter({
              accountId: this.props.accountId,
              pattern: filter.pattern,
              amount: filter.amount,
              splits: splits,
              category: values.category,
              filterId: values.filterId,
              type:
                values.type.toLowerCase() === "income" ? "INCOME" : "EXPENSE"
            });
          });
        }
      } else {
        let txnByAsset = {};
        values.assets.map(asset => {
          txnByAsset[asset.asset] = values.transactions.map(txn => {
            return {
              category: values.category || txn.category,
              type:
                values.type.toLowerCase() === "income" ? "INCOME" : "EXPENSE",
              date: txn.date,
              amount: (asset.split * txn.amount) / 100,
              name: txn.name,
              bankTxnId: txn.txnId
            };
          });
          return txnByAsset;
        });
        this.setState({ imported: false, importingTransactions: true }, () => {
          this.props.importBankTransaction(txnByAsset);
        });
      }
    } else {
      this.setState({ imported: false, importingFile: true }, () => {
        this.props.saveTransactions({
          slug: values.assets[0].slug,
          transactions: values.transactions
        });
      });
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      accountFilter,
      bankTransactionImport,
      fileTransactionImport
    } = nextProps;

    if (prevState.addingFilter && !isFetching(accountFilter)) {
      if (isSuccess(accountFilter)) {
        return {
          addingFilter: false,
          showModal: false,
          imported: true
        };
      } else {
        return {
          addingFilter: false,
          error: accountFilter.error,
          showModal: true
        };
      }
    }

    if (prevState.importingTransactions && !isFetching(bankTransactionImport)) {
      if (isSuccess(bankTransactionImport)) {
        return {
          importingTransactions: false,
          showModal: false,
          imported: true
        };
      } else {
        return {
          importingTransactions: false,
          error: bankTransactionImport.error,
          showModal: false
        };
      }
    }

    if (prevState.importingFile && !isFetching(fileTransactionImport)) {
      if (isSuccess(fileTransactionImport)) {
        return {
          importingFile: false,
          showModal: false,
          imported: true
        };
      } else {
        return {
          importingFile: false,
          error: fileTransactionImport.error,
          showModal: false
        };
      }
    }
    return null;
  }

  modalTitle = () => {
    if (this.props.createFilter) return "Create Filter";
    else if (this.props.editFilter) return "Update Filter";
    else return "Import Transaction";
  };

  actionButton = () => {
    if (this.props.createFilter || this.props.editFilter) {
      return (
        <Button color="primary" type="submit">
          <span className="btn-wrapper--icon">
            <FontAwesomeIcon icon={faFunnelDollar} />
          </span>
          <span className="btn-wrapper--label">
            {this.props.createFilter ? "Create" : "Update"}
          </span>
        </Button>
      );
    } else {
      return (
        <Button color="primary" type="submit">
          <span className="btn-wrapper--icon">
            <FontAwesomeIcon icon={faDownload} />
          </span>
          <span className="btn-wrapper--label">Import</span>
        </Button>
      );
    }
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.imported && !prevState.imported && this.props.onImport) {
      this.props.onImport();
    }

    if (
      !prevState.showModal &&
      !this.state.showModal &&
      (this.props.createFilter ||
        this.props.showImport ||
        this.props.editFilter)
    ) {
      this.setState({ showModal: true, imported: false });
    } else if (
      prevState.showModal &&
      !this.state.showModal &&
      this.props.onCancel
    ) {
      this.props.onCancel();
    }
  };

  render() {
    const { selectedTrans, assets, bankImport } = this.props;
    let defaultCategory = selectedTrans[0] ? selectedTrans[0].category : "";
    let initialValues = {
      transactions: selectedTrans,
      category: defaultCategory,
      type: getTypeForCategory(defaultCategory),
      assets: [{ _id: "", split: 100 }]
    };

    if (this.props.editFilter) {
      const { filter } = selectedTrans[0];
      initialValues.assets = filter.assetSplits;
      initialValues.assets.map(asset => (asset.split = asset.percent));
      initialValues.category = filter.category;
      initialValues.type = getTypeForCategory(initialValues.category);
      initialValues.filterId = filter._id;
    }

    return (
      <div>
        {!this.props.bankImport && (
          <Button size="sm" color="neutral-primary" onClick={this.toggleModal}>
            <span className="btn-wrapper--icon">
              <FontAwesomeIcon
                icon={faPlus}
                className="opacity-8 font-size-xs"
              />
            </span>
            <span className="btn-wrapper--label">Add to Asset</span>
          </Button>
        )}
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={this.importTransaction}
        >
          {props => {
            const { handleSubmit, values, setFieldValue } = props;
            return (
              <Modal
                size="lg"
                centered
                isOpen={this.state.showModal}
                toggle={this.toggleModal}
              >
                <ModalHeader toggle={this.toggleModal}>
                  {this.modalTitle()}
                </ModalHeader>
                <form onSubmit={handleSubmit}>
                  <ModalBody className="mx-3">
                    <div className="form-group row">
                      <label
                        htmlFor="staticEmail"
                        className="col-sm-2 col-form-label"
                      >
                        Update Category:
                      </label>
                      <div className="col-sm-10 inputblock">
                        <CategorySelect
                          name="category"
                          value={values.category}
                          onUpdate={value => {
                            setFieldValue("category", value);
                            setFieldValue("type", getTypeForCategory(value));
                          }}
                        />
                      </div>
                    </div>
                    <FieldArray
                      name="assets"
                      render={arrayHelpers => (
                        <div>
                          {values.assets && values.assets.length > 0 && (
                            <table className="table">
                              <thead>
                                <tr>
                                  <th
                                    scope="col"
                                    style={{ borderBottom: "none" }}
                                  >
                                    Asset
                                  </th>
                                  {bankImport && (
                                    <th
                                      scope="col"
                                      style={{
                                        borderBottom: "none"
                                      }}
                                    >
                                      Split
                                    </th>
                                  )}
                                  {bankImport && (
                                    <th
                                      scope="col"
                                      style={{ borderBottom: "none" }}
                                    >
                                      Action
                                    </th>
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {values.assets.map((t, i) => (
                                  <tr key={i}>
                                    <td className="col-md-4">
                                      <Field
                                        name={`assets.${i}.asset`}
                                        placeholder={"Asset"}
                                        className="form-control"
                                        component={SelectField}
                                        searchable={true}
                                        options={assets.map(asset => {
                                          return {
                                            value: asset._id,
                                            label: asset.address
                                          };
                                        })}
                                      />
                                    </td>
                                    {bankImport && (
                                      <td className="col-md-4">
                                        <Field
                                          name={`assets.${i}.split`}
                                          placeholder={"Split"}
                                          className="form-control"
                                          style={{ width: "150px" }}
                                        />
                                      </td>
                                    )}
                                    {bankImport && (
                                      <td className="col-md-2">
                                        <ButtonGroup>
                                          <Button
                                            color="neutral-primary"
                                            className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                                            onClick={() => {
                                              let splitRatio = Math.floor(
                                                100 / (values.assets.length + 1)
                                              );
                                              values.assets.forEach(
                                                (a, idx) => {
                                                  a.split = splitRatio;
                                                  arrayHelpers.replace(idx, a);
                                                }
                                              );
                                              arrayHelpers.push({
                                                slug: "",
                                                split: splitRatio
                                              });
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              icon={faPlus}
                                              className="font-size-sm"
                                            />
                                          </Button>

                                          <Button
                                            color="neutral-danger"
                                            className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                                            onClick={() => {
                                              let splitRatio = Math.floor(
                                                100 / (values.assets.length - 1)
                                              );
                                              values.assets.forEach(
                                                (a, idx) => {
                                                  a.split = splitRatio;
                                                  arrayHelpers.replace(idx, a);
                                                }
                                              );
                                              arrayHelpers.remove(i);
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              icon={faTimes}
                                              className="font-size-sm"
                                            />
                                          </Button>
                                        </ButtonGroup>
                                      </td>
                                    )}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          )}
                        </div>
                      )}
                    />
                  </ModalBody>
                  <ModalFooter>{this.actionButton()}</ModalFooter>
                </form>
              </Modal>
            );
          }}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  bankTransactionImport: state.transactions.bankTransactionImport,
  accountFilter: state.accountFilter,
  fileTransactionImport: state.transactions.transactions
});

const mapDispatchToProps = dispatch => {
  return {
    importBankTransaction: data => importBankTransaction(data, dispatch),
    saveTransactions: data => saveTransactions(data, dispatch),
    addAccountFilter: data => addAccountFilter(data, dispatch),
    updateAccountFilter: data => updateAccountFilter(data, dispatch)
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportConfirmModal);
