export default str => {
  if (typeof str == "string") {
    const splittedStr = str.toLowerCase().split(/[ -]/g);
    splittedStr.forEach((s, i) => {
      splittedStr[i] = s.charAt(0).toUpperCase() + s.substring(1);
    });
    return splittedStr.join(" ");
  }

  return "";
};
