import React, { PureComponent } from "react";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faHandHoldingDollar,
  faHouse,
  faCheck,
  faHouseUser,
} from "@fortawesome/free-solid-svg-icons";
import { faAirbnb } from "@fortawesome/free-brands-svg-icons";

class AssetTypeSelector extends PureComponent {
  render() {
    const {
      field,
      form: { setFieldValue },
    } = this.props;
    const assetTypes = {
      rental: {
        label: "Rental",
        icon: faHouse,
      },
      short_term_rental: {
        label: "Short Term Rental",
        icon: faAirbnb,
      },
      note: {
        label: "Private Lending",
        icon: faHandHoldingDollar,
      },
      syndication: {
        label: "Syndication",
        icon: faBuilding,
      },
      primary: {
        label: "Primary",
        icon: faHouseUser,
      },
    };

    return (
      <div className="d-flex justify-content-center">
        {Object.keys(assetTypes).map((type) => {
          const isActive = field.value === type;
          return (
            <a
              href="#/"
              onClick={() => setFieldValue(field.name, type)}
              className={clsx("m-3 btn-input-select", {
                active: isActive,
              })}
            >
              {isActive && (
                <div className="selected-icon">
                  <FontAwesomeIcon icon={faCheck} />
                </div>
              )}
              <div
                className={clsx(
                  "d-30 d-flex align-items-center justify-content-center rounded-pill",
                  {
                    "text-white bg-primary": isActive,
                    "text-white bg-second": !isActive,
                  }
                )}
              >
                <FontAwesomeIcon icon={assetTypes[type].icon} />
              </div>
              <div className="font-weight-bold mt-2">
                {assetTypes[type].label}
              </div>
            </a>
          );
        })}
      </div>
    );
  }
}

export default AssetTypeSelector;
