import {
  GET_ALL_DOCUMENTS,
  GET_ALL_DOCUMENTS_SUCCESS,
  GET_ALL_DOCUMENTS_FAILURE,
  UPLOAD_DOCUMENTS,
  UPLOAD_DOCUMENTS_SUCCESS,
  UPLOAD_DOCUMENTS_FAILURE,
  UPDATE_DOCUMENT,
  UPDATE_DOCUMENT_SUCCESS,
  UPDATE_DOCUMENT_FAILURE,
  DELETE_DOCUMENT,
  DELETE_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_FAILURE,
  DELETE_MULTIPLE_DOCUMENT,
  DELETE_MULTIPLE_DOCUMENT_SUCCESS,
  DELETE_MULTIPLE_DOCUMENT_FAILURE,
  RESET_DOCUMENTS
} from "../actions/actionTypes";

import {
  setLoadingState,
  setSuccessResponse,
  setErrorResponse
} from "./reducerUtils";

const initialState = {
  data: null,
  state: "INIT",
  error: null
};

const documentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_DOCUMENTS:
      return setLoadingState(state, "", "GET");
    case GET_ALL_DOCUMENTS_SUCCESS:
      return setSuccessResponse(state, action, "", "GET");
    case GET_ALL_DOCUMENTS_FAILURE:
      return setErrorResponse(state, action, "", "GET");

    case UPLOAD_DOCUMENTS:
      return setLoadingState(state, "", "UPLOAD");
    case UPLOAD_DOCUMENTS_SUCCESS:
      return setSuccessResponse(state, action, "", "UPLOAD");
    case UPLOAD_DOCUMENTS_FAILURE:
      return setErrorResponse(state, action, "", "UPLOAD");
    case DELETE_DOCUMENT:
      return setLoadingState(state, "", "DELETE");
    case DELETE_DOCUMENT_SUCCESS:
      return setSuccessResponse(state, action, "", "DELETE");
    case DELETE_DOCUMENT_FAILURE:
      return setErrorResponse(state, action, "", "DELETE");
    case DELETE_MULTIPLE_DOCUMENT:
      return setLoadingState(state, "", "DELETE_MULTIPLE");
    case DELETE_MULTIPLE_DOCUMENT_SUCCESS:
      return setSuccessResponse(state, action, "", "DELETE_MULTIPLE");
    case DELETE_MULTIPLE_DOCUMENT_FAILURE:
      return setErrorResponse(state, action, "", "DELETE_MULTIPLE");
    case RESET_DOCUMENTS:
      return setSuccessResponse(state, initialState);
    case UPDATE_DOCUMENT:
      return setLoadingState(state, "", "UPDATE");
    case UPDATE_DOCUMENT_SUCCESS:
      return setSuccessResponse(state, action, "", "UPDATE");
    case UPDATE_DOCUMENT_FAILURE:
      return setErrorResponse(state, action, "", "UPDATE");

    default:
      return state;
  }
};

export default documentReducer;
