import React from "react";
import { Card, CardBody, CardImg } from "reactstrap";
import { Link } from "react-router-dom";

import { numberFormatter } from "../../helpers";
import CarImage from "../../assets/images/car-icon.png";
import BathImage from "../../assets/images/bath-icon.png";
import CalendarImage from "../../assets/images/calender-icon.png";
import ChatImage from "../../assets/images/chat-icon.png";
import StarImage from "../../assets/images/star-icon.png";
import SqftImage from "../../assets/images/sqft-icon.png";
import BookmarkImage from "../../assets/images/bookmark-icon.png";

const OpportunityCard = ({ medias, posted_by, property, title, slug }) => {
  return (
    <Link to={`/opportunities/${slug}`}>
      <Card className="blog rounded border-0 ">
        <div className="position-relative">
          <CardImg
            top
            src={`https://cms.bettercapital.us${medias[0].url}`}
            className="rounded-top content-card-image"
            alt=""
          />
          <div className="overlay rounded-top bg-dark" />
          {property.neighborhood_rating && (
            <div className="date-posted">
              <img src={StarImage} alt="starimage" />
              <div className="star-text">{property.neighborhood_rating}</div>
            </div>
          )}
          {property.status === "sold" ? (
            <div className="button-sold">Sold Out</div>
          ) : (
            <div className="button-available">Available</div>
          )}
          <div className="heart-icon">
            <img src={BookmarkImage} alt="bookmarkImage" />
          </div>
        </div>
        <CardBody className="card-content">
          <div>
            <h6>{title}</h6>
          </div>
          {/* <div className="d-flex justify-content-start align-items-center">
                <div className="sale-indicator"></div>
                <p className="sale-text mb-0">For sale</p>
                </div> */}
          <hr className="card-margin-ruler" />
          <div className="d-flex justify-content-between align-items-center price-container">
            <div className="d-flex justify-content-center align-items-start flex-column">
              <div className="price-details">Price</div>
              <h5 className="mb-0 font-weight-bold">
                {numberFormatter(property.sale_price)}
              </h5>
            </div>
            <div className="d-flex justify-content-center align-items-start flex-column">
              <div className="price-details">Rent</div>
              <h6 className="mb-0">{numberFormatter(property.rent)}/m</h6>
            </div>
            <div className="d-flex justify-content-center align-items-start flex-column">
              <div className="price-details">Gross Yeild</div>
              <h6 className="mb-0">10%</h6>
            </div>
          </div>
          <hr className="card-margin-ruler" />
          <div className="d-flex justify-content-center align-items-center">
            <div className="d-flex justify-content-center align-items-center sale-details">
              <img src={CarImage} alt="bed" />
              <span className="sale-details-number">{property.beds}</span>
            </div>
            <div className="d-flex justify-content-center align-items-center sale-details">
              <img src={BathImage} alt="bath" />
              <span className="sale-details-number">{property.baths}</span>
            </div>
            <div className="d-flex justify-content-center align-items-center sale-details">
              <img src={SqftImage} alt="sqft" />
              <span className="sale-details-number">{property.lot_size}</span>
            </div>
            <div className="d-flex justify-content-center align-items-center sale-details">
              <img src={CalendarImage} alt="calendar" />
              <span className="sale-details-number">{property.year_build}</span>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <Link to={`/opportunities/${slug}`} className="email-button">
              <img src={ChatImage} alt="chat" /> &nbsp;&nbsp;&nbsp;
              <div className="text-center">{posted_by.name}</div>
            </Link>
          </div>
        </CardBody>
      </Card>
    </Link>
  );
};

export default OpportunityCard;
