import { put, call, takeLatest } from "redux-saga/effects";
import {} from "../api/agent";
import handleFailedAuth from "../helpers/handleExpiredToken";

import {
  SAVE_ATTACHMENTS,
  SAVE_ATTACHMENTS_SUCCESS,
  SAVE_ATTACHMENTS_FAILURE,
  GET_ATTACHMENTS,
  GET_ATTACHMENTS_FAILURE,
  GET_ATTACHMENTS_SUCCESS,
  GET_ATTACHMENT_URL,
  GET_ATTACHMENT_URL_FAILURE,
  GET_ATTACHMENT_URL_SUCCESS,
  DELETE_ATTACHMENT,
  DELETE_ATTACHMENT_FAILURE,
  DELETE_ATTACHMENT_SUCCESS,
  DELETE_ATTACHMENTS,
  DELETE_ATTACHMENTS_FAILURE,
  DELETE_ATTACHMENTS_SUCCESS,
  ADD_ATTACHMENT_TAGS,
  ADD_ATTACHMENT_TAGS_SUCCESS,
  ADD_ATTACHMENT_TAGS_FAILURE,
  GET_ASSET_CHECKLIST,
  REQUEST_DOCUMENT,
  REQUEST_DOCUMENT_SUCCESS,
  REQUEST_DOCUMENT_FAILURE,
  LINK_GOOGLE_FOLDER,
  LINK_GOOGLE_FOLDER_SUCCESS,
  LINK_GOOGLE_FOLDER_FAILURE,
  GET_DOCUMENT_URL,
  GET_DOCUMENT_URL_SUCCESS,
  GET_DOCUMENT_URL_FAILURE
} from "../actions/actionTypes";

import { Attachments, Document } from "../api/agent";
import showToast from "../helpers/toastNotification";

function* saveAttachments(action) {
  try {
    const { slug, params } = action.payload;
    const payload = yield call(
      Attachments.saveAttachments,
      action.payload,
      params
    );
    yield put({ type: GET_ASSET_CHECKLIST, payload: slug });
    yield put({ type: SAVE_ATTACHMENTS_SUCCESS, payload });
    showToast("s", "Attachment Uploaded Successfully");
  } catch (error) {
    if (error.response.status === 401 || error.response.statsCode === 401) {
      handleFailedAuth();
      return;
    }
    showToast("e", "Error while uploading Attachments");
    const err = error.response.body;
    yield put({ type: SAVE_ATTACHMENTS_FAILURE, err });
  }
}

export function* watchSaveAttachments() {
  yield takeLatest(SAVE_ATTACHMENTS, saveAttachments);
}

function* getAttachments(action) {
  try {
    const payload = yield call(Attachments.getAttachments, action.payload);
    yield put({ type: GET_ATTACHMENTS_SUCCESS, payload });
  } catch (error) {
    if (error.response.status === 401 || error.response.statsCode === 401) {
      handleFailedAuth();
      return;
    }
    const err = error.response.body;
    yield put({ type: GET_ATTACHMENTS_FAILURE, err });
  }
}

export function* watchGetAttachments() {
  yield takeLatest(GET_ATTACHMENTS, getAttachments);
}

function* getAttachmentUrl(action) {
  try {
    const payload = yield call(Attachments.getAttachmentUrl, action.payload);
    yield put({ type: GET_ATTACHMENT_URL_SUCCESS, payload });
  } catch (error) {
    if (error.response.status === 401 || error.response.statsCode === 401) {
      handleFailedAuth();
      return;
    }
    const err = error.response.body;
    yield put({ type: GET_ATTACHMENT_URL_FAILURE, err });
  }
}

export function* watchGetAttachmentUrl() {
  yield takeLatest(GET_ATTACHMENT_URL, getAttachmentUrl);
}

function* deleteAttachment(action) {
  try {
    const slug = action.payload.slug;
    const payload = yield call(Attachments.deleteAttachment, action.payload);
    yield put({ type: GET_ASSET_CHECKLIST, payload: slug });
    yield put({ type: DELETE_ATTACHMENT_SUCCESS, payload });
    showToast("s", "Attachment Deleted Successfully");
  } catch (error) {
    if (error.response.status === 401 || error.response.statsCode === 401) {
      handleFailedAuth();
      return;
    }
    const err = error.response.body;
    showToast("e", err.message || "Error while Deleting Attachments");

    yield put({ type: DELETE_ATTACHMENT_FAILURE, err });
  }
}

export function* watchDeleteAttachment() {
  yield takeLatest(DELETE_ATTACHMENT, deleteAttachment);
}

function* deleteAttachments(action) {
  try {
    const slug = action.payload.slug;
    const payload = yield call(Attachments.deleteAttachments, action.payload);
    yield put({ type: GET_ASSET_CHECKLIST, payload: slug });
    yield put({ type: DELETE_ATTACHMENTS_SUCCESS, payload });
    showToast("s", "Attachment Deleted Successfully");
  } catch (error) {
    if (error.response.status === 401 || error.response.statsCode === 401) {
      handleFailedAuth();
      return;
    }
    const err = error.response.body;
    showToast("e", err.message || "Error while Deleting Attachments");

    yield put({ type: DELETE_ATTACHMENTS_FAILURE, err });
  }
}

export function* watchDeleteAttachments() {
  yield takeLatest(DELETE_ATTACHMENTS, deleteAttachments);
}

function* addAttachmentTags(action) {
  try {
    const payload = yield call(Attachments.addTags, action.payload);
    yield put({ type: ADD_ATTACHMENT_TAGS_SUCCESS, payload });
    showToast("s", "Tags Added Successfully");
  } catch (error) {
    if (error.response.status === 401 || error.response.statsCode === 401) {
      handleFailedAuth();
      return;
    }
    const err = error.response.body;
    showToast("e", "Error While Adding Tags");

    yield put({ type: ADD_ATTACHMENT_TAGS_FAILURE, err });
  }
}

export function* watchAddAttachmentTags() {
  yield takeLatest(ADD_ATTACHMENT_TAGS, addAttachmentTags);
}

function* requestDocument(action) {
  try {
    const payload = yield call(Attachments.requestDocument, action.payload);
    yield put({ type: REQUEST_DOCUMENT_SUCCESS, payload });
    showToast("s", "Document Request Sent");
  } catch (error) {
    if (error.response.status === 401 || error.response.statsCode === 401) {
      handleFailedAuth();
      return;
    }
    const err = error.response.body;
    yield put({ type: REQUEST_DOCUMENT_FAILURE, err });
    showToast("e", "Error while trying to send document request");
  }
}

export function* watchRequestDocument() {
  yield takeLatest(REQUEST_DOCUMENT, requestDocument);
}

function* linkGoogleFolder(action) {
  try {
    const payload = yield call(Attachments.linkGoogleFolder, action.payload);
    yield put({ type: LINK_GOOGLE_FOLDER_SUCCESS, payload });
    showToast("s", "Linked Google folder");
  } catch (error) {
    if (error.response.status === 401 || error.response.statsCode === 401) {
      handleFailedAuth();
      return;
    }
    const err = error.response.body;
    yield put({ type: LINK_GOOGLE_FOLDER_FAILURE, err });
    showToast("e", "Error while trying link google folder");
  }
}

export function* watchLinkGoogleFolder() {
  yield takeLatest(LINK_GOOGLE_FOLDER, linkGoogleFolder);
}

function* getDocumentUrl(action) {
  try {
    const payload = yield call(Document.getDocumentUrl, action.payload);
    yield put({ type: GET_DOCUMENT_URL_SUCCESS, payload });
  } catch (error) {
    if (error.response.status === 401 || error.response.statsCode === 401) {
      handleFailedAuth();
      return;
    }
    const err = error.response.body;
    yield put({ type: GET_DOCUMENT_URL_FAILURE, err });
  }
}

export function* watchGetDocumentUrl() {
  yield takeLatest(GET_DOCUMENT_URL, getDocumentUrl);
}
