import React from "react";
import moment from "moment";
import { RRule } from "rrule";
import { Calendar, momentLocalizer } from "react-big-calendar";
// import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

const localizer = momentLocalizer(moment);

function Event({ event }) {
  return (
    <span>
      <strong>{event.title}</strong>
    </span>
  );
}
// const DragAndDropCalendar = withDragAndDrop(Calendar);

export default class CalendarComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: []
    };
  }
  static getRule = e => {
    let freq;
    switch (e.frequency) {
      case "Monthly":
        freq = RRule.MONTHLY;
        break;
      case "Yearly":
        freq = RRule.YEARLY;
        break;
      case "Daily":
        freq = RRule.DAILY;
        break;
      case "Weekly":
        freq = RRule.WEEKLY;
        break;
      default:
        return [];
    }
    return new RRule({
      freq: freq,
      interval: 1,
      dtstart: moment(e.start)
        .startOf("day")
        .toDate(),
      until: moment().add(1, "years")
    }).all();
  };
  static formatEventData = events => {
    return events.reduce((result, e) => {
      const rules = CalendarComponent.getRule(e);

      if (rules.length > 0) {
        rules.forEach(eventDate => {
          const recEvent = Object.assign({}, e);
          recEvent.start = new Date(eventDate);
          recEvent.end = new Date(eventDate);
          // recEvent.style = this.eventStyleGetter(e);
          result.push(recEvent);
        });
      } else {
        const oneTimeEvent = Object.assign({}, e);
        oneTimeEvent.start = new Date(e.start);
        oneTimeEvent.end = new Date(e.start);
        // oneTimeEvent.style = this.eventStyleGetter(e);
        result.push(oneTimeEvent);
      }
      return result;
    }, []);
  };

  eventStyleGetter = event => {
    let color;
    switch (event.status) {
      case "pending":
        color = "#11c5db";
        break;

      case "resolved":
        color = "#11c5db";
        break;

      default:
        color = "#11c5db";
        break;
    }

    return {
      backgroundColor: color,
      borderColor: color
    };
  };

  checkIfDateInFuture = data => {
    const selected = moment(data.date);
    if (selected.isBefore(moment().add(-1, "days"))) return;
    this.props.handleDateClick(data);
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.events.length !== prevState.events.length) {
      return {
        events: CalendarComponent.formatEventData(nextProps.events)
      };
    }
    return null;
  }

  render() {
    const { handleEventClick, handleDateClick } = this.props;
    return (
      <Calendar
        popup
        selectable
        localizer={localizer}
        components={{
          event: Event
        }}
        events={this.state.events}
        startAccessor="start"
        endAccessor="end"
        style={{ minHeight: 550 }}
        onSelectEvent={event => handleEventClick(event)}
        onSelectSlot={handleDateClick}
      />
    );
  }
}
