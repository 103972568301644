import React from "react";
import { Badge, Button } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import TableWithSearch from "../../../shared/components/TableWithSearch";
import moment from "moment";

import {
  getAssetStyleClass,
  getAssetTypeLabel,
  numberFormatter,
  formatCurrency,
  formatPercent,
  formatRelativeTime,
  unmaskCurrency
} from "../../../helpers";

const AssetTable = ({ assets, viewAsset, currentAssetType }) => {
  const columnProps = [
    {
      dataField: "address",
      text: "Asset",
      sort: true,
      style: {
        maxWidth: "200px"
      }
    },
    {
      dataField: "assetType",
      text: "Type",
      sort: true,
      formatter: (cell, row) => {
        return (
          <Badge
            className="py-2 m-1 h-auto font-size-md"
            color={getAssetStyleClass(row.assetType)}
          >
            {getAssetTypeLabel(row.assetType)}
          </Badge>
        );
      }
    },
    {
      dataField: "stats.cashflow.currMonth",
      text: "CashFlow",
      sort: true,
      hidden: currentAssetType !== "rental",
      formatter: (cell, row) => {
        return numberFormatter(row.stats.cashflow.currMonth);
      },
      style: {
        fontWeight: "Semibold",
        fontSize: "14px",
        color: "#171725"
      }
    },
    {
      dataField: "row.stats.noi.currMonth",
      text: "NOI",
      hidden: currentAssetType !== "rental",
      sort: true,
      formatter: (cell, row) => {
        return numberFormatter(row.stats.noi.currMonth);
      },
      style: {
        fontWeight: "Semibold",
        fontSize: "14px",
        color: "#171725"
      }
    },
    {
      dataField: "stats.cummCashflow",
      text: "Cashflow (Total)",
      sort: true,
      formatter: (cell, row) => {
        return numberFormatter(row.stats.cummCashflow);
      },
      style: {
        fontWeight: "Semibold",
        fontSize: "14px",
        color: "#171725"
      }
    },
    {
      dataField: "stats.investmentInfo",
      text: "Annual CoC",
      sort: true,
      hidden: currentAssetType !== "syndication",
      formatter: (cell, row) => {
        return formatPercent.format(cell.meta ? cell.meta.annual_coc / 100 : 0);
      },
      style: {
        fontWeight: "Semibold",
        fontSize: "14px",
        color: "#171725"
      }
    },

    {
      dataField: "stats.investmentInfo",
      text: "Interest",
      sort: true,
      hidden: currentAssetType !== "note",
      formatter: (cell, row) => {
        return formatPercent.format(cell.meta ? cell.meta.interest / 100 : 0);
      },
      style: {
        fontWeight: "Semibold",
        fontSize: "14px",
        color: "#171725"
      }
    },
    {
      dataField: "stats.equity",
      text: "Equity",
      sort: true,
      hidden: currentAssetType !== "rental",
      formatter: (cell, row) => {
        return formatCurrency.format(unmaskCurrency(row.stats.equity));
      },
      style: {
        fontWeight: "Semibold",
        fontSize: "14px",
        color: "#171725"
      }
    },
    {
      dataField: "stats.investmentInfo",
      text: "Investment Amount",
      sort: true,
      hidden: currentAssetType === "rental" || currentAssetType === "primary",
      formatter: (cell, row) => {
        return formatCurrency.format(
          unmaskCurrency(cell.meta ? cell.meta.investment_amount : 0)
        );
      },
      style: {
        fontWeight: "Semibold",
        fontSize: "14px",
        color: "#171725"
      }
    },
    {
      dataField: "investmentEndDate",
      text: "Maturity",
      sort: true,
      hidden: currentAssetType !== "syndication",
      formatter: (cell, row) => {
        let maturityDate = moment(new Date(cell));
        let daysToMaturity = 0;
        if (maturityDate.isValid()) {
          daysToMaturity = maturityDate.diff(moment(), "days");
        }
        return formatRelativeTime.format(daysToMaturity, "days");
      },
      style: {
        fontWeight: "Semibold",
        fontSize: "14px",
        color: "#171725"
      }
    },
    {
      text: "Actions",
      dataField: "",
      isDummyField: true,
      formatter: (cell, row) => {
        return (
          <>
            <Button
              className={"btn-sm btn-neutral-primary mr-2"}
              onClick={() => viewAsset(row)}
            >
              <FontAwesomeIcon icon={faEye} />
            </Button>
          </>
        );
      }
    }
  ];

  return (
    <div>
      <TableWithSearch
        keyField="_id"
        data={assets}
        totalSize={assets.length}
        page={1}
        sizePerPage={5}
        columns={columnProps}
        searchPlaceholder="Search Assets..."
        noDataIndicator="No Assets found!"
        enableCellEdit={false}
        remoteData={false}
        showSearch={false}
        // onSelectRow={selectRow}
      />
    </div>
  );
};

export default AssetTable;
