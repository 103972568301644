import React, { PureComponent } from "react";
import { getIn } from "formik";
import PropTypes from "prop-types";
import clsx from "clsx";
import _ from "lodash";

import {
  InputGroup,
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonGroup,
  Button,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";

import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { unmaskCurrency } from "../../../helpers";

const currencyMask = createNumberMask({
  prefix: "$",
  allowDecimal: true,
});

const percentMask = createNumberMask({
  suffix: "%",
  prefix: "",
  allowDecimal: true,
});

class FinancialInputField extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      frequency: props.frequencies[0],
      amount: "",
      isPercent: "",
      dropdownOpen: false,
    };
  }

  changeValue = (event) => {
    this.updateFormikState({
      amount: event.target.value,
    });
  };

  changeValueType = (isPercent) => {
    this.updateFormikState({
      isPercent: isPercent,
      amount: "",
    });
  };

  changeFrequency = (event) => {
    this.updateFormikState({ frequency: event.target.innerText });
  };

  toggleDropDown = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  updateFormikState = (value) => {
    const {
      field,
      form: { setFieldValue, getFieldMeta },
    } = this.props;

    let fieldValue = getFieldMeta(field.name).value;

    let amount = value.amount;
    if (amount === undefined) amount = _.get(fieldValue, "amount", "");
    let frequency = value.frequency;
    if (frequency === undefined)
      frequency = _.get(fieldValue, "frequency", this.props.frequencies[0]);
    let isPercent = value.isPercent;
    if (isPercent === undefined)
      isPercent = _.get(fieldValue, "isPercent", false);

    setFieldValue(field.name, {
      amount: unmaskCurrency(amount),
      isPercent,
      frequency,
    });
  };

  render() {
    const {
      label,
      field,
      form: { touched, errors, getFieldMeta },
      allowPercent,
      allowDaily,
      onlyPercent,
    } = this.props;

    let { frequencies } = this.props;

    const touch = getIn(touched, field.name);
    const error = getIn(errors, field.name);
    const isPercent =
      _.get(getFieldMeta(field.name).value, "isPercent") || onlyPercent;
    return (
      <div className={"financial-input form-group"}>
        {label && <label className="col-sm-2 col-form-label">{label}</label>}
        <InputGroup size={"lg"}>
          {(allowPercent && isPercent) || onlyPercent ? (
            <MaskedInput
              className={clsx("form-control", {
                "is-invalid": touch && error,
              })}
              value={_.get(getFieldMeta(field.name).value, "amount")}
              placeholder={"Enter Percent"}
              onChange={this.changeValue}
              mask={percentMask}
            />
          ) : (
            <MaskedInput
              className={clsx("form-control", {
                "is-invalid": touch && error,
              })}
              value={_.get(getFieldMeta(field.name).value, "amount")}
              placeholder={"Enter Amount"}
              onChange={this.changeValue}
              mask={currencyMask}
            />
          )}
          {frequencies.length > 1 ? (
            <InputGroupButtonDropdown
              addonType="append"
              isOpen={this.state.dropdownOpen}
              toggle={this.toggleDropDown}
            >
              <DropdownToggle color="first" caret>
                {_.get(
                  getFieldMeta(field.name).value,
                  "frequency",
                  frequencies[0]
                )}
              </DropdownToggle>
              <DropdownMenu className="dropdown_input">
                {frequencies.map((frequency, i) => {
                  return (
                    <DropdownItem
                      className="custom-dropdown-item"
                      onClick={this.changeFrequency}
                      key={i}
                    >
                      {frequency}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </InputGroupButtonDropdown>
          ) : (
            <InputGroupAddon addonType="append">
              <InputGroupText className="text-white bg-first">
                {frequencies[0]}
              </InputGroupText>
            </InputGroupAddon>
          )}
        </InputGroup>
        {allowPercent && !onlyPercent && (
          <ButtonGroup>
            <Button
              color="link"
              active={!isPercent}
              className="btn-link-primary p-2"
              onClick={() => this.changeValueType(false)}
            >
              <span>Dollar</span>
            </Button>
            <Button
              color="link"
              active={isPercent}
              className="btn-link-primary p-2"
              onClick={() => this.changeValueType(true)}
            >
              <span>Percent</span>
            </Button>
          </ButtonGroup>
        )}
        <div className="invalid-feedback">{error}</div>
      </div>
    );
  }
}

FinancialInputField.propTypes = {
  label: PropTypes.string,
  frequencies: PropTypes.array,
  allowPercent: PropTypes.bool,
  allowDaily: PropTypes.bool,
  onlyPercent: PropTypes.bool,
};

FinancialInputField.defaultProps = {
  frequencies: ["Monthly", "Yearly"],
  allowPercent: false,
  allowDaily: false,
  onlyPercent: false,
};

export default FinancialInputField;
