import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Button, Card } from "reactstrap";
import { deleteIntegration } from "../../../actions/integrations";

const GoogleIntegration = ({ activeTab, integrations, deleteIntegration }) => {
  const [googleIntegrationsData, setGoogleIntegrationsData] = useState({});
  useEffect(
    () => {
      const { data } = integrations;
      data.forEach(item => {
        if (item.type === "google") {
          setGoogleIntegrationsData(item);
        }
      });
    },
    [integrations]
  );
  const createIntegration = () => {
    window.location.href = `${process.env.REACT_APP_API_ROOT}/users/google`;
  };

  const viewDriveFolder = () => {
    window.open(googleIntegrationsData.storageUrl, "_blank");
  };

  const deleteCurrentIntegration = () => {
    deleteIntegration(googleIntegrationsData._id);
  };

  return (
    <>
      {googleIntegrationsData.type ? (
        <div className="my-5">
          <Card className="card-box m-5 p-4">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex justify-content-start align-items-center">
                <img
                  src="https://storage.googleapis.com/gweb-uniblog-publish-prod/images/Google_Drive.max-100x100.png"
                  alt="drive"
                  width="40px"
                  height="40px"
                />
                <img
                  src="https://storage.googleapis.com/gweb-uniblog-publish-prod/images/Google_Calendar.max-100x100.png"
                  alt="Calender"
                  width="40px"
                  height="40px"
                />
                <div>
                  <h5 className="title ml-5">Google Integrations Active</h5>
                </div>
              </div>
              <div className="text-center">
                <Button
                  color="primary"
                  className={"btn-pill mr-2"}
                  onClick={viewDriveFolder}
                >
                  <span className="btn-wrapper--label">View Folder</span>
                  <span className="btn-wrapper--icon">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </span>
                </Button>
                <Button
                  color="primary"
                  className={"btn-pill mr-2"}
                  onClick={deleteCurrentIntegration}
                >
                  <span className="btn-wrapper--label">Delete Integration</span>
                  <span className="btn-wrapper--icon">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </span>
                </Button>
              </div>
            </div>
          </Card>
        </div>
      ) : (
        <div className="text-center my-5">
          <Button
            color="primary"
            className={"btn-pill mr-2"}
            onClick={createIntegration}
          >
            <span className="btn-wrapper--icon">
              <FontAwesomeIcon icon={faPlus} />
            </span>
            <span className="btn-wrapper--label">Add Google</span>
          </Button>
        </div>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    integrations: state.integrations
  };
};

const mapDispatchToProps = dispatch => ({
  deleteIntegration: data => deleteIntegration(dispatch, data)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(GoogleIntegration));
