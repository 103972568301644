import React, { Component } from "react";
import { connect } from "react-redux";
import { deleteEntity } from "../../actions/asset/assetAction";
import ConfirmModal from "../../shared/components/ConfirmModal";

class DeleteEntity extends Component {
  constructor() {
    super();
    this.state = {
      error: null
    };
  }
  closeModal = () => {
    const { toggleModal } = this.props;
    this.setState({ loading: false, error: null }, () => {
      toggleModal({});
    });
  };

  deleteEntity = () => {
    this.setState({ loading: true, error: null }, () => {
      console.log(this.props);
      const { entity } = this.props;
      this.props.deleteEntity({
        id: entity._id
      });
    });
  };

  render() {
    const { entity, modalOpen } = this.props;
    return (
      <ConfirmModal
        title={`Are you sure to delete ${entity && entity.name}?`}
        subtitle={"You cannot undo this operation."}
        type="danger"
        modalOpen={modalOpen}
        onCancel={this.closeModal}
        onSubmit={this.deleteEntity}
        btnText={"Delete"}
        btnTextOnLoading={"Deleting"}
      />
    );
  }
}

const mapStateToProps = state => ({
  entities: state.asset.entities
});

const mapDispatchToProps = dispatch => ({
  deleteEntity: data => deleteEntity(dispatch, data)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteEntity);
