/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import CountUp from "react-countup";
import { Line } from "react-chartjs-2";

import { Row, Col, CardBody } from "reactstrap";
import LoadingIndicator from "../../shared/components/LoadingIndicator";
import AssetMedias from "../AssetDetails/AssetMedias";

const data3Success = {
  labels: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August"
  ],
  datasets: [
    {
      backgroundColor: "rgba(27, 201, 67, 0.1)",
      borderCapStyle: "round",
      borderDash: [],
      borderWidth: 2,
      borderColor: "#1bc943",
      borderDashOffset: 0.0,
      borderJoinStyle: "round",
      pointBorderColor: "#1bc943",
      pointBackgroundColor: "#ffffff",
      pointBorderWidth: 0,
      pointHoverRadius: 0,
      pointHoverBackgroundColor: "#ffffff",
      pointHoverBorderColor: "#1bc943",
      pointHoverBorderWidth: 0,
      pointRadius: 0,
      pointHitRadius: 0,
      data: [65, 59, 80, 81, 55, 38, 59, 80, 46],
      datalabels: {
        display: false
      }
    }
  ]
};
const data3SuccessOptions = {
  layout: {
    padding: {
      left: -10,
      right: 0,
      top: 0,
      bottom: -10
    }
  },
  scales: {
    yAxes: [
      {
        ticks: {
          display: false,
          beginAtZero: true
        },
        gridLines: {
          display: false,
          drawBorder: false
        }
      }
    ],
    xAxes: [
      {
        ticks: {
          display: false,
          beginAtZero: true
        },
        gridLines: {
          display: false,
          drawBorder: false
        }
      }
    ]
  },
  legend: {
    display: false
  },
  responsive: true,
  maintainAspectRatio: false
};

export default class DealOverview extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1"
    };
  }

  toggle = tab => {
    if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
  };
  render() {
    const { basicInfo, assetSlug } = this.props;
    if (!basicInfo) {
      return <LoadingIndicator />;
    }
    return (
      <div className="no-gutters">
        <CardBody className="px-4 pt-4">
          <Row>
            <Col xl={6}>
              <div className="d-flex align-items-center font-size-l justify-content-between p-3">
                <div>
                  <b>Address</b>
                </div>
                <div className="font-weight-bold text-first font-size-xl">
                  {basicInfo.address}
                </div>
              </div>
              <div className="divider" />
              <div className="d-flex align-items-center font-size-l justify-content-between p-3">
                <div>
                  <b>Bed(s)</b>
                </div>
                <div className="font-weight-bold text-first font-size-xl">
                  {basicInfo.beds}
                </div>
              </div>
              <div className="divider" />
              <div className="d-flex align-items-center font-size-l justify-content-between p-3">
                <div>
                  <b>Bath(s)</b>
                </div>
                <div className="font-weight-bold text-first font-size-xl">
                  {basicInfo.baths}
                </div>
              </div>
              <div className="divider" />
              <div className="d-flex align-items-center font-size-l justify-content-between p-3">
                <div>
                  <b>Floor Size Sq Ft</b>
                </div>
                <div className="font-weight-bold text-first font-size-xl">
                  <CountUp
                    start={0}
                    end={parseInt(basicInfo.areaSquareFeet)}
                    duration={6}
                    deplay={2}
                    separator=""
                    decimals={0}
                    decimal=","
                  />
                </div>
              </div>
              <div className="divider" />
              <div className="d-flex align-items-center font-size-l justify-content-between p-3">
                <div>
                  <b>Lot Size Sq Ft</b>
                </div>
                <div className="font-weight-bold text-first font-size-xl">
                  <CountUp
                    start={0}
                    end={parseInt(basicInfo.lotSizeSquareFeet)}
                    duration={6}
                    deplay={2}
                    separator=""
                    decimals={0}
                    decimal=","
                  />
                </div>
              </div>
            </Col>
            <Col xl={6}>
              <div className="h-50">
                <AssetMedias slug={assetSlug} readOnly={true} />
              </div>
            </Col>
          </Row>
        </CardBody>
        <div className="sparkline-full-wrapper">
          <Line data={data3Success} options={data3SuccessOptions} />
        </div>
      </div>
    );
  }
}
