import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import SectionHeader from "../../shared/components/SectionHeader";
import Pricing from "../../shared/components/Pricing";
import IntegrationTabs from "./IntegrationTabs";
import { getIntegrations, addIntegration } from "../../actions/integrations";
import { subscriptionLimit } from "../../helpers";
import { setCurrentFilter } from "../../actions/auth/authAction";

const Integrations = ({
  auth,
  history,
  integrations,
  getIntegrations,
  addIntegration,
  currentFilter,
  setCurrentFilter,
}) => {
  useEffect(() => {
    getIntegrations();
  }, []);
  useEffect(() => {
    const code = new URLSearchParams(window.location.search).get("code");
    if (code) {
      addIntegration({ type: "google", options: { code } });
      history.push("/integrations?tab=google");
    }
  }, []);

  const limit = subscriptionLimit(auth.user, "integration");

  return (
    <main className="p-5">
      <SectionHeader titleHeading={"Integrations"} />
      {integrations.data.length >= limit ? (
        <Pricing user={auth.user} />
      ) : (
        <IntegrationTabs
          integrations={integrations}
          setCurrentFilter={setCurrentFilter}
          currentFilter={currentFilter}
        />
      )}
    </main>
  );
};

const mapStateToProps = (state) => {
  return { integrations: state.integrations, auth: state.login, currentFilter: state.filter.currentFilter };
};

const mapDispatchToProps = (dispatch) => ({
  getIntegrations: (pagination) => getIntegrations(dispatch, pagination - 1),
  addIntegration: (data) => addIntegration(dispatch, data),
  setCurrentFilter: (data) => setCurrentFilter(data, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Integrations));
