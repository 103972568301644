import React, { Component } from "react";
import { connect } from "react-redux";
import ConfirmModal from "../../../shared/components/ConfirmModal";
import { deleteTransaction } from "../../../actions/transaction";
import { isFetching, isSuccess } from "../../../reducers/reducerUtils";

class DeleteTransaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      closeModal: false
    };
  }

  deleteTransaction = () => {
    this.setState({ loading: true, error: null }, () => {
      const {
        deleteTransaction,
        assetSlug,
        transaction: { _id },
        queryParams
      } = this.props;
      deleteTransaction({
        slug: assetSlug,
        transaction: { _id: _id },
        query: queryParams
      });
    });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      transactions: { transactions }
    } = nextProps;

    if (
      prevState.loading &&
      !isFetching(transactions) &&
      transactions.type === "DELETE"
    ) {
      if (isSuccess(transactions)) {
        return { loading: false, closeModal: true };
      } else {
        return { loading: false, error: transactions.error, closeModal: true };
      }
    }
    return false;
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (!prevState.closeModal && this.state.closeModal) {
      this.props.closeModal();
    }
  };

  render() {
    return (
      <ConfirmModal
        title={"Are you sure to delete this transaction?"}
        subtitle={"You cannot undo this operation."}
        type="danger"
        modalOpen={this.props.showModal}
        onCancel={this.props.closeModal}
        onSubmit={this.deleteTransaction}
        error={this.state.error}
        loading={this.state.loading}
        btnText={"Delete"}
        btnTextOnLoading={"Deleting"}
      />
    );
  }
}

const mapStateToProps = state => ({
  transactions: state.transactions
});

const mapDispatchToProps = dispatch => {
  return {
    deleteTransaction: data => deleteTransaction(data, dispatch)
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteTransaction);
