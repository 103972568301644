import React, { Component } from "react";
import { connect } from "react-redux";
import clsx from "clsx";

import ChatUserCard from "./ChatUserCard";
import ChatSidebar from "./ChatSidebar";
import socketIoObject from "./socketinit";

class ChatCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contacts: [],
      conversations: [],
      conversationsLoaded: false,
      contactLoaded: false,
      queryParams: null,
      userId: null,
      conversationId: props.match.params.conversationId,
      activeConversation: null,
      currentMessages: null,
      chats: [],
      chatsLoaded: false,
      activeContact: null,
      defaultMessage: "",
      openContacts: false,
      loadConversation: null
    };
  }

  componentDidMount() {
    const { user } = this.props.auth;
    socketIoObject.emit("USER_CONNECTED", { auth: user });

    socketIoObject.on("USER_CONNECTED_SUCCESS", () => {
      socketIoObject.emit("GET_CONVERSATIONS", { auth: user });
    });

    socketIoObject.on("GET_CONVERSATIONS_SUCCESS", data => {
      this.setState({
        conversations: data.conversations,
        conversationsLoaded: true,
        loadConversation: this.props.match.params.conversationId
      });
    });

    socketIoObject.on("GET_CONVERSATION_SUCCESS", data => {
      this.setState({
        chats: data.chats,
        chatsLoaded: true
      });
    });

    socketIoObject.on("GOT_NEW_MESSAGE", data => {
      const { activeConversation } = this.state;
      if (data.conversationId === activeConversation._id) {
        let chats = this.state.chats;
        chats.push(data);
        this.setState({ chats });
      }
    });
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { loadConversation, conversations } = this.state;

    if (prevState.loadConversation === null && loadConversation !== null) {
      let activeConversation = conversations.find(
        conversation => conversation._id === loadConversation
      );
      this.setState({ activeConversation, loadConversation: null }, () => {
        if (activeConversation)
          socketIoObject.emit("GET_CONVERSATION", {
            auth: this.props.auth,
            conversation: activeConversation
          });
      });
    }
  };

  componentWillUnmount() {
    const { user } = this.props.auth;
    socketIoObject.emit("USER_DISCONNECT", { auth: user });
    socketIoObject.off("USER_CONNECTED_SUCCESS");
    socketIoObject.off("GOT_NEW_MESSAGE");
    socketIoObject.off("GET_CONVERSATIONS_SUCCESS");
    socketIoObject.off("GET_CONVERSATION_SUCCESS");
  }

  onOpenChat = (conversation, defaultMessage, e) => {
    this.setState(
      {
        activeConversation: conversation
      },
      () => {
        socketIoObject.emit("GET_CONVERSATION", {
          auth: this.props.auth,
          conversation
        });
      }
    );
  };

  render() {
    const { conversations, conversationsLoaded, chats } = this.state;
    const { user } = this.props.auth;

    if (!conversationsLoaded) return <div />;
    return (
      <div className="app-inner-content-layout ">
        <ChatSidebar
          conversations={conversations}
          onClick={this.onOpenChat}
          activeConversation={this.state.activeConversation}
          isInnerSidebarOpen={this.props.isInnerSidebarOpen}
        />
        <ChatUserCard
          currentUser={user}
          activeContact={user}
          chats={chats}
          activeConversation={this.state.activeConversation}
          defaultMessage={this.state.defaultMessage}
        />
        <div
          onClick={this.toggleSidebarMenu}
          className={clsx("sidebar-inner-layout-overlay", {
            active: this.state.toggleSidebarMenu
          })}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.login,
  chat: state.chat
});

export default connect(
  mapStateToProps,
  null
)(ChatCard);
