import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getAllTransactions,
  updateTransaction
} from "../../actions/transaction/";
import SectionHeader from "../../shared/components/SectionHeader";
import TransactionListing from "./TransactionListing";
import DashFilter from "../../shared/components/DashFilter";
import { isFetching, isSuccess } from "../../reducers/reducerUtils";
import moment from "moment";

class Transactions extends Component {
  constructor(props) {
    super(props);
    let queryParams = new URLSearchParams(
      "offset=0&limit=10&sortBy=date&orderBy=desc"
    );
    if (props.currentFilter.entity) {
      queryParams.set("entity", props.currentFilter.entity);
    }
    if (props.currentFilter.status) {
      queryParams.set("status", props.currentFilter.status);
    }
    if (props.currentFilter.txnType) {
      queryParams.set("type", props.currentFilter.txnType);
    }
    queryParams.set("from", props.currentFilter.fromDate.format("MM-DD-YYYY"));
    queryParams.set("to", props.currentFilter.toDate.format("MM-DD-YYYY"));

    this.state = {
      loading: true,
      error: false,
      currentFilter: props.currentFilter,
      queryParams: queryParams.toString(),
      transactions: [],
      totalCount: 0,
      page: 1,
      sizePerPage: 10
    };
  }

  componentDidMount = () => {
    this.queryTransactions();
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      transactions: { transactions }
    } = nextProps;

    if (!prevState.loading && isFetching(transactions)) {
      return {
        loading: true
      };
    }
    if (prevState.loading && !isFetching(transactions)) {
      if (isSuccess(transactions) && transactions.type === "GET_ALL") {
        return {
          loading: false,
          transactions: transactions.data.transactions,
          totalCount: transactions.data.totalCount
        };
      }
    }
  }

  onFilterChange = filter => {
    let queryParams = new URLSearchParams(this.state.queryParams);
    if (filter.entity) {
      queryParams.set("entity", filter.entity);
    }
    if (filter.status) {
      queryParams.set("status", filter.status);
    }
    if (filter.txnType) {
      queryParams.set("type", filter.txnType);
    }
    queryParams.set("from", moment(filter.fromDate).format("MM-DD-YYYY"));
    queryParams.set("to", moment(filter.toDate).format("MM-DD-YYYY"));

    this.setState(
      {
        currentFilter: filter,
        loading: true,
        queryParams: queryParams.toString()
      },
      () => {
        this.queryTransactions();
      }
    );
  };

  handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, searchText, data, cellEdit }
  ) => {
    if (cellEdit) {
      const { rowId, dataField, newValue } = cellEdit;

      if (rowId && newValue) {
        // if there was a edit operation, we need to call update transactions
        let rowData = data.find(row => row._id === rowId);
        rowData[dataField] = newValue;
        this.setState(
          {
            updating: true
          },
          () => {
            if (rowData.asset)
              this.props.updateTransaction({
                slug: rowData.asset.slug,
                transaction: rowData,
                query: this.state.queryParams
              });
          }
        );
      }
    } else {
      let queryParams = new URLSearchParams(this.state.queryParams);
      let currentSearchText = this.state.searchText;
      if (searchText) currentSearchText = searchText;
      queryParams.set("offset", page - 1);
      if (sortField) queryParams.set("sortBy", sortField);
      if (sortOrder) queryParams.set("orderBy", sortOrder);
      if (currentSearchText) queryParams.set("search", currentSearchText);
      this.setState(
        {
          loading: true,
          page: page,
          sizePerPage: sizePerPage,
          queryParams: queryParams.toString()
        },
        () => {
          this.queryTransactions();
        }
      );
    }
  };

  handleResetQueryParams = () => {
    let queryParams = new URLSearchParams(this.state.queryParams);
    queryParams.delete("search");
    this.setState(
      { searchText: null, queryParams: queryParams.toString() },
      () => {
        this.queryTransactions();
      }
    );
  };

  queryTransactions = () => {
    this.props.getAllTransactions({ query: this.state.queryParams.toString() });
  };

  render() {
    return (
      <main className="container-fluid p-5">
        <SectionHeader
          pathItems={[
            { label: "Dashboard", path: "/dashboard", active: false },
            {
              label: "Transactions",
              path: `/transactions`,
              active: false
            }
          ]}
        >
          <DashFilter
            onChange={this.onFilterChange}
            currentFilter={this.state.currentFilter}
            showTxnType={true}
          />
        </SectionHeader>

        <TransactionListing
          currentFilter={this.state.currentFilter}
          queryParams={this.state.queryParams}
          handleResetQueryParams={this.handleResetQueryParams}
          transactions={this.state.transactions}
          totalCount={this.state.totalCount}
          page={this.state.page}
          sizePerPage={this.state.sizePerPage}
          handleTableChange={this.handleTableChange}
        />
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {
    transactions: state.transactions,
    currentFilter: state.filter.currentFilter
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAllTransactions: data => getAllTransactions(data, dispatch),
    updateTransaction: data => updateTransaction(data, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Transactions);
