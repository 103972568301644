import React, { Component, Fragment } from "react";
import { Formik, Field } from "formik";
import SlidingPane from "react-sliding-pane";

import * as Yup from "yup";
import {
  Form,
  FormGroup,
  Label,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Progress
} from "reactstrap";
import { addIntegration, syncIntegration } from "../../../actions/integrations";
import { connect } from "react-redux";
import FormField from "../../../shared/components/form/FormField";
import SelectField from "../../../shared/components/form/SelectField";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { isFetching, isSuccess } from "../../../reducers/reducerUtils";

const validationSchema = Yup.object({
  portalUrl: Yup.string()
    .trim()
    .required("Portal url is required"),
  userName: Yup.string("").required("Username is required"),
  password: Yup.string("").required("Password is required")
});

class ConnectPropertyManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      error: null,
      showModal: false,
      counter: 0
    };
  }

  toggleModal = () => {
    this.setState(prevState => ({
      showModal: !prevState.showModal,
      saving: false,
      error: null,
      counter: 0
    }));
  };

  setupCounter = () => {};

  propertyManagerName = (type, portalUrl) => {
    if (type === "propertyware") {
      let companyMatches = portalUrl.match(
        /(?<=owner\/).*$|(?<=portals\/)(.*)(?=\/owner.action)/g
      );
      if (companyMatches.length === 1) return companyMatches[0];
    } else if (type === "rentmanager") {
      let companyMatches = portalUrl.match(/\w+(?=.owa.rentmanager.com)/g);
      if (companyMatches.length === 1) return companyMatches[0];
    }
    return null;
  };

  saveIntegration = values => {
    this.setState({ saving: true, error: null }, () => {
      const { type, portalUrl } = values;
      values.pwDomain = this.propertyManagerName(type, portalUrl);
      this.props.addIntegration({
        type: values.type,
        options: values
      });
    });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { integrations } = nextProps;
    if (
      prevState.saving &&
      (integrations.type === "ADD" || integrations.type === "UPDATE")
    ) {
      if (!isFetching(integrations)) {
        if (isSuccess(integrations)) {
          return {
            saving: false,
            showModal: false,
            error: "",
            counter: 0
          };
        }
      }
    }

    return null;
  }

  render() {
    const { saving, showModal, counter } = this.state;
    let initialValues = {
      type: "",
      portalUrl: "",
      userName: "",
      password: "",
      lastSync: "",
      autoSync: true,
      syncOn: 5
    };
    let portalUrlPlaceholder = "";

    let counterId;
    if (saving) {
      counterId = setInterval(() => {
        this.setState(prevState => ({
          counter: prevState.counter + 1
        }));
      }, 500);
    }

    if (counterId && (!saving || counter >= 100)) {
      clearInterval(counterId);
    }

    return (
      <>
        <Button
          size="lg"
          color="primary"
          className={"btn-pill mr-2"}
          onClick={this.toggleModal}
        >
          <span className="btn-wrapper--icon">
            <FontAwesomeIcon icon={faPlus} />
          </span>
          <span className="btn-wrapper--label">Add Property Manager</span>
        </Button>
        <SlidingPane
          isOpen={showModal}
          from="right"
          width="350px"
          hideHeader={true}
          className="d-flex justify-content-center modal-content"
          onRequestClose={this.toggleModal}
        >
          <ModalHeader toggle={this.toggleModal}>
            Connect Property Manager
          </ModalHeader>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={this.saveIntegration}
            render={props => {
              const { handleSubmit } = props;
              return (
                <Fragment>
                  <ModalBody>
                    <Form onSubmit={handleSubmit}>
                      {saving && (
                        <FormGroup>
                          <Label>Fetching data</Label>
                          <Progress
                            animated
                            className="progress-sm progress-bar-rounded"
                            color="info"
                            value={counter}
                          />
                          <div className="align-box-row progress-bar--label mt-2 text-muted">
                            <div className="ml-auto">{`${counter}%`}</div>
                          </div>
                        </FormGroup>
                      )}
                      <FormGroup>
                        <Label>Platform</Label>
                        <Field
                          name="type"
                          component={SelectField}
                          options={[
                            { value: "propertyware", label: "Propertyware" },
                            { value: "rentmanager", label: "Rent Manager" }
                          ]}
                          placeholder="Select Platform"
                          onChange={platform => {
                            if (platform === "propertyware") {
                              portalUrlPlaceholder =
                                "https://app.propertyware.com/pw/index.html#/login/owner/<pmcompany>";
                            } else if (platform === "rentmanager") {
                              portalUrlPlaceholder =
                                "https://<propertymanager>.owa.rentmanager.com";
                            }
                          }}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="exampleEmail"> Portal URL</Label>
                        <Field
                          name="portalUrl"
                          placeholder={portalUrlPlaceholder}
                          component={FormField}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label>User</Label>
                        <Field name="userName" component={FormField} />
                      </FormGroup>
                      <FormGroup>
                        <Label>Password</Label>
                        <Field
                          name="password"
                          type="password"
                          component={FormField}
                        />
                      </FormGroup>
                      {/* <FormGroup>
                    <Label>Last Synced</Label>
                    <Field
                      name="lastSync"
                      component={DatePickerField}
                      disabled={true}
                    />
                  </FormGroup>
                  <Row>
                    <Col lg={2}>
                      <FormGroup>
                        <Label>Auto Sync</Label>
                        <Field
                          name="autoSync"
                          component={ToggleSwitch}
                          type={"number"}
                        />
                      </FormGroup>
                    </Col>
                    {values.autoSync && (
                      <Col lg={3}>
                        <FormGroup>
                          <Label>Sync every month on</Label>
                          <Field name="syncOn" component={FormField} />
                        </FormGroup>
                      </Col>
                    )}
                  </Row> */}
                      <ModalFooter className="d-flex justify-content-end">
                        <Button
                          color="primary"
                          className="btn savebtns btn-primary px-5  mx-2"
                          type="submit"
                          disabled={saving}
                        >
                          {saving ? "Adding" : "Add"}
                        </Button>
                      </ModalFooter>
                    </Form>
                  </ModalBody>
                </Fragment>
              );
            }}
          />
        </SlidingPane>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    integrations: state.integrations
  };
};

const mapDispatchToProps = dispatch => ({
  addIntegration: data => addIntegration(dispatch, data),
  syncIntegration: data => syncIntegration(dispatch, data)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectPropertyManager);
