import ReactDOM from "react-dom";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as WebDataRocks from "webdatarocks";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

// import "webdatarocks/webdatarocks.min.css";
import "webdatarocks/theme/lightblue/webdatarocks.min.css";
import "webdatarocks/webdatarocks.googlecharts";

const PivotTable = props => {
  const pivotTableRef = React.createRef();
  const [instance, setInstance] = useState(null);
  let instanceObj;
  const parseProps = (config: Params) => {
    if (props.toolbar !== undefined) {
      config.toolbar = props.toolbar;
    }
    if (props.width !== undefined) {
      config.width = props.width;
    }
    if (props.height !== undefined) {
      config.height = props.height;
    }
    if (props.report !== undefined) {
      config.report = props.report;
    }
    if (props.global !== undefined) {
      config.global = props.global;
    }
    if (props.customizeCell !== undefined) {
      config.customizeCell = props.customizeCell;
    }
    // events
    if (props.cellclick !== undefined) {
      config.cellclick = props.cellclick;
    }
    if (props.celldoubleclick !== undefined) {
      config.celldoubleclick = props.celldoubleclick;
    }
    if (props.dataerror !== undefined) {
      config.dataerror = props.dataerror;
    }
    if (props.datafilecancelled !== undefined) {
      config.datafilecancelled = props.datafilecancelled;
    }
    if (props.dataloaded !== undefined) {
      config.dataloaded = props.dataloaded;
    }
    if (props.datachanged !== undefined) {
      config.datachanged = props.datachanged;
    }
    if (props.fieldslistclose !== undefined) {
      config.fieldslistclose = props.fieldslistclose;
    }
    if (props.fieldslistopen !== undefined) {
      config.fieldslistopen = props.fieldslistopen;
    }
    if (props.filteropen !== undefined) {
      config.filteropen = props.filteropen;
    }
    if (props.fullscreen !== undefined) {
      config.fullscreen = props.fullscreen;
    }
    if (props.loadingdata !== undefined) {
      config.loadingdata = props.loadingdata;
    }
    if (props.loadinglocalization !== undefined) {
      config.loadinglocalization = props.loadinglocalization;
    }
    if (props.loadingreportfile !== undefined) {
      config.loadingreportfile = props.loadingreportfile;
    }
    if (props.localizationerror !== undefined) {
      config.localizationerror = props.localizationerror;
    }
    if (props.localizationloaded !== undefined) {
      config.localizationloaded = props.localizationloaded;
    }
    if (props.openingreportfile !== undefined) {
      config.openingreportfile = props.openingreportfile;
    }
    if (props.querycomplete !== undefined) {
      config.querycomplete = props.querycomplete;
    }
    if (props.queryerror !== undefined) {
      config.queryerror = props.queryerror;
    }
    if (props.ready !== undefined) {
      config.ready = props.ready;
    }
    if (props.reportchange !== undefined) {
      config.reportchange = props.reportchange;
    }
    if (props.reportcomplete !== undefined) {
      config.reportcomplete = props.reportcomplete;
    }
    if (props.reportfilecancelled !== undefined) {
      config.reportfilecancelled = props.reportfilecancelled;
    }
    if (props.reportfileerror !== undefined) {
      config.reportfileerror = props.reportfileerror;
    }
    if (props.reportfileloaded !== undefined) {
      config.reportfileloaded = props.reportfileloaded;
    }
    if (props.runningquery !== undefined) {
      config.runningquery = props.runningquery;
    }
    if (props.update !== undefined) {
      config.update = props.update;
    }
    if (props.beforetoolbarcreated !== undefined) {
      config.beforetoolbarcreated = props.beforetoolbarcreated;
    }
  };

  useEffect(() => {
    if (pivotTableRef.current) {
      var config = {};
      config.container = ReactDOM.findDOMNode(pivotTableRef.current);
      parseProps(config);
      instanceObj = new WebDataRocks(config);
      setInstance(instanceObj);
      props.setInstance(instanceObj);
    }

    return () => {
      if (instance) instance.dispose();
    };
  }, []);

  const exportData = type => {
    instance.exportTo(type, {
      filename: "bettercapital-export"
    });
  };

  return (
    <>
      <div>
        <Button
          color="first"
          className="m-2"
          onClick={() => exportData("excel")}
        >
          <span className="btn-wrapper--icon">
            <FontAwesomeIcon icon={faFileExcel} />
          </span>
          <span className="btn-wrapper--label">Export(Excel)</span>
        </Button>
        <Button
          color="second"
          className="m-2"
          onClick={() => exportData("pdf")}
        >
          <span className="btn-wrapper--icon">
            <FontAwesomeIcon icon={faFilePdf} />
          </span>
          <span className="btn-wrapper--label">Export(PDF)</span>
        </Button>
      </div>
      <div ref={pivotTableRef} />
    </>
  );
};

PivotTable.propTypes = {
  global: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
  report: PropTypes.object,
  toolbar: PropTypes.bool,
  customizeCell: PropTypes.func,
  cellclick: PropTypes.func,
  celldoubleclick: PropTypes.func,
  dataerror: PropTypes.func,
  datafilecancelled: PropTypes.func,
  dataloaded: PropTypes.func,
  datachanged: PropTypes.func,
  fieldslistclose: PropTypes.func,
  fieldslistopen: PropTypes.func,
  filteropen: PropTypes.func,
  fullscreen: PropTypes.func,
  loadingdata: PropTypes.func,
  loadinglocalization: PropTypes.func,
  loadingreportfile: PropTypes.func,
  localizationerror: PropTypes.func,
  localizationloaded: PropTypes.func,
  openingreportfile: PropTypes.func,
  querycomplete: PropTypes.func,
  queryerror: PropTypes.func,
  ready: PropTypes.func,
  reportchange: PropTypes.func,
  reportcomplete: PropTypes.func,
  reportfilecancelled: PropTypes.func,
  reportfileerror: PropTypes.func,
  reportfileloaded: PropTypes.func,
  runningquery: PropTypes.func,
  update: PropTypes.func,
  beforetoolbarcreated: PropTypes.func
};

export default PivotTable;
