import React, { useMemo } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { Field, Formik } from "formik";
import SelectField from "../../../shared/components/form/SelectField";
import { connect } from "react-redux";

const LinkAsset = ({
  modalOpen,
  toggleModal,
  assetSlug,
  handleSubmit,
  assetList
}) => {
  const initialValues = {
    parent_slug: "",
    asset_slug: assetSlug
  };

  const options = useMemo(
    () => {
      if (assetList) {
        return assetList.map(asset => {
          return {
            value: asset.slug,
            label: asset.asset_name
          };
        });
      } else {
        return [];
      }
    },
    [assetList]
  );

  return (
    <Modal isOpen={modalOpen} toggle={toggleModal} centered>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ handleSubmit }) => {
          return (
            <>
              <ModalHeader>Link to Asset</ModalHeader>
              <ModalBody>
                <Field
                  name="parent_slug"
                  component={SelectField}
                  options={options}
                  placeholder="Select Parent Asset"
                />
              </ModalBody>
              <ModalFooter>
                <div className={"d-flex justify-content-end"}>
                  <Button className={"btn-sm mr-2"} onClick={toggleModal}>
                    Cancel
                  </Button>
                  <Button
                    className={"btn-sm mr-2"}
                    color={"primary"}
                    onClick={() => {
                      handleSubmit();
                      toggleModal();
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </ModalFooter>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = state => {
  return {
    assetList: state.assetList.data
  };
};

export default connect(
  mapStateToProps,
  null
)(LinkAsset);
