import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import {
	Badge,
	ListGroup,
	ListGroupItem,
	Nav,
	NavItem,
	Container,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
} from "reactstrap";
import { NavLink as NavLinkStrap } from "reactstrap";

import { logout } from "../../../actions/auth/authAction";
import DefaultUser from "../../../img/default_user.png";

const HeaderUserbox = ({ auth, logout, onlyImage = false, className = "" }) => {
	return (
		<>
			<UncontrolledDropdown className={`position-relative ml-4 ${className}`}>
				<DropdownToggle
					color="link"
					className="p-0 text-left d-flex btn-transition-none align-items-center"
				>
					<div className="d-block p-0 avatar-icon-wrapper">
						<Badge color="success" className="badge-circle p-top-a">
							Online
						</Badge>
						<div className="avatar-icon rounded">
							<img src={auth.user.picture || DefaultUser} alt="..." />
						</div>
					</div>
					{onlyImage ? (
						<div />
					) : (
						<div className="d-none d-xl-block pl-2">
							<div className="font-weight-bold">{auth.user.fullName}</div>
							<span className="text-black-50">{auth.user.email}</span>
						</div>
					)}
				</DropdownToggle>
				<DropdownMenu right className="dropdown-menu-lg overflow-hidden p-0">
					<ListGroup flush className="text-left bg-transparent">
						<ListGroupItem className="rounded-top">
							<Nav pills className="nav-neutral-primary flex-column">
								<NavItem>
									<Container tag={NavLink} to="/settings" className="nav-link">
										Settings
									</Container>
								</NavItem>
								<NavItem>
									<NavLinkStrap href="#/" onClick={() => logout()}>
										Logout
									</NavLinkStrap>
								</NavItem>
							</Nav>
						</ListGroupItem>
					</ListGroup>
				</DropdownMenu>
			</UncontrolledDropdown>
		</>
	);
};

const mapStateToProps = (state) => ({
	auth: state.login,
});

const mapDispatchToProps = (dispatch) => ({
	logout: () => {
		logout(dispatch);
	},
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(HeaderUserbox);
