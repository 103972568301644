import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { getNeighborhoodHighlights } from "../../actions/opportunity";

import { isFetching, isSuccess, isError } from "../../reducers/reducerUtils";

const NeighborhoodReview = ({
	zip,
	getNeighborhoodHighlights,
	neighborhoodHighlights,
}) => {
	const [neighborhoodReview, setNeighborhoodReview] = useState({});
	const [error, setError] = useState(false);
	useEffect(
		() => {
			getNeighborhoodHighlights(zip);
		},
		[zip]
	);

	useEffect(
		() => {
			if (
				!isFetching(neighborhoodHighlights) &&
				isSuccess(neighborhoodHighlights)
			) {
				let { data } = neighborhoodHighlights;
				if (data) {
					setNeighborhoodReview(data[0]);
				}
			}
			if (isError(neighborhoodHighlights)) setError(true);
		},
		[neighborhoodHighlights]
	);
	if (error) return <div className="review-text" />;
	return (
		<div className="review-text">
			{neighborhoodReview ? neighborhoodReview.highlights : ""}
		</div>
	);
};

const mapStateToProps = (state) => ({
	neighborhoodHighlights: state.opportunity.neighborhoodHighlights,
});

const mapDispatchToProps = (dispatch) => ({
	getNeighborhoodHighlights: (slug) =>
		getNeighborhoodHighlights(dispatch, slug),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(NeighborhoodReview);
