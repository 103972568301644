/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from "react";

import { Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import DescriptionItem from "../../shared/components/form/DescriptionItem";
import NumberFormat from "react-number-format";
import OwnerCard from "./OwnerCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { faDotCircle } from "@fortawesome/free-solid-svg-icons";
import { faHandHoldingUsd } from "@fortawesome/free-solid-svg-icons";
import { faWallet } from "@fortawesome/free-solid-svg-icons";

export default class NoteAnalyzer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1"
    };
  }

  toggle = tab => {
    if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
  };
  render() {
    const { basicInfo } = this.props;
    const {
      investmentInfo: {
        marketValue,
        investmentAmount,
        interestRate,
        lienPosition,
        isPerforming
      }
    } = this.props;
    return (
      <Fragment>
        <Row>
          <Col xl="5">
            <Card className="card-box mb-5">
              <CardHeader>
                <h4 className="font-size-lg mb-0 py-2 font-weight-bold">
                  Summary
                </h4>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col className="col-xl-6">
                    <div className="inputblock py-2">
                      <label htmlFor="staticEmail" className="col-form-label">
                        Investment Amount:{" "}
                        <NumberFormat
                          value={investmentAmount}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                          decimalScale={2}
                        />
                      </label>
                      <div className="inputblock pt-2" />
                    </div>
                    <div className="inputblock  py-2">
                      <label htmlFor="staticEmail" className="col-form-label">
                        Market Value:{" "}
                        <NumberFormat
                          value={marketValue}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                          decimalScale={2}
                        />
                      </label>
                      <div className="inputblock  pt-2" />
                    </div>
                    <div className="inputblock  py-2">
                      <label htmlFor="staticEmail" className="col-form-label">
                        Lien Position: {lienPosition === 1 ? " 1st" : " 2nd"}
                      </label>
                      <div className="inputblock  pt-2" />
                    </div>
                    <div className="inputblock  py-2">
                      <label htmlFor="staticEmail" className="col-form-label">
                        Performing: {isPerforming === "1" ? "Yes" : "No"}
                      </label>
                      <div className="inputblock  pt-2" />
                    </div>
                  </Col>
                  <Col className="col-xl-6">
                    <ul className="list-group list-group-flush">
                      <DescriptionItem
                        label="Interest Rate"
                        value={
                          <NumberFormat
                            value={interestRate}
                            displayType={"text"}
                            renderText={value => `${value}%`}
                            decimalScale={2}
                          />
                        }
                        icon={faDotCircle}
                        iconClass="text-primary mr-2"
                      />
                      <DescriptionItem
                        label="Cash Flow (Monthly)"
                        value={
                          <NumberFormat
                            value={(investmentAmount * interestRate) / 1200}
                            displayType={"text"}
                            renderText={value => `${value}`}
                            decimalScale={2}
                          />
                        }
                        icon={faHandHoldingUsd}
                        iconClass="text-primary mr-2"
                      />
                      <DescriptionItem
                        label="Loan To Value"
                        value={
                          <NumberFormat
                            value={(investmentAmount / marketValue) * 100}
                            displayType={"text"}
                            renderText={value => `${value}%`}
                            decimalScale={2}
                          />
                        }
                        icon={faWallet}
                        iconClass="text-primary mr-2"
                      />
                    </ul>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xl={3}>
            <Card className="card-box mb-5">
              <CardHeader>
                <h4 className="font-size-lg mb-0 py-2 font-weight-bold">
                  Highlights
                </h4>
              </CardHeader>
              <CardBody>
                <ul className="list-unstyled text-left my-4 font-weight-bold">
                  {basicInfo.dealHighlights.length > 0 ? (
                    basicInfo.dealHighlights.map((highlight, idx) => {
                      return (
                        <li className="px-4 py-2" key={`deal-highlight-${idx}`}>
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="text-success font-size-lg mr-2"
                          />
                          {highlight}
                        </li>
                      );
                    })
                  ) : (
                    <div>No Highlight Provided</div>
                  )}
                </ul>
              </CardBody>
            </Card>
          </Col>
          <Col xl={3}>
            <OwnerCard basicInfo={basicInfo} />
          </Col>
        </Row>
      </Fragment>
    );
  }
}
