import React, { Component } from "react";
import { Field, FieldArray, withFormik } from "formik";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import FinancialInputField from "../../../../shared/components/form/FinancialInputField";
import * as Yup from "yup";

import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

const currencyMask = createNumberMask({
  prefix: "$",
  allowDecimal: true
});

const InlineCurrencyField = ({
  type,
  field,
  form: { touched, errors },
  ...props
}) => (
  <MaskedInput
    {...field}
    mask={currencyMask}
    type={type}
    {...props}
    className="form-control"
  />
);

class Financials extends Component {
  render() {
    const {
      values,
      backHandler,
      handleSubmit,
      cantBack,
      isSubmitting
    } = this.props;
    return (
      <div className="wizard-steps horizontal">
        <form className="px-2" onSubmit={handleSubmit}>
          <Row className="p-4" xl={2}>
            <Col md={6}>
              {values.units === 1 && (
                <FormGroup>
                  <Label>Rent</Label>
                  <Field name="rent" component={FinancialInputField} />
                </FormGroup>
              )}
              <FormGroup>
                <Label>Property Taxes</Label>
                <Field
                  name="property_tax"
                  component={FinancialInputField}
                  frequencies={["Yearly", "Half-Yearly"]}
                />
              </FormGroup>
              <FormGroup>
                <Label>Insurance</Label>
                <Field name="insurance" component={FinancialInputField} />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>HOA Fee</Label>
                <Field name="hoa_fee" component={FinancialInputField} />
              </FormGroup>
              <FormGroup>
                <Label>PM Fee</Label>
                <Field
                  name="management_fee"
                  component={FinancialInputField}
                  allowPercent={true}
                />
              </FormGroup>
            </Col>
          </Row>
          {values.units > 1 && (
            <Row className="p-4">
              <FieldArray
                name="rents"
                render={arrayHelpers => {
                  return (
                    <table className="table">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            style={{ borderBottom: "none" }}
                            className="ft"
                          >
                            Unit
                          </th>
                          <th scope="col" style={{ borderBottom: "none" }}>
                            Rent
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {values &&
                          values.rents.map((r, i) => (
                            <tr key={i}>
                              <td className="ft">
                                <Field
                                  name={`rents.${i}.name`}
                                  className="form-control"
                                />
                              </td>
                              <td>
                                <Field
                                  component={InlineCurrencyField}
                                  name={`rents.${i}.amount`}
                                  type="text"
                                  placeholder="Enter amount"
                                />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  );
                }}
              />
            </Row>
          )}
          <div className={"d-flex justify-content-end col-12 py-3"}>
            <Button
              className={"mr-4"}
              outline
              color={"primary"}
              onClick={() => backHandler(values)}
              disabled={cantBack || isSubmitting}
            >
              Prev
            </Button>
            <Button
              color={"primary"}
              type="submit"
              disabled={cantBack || isSubmitting}
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

const FinancialsForm = withFormik({
  mapPropsToValues: props => ({
    parcel_id: props.asset.parcel_id,
    investment_type: props.investment_type || "rental",
    entity: props.asset.entity,
    user_role: props.user_role,
    address: props.asset.address,
    beds: props.asset.beds,
    baths: props.asset.baths,
    co_ordinates: props.asset.co_ordinates,
    units: props.asset.units,
    stories: props.asset.stories,
    asset_type: props.asset.asset_type,
    lotSizeSquareFeet: props.asset.lotSizeSquareFeet,
    areaSquareFeet: props.asset.areaSquareFeet,
    apn: props.asset.apn,
    county: props.asset.county,
    yearBuilt: props.asset.yearBuilt,
    imageUrl: props.asset.imageUrl,
    management_platform: props.asset.management_platform,
    purchase_date: props.asset.purchase_date,
    purchase_price: props.asset.purchase_price,
    down_payment: props.asset.down_payment,
    market_value: props.asset.market_value,
    market_rent: props.asset.market_rent,
    property_tax: props.asset.property_tax,
    insurance: props.asset.insurance,
    hoa_fee: props.asset.hoa_fee,
    self_managed: props.asset.self_managed,
    management_fee: props.asset.management_fee,
    rent: props.asset.rent,
    rents: props.asset.rents
  }),

  validationSchema: Yup.object().shape({}),

  handleSubmit: (values, { props }) => {
    props.nextHandler(values);
  }
})(Financials);

export default FinancialsForm;
