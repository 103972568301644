import React from "react";
import { Card, Nav, NavItem, TabContent, TabPane } from "reactstrap";
import { NavLink as NavLinkStrap } from "reactstrap";
import GoogleIntegration from "./GoogleIntegration";
import PropertyManagers from "../PropertyManagers";
import { useParams, withRouter } from "react-router-dom";
import { isEmpty } from "lodash";

import clsx from "clsx";

const IntegrationTabs = ({
  history,
  integrations,
  currentFilter,
  setCurrentFilter
}) => {
  const { type } = useParams();

  const toggle = type => {
    history.push(`/integrations/${type}`);
  };
  return <Card className="card-box mb-5">
      <div className="nav-tabs-first tabs-animated tabs-bordered tabs-animated-shadow">
        <Nav tabs>
          <NavItem>
            <NavLinkStrap className={clsx({
                active: type === "google" || isEmpty(type),
              })} onClick={() => {
                toggle("google");
              }}>
              <span>Google</span>
            </NavLinkStrap>
          </NavItem>
          <NavItem>
            <NavLinkStrap className={clsx({
                active: type === "propertymanagers",
              })} onClick={() => {
                toggle("propertymanagers");
              }}>
              <span>Property Managers</span>
            </NavLinkStrap>
          </NavItem>
        </Nav>
      </div>
      <TabContent activeTab={type || "google"}>
        <TabPane tabId="google">
          <GoogleIntegration integrations={integrations} data={integrations.data.find((i) => i.type === "google")} />
        </TabPane>
        <TabPane tabId="propertymanagers">
          <PropertyManagers integrations={integrations} setCurrentFilter={setCurrentFilter} currentFilter={currentFilter} />
        </TabPane>
      </TabContent>
    </Card>;
};

export default withRouter(IntegrationTabs);
