/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";
import holderImage from "../../img/property_placeholder.jpg";
import { Link } from "react-router-dom";
import { capAllWords, formatCurrency } from "../../helpers";

import { getDeals } from "../../actions/deal";

class Flip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPostDeal: false
    };
  }

  url = window.location.origin;

  componentWillMount() {
    this.props.getDeals("flip");
  }

  toggleShowPostDeal = () => {
    this.setState(prevState => ({ showPostDeal: !prevState.showPostDeal }));
  };

  render() {
    const { dealProp } = this.props;
    const deals = dealProp.deals;
    return (
      <div>
        <div className="tab-content border-top border-0" id="myTabContent">
          <div
            className="tab-pane fade show active border-0"
            id="resentblog"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            {/* <h3 className="pb-3 webinartitle">
              Properties you plan to buy, rehab and flip for a profit.</h3> */}
            <div className="row">
              {deals.length === 0 && <p>No Flips available</p>}

              {deals.map((asset, i) => (
                <Link
                  className="col-sm-12 col-md-6 col-lg-6 col-xl-6"
                  to={`/deals/${asset.slug}`}
                  key={i}
                >
                  <div className="assets_block">
                    <div className="assets_info">
                      <div className="assets_img">
                        <img
                          src={asset.images}
                          alt=""
                          onError={e => {
                            e.target.src = holderImage;
                          }}
                        />
                      </div>
                      <div className="assets_name">
                        <h4 className=" pt-0 pt-md-2">
                          {capAllWords(asset.asset_name)}
                        </h4>
                        <h5 className=" pt-0 pt-md-2">
                          Sales Price:{" "}
                          {formatCurrency.format(asset.dealSalesPrice)}
                        </h5>

                        <p>
                          <i className="fas fa-map-marker-alt pr-1" />
                          {asset.address}
                        </p>
                      </div>
                    </div>
                    <div className="assets_card_info">
                      <table className="table">
                        <tr>
                          <th className="pl-4">Cash Flow</th>
                          <th>NOI</th>
                          <th>Cash Flow (Total)</th>
                          <th>COC ROI</th>
                        </tr>
                        {/*<tr>
                          <td className="pl-4">
                            {" "}
                            {formatCurrency.format(
                              asset.stats.cashflow.currMonth
                            )}
                            {asset.stats.cashflow.currMonth >=
                            asset.stats.cashflow.prevMonth ? (
                              <ShowProfit />
                            ) : (
                              <ShowLoss />
                            )}
                          </td>
                          <td>
                            {formatCurrency.format(asset.stats.noi.currMonth)}
                            {asset.stats.noi.currMonth >=
                            asset.stats.noi.prevMonth ? (
                              <ShowProfit />
                            ) : (
                              <ShowLoss />
                            )}
                          </td>
                          <td>
                            {formatCurrency.format(asset.stats.cummCashflow)}
                          </td>
                          <td>{asset.stats.cocROI}%</td>
                        </tr>*/}
                      </table>
                    </div>
                  </div>
                </Link>
              ))}
              {/* <PostDealPlaceholder toggleShowPostDeal={this.toggleShowPostDeal}/> */}
            </div>
          </div>
        </div>
        {/*<PostDeal
          modalOpen={this.state.showPostDeal}
          toggleModal={this.toggleShowPostDeal}
        />*/}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  dealProp: state.deal
});

const mapDispatchToProps = dispatch => ({
  getDeals: query => getDeals(query, dispatch)
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Flip);
