import React from "react";
import { Col } from "reactstrap";
import PropTypes from "prop-types";
// import { bytesToSize } from "../../../helpers";
import { FileText } from "react-feather";

const PreviewFile = ({ file, asCard }) => {
  let fileUrl;
  if (file.type.includes("image")) {
    fileUrl = URL.createObjectURL(file);
  }
  if (asCard)
    return (
      <Col md="3" className="p-2" key={file.name}>
        <div
          className="p-2 bg-white shadow-xxl border-dark card-box d-flex overflow-hidden rounded-sm"
          style={{ height: 120 }}
        >
          {fileUrl ? (
            <img src={fileUrl} alt="..." />
          ) : (
            <FileText
              className="w-50 h-50"
              style={{
                position: "relative",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)"
              }}
            />
          )}
        </div>
        <span style={{ overflowWrap: "break-word" }}>{file.path}</span>
      </Col>
    );
  else
    return (
      <Col>
        <li>{file.path}</li>
      </Col>
    );
};

PreviewFile.propTypes = {
  file: PropTypes.object.isRequired
};

export default PreviewFile;
